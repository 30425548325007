import React, {
  ReactElement, useContext, useEffect, useMemo, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './reportCenterTemplate.module.css';
import { ReactComponent as DashboardImg } from '../../assets/dashBoardSubnav.svg';
import { ReactComponent as GuestImg } from '../../assets/guestSubnav.svg';
import { ReactComponent as VendorImg } from '../../assets/vendorSubnav.svg';
import { ReactComponent as ResidentImg } from '../../assets/residentSubnav.svg';
import { ReactComponent as HostImg } from '../../assets/hostSubnav.svg';
import { ReactComponent as UserSettingImg } from '../../assets/userSettingSubnav.svg';
import { ReactComponent as ReportsImg } from '../../assets/reportsSubnav.svg';
import { ReactComponent as SecurityImg } from '../../assets/securitySubnav.svg';
import GenericButton from '../genericButton/genericButton';
import { ReactComponent as DocImg } from '../../assets/fileSmall.svg';

import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';

export default function ReportCenterTemplate(props: {
  children?: ReactElement | Array<ReactElement>;
}): ReactElement {
  const { children } = props;
  const location = useLocation<any>();
  const [role, setRole] = useState<'dashboard' | 'reports' | 'guest' | 'resident' | 'vendor' | 'host' | 'user-setting' | 'security' | 'documents' | ''>('');
  const [showNav, setShowNav] = useState<boolean>(!location.state?.hideNav);
  const { communityId } = useContext(CommunityContext);
  const showDeadLinks = useMemo(() => communityId === 'palmettodunes', [communityId]);

  let hTitle = '';
  let showAddUserPrompt = false;
  let showAddDoc = false;
  let roleImgPick = <DashboardImg />;
  switch (role) {
    case 'dashboard':
      roleImgPick = <DashboardImg />;
      hTitle = 'Dashboard';
      break;
    case 'reports':
      roleImgPick = <ReportsImg />;
      hTitle = 'Reports';
      break;
    case 'guest':
      roleImgPick = <GuestImg />;
      hTitle = 'Guests';
      break;
    case 'vendor':
      roleImgPick = <VendorImg />;
      hTitle = 'Vendors';
      break;
    case 'resident':
      roleImgPick = <ResidentImg />;
      hTitle = communityId === 'grandcomplex' ? 'Owners' : 'Residents';
      break;
    case 'host':
      roleImgPick = <HostImg />;
      hTitle = communityId === 'grandcomplex' ? 'Property Manager' : 'Hosts';
      break;
    case 'security':
      roleImgPick = <SecurityImg />;
      hTitle = 'Security';
      break;
    case 'user-setting':
      roleImgPick = <UserSettingImg />;
      hTitle = 'User Settings';
      showAddUserPrompt = true;
      break;
    case 'documents':
      hTitle = 'Documents';
      showAddDoc = true;
      break;
    default:
      break;
  }
  let subNav;
  let invisiableBox;
  let drawerBar;
  let subHeader;
  let infoBox;
  let triangle;
  let icon;
  if (showNav) {
    subNav = style.subNav;
    invisiableBox = role === 'security' ? style.invisiableBoxSecurity : style.invisiableBox;
    drawerBar = style.drawerBar;
    subHeader = style.subHeader;
    infoBox = style.infoBox;
    triangle = style.triangleLeft;
    icon = style.icon;
  } else {
    subNav = style.subNavShort;
    invisiableBox = role === 'security' ? style.invisableBoxShortSecurity : style.invisableBoxShort;
    drawerBar = style.drawerBarLeft;
    subHeader = style.subHeaderWithoutNav;
    infoBox = style.infoBoxWithoutNav;
    triangle = style.triangleRight;
    icon = style.iconLeft;
  }

  useEffect(() => {
    switch (true) {
      case /dashboard/.test(location.pathname):
        setRole('dashboard');
        break;
      case /reports/.test(location.pathname):
        setRole('reports');
        break;
      case /guest/.test(location.pathname):
        setRole('guest');
        break;
      case /resident/.test(location.pathname):
        setRole('resident');
        break;
      case /host/.test(location.pathname):
        setRole('host');
        break;
      case /vendor/.test(location.pathname):
        setRole('vendor');
        break;
      case /user-settings/.test(location.pathname):
        setRole('user-setting');
        break;
      case /security/.test(location.pathname):
        setRole('security');
        break;
      case /documents/.test(location.pathname):
        setRole('documents');
        break;
      default: setRole('');
    }
  }, [location, role]);
  return (
    <div className={style.box}>
      {/* <div className={invisiableBox} /> */}

      <div className={`${subNav} darkGrey`}>
        {showDeadLinks && (
          <Link
            className={role === 'dashboard' ? style.active : ''}
            to="/admin/report-center/dashboard"
            onClick={() => setRole('dashboard')}
          >
            <div className={`${style.subNavBtn} `}>

              <div className={style.btnImg}>
                {' '}
                <DashboardImg />
              </div>

              Dashboard

            </div>
          </Link>
        )}
        {/* ============ Link to reports. Include when finished ============ */}
        {/* <Link
          className={role === 'reports' ? style.active : ''}
          to="/admin/report-center/reports"
          onClick={() => setRole('reports')}
        >
          <div className={`${style.subNavBtn} `}>
            <div className={style.btnImg}>
              {' '}
              <ReportsImg />
            </div>

            Reports

          </div>
        </Link> */}
        <Link
          className={role === 'guest' ? style.active : 'inactive'}
          to="/admin/report-center/guest"
          onClick={() => setRole('guest')}
        >
          <div className={`${style.subNavBtn} `}>
            <div className={style.btnImg}><GuestImg /></div>

            Guests

          </div>
        </Link>
        {communityId === 'grandcomplex' || communityId === 'watercolor' ? null
          : (
            <Link
              className={role === 'vendor' ? style.active : 'inactive'}
              to="/admin/report-center/vendor"
              onClick={() => setRole('vendor')}
            >
              <div className={`${style.subNavBtn} `}>
                <div className={style.btnImg}><VendorImg /></div>

                Vendors

              </div>
            </Link>
          )}
        {communityId !== 'grandcomplex'
          ? (
            <Link
              className={role === 'resident' ? style.active : ''}
              to="/admin/report-center/resident"
              onClick={() => setRole('resident')}
            >
              <div className={`${style.subNavBtn} `}>
                <div className={style.btnImg}><ResidentImg /></div>

                Residents

              </div>
            </Link>
          ) : <></>}
        <Link
          className={role === 'host' ? style.active : ''}
          to="/admin/report-center/host"
          onClick={() => setRole('host')}
        >
          <div className={`${style.subNavBtn} `}>
            <div className={style.btnImg}><HostImg /></div>

            {communityId === 'grandcomplex' ? 'Property Manager' : 'Hosts'}

          </div>
        </Link>
        {/* <Link
          className={role === 'security' ? style.active : ''}
          to="/admin/report-center/security"
          onClick={() => setRole('security')}
        >
          <div className={`${style.subNavBtn} `}>
            <div className={style.btnImg}><SecurityImg /></div>

            Security

          </div>
        </Link> */}
        <Link
          className={role === 'user-setting' ? style.active : ''}
          to="/admin/report-center/user-settings"
          onClick={() => setRole('user-setting')}
        >
          <div className={`${style.subNavBtn} `}>
            <div className={style.btnImg}><UserSettingImg /></div>

            User Settings

          </div>
        </Link>

      </div>

      <div className={`${drawerBar} mediumGrey`}>
        {' '}
        <button
          onClick={() => setShowNav(!showNav)}
          className={style.btnBox}
        >
          <div className={triangle} />
        </button>
      </div>
      <div className={`${subHeader} white`}>
        <div className={icon}>
          {' '}
          {roleImgPick}
        </div>
        <h1 className={`${style.title} textColorMain`}>
          {hTitle}
        </h1>
        <div className={style.addUserBox}>
          {
            showAddUserPrompt
              ? (
                <Link to="/admin/popup/add-user">
                  <GenericButton
                    title="Add User +"
                    color="yellow"
                    size="medium"
                  />
                </Link>
              ) : null
          }
          {showAddDoc ? (
            <Link to="/admin/popup/add-doc">
              <GenericButton title="Add Document +" color="yellow" size="medium" />
            </Link>
          ) : null}

        </div>
      </div>
      <div className={infoBox}>
        {children}
      </div>
    </div>
  );
}
ReportCenterTemplate.defaultProps = {
  children: undefined,
};
