import queryString from 'querystring';
import React, { ReactElement, useCallback, useContext } from 'react';
import {
  Link,
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { gql } from '@apollo/client';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
// import { QueryString } from '../../../common_lib_front/utilities/QueryString';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import style from './hostSearch.module.css';
import useReportCenterSearch from './useHostSearch';
import SelectMany from '../../../components/selectMany/selectMany';
import DateDropDown from '../../../components/dateDropDown/dateDropDown';
import RcDownloader from '../../../components/rcDownloader/rcDownloader';
import SearchSelector from '../../../common_lib_front/components/searchSelector/searchSelector';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';

const useRentalValidation = (communityId: string) => useCallback(async (val: string) => {
  if (val.length === 0) {
    return new Promise<string[]>((resolve) => resolve([]));
  }
  return backendClient.query<{ addressValidation: backendResponse<{ address: string }[]> }>({
    query: gql`
      query AddressValidation($address: String!) {
        addressValidation(communitySymbol: "${communityId}", address: $address) {
          success
          error
          data {
            address
            city
            state
            zip
            postalAddress
          }
        }
      }
    `,
    variables: {
      address: val,
    },
  })
    .then((res) => res.data.addressValidation.data?.map((a) => a.address) || [])
    .catch(() => []);
}, [communityId]);

export default function ReportCenterSearch(): ReactElement {
  const {
    queryParams,
    qpChangeHandler,
    searchRes,
    searchLoading,
    history,
    pageInfo,
    pageChange,
    pageValidator,
    numPerPageChange,
  } = useReportCenterSearch();
  const { communityId } = useContext(CommunityContext);
  const rentalValidation = useRentalValidation(communityId);

  return (
    <div className={style.container}>
      <form
        className={`${style.searchBox} white`}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          history.replace({
            pathname: '/admin/report-center/host/',
            hash: history.location.hash,
            search: queryString.stringify({ ...queryParams, force: !queryParams.force }),
          });
        }}
      >
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="lname-inpt"
            labelTitle="Last Name"
            inputValue={queryParams.lastName}
            changeHandler={(e) => {
              qpChangeHandler('lastName', e.target.value);
            }}
          />
          {' '}

        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="fname-inpt"
            labelTitle="First Name"
            inputValue={queryParams.firstName}
            changeHandler={(e) => {
              qpChangeHandler('firstName', e.target.value);
            }}
          />

        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="phone-inpt"
            labelTitle="Phone Number"
            inputType="tel"
            inputValue={queryParams.phoneNumber}
            changeHandler={(e) => {
              qpChangeHandler('phoneNumber', e.target.value);
            }}
          />

        </div>
        <div className={`${style.inputLong}`}>
          <InputField
            closedInput
            htmlFor="email-inpt"
            labelTitle="Email Address"
            inputValue={queryParams.email}
            changeHandler={(e) => {
              qpChangeHandler('email', e.target.value);
            }}
          />

        </div>
        <div className={`${style.inputLong} ${style.rentalAddress}`}>
          <SearchSelector
            htmlFor="rental-inpt"
            title="Rental Address"
            highLightOnFocus={false}
            value={queryParams.rentalAddress?.toString() || ''}
            hideNoMatchAlert
            changeHandler={(e) => {
              qpChangeHandler('rentalAddress', e);
            }}
            fetch={rentalValidation}
          />

        </div>
        {/* <div className={`${style.inputLong}`}>
          <InputField
            closedInput
            htmlFor="address-inpt"
            labelTitle="Mailing Address"
            inputValue={queryParams.address?.toString()}
            changeHandler={(e) => {
              qpChangeHandler('address', e.target.value);
            }}
          />
        </div> */}
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="city-inpt"
            labelTitle="City"
            inputValue={queryParams.city}
            changeHandler={(e) => {
              qpChangeHandler('city', e.target.value);
            }}
          />

        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="owner-pin-inpt"
            labelTitle="Owner Pin"
            inputValue={queryParams.ownerPin}
            changeHandler={(e) => {
              qpChangeHandler('ownerPin', e.target.value);
            }}
          />
        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="propterty-manager-inpt"
            labelTitle="Property Manager"
            inputValue={queryParams.propertyManager}
            changeHandler={(e) => {
              qpChangeHandler('propertyManager', e.target.value);
            }}
          />
        </div>
        <div className={style.inputLong}>
          <div className={style.btnBox}>
            <GenericButton
              color="yellow"
              size="medium"
              title="Search"
              type="submit"
              clickHandler={() => {
                pageInfo.pageNum = 0;
              }}
            />

          </div>

        </div>
      </form>
      <div className={style.tableContainer}>
        <div className={`${style.numInputBoxHost} white`}>
          <div className={style.filterBox}>
            {/* Overview */}
            <div className={style.selectMany}>
              <SelectMany
                id="license-select-many-inpt"
                title="Registration Status"
                values={[queryParams.licenseStatus || '']}
                options={[
                  {
                    name: 'Pending Review',
                    title: 'Pending Review',
                  }, {
                    name: 'Active',
                    title: 'Active',
                  }, {
                    name: 'Rejected',
                    title: 'Rejected',
                  }, {
                    name: 'Expired',
                    title: 'Expired',
                  }, {
                    name: 'Refunded',
                    title: 'Refunded',
                  },
                ]}
                changeHandler={(name, val) => qpChangeHandler('licenseStatus', val ? name : undefined)}
              />

            </div>

            <div className={style.selectMany}>
              <DateDropDown
                id="host-date-inputs"
                title="Application Date"
                values={[
                  queryParams.fromDate,
                  queryParams.toDate,
                ]}
                changeHandler={qpChangeHandler}
              />

            </div>
          </div>
          <div className={style.rightContent}>
            <div className={style.rcDownloaderBox}>
              <RcDownloader
                vars={queryParams}
              />
            </div>
            <label htmlFor="page-num-inpt" className={style.inputBox}>
              Show
              <select
                className={style.selectBox}
                value={pageInfo.pageSize}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  numPerPageChange(Number.parseInt(e.target.value, 10));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              Per Page
            </label>
          </div>
        </div>
        <div className={style.radiusBoxHost}>

          <table width="100%" cellSpacing="0px" className={style.tableHost}>
            <thead className={style.tHead}>
              <tr className={`${style.tableHeader}   `}>
                <th className={style.firstTh}>
                  &#160;
                </th>
                <th className={`${style.theaderItem} ${style.nameLength} textColorWhite`}>
                  Name
                </th>
                <th className={`${style.theaderItem} ${style.phoneNumberLength} textColorWhite`}>
                  Phone Number
                </th>
                <th className={`${style.theaderItem} ${style.emailLength} textColorWhite`}>
                  Email Address
                </th>
                <th className={`${style.theaderItem} ${style.mailingLength} textColorWhite`}>
                  Mailing Address
                </th>
                <th className={`${style.theaderItem} ${style.ownerpinLength} textColorWhite`}>
                  Owner Pin
                </th>
                <th className={`${style.theaderItem} ${style.cityLength} textColorWhite`}>
                  City
                </th>
                <th className={`${style.theaderItem} ${style.statusLength} textColorWhite`}>
                  Registration Status
                </th>
                <th className={`${style.theaderItem} ${style.passNumberLength} textColorWhite`}>
                  #Passes
                </th>
                <th className={`${style.theaderItem} ${style.approverLength} textColorWhite`}>
                  Approved By
                </th>
                <th className={`${style.theaderItem} ${style.dateLength} textColorWhite`}>
                  Application Date
                </th>
              </tr>
            </thead>
            <tbody>
              {
                searchLoading
                  ? (
                    <LoadingDiamonds
                      title="Loading Results"
                    />
                  ) : (
                    (searchRes || []).map((elem: any, idx: number) => (
                      <tr key={elem.userId} className={`${style.row} white`}>

                        <td className={`${style.tdItems} ${style.center}`}>
                          <Link to={`/admin/report-center/host/details/${elem.hostInfoId}`}>
                            <div className={style.detailBtnBox}>
                              <GenericButton
                                outline="small"
                                title="Details"
                              />

                            </div>
                          </Link>
                        </td>
                        <td className={style.tdItems} data-tip={`${elem.firstName || ''} ${elem.lastName || ''}`}>
                          {`${elem.firstName || ''} ${elem.lastName || ''}`}
                          <ReactTooltip />
                        </td>
                        <td className={style.tdItems}>
                          {elem.phoneNumber}
                        </td>
                        <td className={style.tdItems} data-tip={elem.email}>
                          {elem.email}
                        </td>
                        <td className={style.tdItems} data-tip={elem.address}>
                          {elem.address}
                        </td>
                        <td className={style.tdItems}>
                          {elem.ownerPin}
                        </td>
                        <td className={style.tdItems} data-tip={elem.city}>
                          {elem.city}
                        </td>
                        <td className={`${style.tdItems}`}>
                          <span className={`${style.registrationStatus} 
                        ${elem.licenseStatus === 'Pending Review' ? style.grey : ''}
                        ${elem.licenseStatus === 'Active' ? style.green : ''}
                        ${elem.licenseStatus === 'Rejected' ? style.pink : ''}
                        ${elem.licenseStatus === 'Expired' ? style.red : ''}
                        ${elem.licenseStatus === 'Refunded' ? style.orange : ''}`}
                          >
                            {elem.licenseStatus}

                          </span>
                        </td>
                        <td className={style.tdItems}>
                          {elem.numPasses}
                        </td>
                        <td className={style.tdItems}>
                          {
                            elem.approvedBy
                              ? (
                                <div className={style.approverBox}>
                                  <div className={style.circle}>
                                    {elem.approvedBy.firstName.substring(0, 1).toUpperCase()}
                                  </div>
                                  <Link to={`/admin/report-center/user-settings/details/${elem.approvedBy.userId}`} className={style.approved}>
                                    {`${elem.approvedBy.firstName} ${elem.approvedBy.lastName}`}
                                  </Link>
                                </div>
                              ) : (
                                <div className={style.approverBox}>
                                  <div className={style.circleNone}>
                                    ?

                                  </div>
                                  <p>None</p>
                                </div>
                              )
                          }
                        </td>
                        <td className={style.tdItems}>
                          {formatDate(new Date(elem.applicationDate))}
                        </td>
                      </tr>
                    )))
              }
            </tbody>
          </table>

        </div>
        <div className={style.pageBox}>
          <div>
            <GenericButton
              size="small"
              color="grey"
              title="Prev Page"
              clickHandler={() => pageChange(pageInfo.pageNum - 1)}
            />

          </div>
          <div>
            <label htmlFor="page-num-display">
              <input
                placeholder={(pageInfo.pageNum + 1).toString()}
                type="text"
                className={style.pageInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const val = Number.parseInt(e.target.value, 10) - 1;
                  if (Number.isNaN(val)) return;
                  e.target.value = (pageValidator(val) + 1).toString();
                  pageChange(val);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.target.value = '';
                }}
              />
              {`/ ${pageInfo.numPages}`}
            </label>
          </div>
          <div>
            <GenericButton
              size="small"
              color="grey"
              title="Next Page"
              clickHandler={() => pageChange(pageInfo.pageNum + 1)}
            />

          </div>
        </div>
      </div>

    </div>
  );
}
