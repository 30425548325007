import React, { forwardRef, ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { ReactComponent as ChervonDown } from '../../assets/chervonDown.svg';
import styles from './yearPicker.module.css';

interface IProps {
  year: number;
  setYear: (value: number) => void;
  disabled?: boolean
}

// Due to have not fit properties to use, we need to use this styled component library
const Wrapper = styled.div`
.react-datepicker {
  background: #FFFFFF;
  box-shadow: 0px 1px 2px 3px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  border: none;
}
.react-datepicker__year-wrapper {
  max-width: 330px;
  height: 120px;
  row-gap: 10px;
}
.react-datepicker__year-text {
  width: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #636365;
}
.react-datepicker__year-text--selected, .react-datepicker__year-text--keyboard-selected {
  color: #ffffff;
  background: #3E81D0;
}
.react-datepicker-year-header {
  background: #FFFFFF;
  border: none;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.react-datepicker__navigation *::before {
  border-color: #2E3032;
}
display: flex;
align-items: center;
background-color: #F1F1F1;
border-radius: 4px;
width: 30%;
padding: 8px;
cursor: pointer;
`;

export default function YearPicker({
  year,
  setYear,
  disabled,
}: IProps): ReactElement {
  const date = new Date(year, 1);

  let { input } = styles;
  if (disabled === true) {
    input = styles.inputDisabled;
  }

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button className={input} onClick={onClick} ref={ref}>
      {value}
      <span className={disabled ? styles.svgDisabled : styles.svg}>
        <ChervonDown />
      </span>
    </button>
  ));

  return (
    <Wrapper>
      <DatePicker
        disabled={disabled}
        selected={date}
        onChange={(d: Date) => setYear(d.getFullYear())}
        showYearPicker
        dateFormat="yyyy"
        customInput={<CustomInput />}
      />
    </Wrapper>
  );
}

YearPicker.defaultProps = {
  disabled: false,
};
