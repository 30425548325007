import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, {
  ReactElement, useState, useRef, useCallback, useMemo,
} from 'react';
import LoadingDiamonds from '../../../../components/loadingDiamonds/loadingDiamonds';
import style from './passGrid.module.css';
import PopupCellRenderer from './popUpCellRender';

interface PropsType {
  fetchedUserPasses:any[],

}
const defaultColDef = {
  sortable: true,
  resizable: true,
  flex: 1,
  wrapHeaderText: true,
};
export default function PassGrid(props: PropsType) :ReactElement {
  const gridRef = useRef<AgGridReact>(null);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const {
    fetchedUserPasses,
  } = props;

  const allNodes: any[] = [];
  gridRef.current?.api?.forEachNode((row) => {
    if (row.displayed) allNodes.push(row);
  });
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const DateRender = ({ value }: { value: string }) => (
    <div>
      {`${new Date(value).getUTCDate()} ${
        months[new Date(value).getUTCMonth()]
      }  ${new Date(value).getUTCFullYear()}`}
    </div>
  );

  const PassStatusRender = ({ value }: { value: string }) => (
    <div>
      {value.includes('delete') ? 'Deleted' : value}
    </div>
  );
  const PassTypeRender = ({ value }: { value: string }) => (
    <div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {value.includes('guest') ? 'Guest' : value.includes('host') ? 'Host' : value}
    </div>
  );
  const onBtnExport = useCallback(() => {
    const exportParams = {
      columnKeys: ['passType', 'number', 'startDate', 'endDate', 'paymentStatus', 'status'],
    };
    gridRef.current?.api.exportDataAsCsv(exportParams);
  }, []);
  const [columnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: 'Type', field: 'passType', cellRendererFramework: PassTypeRender, minWidth: 180,
    },
    { headerName: 'Name', field: 'name', minWidth: 180 },
    { headerName: 'Number', field: 'number', minWidth: 180 },
    {
      headerName: 'Start Date', field: 'startDate', cellRendererFramework: DateRender, minWidth: 180,
    },
    {
      headerName: 'Expiration Date', field: 'endDate', cellRendererFramework: DateRender, minWidth: 180,
    },
    { headerName: 'Payment Status', field: 'paymentStatus', minWidth: 180 },
    {
      headerName: 'Pass Status', field: 'status', minWidth: 180, cellRendererFramework: PassStatusRender,
    },

  ]);
  const rowData = fetchedUserPasses?.filter((ele) => ele.passType !== 'host')?.flatMap((item) => (
    {
      passType: item.passType,
      number: item.number,
      startDate: item.startDate,
      endDate: item.endDate,
      status: item.status,
      paymentStatus: item.paid,
      shared: item.shared,
      name: item.name,
      actions: item,
    // download: item,
    }));
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: 'calc(100vh - 500px)', width: '100%', boxSizing: 'border-box', padding: '20px',
      }}
    >
      {!fetchedUserPasses ? (
        <div className={style.loadingBox}>
          <LoadingDiamonds
            title="Loading Company Documents"
          />
        </div>
      ) : (
        <>
          <h2 className="">Pass Information</h2>
          <div className={style.topLine}>
            <input
              type="search"
              className={style.gridSearch}
              onChange={onFilterTextChange}
              placeholder="Search anything here .."
            />
            <div className={style.topLineRight}>
              <label htmlFor="page-num-inpt">
                Show
                <select
                  className={style.selectBox}
                  value={itemsPerPage}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setItemsPerPage(Number.parseInt(e.target.value, 10));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                Per Page
              </label>
              <button className={style.btnExport} onClick={onBtnExport}>Export CSV</button>
            </div>

          </div>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            enableCellTextSelection
            ensureDomOrder
            animateRows
            pagination
            ref={gridRef}
            paginationPageSize={itemsPerPage}
            defaultColDef={defaultColDef}
          />
        </>
      )}

    </div>
  );
}
