import React, { ReactElement, useState, useRef } from 'react';
import { ReactComponent as Alarm } from '../../assets/alarm.svg';
import useOnClickOutside from '../../hooks/useClickOutside';
import styles from './timePicker.module.css';

interface IProps {
  time: Date;
  setTime: (date: Date) => void;
  disabled?:boolean
}

export default function TimePicker({
  time,
  setTime,
  disabled,
}: IProps): ReactElement {
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
    14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  const minutes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popupRef = useRef();
  useOnClickOutside(popupRef, () => setIsOpen(false));
  const [hour, setHour] = useState<number>(time.getHours());
  const [minute, setMinute] = useState<number>(time.getMinutes());

  const formatNumber = (value: number) => value.toString().padStart(2, '0');

  const onConfirm = (e: any) => {
    e?.preventDefault();
    setTime(new Date(2000, 1, 1, hour, minute));
    setIsOpen(false);
  };
  let { input } = styles;
  if (disabled === false) {
    input = styles.inputDisabled;
  }

  const onCancel = (e: any) => {
    e?.preventDefault();
    setIsOpen(false);
  };

  return (
    <div className={styles.container}>
      <button onClick={() => setIsOpen(true)} className={input}>
        <div>
          {formatNumber(time.getHours())}
          :
          {formatNumber(time.getMinutes())}
        </div>
        <Alarm />
      </button>
      {disabled && isOpen && (
        <div className={styles.popup} ref={popupRef as any}>
          <div className={styles.dropdowns}>
            <div>
              <span className={styles.title}>Hour</span>
              <div className={styles.dropDownBox}>
                {hours.map((h) => (
                  <button
                    key={h}
                    className={`${styles.button} ${h === hour ? styles.active : ''}`}
                    onClick={() => setHour(h)}
                  >
                    {formatNumber(h)}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <span className={styles.title}>Minute</span>
              <div className={styles.dropDownBox}>
                {minutes.map((m) => (
                  <button
                    key={m}
                    className={`${styles.button} ${m === minute ? styles.active : ''}`}
                    onClick={() => setMinute(m)}
                  >
                    {formatNumber(m)}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <button onClick={onCancel} className={styles.cancelButton}>Cancel</button>
            <button onClick={onConfirm} className={styles.confirmButton}>Confirm</button>
          </div>
        </div>
      )}
    </div>
  );
}

TimePicker.defaultProps = {
  disabled: false,
};
