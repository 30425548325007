import { gql, useMutation, useQuery } from '@apollo/client';
import React, {
  ReactElement, useRef, useState, useCallback, useEffect,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { AgGridReact } from 'ag-grid-react';
import { PopUp } from '../../../components/popUp/popUp';
import style from './passDetail.module.css';
import UsePassDetails from './usePassDetails';
import PopupCellRenderer from './popUpCellPassMenu';

import { ReactComponent as ActionImg } from '../../../assets/actionIcon.svg';

export default function AddDoc(): ReactElement {
  const history = useHistory();
  const {
    userPassInfo,
    userVehicleInfo,
  } = UsePassDetails();
  const myInputRef = useRef<HTMLInputElement[]>([]);
  myInputRef.current = [];
  function showDetails(passType: any) {
    switch (passType) {
      case 'vendor':
        return 'Company Name';
      case 'resident':
        return 'Resident Name';
      case 'guest':
        return 'Property Manager';
      case 'visitor':
        return 'Property Owner';
      default:
        return 'Company Name';
    }
  }
  const EditDriver = () => (
    // TODO : update redirection for edit driver

    // <Link to="/admin/report-center/vendor/details/">
    <div className={style.actionBox}>
      <button className={style.actionBtn}>
        <p>Edit</p>
        <ActionImg />
      </button>
    </div>
    // </Link>
  );

  const EditVehicle = () => (
    // TODO : update redirection for edit vehicle

    // <Link to="/admin/report-center/vendor/details/">
    <div className={style.actionBox}>
      <button className={style.actionBtn}>
        <p>Edit</p>
        <ActionImg />
      </button>
    </div>
    // </Link>
  );
  const [passInfoColumnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
      width: 160,
    },
    {
      headerName: 'Pass Number',
      field: 'number',
      width: 142,
    },
    {
      headerName: 'Pass Type',
      field: 'passType',
      width: 142,
    },
    {
      headerName: 'Community Address',
      field: 'communityAddress',
      width: 142,
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      width: 142,
    },
    {
      headerName: 'Valid Through',
      field: 'endDate',
      width: 142,
    },
    {
      headerName: 'Payment Status',
      field: 'paid',
      width: 142,
    },
    {
      headerName: 'Pass Status',
      field: 'status',
      width: 142,
    },
    // {
    //   headerName: '2d credentials',
    //   field: '',
    // },

  ]);
  const [driverInfoColumnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRendererFramework: EditDriver,
      editable: false,
      sortable: false,
      filter: false,
      width: 160,
    },
    {
      headerName: 'Name',
      field: 'primaryDriverName',
      width: 142,
    },
    {
      headerName: 'Phone Number',
      field: 'phoneNumber',
      width: 142,
    },
    {
      headerName: 'Email',
      field: 'email',
      width: 142,
    },
    {
      headerName: 'Driver`s License',
      field: 'licensePrimaryDriver',
      width: 142,
    },
    {
      headerName: 'Driver`s License state',
      field: 'licensePlateState',
      width: 142,
    },
    {
      headerName: 'DL Expiration',
      field: 'driversLicenseExp',
      width: 142,
    },
    {
      headerName: 'Personal Credentials',
      field: '',
      width: 142,
    },

  ]);

  const [vehicleInfoColumnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRendererFramework: EditVehicle,
      //   cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
      width: 160,
    },
    {
      headerName: 'License Plate',
      field: 'licensePlate',
      width: 130,
    },
    {
      headerName: 'Make',
      field: 'make',
      width: 130,
    },
    {
      headerName: 'Model',
      field: 'vehicleModel',
      width: 130,
    },
    {
      headerName: 'Color',
      field: 'color',
      width: 130,
    },
    {
      headerName: 'Year',
      field: 'year',
      width: 130,
    },

    {
      headerName: 'Plate State',
      field: 'licensePlateState',
      width: 130,
    },
    // {
    //   headerName: 'Pass category',
    //   field: 'passCategory',
    // },
    // {
    //   headerName: 'Fleet Number',
    //   field: 'fleetNumber',
    // },
    {
      headerName: 'Vehicle Credentials',
      field: 'vehicleCred',
    },

  ]);
  return (
    <PopUp title="Pass Detail " close={() => history.goBack()}>
      <div className={style.box}>
        <div className={style.passNumber}>
          {`${userPassInfo?.number || ''}`}
        </div>
        {/* <div className="info">
          <span>
            {userPassInfo ? showDetails(userPassInfo?.passType) : 'Company Name'}
            :
          </span>
          <span style={{ marginLeft: '10px' }}>{userPassInfo?.companyInfo?.companyName}</span>
        </div> */}
        <div className={style.imgBox}>
          <img
            height={150}
            width={250}
            src={userVehicleInfo.driversLicenseImageUrl}
            alt="Not Available"
            className={style.icon}
          />
        </div>
        <div className={style.passInfos}>
          <div>
            <p className={style.subHeading}>
              Pass Info  :
            </p>
            <div
              className="ag-theme-alpine"
              style={{
                height: '120px',
                width: '1200px',
              }}
            >
              <AgGridReact
                rowData={ [{
                  ...userPassInfo, communityAddress: userVehicleInfo?.destination || '', endDate: new Date(userPassInfo.endDate).toDateString(), startDate: new Date(userPassInfo.startDate).toDateString(),
                }] }
                columnDefs={ passInfoColumnDefs }
              />
            </div>
          </div>

        </div>
        <div className={style.passInfo}>
          <p className={style.subHeading}>Driver Info  :</p>
          <div
            className="ag-theme-alpine"
            style={{
              height: '120px',
              width: '1200px',
            }}
          >
            <AgGridReact
              rowData={ [{
                ...userVehicleInfo,
              }] }
              columnDefs={ driverInfoColumnDefs }
            />
          </div>
        </div>
        <div className={style.passInfo}>
          <p className={style.subHeading}>Vehicle Info  :</p>
          <div
            className="ag-theme-alpine"
            style={{
              height: '120px',
              width: '1200px',
            }}
          >
            <AgGridReact
              rowData={ [{ ...userVehicleInfo }] }
              columnDefs={ vehicleInfoColumnDefs }
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
}
