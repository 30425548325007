import queryString from 'querystring';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { vehicleTypeOptions, vehicleTypeOptionsArray } from '../../../common_lib_front/types/vehicleInfo';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import style from './vehicleSearch.module.css';

export interface IFilter {
  licensePlate?: string;
  color?: string;
  type?: vehicleTypeOptions | null;
  make?: string;
}

export default function VehicleSearch(): ReactElement {
  const [filters, setFilters] = useState<IFilter>({});
  const { search: urlSearch } = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (!urlSearch) {
      return;
    }
    const parsedSearch = queryString.parse(urlSearch.replace(/\?/, ''));
    setFilters(parsedSearch as IFilter);
  }, []);

  const changeFilter = (name: keyof IFilter, value: any) => setFilters((prev) => ({
    ...prev,
    [name]: value,
  }));

  return (
    <div className={style.vehicleSearchBox}>
      <div className={style.infoBox}>
        <p className={style.title}>License Plate Number</p>
        <p className={style.subTitle}>
          Please enter the License Plate Number or Full Vehicle Description
        </p>
        <div className={style.inputArea}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="license-plate-inpt"
              labelTitle="License Plate"
              inputValue={filters.licensePlate}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeFilter('licensePlate', e.target.value);
              }}
            />
          </div>
          <p className={style.or}>-Or-</p>
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="vehicle-color-inpt"
              labelTitle="Vehicle Color"
              inputValue={filters.color}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeFilter('color', e.target.value);
              }}
            />
          </div>
          <div className={style.inputLong}>
            <label htmlFor="vehicle-type-inpt">
              Vehicle Type
              <select
                id="vehicle-type-inpt"
                className={style.input}
                value={filters.type as any}
                onChange={(e: any) => {
                  changeFilter('type', e.target.value);
                }}
              >
                <option>{null}</option>
                {
                  vehicleTypeOptionsArray.map((v) => (
                    <option>{v}</option>
                  ))
                }
              </select>
            </label>
          </div>
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="vehicle-make-inpt"
              labelTitle="Vehicle Make"
              inputValue={filters.make}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeFilter('make', e.target.value);
              }}
            />
          </div>
          <p className={style.or}>-Or-</p>
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="assign-pass-barcode-inpt"
              labelTitle="Assigned Pass Barcode"
            />
          </div>

        </div>
      </div>
      <div className={style.genericSubmitBtn}>
        <div className={style.genericSubmitBtnInnerBox}>
          <GenericButton
            color="blue"
            size="large"
            title="Submit"
            type="submit"
            clickHandler={() => {
              // Save the search params
              history.replace({
                pathname: history.location.pathname,
                search: queryString.stringify(filters as any),
              });
              history.push({ pathname: '/admin/popup/search-vehicle', search: queryString.stringify(filters as any) });
            }}
          />
        </div>
      </div>
    </div>
  );
}
