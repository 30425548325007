import React, { ReactElement, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { gql } from '@apollo/client';
import PassInfo, { passStatusMapTitles, paymentStatusMapTitles } from '../../../common_lib_front/types/passInfo';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import ActionsBtn from '../../../components/actionsBtn/actionsBtn';
import GenericAlert from '../../../components/genericAlert/genericAlert';
import GenericButton from '../../../components/genericButton/genericButton';
import InfoStection from '../../../components/infoSection/infoSection';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import { PopUp } from '../../../components/popUp/popUp';
import ReportTable from '../../../components/reportTable/reportTable';
import style from './reportCenterDetails.module.css';
import useGetPasses from './useGetPasses';
import useReportCenterDetails from './useReportCenterDetails';
import useParkingPass from '../../../hooks/useParkingPass';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const REINVITE = gql`
mutation Mutation($registrationId: String!, $email: String, $phoneNumber: String) {
  reinviteGuest(registrationId: $registrationId, email: $email, phoneNumber: $phoneNumber) {
    error
    success
  }
}
`;
type REINVITE_VARS = {
  registrationId: string;
  email?: string;
  phoneNumber?: string;
}
type REINVITE_RES = {
  reinviteGuest: backendResponse<undefined>;
}

export default function ReportCenterDetails(): ReactElement {
  const {
    userData,
    userLoading,
    userError,
    reportData,
    reportLoading,
    reportError,
    employeeData,
    employeeLoading,
    employeeError,
    documentInfoData,
    passTypeLoading,
    passTypeError,
    documentData,
    documentLoading,
    documentError,
    role,
    userId,
    currentTable,
    history,
    guestHidden,
    vendorHidden,
    residentHidden,
    tableHiddenVehicle,
    tableHiddenEmployee,
    tableHiddenCVI,
  } = useReportCenterDetails();
  const {
    data: passData,
    loading: passLoading,
    error: passError,
    pageNum: passPageNum,
    setPage: passSetPage,
    numberPages,
  } = useGetPasses(userId);
  const { doGetParkingPass } = useParkingPass({
    autoNavToPass: true,
  });

  const [currentDocumentInfo, setCurrentDocumentInfo] = useState<string>('');
  const [currenPassInfo, setCurrentPassInfo] = useState<PassInfo>();
  const [redirect, setRedirect] = useState<string>('');
  const viewDocument = (url: string) => {
    window.open(url);
  };

  if (!currentTable) {
    return (
      <Redirect to={`/admin/report-center/${role}/details/${userId}/vehicles-passes`} />
    );
  }
  if (userLoading || passTypeLoading || documentLoading) {
    return (
      <LoadingDiamonds
        title="Getting User..."
      />
    );
  }

  const handleSelectPassPaid = (event: React.ChangeEvent<HTMLSelectElement>, value: PassInfo) => {
    event.preventDefault();
    if (event.target.value === 'paid') {
      setCurrentPassInfo(value);
      setRedirect('/admin/popup/mark-paid');
    }
  };

  const passStatusStyleMap: { [key: string]: string } = {
    active: style.passStatusActive,
    expired: style.passStatusExpired,
    inactive: style.passStatusInactive,
    incomplete: style.passStatusIncomplete,
    refunded: style.passStatusRefunded,
    default: style.passStatusDefault,
  };

  if (redirect) {
    history.push({
      pathname: redirect,
      state: currenPassInfo,
    });
    // return (
    //   <Redirect to={{ pathname: redirect, state: currenPassInfo }} />
    // );
  }
  const backToReport = () => {
    if (role === 'guest') {
      history.replace('/admin/report-center/guest');
    }
    if (role === 'vendor') {
      history.replace('/admin/report-center/vendor');
    }
    if (role === 'resident') {
      history.replace('/admin/report-center/resident');
    }
  };

  const vendorPassStatusTitleMap: typeof passStatusMapTitles = {
    ...passStatusMapTitles,
    'incomplete-rental-car': 'Incomplete',
  };

  return (
    <div className={style.container}>
      {currentDocumentInfo && documentData && (
        <div className={style.localPopUp}>
          <PopUp
            title={`Documents - ${currentDocumentInfo}`}
            close={() => setCurrentDocumentInfo('')}
          >
            <ul className={style.documentList}>
              {documentData.filter((item) => item.documentName === currentDocumentInfo)
                .map((item) => (
                  <li className={style.documentItem}>
                    <a href={item.url}>
                      {item.fileName}
                    </a>
                  </li>
                ))}
              {documentData.filter(
                (item) => item.documentName === currentDocumentInfo,
              ).length === 0 && (
                  // eslint-disable-next-line react/jsx-indent
                  <li className={style.documentItem}>
                    <i>Empty</i>
                  </li>
                  // eslint-disable-next-line indent
                )}
            </ul>
          </PopUp>
        </div>
      )}

      <div className={style.flexBox}>
        <div className={`${style.infoBox} white`}>
          <GenericAlert
            title={userError}
            hidden={!userError}
            color="red"
          />
          <button
            className={style.exitBtn}
            onClick={() => backToReport()}
          >
            &#160;
          </button>
          <h1 hidden={!['guest', 'resident'].includes(role)} className={`${style.infoTitle} textColorSuperDark`}>
            {`${userData.firstName || ''} ${userData.lastName || ''}`}
          </h1>
          <h1 hidden={role !== 'vendor'} className={`${style.infoTitle} textColorSuperDark`}>
            {userData.companyName}
          </h1>
          <div className={style.infoSectionBox}>
            <div className={`${style.section} ${vendorHidden} ${residentHidden} `}>
              <InfoStection
                editable={`/admin/popup/edit-user/${userId}`}
                hidden={role !== 'guest'}
                title="Guest Information"
                data={{
                  NAME: `${userData.firstName || ''} ${userData.lastName || ''}`,
                  // 'ARRIVAL DATE': '09/12/2020',
                  // 'DEPARTURE DATE': '09/12/2021',
                  'PHONE #': userData.phoneNumber || '',
                  EMAIL: userData.email || '',
                  'VEHICLES #': userData.numberOfVehicles || '',
                  // 'CURRENT RENTAL ADDRESS': '2081 North Logan',
                }}
              />

            </div>
            <div className={`${style.section} ${guestHidden} ${residentHidden}`}>
              <InfoStection
                // editable="/admin/popup/edit-user"
                hidden={role !== 'vendor'}
                title="Company Information"
                data={{
                  'COMPANY NAME': userData.companyName,
                  NAME: `${userData.firstName} ${userData.lastName}`,
                  ADDRESS: userData.address,
                  'PHONE #': userData.businessPhoneNumber,
                  'EMERGENCY #': userData.emergencyPhone,
                  EMAIL: userData.email,
                  'ASSIGNED BAR CODE': userData.barCodes?.join(', '),
                }}
              />

            </div>
            <div className={`${style.section} ${guestHidden} ${vendorHidden}`}>
              <InfoStection
                editable={`/admin/popup/edit-user/${userId}`}
                hidden={role !== 'resident'}
                title="Resident Information"
                data={{
                  NAME: `${userData.firstName} ${userData.lastName}`,
                  'PHONE #': userData.phoneNumber,
                  EMAIL: userData.email,
                  'VEHICLES #': userData.numberOfVehicles,
                }}
              />
            </div>
            <div className={`${style.section} ${guestHidden} ${residentHidden}`}>
              <InfoStection
                // editable="/admin/popup/edit-user"
                hidden={role !== 'vendor'}
                title="Company Documents"
                data={documentInfoData || {}}
                dataMapper={(obj) => (k, idx) => (
                  <div key={k} className={style.listItemBox}>
                    <ul className={style.circleBox}><div className={`${obj[k] === 't' ? 'green' : 'red'} ${style.circle}`} /></ul>
                    <button
                      className={style.listContent}
                      onClick={() => setCurrentDocumentInfo(k)}
                    >
                      {k}
                    </button>
                  </div>
                )}
              />

            </div>
            {/* <div className={`${style.section} `}>
              <InfoStection
                title="Education Completion"
                data={process.env.REACT_APP_DEBUG === 'true'
                  ? educationRecords : {}}
                dataMapper={(obj) => (k, idx) => (
                  <div key={k} className={style.listItemBoxEdu}>
                    <ul className={style.listTitle}>{k}</ul>
                    <li className={`${obj[k] === 'COMPLETE' ? '
                    textGreen' : 'textRed'} ${style.listContent}`}>
                      {obj[k]}
                    </li>
                  </div>
                )}
              />
            </div> */}

          </div>
        </div>
        <div>
          <div className={`${style.tabBox} white `}>
            <div className={`${style.tab} ${guestHidden} ${residentHidden} ${currentTable === 'employees' ? style.active : ''}`}>
              <Link hidden={role !== 'vendor'} to={`/admin/report-center/${role}/details/${userId}/employees`} className={`${style.tabTitle}`}>
                Employees
              </Link>
              <div className={style.tabLine} />
            </div>
            <div className={`${style.tab} ${currentTable === 'vehicles-passes' ? style.active : ''}`}>
              <Link to={`/admin/report-center/${role}/details/${userId}/vehicles-passes`} className={`${style.tabTitle}`}>
                Vehicles/Passes
              </Link>
              <div className={style.tabLine} />
            </div>
            {/* <div className={`${style.tab} ${guestHidden} ${vendorHidden}
             ${currentTable === 'pets' ? style.active : ''}`}>
              <Link hidden=
              {role !== 'resident'} to={`/admin/report-center/${role}/details/${userId}/pets`}
              className={`${style.tabTitle}`}>
                Pets
              </Link>
              <div className={style.tabLine} />
            </div> */}
            {/* <div className={`${style.tab} ${currentTable ==
            = 'complaints' ? style.active : ''}`}>
              <Link to={`/admin/report-center/${role}/details/${userI
                d}/complaints`} className={`${style.tabTitle}`}>
                Complaints/Violations/Incidents
              </Link>
              <div className={style.tabLine} />
            </div> */}
          </div>
          <div className={style.tablesBox}>
            <div className={`${tableHiddenCVI} ${tableHiddenEmployee}`}>
              <ReportTable
                hidden={currentTable !== 'vehicles-passes'}
                withPagination
                pageNum={passPageNum + 1}
                setPage={passSetPage}
                numPages={numberPages}
                headers={[
                  '',
                  'Make',
                  'Vehicle Type',
                  'Color',
                  'License Plate',
                  'Pass Number',
                  'Pass Type',
                  'Pass Status',
                  'Start Date',
                  'Valid Through',
                  'Payment Type',
                  'Payment Status',

                ]}
                data={passData || []}
                loading={passLoading}
                error={passError}
                mapper={(elem, idx) => (
                  <tr>
                    <ActionsBtn idx={idx || 0}>
                      <div className={`${style.actionBox} white`}>
                        <Link to={{
                          pathname: '/admin/popup/more-details',
                          state: {
                            passInfo: elem,
                            role,
                          },
                        }}
                        >
                          <button className={style.actionBtn}>
                            More Details
                          </button>
                        </Link>
                        <Link to={{
                          pathname: `/admin/popup/edit-pass/${elem.passId}`,
                          state: {
                            passInfo: elem,
                            role,
                          },
                        }}
                        >
                          <button className={style.actionBtn}>
                            Edit
                          </button>
                        </Link>
                        <Link to={{
                          pathname: '/admin/popup/share-pass',
                          state: elem,
                        }}
                        >
                          <button className={style.actionBtn}>
                            Share
                          </button>
                        </Link>
                        <Link to={{
                          pathname: '/admin/popup/view-pass',
                          state: {
                            passInfo: elem,
                            role,
                          },
                        }}
                        >
                          <button className={style.actionBtn}>
                            View/Download
                          </button>
                        </Link>
                        {elem.passId && (
                          <button
                            className={style.actionBtn}
                            onClick={() => doGetParkingPass(elem.passId)}
                          >
                            Print Parking Pass
                          </button>
                        )}
                        {role === 'guest' && (
                          <button
                            className={style.actionBtn}
                            onClick={() => backendClient.mutate<
                              REINVITE_RES, REINVITE_VARS
                              >({
                                mutation: REINVITE,
                                variables: {
                                  registrationId: elem.registrationId,
                                  email: userData?.email,
                                  phoneNumber: userData?.phoneNumber,
                                },
                              })
                              .then((d) => {
                                if (d.data?.reinviteGuest.success) {
                                  window.alert('Reinvite has been sent');
                                } else {
                                  window.alert(d.data?.reinviteGuest.error || 'Reinvite could not been sent');
                                }
                              })
                              .catch((e) => {
                                window.alert('Reinvite could not been sent');
                                console.error(e);
                              })}
                          >
                            Reinvite
                          </button>
                        )}
                        <Link to={{
                          pathname: `/admin/popup/refund-pass/${elem.passId}`,
                          state: elem,
                        }}
                        >
                          <button className={style.actionBtn}>
                            Refund
                          </button>
                        </Link>
                        <Link to={{
                          pathname: `/admin/popup/delete-pass/${elem.passId}`,
                          state: elem,
                        }}
                        >
                          <button className={style.actionBtn}>
                            Delete
                          </button>
                        </Link>
                      </div>
                    </ActionsBtn>
                    <td className={style.tdItem} data-tip={elem.make || ''}>
                      {' '}
                      <ReactTooltip />
                      {elem.make || ''}
                    </td>
                    <td className={style.tdItem} data-tip={elem.type || ''}>{elem.type || ''}</td>
                    <td className={style.tdItem} data-tip={elem.color || ''}>{elem.color || ''}</td>
                    <td className={style.tdItem} data-tip={elem.licensePlate}>
                      {elem.licensePlate}
                    </td>
                    <td className={style.tdItem} data-tip={elem.number || ''}>{elem.number || ''}</td>
                    <td className={style.tdItem} data-tip={elem.passType}>{elem.passType}</td>
                    {role === 'vendor'
                      ? (
                        <td>
                          <span className={`${passStatusStyleMap[elem.passStatus] || passStatusStyleMap.default} textColorWhite`}>
                            {vendorPassStatusTitleMap[elem.passStatus] || elem.passStatus}
                          </span>
                        </td>
                      ) : (
                        <td>
                          <span className={`${passStatusStyleMap[elem.passStatus] || passStatusStyleMap.default} textColorWhite`}>
                            {passStatusMapTitles[elem.passStatus] || elem.passStatus}
                          </span>
                        </td>
                      )}
                    <td>
                      {formatDate(new Date(elem.startDate))}
                    </td>
                    <td>
                      {formatDate(new Date(elem.endDate))}
                    </td>
                    <td className={style.tdItem}>{elem.paymentType}</td>
                    <td>
                      {
                        elem.paid === 'unpaid' ? (
                          <select
                            className={style.passPaymentStatus}
                            onChange={(event) => handleSelectPassPaid(event, elem)}
                          >
                            <option className={style.optionPaymentStatus} hidden value="unpaid">
                              {paymentStatusMapTitles.unpaid}
                            </option>
                            <option className={style.optionPaymentStatus} value="paid">
                              {paymentStatusMapTitles.paid}
                            </option>
                          </select>
                        ) : (
                        // TODO styles for other payment statuses
                          <p className={`${elem.paid === 'paid' && style.paid}
                          ${elem.paid === 'refunded' && style.refunded}
                          ${elem.paid === 'no-charge' && style.noCharge}`}
                          >
                            {paymentStatusMapTitles[elem.paid] || ''}
                          </p>
                        )
                      }
                    </td>
                  </tr>
                )}
              />

            </div>
            <div className={`${tableHiddenVehicle} ${tableHiddenEmployee} `}>
              <ReportTable
                hidden={currentTable !== 'complaints'}
                headers={[
                  '',
                  'Submitted By',
                  'Role',
                  'Concerning',
                  'Type',
                  'Description',
                  'Date',
                  'Time',
                  'Fine Ammount',

                ]}
                data={reportData || []}
                loading={reportLoading}
                error={reportError}
                // data={[1, 1, 1, 1, 1, 1]}
                mapper={(elem) => (
                  <tr>
                    <td className={style.stickyTd}>
                      <div className={style.flexDiv}>
                        <Link to={{
                          pathname: '/admin/popup/view-complaint',
                          state: elem,
                        }}
                        >
                          <div className={style.detailsBtn}>
                            <GenericButton
                              outline="small"
                              title="Details"
                            />

                          </div>
                        </Link>
                        <span className={style.delete}>
                          <GenericButton
                            icon="delete"
                            title=""
                          />

                        </span>

                      </div>
                    </td>
                    <td>Henry Tate</td>
                    <td>Guest</td>
                    <td>Mike Young</td>
                    <td>Violation</td>
                    <td>noisy neighbors</td>
                    <td>10/10/2020</td>
                    <td>10:10</td>
                    <td>$20</td>

                  </tr>
                )}
              />

            </div>
            <div className={`${tableHiddenCVI} ${tableHiddenVehicle}`}>
              <ReportTable
                hidden={currentTable !== 'employees'}
                headers={[
                  '',
                  'Last Name',
                  'First Name',
                  'phone Number',
                  'Email',

                ]}
                data={employeeData || []}
                loading={employeeLoading}
                error={employeeError}
                mapper={(elem) => (
                  <tr>
                    <td className={style.stickyTd}>
                      <Link to={{
                        pathname: `/admin/popup/delete-employee/${elem.employeeId}/${userId}`,
                      }}
                      >
                        <GenericButton
                          icon="delete"
                          title=""
                        />
                      </Link>
                    </td>
                    <td>{elem.lastName}</td>
                    <td>{elem.firstName}</td>
                    <td>{elem.phoneNumber}</td>
                    <td>{elem.email}</td>

                  </tr>
                )}
              />

            </div>
            {/* <ReportTable
              hidden={currentTable !== 'pets'}
              headers={[
                'Pet Type',
                'Name',
                'Collar ID',
                'Owner',
                '',
                '',
              ]}
              data={[1, 1, 1, 1]}
              mapper={(elem) => (
                <tr>
                  <td>Dog (Large, Brown)</td>
                  <td>Berry</td>
                  <td>123123</td>
                  <td>Taylor James</td>
                  <td>
                    <GenericButton
                      outline="small"
                      title="Details"
                    />
                  </td>
                  <td>
                    <GenericButton
                      icon="delete"
                      title=""
                    />
                  </td>
                </tr>
              )}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
