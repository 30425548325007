import React, { ReactElement, useState } from 'react';
import GenericButton from '../genericButton/genericButton';
import style from './fullInfoPopup.module.css';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';

export default function FullInfoPopup(props: {
    title:string
    infoTitle:string
    content:string
    id:string
  }) :ReactElement {
  const {
    title, infoTitle, content, id,
  } = props;

  //   const [open, setOpen] = useState<boolean>(false);

  const {
    open, setOpen, containerId,
  } = useContainerBlur(`${id}-container`);

  let buttonStatus;
  if (open) {
    buttonStatus = style.buttonActive;
  }
  if (!open) {
    buttonStatus = style.button;
  }

  return (
    <div className={style.infoBox} id={containerId}>
      <div className={buttonStatus}>
        <GenericButton
          title={title}
          clickHandler={() => setOpen(!open)}
        />

      </div>
      {
        open
          ? (
            <div className={style.dropDownBox}>
              <div className={style.triangle} />
              <h4 className={style.infoTitle}>{infoTitle}</h4>
              <div className={style.content}>{content}</div>

            </div>
          ) : null
      }
    </div>
  );
}
