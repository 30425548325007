import React, { ReactElement, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import style from './searchPasses.module.css';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import VendorPassGrid from '../../../components/vendorPassGrid/vendorPassGrid';

const SEARCH_VENDOR_PASSES = gql`
query SearchVendorPasses(
  $pageNumber: Float!, 
  $pageSize: Float!, 
  $companyName: String, 
  $driverName: String, 
  $number: Float, 
  $startDate: DateTime, 
  $endDate: DateTime
) {
  searchVendorPasses(
    pageNumber: $pageNumber, 
    pageSize: $pageSize, 
    companyName: $companyName, 
    driverName: $driverName, 
    number: $number, 
    startDate: $startDate, 
    endDate: $endDate
  ) {
    success
    error
    numberPages
    data {
      passId
      number
      url
      startDate
      endDate
      userId
      passType
      paid
      status
      vehicle {
        make
        licensePlate
        fleetNumber
        licensePrimaryDriver
        licensePlateState
        primaryDriverName
        destination
        color
      }
      companyInfo {
        companyName
      }
      passInfo {
        name
        priceInfo {
          price
        }
      }
    }
  }
}
`;

export default function ReportCenterSearch(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const { loading, data } = useQuery(SEARCH_VENDOR_PASSES, {
    variables: {
      pageNumber: 0,
      pageSize: 500,
    },
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
  });

  return (
    <div className={style.container}>
      <div className={style.tableContainer}>
        <GenericAlert
          color={alertColor}
          title={alert}
          hidden={!alert}
        />

        {loading ? (
          <div className={style.loadingBox}>
            <LoadingDiamonds
              title="Loading Company Documents"
            />
          </div>
        ) : (
          <VendorPassGrid
            data={data?.searchVendorPasses.data}
          />
        )}
      </div>
    </div>
  );
}
