import React, { ReactElement } from 'react';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import style from './complaint.module.css';

export default function Complaint(): ReactElement {
  return (
    <div className={style.violationIncidentBox}>
      {/* main input infor */}
      <div className="content">
        <div className={style.mainInfor}>
          {/* First row */}
          <div className={style.firstInfor}>
            <p className={style.title}>Complaint Information</p>
            <div className={style.inputsContent}>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="date-complaint-inpt"
                    labelTitle="Date of Complaint"
                  />
                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="time-complaint-inpt"
                    labelTitle="Time of Complaint"
                  />

                </div>
              </div>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="type-complaint-inpt"
                    labelTitle="Complaint Type"
                  />
                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="location-violation-inpt"
                    labelTitle="Location"
                  />
                </div>
              </div>
            </div>
            <div className={style.textAreaInput}>
              <label
                htmlFor="role-inpt"
                className={style.labelBox}
              >
                <p className={style.noteTitle}>Notes</p>
                <textarea className={style.textArea}>
                  demo text
                </textarea>
              </label>
            </div>
            <div className={style.genericBtn}>
              <div className={style.genericBtnInnerBox}>
                <GenericButton
                  color="yellow"
                  size="large"
                  title="Upload Photos"
                  type="submit"
                />
              </div>
            </div>

          </div>
          {/* Second row */}
          <div className={style.secondInfor}>
            {/*  eslint-disable-next-line react/no-unescaped-entities */}
            <p className={style.title}>Offender's Information</p>
            <div className={style.inputsContent}>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="name-inpt"
                    labelTitle="Name"
                  />
                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="address-inpt"
                    labelTitle="Address"
                  />

                </div>
              </div>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="phone-inpt"
                    labelTitle="Phone Number"
                  />
                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="lience-inpt"
                    labelTitle="Lience Plate"
                  />
                </div>
              </div>
            </div>
            {/* button search */}
            <div className={style.genericBtn}>
              <div className={style.genericBtnInnerBox}>
                <GenericButton
                  color="blue"
                  size="large"
                  title="Search"
                  type="submit"
                />
              </div>
            </div>
            <p className={style.or}>-Or-</p>
            <div className={style.genericBtn}>
              <div className={style.genericBtnInnerBox}>
                <GenericButton
                  color="yellow"
                  size="large"
                  title="Add to Registery"
                  type="submit"
                />
              </div>
            </div>

          </div>
        </div>
        {/* Other submit */}
        <div>
          <p className={style.submitTitle}>Submitted By</p>
          <div className={` otherSubmit ${style.other}`}>

            <div className={style.inputOtherRow}>
              <div className={style.inputLong}>
                <InputField
                  closedInput
                  htmlFor="lience-inpt"
                  labelTitle="Name"
                />
              </div>
              <div className={style.inputLong}>
                <InputField
                  closedInput
                  htmlFor="lience-inpt"
                  labelTitle="Lience Plate"
                />
              </div>
              <div className={style.btnSearch}>
                {/* Search Icon */}
                <div className={style.searchIcon} />
              </div>

            </div>

          </div>
        </div>
        <div className={style.genericSubmitBtn}>
          <div className={style.genericSubmitBtnInnerBox}>
            <GenericButton
              color="blue"
              size="large"
              title="Submit Complaint"
              type="submit"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
