import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const GET_RENTAL = gql`
query GetRental (
  $rentalUnitId: String!
) {
  getRental (
    rentalUnitId: $rentalUnitId
  ) {
    success
    error
    data {
      registrationId
      price
      address
      city
      state
      zipCode
      companyName
      primaryEmergencyPhone
      secondaryEmergencyPhone
      email
    }
  }
}
`;

const EDIT_RENTAL = gql`
mutation Mutation(
  $email: String, 
  $secondaryEmergencyPhone: String, 
  $primaryEmergencyPhone: String, 
  $companyName: String, 
  $zipCode: String, 
  $state: String, 
  $city: String, 
  $address: String, 
  $rentalUnitId: String!
) {
  editRental(
    rentalUnitId: $rentalUnitId
    rentalUnitInfo: {
      email: $email,
      secondaryEmergencyPhone: $secondaryEmergencyPhone,
      primaryEmergencyPhone: $primaryEmergencyPhone,
      companyName: $companyName,
      zipCode: $zipCode,
      state: $state,
      city: $city,
      address: $address,
    }
  ) {
    success
    error
  }
}
`;

export default function useEditRental() :{
  rentalId: string,
  history: History | any,
  data: any,
  editData: (key:string, val:string) => void,
  loading: boolean,
  error: string,
  editSuccess: boolean,
  // editError: string,
  submitHandler: () => void,
  } {
  const { rentalId } = useParams<{ rentalId: string }>();
  const history = useHistory();
  const [rental, setRental] = useState<any>({});
  const editRental = (key: string, value: string) => {
    const res = { ...rental };
    res[key] = value;
    setRental(res);
  };

  const {
    data, loading, error, refetch,
  } = useQuery(GET_RENTAL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { rentalUnitId: rentalId },
    onCompleted: (d) => {
      try {
        setRental(d.getRental.data[0]);
      } catch {
        //
      }
    },
  });

  const [doEdit, {
    data: editData, error: editError,
  }] = useMutation(EDIT_RENTAL, {
    // onCompleted: () => {
    //   refetch();
    // },
  });
  const submitHandler = () => {
    doEdit({
      variables: {
        ...rental,
        rentalUnitId: rentalId,
      },
    });
  };

  return {
    rentalId,
    history,
    data: rental,
    editData: editRental,
    loading,
    error: editError?.message
      || editData?.editRental?.error
      || error?.message
      || data?.getRental?.error
      || '',
    editSuccess: editData?.editRental?.success
      || false,
    // editError: editError?.message
    //   || editData?.editRental?.error
    //   || '',
    submitHandler,
  };
}
