import React, { ReactElement, useState, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { gql, useQuery } from '@apollo/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PassInfo, { passStatusMapTitles, paymentStatusMapTitles } from '../../common_lib_front/types/passInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import ActionsBtn from '../../components/actionsBtn/actionsBtn';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import InputField from '../../components/inputField/inputField';
import LoadingDiamonds from '../../components/loadingDiamonds/loadingDiamonds';
import { PopUp } from '../../components/popUp/popUp';
import style from './vendorDetails.module.css';
import dataGridStyle from '../../components/vendorPassGrid/vendorPassGrid.module.css';
import useGetPasses from './vendorGetPasses';
import useVendorDetails from './useVendorDetails';
import useParkingPass from '../../hooks/useParkingPass';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import PopupCellRenderer from '../../components/vendorPassGrid/popupCellRenderer';
import SelectTable from '../../common_lib_front/components/selectTable/selectTable';
import { IAddEmployeeInfo, newEmployeeInfo } from '../../common_lib_front/types/employeeInfo';
import { useEmployees } from '../../hooks/useEmployees';

const REINVITE = gql`
mutation Mutation($registrationId: String!, $email: String, $phoneNumber: String) {
  reinviteGuest(registrationId: $registrationId, email: $email, phoneNumber: $phoneNumber) {
    error
    success
  }
}
`;
type REINVITE_VARS = {
  registrationId: string;
  email?: string;
  phoneNumber?: string;
}
type REINVITE_RES = {
  reinviteGuest: backendResponse<undefined>;
}

const paymentStatusStyleMap: { [key: string]: string } = {
  paid: style.active,
  unpaid: style.incomplete,
  'no-charge': style.active,
  refunded: style.refunded,
};
const passStatusStyleMap: { [key: string]: string } = {
  active: style.passStatusActive,
  expired: style.passStatusExpired,
  inactive: style.passStatusInactive,
  incomplete: style.passStatusIncomplete,
  refunded: style.passStatusRefunded,
  default: style.passStatusDefault,
};

export default function VendorDetails(): ReactElement {
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const gridRef = useRef<AgGridReact>(null);
  const {
    userData,
    userLoading,
    userError,
    passTypeLoading,
    userId,
    history,
  } = useVendorDetails();
  const { data: employeesData } = useEmployees({
    variables: { userId },
  });
  const [employees, setEmployees] = useState<IAddEmployeeInfo | undefined>(employeesData);
  const {
    data: passData,
    loading: passLoading,
    error: passError,
    pageNum: passPageNum,
    setPage: passSetPage,
    numberPages,
  } = useGetPasses(userId);
  const [currenPassInfo, setCurrentPassInfo] = useState<PassInfo>();
  const [redirect, setRedirect] = useState<string>('');
  const viewDocument = (url: string) => {
    window.open(url);
  };

  const PassStatus = ({ value }: { value: string }) => (
    <div className={`${passStatusStyleMap[value] || ''}`}>
      {passStatusMapTitles[value] || value}
    </div>
  );

  const PaymentStatus = ({ value }: { value: string }) => (
    <div className={`${paymentStatusStyleMap[value] || ''}`}>
      {paymentStatusMapTitles[value] || value}
    </div>
  );

  const PassNumber = (item: { data: PassInfo }) => {
    const { data: { passNumber, data: itemData } } = item;
    const { vehicle } = itemData;
    return (
      <Link className={dataGridStyle.tableLink} to={{ pathname: '/admin/popup/more-details', state: { passInfo: { ...itemData, ...vehicle }, role: 'vendor' } }}>
        {passNumber}
      </Link>
    );
  };

  const DriveList = (item: { data: PassInfo }) => {
    const { primaryDriverName } = item.data;
    return primaryDriverName;
    // (
    // <SelectTable<IAddEmployeeInfo>
    //   id={primaryDriverName}
    //   displayVal={(e) => `${e?.firstName} ${e?.lastName}`}
    //   value={primaryDriverName}
    //   validityTest={(obj) => !!(obj.firstName || obj.lastName || obj.driversLicenseNum)}
    //   noHeader
    //   preventClear
    //   setValue={setEmployees}
    //   data={employeesData?.getEmployee.data?.map((e) => newEmployeeInfo(e)) || []}
    //   headers={[
    //     {
    //       label: 'Name',
    //       field: (e) => `${e.firstName} ${e.lastName}`,
    //     },
    //   ]}
    //   topHead={(
    //     <div>
    //       <Link to="/vendor/popup/add-employee">Add Employee</Link>
    //     </div>
    //   )}
    // />
    // );
  };
  console.log('Employee data Start');
  console.log(employeesData);
  console.log('Employee data end');

  const VehicleList = (item: { data: PassInfo }) => {
    const { licensePlate } = item.data;
    return (
      licensePlate
    );
  };

  const dateFormatter = ({ value }: { value: string | number }) => formatDate(new Date(value));

  const filterParams = {
    comparator: (filterDate: Date, cellValue: string) => {
      const cellDate = new Date(`${cellValue}T00:00:00`);
      if (cellDate < filterDate) {
        return -1;
      }
      if (cellDate > filterDate) {
        return 1;
      }
      return 0;
    },
  };
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    // wrapText: true, causing checkbox to wrap down
    flex: 1,
    wrapHeaderText: true,
  };

  const [columnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
      width: 160,
    },
    {
      headerName: 'Pass Information',
      children: [
        {
          headerName: 'Pass Number',
          field: 'passNumber',
          cellRendererFramework: PassNumber,
          minWidth: 200,
        },
        {
          headerName: 'Pass Type',
          field: 'passType',
          minWidth: 200,
        },
        {
          headerName: 'Destination',
          field: 'destination',
          minWidth: 200,
        },
        {
          headerName: 'Start Date',
          field: 'startDate',
          filter: 'agDateColumnFilter',
          filterParams,
          valueFormatter: dateFormatter,
          minWidth: 200,
        },
        {
          headerName: 'Expiration Date',
          field: 'endDate',
          filter: 'agDateColumnFilter',
          filterParams,
          valueFormatter: dateFormatter,
          minWidth: 200,
        },
        {
          headerName: 'Payment Status',
          field: 'paymentStatus',
          cellRendererFramework: PaymentStatus,
          minWidth: 200,
        },
        {
          headerName: 'Pass Status',
          field: 'status',
          cellRendererFramework: PassStatus,
          minWidth: 200,
        },
      ],
    },
    {
      headerName: 'Driver / Vehicle',
      children: [
        {
          headerName: 'Name #',
          field: 'primaryDriverName',
          cellRendererFramework: DriveList,
          minWidth: 200,
        },
        {
          headerName: 'License Plate #',
          field: 'licensePlate',
          cellRendererFramework: VehicleList,
          minWidth: 200,
        },
      ],
    },
  ]);

  const rowData = passData?.flatMap((item) => ({
    data: item,
    passNumber: item.number,
    passType: item.passType ? item.passType : '',
    destination: item.destination ? item.destination : '',
    startDate: item.startDate ? item.startDate.slice(0, 10) : '',
    endDate: item.endDate ? item.endDate.slice(0, 10) : '',
    paymentStatus: item?.paid,
    status: item?.passStatus,
    primaryDriverName: item?.licensePrimaryDriver,
    licensePlate: item?.licensePlate,
  }));

  const [rowData1] = useState([
    {
      make: 'Toyota',
      model: 2020,
      price: 35000,
      price1: 35000,
      price2: 35000,
      price3: 35000,
      price4: 35000,
    },
  ]);
  if (userLoading || passTypeLoading) {
    return (
      <LoadingDiamonds
        title="Getting User..."
      />
    );
  }

  const handleSelectPassPaid = (event: React.ChangeEvent<HTMLSelectElement>, value: PassInfo) => {
    event.preventDefault();
    if (event.target.value === 'paid') {
      setCurrentPassInfo(value);
      setRedirect('/admin/popup/mark-paid');
    }
  };

  if (redirect) {
    history.push({
      pathname: redirect,
      state: currenPassInfo,
    });
    // return (
    //   <Redirect to={{ pathname: redirect, state: currenPassInfo }} />
    // );
  }

  // const backToReport = () => {};
  return (
    <div className={style.container}>
      <div className={style.flexBox}>
        <div className={`${style.infoBox} white`}>
          <GenericAlert
            title={userError}
            hidden={!userError}
            color="red"
          />
          <h1 className={`${style.infoTitle} textColorSuperDark`}>
            Vendor (Rerord)
          </h1>
          <div className={style.infoSectionBox}>
            <div className={`${style.section} ${style.section1}`}>
              <div className={`${style.sectionHeader} `}>
                <h3 className={`${style.title} `}>Vendor Information</h3>
              </div>
              <div className={style.infoBoxContainer}>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItem} `}>
                    <InputField
                      boxInput
                      htmlFor="bname-inpt"
                      labelTitle="Business Name"
                      inputValue={userData.companyName}
                      disabled
                    />
                  </div>
                  <div className={`${style.listItem} `}>
                    <InputField
                      boxInput
                      htmlFor="baddress-inpt"
                      labelTitle="Business Address"
                      inputValue={userData.address}
                      disabled
                    />
                  </div>
                </div>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItem} `}>
                    <InputField
                      boxInput
                      htmlFor="country-inpt"
                      labelTitle="Country"
                      inputValue={(userData.country != null) ? userData.country : ''}
                      disabled
                    />
                  </div>
                  <div className={`${style.listItem} `}>
                    <InputField
                      boxInput
                      htmlFor="city-inpt"
                      labelTitle="City"
                      inputValue={userData.city}
                      disabled
                    />
                  </div>
                </div>
                <div className={`${style.rowItem} `}>

                  <div className={`${style.listItem} `}>
                    <InputField
                      boxInput
                      htmlFor="state-inpt"
                      labelTitle="State"
                      inputValue={userData.state}
                      disabled
                    />
                  </div>
                  <div className={`${style.listItem} `}>
                    <InputField
                      boxInput
                      htmlFor="zipcode-inpt"
                      labelTitle="Zip Code"
                      inputValue={userData.zipCode}
                      disabled
                    />
                  </div>
                </div>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItem} `}>
                    <InputField
                      boxInput
                      htmlFor="licence-inpt"
                      labelTitle="Business License Number"
                      inputValue={userData?.businessLicenseNumber}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.section} ${style.section2}`}>
              <div className={`${style.sectionHeader} `}>
                <h3 className={`${style.title} `}>Primary Contact</h3>
              </div>
              <div className={style.infoBoxContainer}>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItemSingle} `}>
                    <InputField
                      boxInput
                      htmlFor="name-inpt"
                      labelTitle="Name"
                      inputValue={`${userData.firstName || ''} ${userData.lastName || ''}`}
                      disabled
                    />
                  </div>
                </div>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItemSingle} `}>
                    <InputField
                      boxInput
                      htmlFor="email-inpt"
                      labelTitle="Email"
                      inputValue={userData.email}
                      disabled
                    />
                  </div>
                </div>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItemSingle} `}>
                    <InputField
                      boxInput
                      htmlFor="primary-number-inpt"
                      labelTitle="Primary Phone Number"
                      inputValue={userData.phoneNumber}
                      disabled
                    />
                  </div>
                </div>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItemSingle} `}>
                    <InputField
                      boxInput
                      htmlFor="alt-number-inpt"
                      labelTitle="Alternate Phone Number"
                      inputValue={userData?.alternatNameNumber}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.section} ${style.section3}`}>
              <div className={`${style.sectionHeader} `}>
                <h3 className={`${style.title} `}>Emergency Contact</h3>
              </div>
              <div className={style.infoBoxContainer}>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItemSingle} `}>
                    <InputField
                      boxInput
                      htmlFor="em-name-inpt"
                      labelTitle="Emergency Contact Name"
                      inputValue={userData?.emergencyName}
                      disabled
                    />
                  </div>
                </div>
                <div className={`${style.rowItem} `}>
                  <div className={`${style.listItemSingle} `}>
                    <InputField
                      boxInput
                      htmlFor="em-number-inpt"
                      labelTitle="Emergency Contact Phone Number"
                      inputValue={userData.emergencyPhone}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={dataGridStyle.radiusBox}
          >
            <div
              className="ag-theme-alpine"
              style={{
                height: '100%',
                width: '100%',
                overflow: 'scroll',
              }}
            >
              {
                employeesData && (
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    enableCellTextSelection
                    ensureDomOrder
                    animateRows
                    pagination
                    paginationPageSize={itemsPerPage}
                    // paginationAutoPageSize
                    ref={gridRef}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
