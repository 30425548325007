import { gql, useMutation, useQuery } from '@apollo/client';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';

const GET_USER_COUMMUNITY_INFO = gql`
  query GetPropertiesByUser  (
    $userId: String!
    ){
    getPropertiesByUser(userId:$userId) {
      success
      error
      data {
        state
        street
        unit
        city
        zipCode
        propertyId
      }
    }
  }
`;
export const GET_USER_PROFILE_INFO = gql`
  query GetUserInfo (
    $userId: String!
    ) {
    getResidentProfile (userId:$userId){
      success
      error
      data {
        email
        firstName
        lastName
        phoneNumber
        altPhoneNum
        mailingStreet
        mailingUnit
        mailingCity
        mailingState
        mailingCountry
        mailingZipCode
        emergencyFirstName
        emergencyLastName
        emergencyPhoneNumber
        emergencyEmail
        ownerPin
      }
    }
  }
`;

export const GET_USER_PASS = gql`
  query GetPassesByUser (
    $userId: String!
    ) {
    getPassesByUser(userId:$userId) {
      success
      error
      data {
        passId
        passType
        paymentType
        number
        startDate
        endDate
        status
        paid
        shared
        purchasedBy
        registrationId
        name
        }
    }
  }
`;

type RegistrationInitialization = {
  userCommunityInfo?: any;
  getPropertyLoading?: boolean;
  getPropertyError?: string;
  userProfileInfo: any;
  getProfileLoading: boolean;
  getProfileError?: string;
  residentInfo: any;
  setResidentInfo: (obj: any) => void;
  userPassInfo:any
};

export default function UseMyProfile(): RegistrationInitialization {
  const [residentInfo, setResidentInfo] = useState<any>(({}));
  const { userId } = useParams<{ userId: string }>();
  const {
    data: userCommunityInfo,
    loading: getPropertyLoading,
    error: getPropertyError,
  } = useQuery(GET_USER_COUMMUNITY_INFO, {
    variables: { userId },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      if (d.getPropertiesByUser.success) {
        setResidentInfo({
          ...residentInfo,
          ...d.getPropertiesByUser.data[0],
          communityAddresses: d.getPropertiesByUser.data,
        });
      }
    },
  });

  const {
    data: userProfileInfo,
    loading: getProfileLoading,
    error: getProfileError,
  } = useQuery(GET_USER_PROFILE_INFO, {
    variables: { userId },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      if (d.getResidentProfile.success) {
        if (d.getResidentProfile.data) {
          setResidentInfo({ ...residentInfo, ...d.getResidentProfile.data });
        }
      }
    },
  });
  const {
    data: userPassInfo,
    loading: getPassLoading,
    error: getPassError,
  } = useQuery(GET_USER_PASS,
    {
      variables: { userId },
      fetchPolicy: 'network-only',
      onCompleted: (d) => {
        console.log(d);
      },
    });

  return {
    userCommunityInfo: userCommunityInfo?.getPropertiesByUser?.data || {},
    getPropertyLoading: getPropertyLoading || false,
    getPropertyError: getPropertyError?.message || '',
    userProfileInfo: userProfileInfo?.getResidentProfile?.data || {},
    getProfileLoading: getProfileLoading || false,
    getProfileError: getProfileError?.message || '',
    residentInfo: residentInfo || {},
    setResidentInfo,
    userPassInfo: userPassInfo?.getPassesByUser?.data || [],

  };
}
