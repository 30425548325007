import React, { ReactElement, useEffect } from 'react';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import GenericAlert from '../../../components/genericAlert/genericAlert';
import GenericButton from '../../../components/genericButton/genericButton';
import { PopUp } from '../../../components/popUp/popUp';
import useEditRental from './useDeleteVehiclePass';
import alertPic from '../../../assets/alert.svg';
import style from './deleteVehiclePass.module.css';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';

export default function DeleteVehiclePass(): ReactElement {
  const {
    passId,
    history,
    deleteHandler,
  } = useEditRental();
  const passInfo = history.location.state;
  console.log(passInfo);

  return (
    <PopUp
      title="Delete Vehicle/Pass"
      close={() => history.goBack()}
    >
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          deleteHandler();
          history.goBack();
        }}
        className={style.form}
      >
        <div className={style.infoBox}>
          <div className={style.header}>
            <h4 className={style.title}>Vehicle/Pass Info</h4>
          </div>
          <div className={style.paddingBox}>
            <div className={style.personalInfoBox}>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="Make"
                  labelTitle="Make"
                  // inputValue={passInfo.make}
                  inputValue={passInfo.make}
                  disabled
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="Vehicle Type"
                  labelTitle="Vehicle Type"
                  inputValue={passInfo.type || ''}
                  disabled
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="Color"
                  labelTitle="Color"
                  inputValue={passInfo.color}
                  disabled
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="License Plate"
                  labelTitle="License Plate"
                  inputValue={passInfo.licensePlate}
                  disabled
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="Pass Type"
                  labelTitle="Pass Type"
                  inputValue={passInfo.passType}
                  disabled
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="Pass Status"
                  labelTitle="Pass Status"
                  inputValue={passInfo.passStatus}
                  disabled
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="Start Date"
                  labelTitle="Start Date"
                  inputValue={formatDate(new Date(Number(passInfo.startDate)))}
                  disabled
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="Valid Through"
                  labelTitle="Valid Through"
                  inputValue={formatDate(new Date(Number(passInfo.endDate)))}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            title="Delete"
            type="submit"
            color="blue"
          />
        </div>
      </form>
    </PopUp>
  );
}
