import React, { ReactElement } from 'react';
import LoadingDiamonds from '../loadingDiamonds/loadingDiamonds';
import style from './reportTable.module.css';
import noDataImg from '../../assets/empty.png';
import GenericButton from '../genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';

interface PropsType {
  headers: Array<string | ReactElement>,
  data: Array<any>,
  loading?: boolean,
  error?: string,
  mapper: (elem: any, idx?: number) => ReactElement,
  hidden?: boolean,
  withPagination?: boolean,
  pageNum?: number,
  setPage?: (val: number | ((prev: number) => number)) => void,
  numPages?: number,
}

export default function ReportTable(props: PropsType): ReactElement {
  const {
    headers,
    data,
    loading,
    error,
    mapper,
    hidden,
    withPagination,
    pageNum,
    setPage,
    numPages,
  } = props;

  const genBody = (): ReactElement | Array<ReactElement> | string => {
    if (loading) {
      return (
        <LoadingDiamonds
          title="Fetching Passes ..."
        />
      );
    }
    if (error && !data) {
      return (
        error
      );
    }
    if (data?.length > 0) {
      return data.map(mapper);
    }
    return (
      <div style={{
        padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}
      >
        <img src={noDataImg} alt="no data found" />
        <h5 style={{ color: 'GrayText' }}> No Data Found</h5>
      </div>
    );
  };

  if (hidden) {
    return (
      <div />
    );
  }
  // if (loading) {
  //   return (
  //     <LoadingDiamonds
  //       title="Getting Passes..."
  //     />
  //   );
  // }
  return (
    <>
      <div className={style.radiusBox}>
        {/* <GenericAlert
        title={error}
        hidden={!error}
        color="red"
      /> */}
        <table width="100%" cellSpacing="0px" className={style.table}>
          <thead className={style.tHead}>
            <tr className={`${style.tableHeader}   `}>
              {headers.map((h: string | ReactElement) => (
                typeof h === 'string' ? (
                  <th key={h.toString()}>
                    {h}
                  </th>
                ) : h
              ))}
            </tr>
          </thead>
          <tbody className={style.tbody}>
            {genBody()}
          </tbody>
        </table>
      </div>
      {withPagination && (
        <div className={style.paginationContainer}>
          {/* <button
            className=" thirdColor textColorWhite"
            onClick={() => setPage?.((prev) => prev - 1)}
          >
            Prev Page
          </button> */}
          <div>
            <GenericButton
              title="Prev Page"
              clickHandler={() => setPage?.((prev) => prev - 1)}
              color="grey"
              size="small"
            />
          </div>
          <div
            className={style.pageNumCenter}
          >
            {pageNum}
            {
              (typeof numPages === 'number')
                ? ` / ${numPages}`
                : null
            }
          </div>
          <div>
            <GenericButton
              title="Next Page"
              clickHandler={() => setPage?.((prev) => prev + 1)}
              color="grey"
              size="small"
            />
          </div>
        </div>
      )}
    </>
  );
}
ReportTable.defaultProps = {
  hidden: false,
  loading: false,
  error: '',
  withPagination: false,
  pageNum: 1,
  setPage: undefined,
  numPages: undefined,
};
