import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { PopUp } from '../../../components/popUp/popUp';
import style from './refund.module.css';
import GenericButton from '../../../components/genericButton/genericButton';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import alertPic from '../../../assets/alert.svg';

const REFUND = gql`
mutation RefundPass(
  $refundAmt: Float!, 
  $passId: String!
) {
  refundPass(
    refundAmt: $refundAmt, 
    passId: $passId
  ) {
    error
    success
  }
}
`;
type REFUND_VARS = {
  refundAmt: number,
  passId: string,
}
type REFUND_RES = {
  refundPass: backendResponse<undefined>
}

const GET_PASS = gql`
query Query(
  $passId: String!
) {
  getPass (
    passId: $passId
  ) {
    error
    success
    data {
      number
      amtPaid
      passType
      paymentType
      startDate
      endDate
    }
  }
}
`;
type GET_PASS_VARS = {
  passId: string,
}
type GET_PASS_RES = {
  getPass: backendResponse<{
      number: string,
      amtPaid: number,
      passType: string,
      paymentType: string,
      startDate: string,
      endDate: string,
  }[]>
}

const EDIT_PASS_STATUS = gql`
mutation editPassStatus(
  $passId: String!
) {
  editPassStatus(
    passId: $passId,
    status: "refunded"
  ) {
    error
    success
  }
}
`;
type EDIT_PASS_STATUS_VARS = {
  passId: string,
}
type EDIT_PASS_STATUS_RES = {
  editPassStatus: backendResponse<undefined>
}

export default function Refund(): ReactElement {
  const history = useHistory();
  const { passId } = useParams<{ passId: string }>();
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [refundAmt, setRefundAmt] = useState<number>(0);

  const { data: passData } = useQuery<
    GET_PASS_RES, GET_PASS_VARS
  >(GET_PASS, {
    variables: {
      passId,
    },
  });

  const max = useMemo<number>(() => {
    const amtPaid = passData?.getPass.data?.[0]?.amtPaid;
    if (typeof amtPaid === 'number') return amtPaid / 100;
    return 0;
  }, [passData]);

  useEffect(() => {
    const amtPaid = passData?.getPass.data?.[0]?.amtPaid;
    if (typeof amtPaid === 'number') setRefundAmt(amtPaid / 100);
  }, [passData]);

  const [doRefund] = useMutation<
    REFUND_RES, REFUND_VARS
  >(REFUND, {
    onCompleted: (d) => {
      if (d.refundPass.error) {
        setAlert(d.refundPass.error);
        setAlertColor('red');
      } else {
        history.goBack();
      }
    },
    onError: () => {
      setAlert('Something went wrong.');
      setAlertColor('red');
    },
  });

  const [doEditPassStatus] = useMutation<
    EDIT_PASS_STATUS_RES, EDIT_PASS_STATUS_VARS
  >(EDIT_PASS_STATUS, {
    onCompleted: (d) => {
      if (d.editPassStatus.error) {
        setAlert(d.editPassStatus.error);
        setAlertColor('red');
      } else {
        history.goBack();
      }
    },
    onError: () => {
      setAlert('Something went wrong.');
      setAlertColor('red');
    },
  });

  const refundHelper = () => {
    if (passData?.getPass.data?.[0]?.paymentType === 'cash') {
      doEditPassStatus({
        variables: {
          passId,
        },
      });
    } else {
      doRefund({
        variables: {
          passId,
          refundAmt: refundAmt * 100,
        },
      });
    }
  };

  return (
    <PopUp
      title="Refund"
      close={() => history.goBack()}
    >
      <div className={style.refundBox}>
        <GenericAlert
          color={alertColor}
          title={alert}
          hidden={!alert}
        />
        <img src={alertPic} alt="" className={style.alertPic} />
        <b className={style.warning}>
          Warning:
        </b>
        <br />
        <h4 className={style.subtitle}>
          Are you sure you want to refund this pass?
          <br />
          You can refund less than the total amount, but not more.
        </h4>

        {/* <div className={style.tableWraper}>
          <div className={style.tableItem}>
            <b>Pass Number:</b>
          &nbsp;
            {passData?.getPass.data?.[0].number}
          </div>
          <div className={style.tableItem}>
            <b>Start Date:</b>
          &nbsp;
            {formatDate(new Date(passData?.getPass.data?.[0].startDate || ''))}
          </div>
          <div className={style.tableItem}>
            <b>Valid Through:</b>
          &nbsp;
            {formatDate(new Date(passData?.getPass.data?.[0].endDate || ''))}
          </div>
        </div> */}
        <div className={style.tableWrapper}>
          <table className={style.table} cellSpacing={0}>
            <thead>
              <tr>
                <th>Pass Number</th>
                <th>Start Date</th>
                <th>Valid Through</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{passData?.getPass.data?.[0].number}</td>
                <td>{formatDate(new Date(passData?.getPass.data?.[0].startDate || ''))}</td>
                <td>{formatDate(new Date(passData?.getPass.data?.[0].endDate || ''))}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <label htmlFor="refund-amount">
          <p className={style.refundTitle}>
            Refund Amount: $
          </p>
          <div className={style.refundInput}>
            <input
              id="refund-amount"
              type="number"
              max={max}
              min={0}
              step="0.01"
              value={refundAmt}
              onChange={(e) => {
                setRefundAmt(parseFloat(e.target.value));
              }}
            />
          </div>
        </label>
        <div className={style.btnBox}>
          <div>

            <GenericButton
              title="Cancel"
              color="white"
              outline="large"
              // size="large"
              clickHandler={() => history.goBack()}
            />
          </div>
          <div>

            <GenericButton
              title="Refund"
              color="blue"
              // outline="no-border"
              size="large"
              clickHandler={refundHelper}
            />
          </div>
        </div>
      </div>
    </PopUp>
  );
}
