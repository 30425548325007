import React, { ReactElement } from 'react';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import style from './reportSecurityDetail.module.css';
import { ReactComponent as CloudIcon } from '../../../assets/cloudSmall.svg';
import { ReactComponent as FileIcon } from '../../../assets/fileSmall.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/deleteSmall.svg';

export default function ReportSecurityDetail(): ReactElement {
  return (
    <div className={style.container}>
      <div className={style.titleBox}>
        <div className={style.leftTitle}>
          <p className={style.title}>Task CB-12</p>
          <p className={style.securityType}>Complaint</p>
          <p className={style.status}>Unresolved</p>
        </div>
        <button className={style.deleteBtn}>
          Delete
        </button>
      </div>

      {/* contain content will device 2 part */}
      <div className={style.box_content}>
        <div className={style.boxLeft}>
          {/* complaint and violation information */}
          <div className={style.securityInfoBox}>
            <p className={style.securityTitle}>Complaint Information</p>
            <div className={style.subContent}>
              <div className={style.subContentItem}>
                <span>
                  <p className={style.itemTitle}>Date of Complaint</p>
                  <p className={style.item}>21-12-7</p>
                </span>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Time of Complaint</p>
                <p className={style.item}>13:08</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Complaint Type</p>
                <p className={style.item}>Distrurt the Residents</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Location</p>
                <p className={style.item}>2081 Oregon</p>
              </div>
            </div>
          </div>
          <div className={style.noteBox}>
            <p>Notes</p>
            <textarea className={style.leftTextArea}>
              demo text
            </textarea>
          </div>
          <div className={style.enclosure}>
            <p>Enclosure</p>
            <div className={style.photoBox}>
              <div className={style.photo}>Photo</div>
              <div className={style.photo}>Photo</div>
              <div className={style.photo}>Photo</div>
              <div className={style.photo}>Photo</div>
            </div>
          </div>
          {/* offender's information */}
          <div className={style.securityInfoBox}>
            <p className={style.securityTitle}>Offerdent&apos;s Information</p>
            <div className={style.subContent}>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>First Name</p>
                <p className={style.item}>Ryan</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Last Name</p>
                <p className={style.item}>Passaquincd</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Phone Number</p>
                <p className={style.item}>Distrurt the Residents</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Emergency Phone Number</p>
                <p className={style.item}>(801) 282 2263</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Email</p>
                <p className={style.item}>Vacaro@gmail.com</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Role</p>
                <p className={style.item}>Resident</p>
              </div>
            </div>
          </div>

          <div className={`${style.securityInfoBox} ${style.borderBottom}`}>
            <p className={style.securityTitle }>Offender&apos;s vehicle Information</p>
            <div className={style.subContent}>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Make</p>
                <p className={style.item}>Honda</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Vehicle Type</p>
                <p className={style.item}>Sedan</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Color</p>
                <p className={style.item}>Black</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Licence Plate</p>
                <p className={style.item}>G31 TW4</p>
              </div>
            </div>
          </div>

          {/* submiiter's information */}
          <div className={style.securityInfoBox}>
            <p className={style.securityTitle}>Submitter&apos;s Information</p>
            <div className={style.subContent}>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>First Name</p>
                <p className={style.item}>Ryan</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Last Name</p>
                <p className={style.item}>Passaquincd</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Phone Number</p>
                <p className={style.item}>Distrurt the Residents</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Emergency Phone Number</p>
                <p className={style.item}>(801) 282 2263</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Email</p>
                <p className={style.item}>Vacaro@gmail.com</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Role</p>
                <p className={style.item}>Resident</p>
              </div>
            </div>
          </div>

          <div className={style.securityInfoBox}>
            <p className={style.securityTitle}>Submitter&apos;s vehicle Information</p>
            <div className={style.subContent}>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Make</p>
                <p className={style.item}>Honda</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Vehicle Type</p>
                <p className={style.item}>Sedan</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Color</p>
                <p className={style.item}>Black</p>
              </div>
              <div className={style.subContentItem}>
                <p className={style.itemTitle}>Licence Plate</p>
                <p className={style.item}>G31 TW4</p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.boxRight}>
          <div className={style.securityInfoBox}>
            <p className={style.securityTitle }>Task Information</p>
            <div className={style.role}>
              <div className={style.roleBox}>
                <p className={style.roleTitle}>Reporter</p>
                <div className={style.roleName}>
                  {/* <div className="avatar">Avatar</div> */}
                  <p className={style.roleItem}>Mike Young</p>
                </div>
              </div>
              <div className={style.roleBox}>
                <p className={style.roleTitle}>Assignee</p>
                <div>
                  {/* <div className="avatar">Avatar</div> */}
                  <select
                    id="assignee-inpt"
                    className={style.select}
                  >
                    <option value="jay_zark">Jay Zark</option>
                    <option value="jay_zark">Jay Zark</option>
                    <option value="jay_zark">Jay Zark</option>
                    <option value="jay_zark">Jay Zark</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={style.roleBox}>
              <p className={style.roleTitle}>Creation Date</p>
              <div>
                <p className={style.roleItem}>2021-12-7 18:08</p>
              </div>
            </div>
          </div>
          {/* Notes */}
          <div className={style.textAreaInput}>
            <label
              htmlFor="note-inpt"
            >
              <p>Notes</p>
              <textarea className={style.leftTextArea}>
                demo text
              </textarea>
            </label>
          </div>
          {/* upload file */}
          <div className={style.uploadBox}>
            <div className={style.uploadInnerBox}>
              <div className={style.leftUpload}>
                <CloudIcon className={style.uploadImg} />
                <p className={style.dragTitle}>Drag files to upload</p>
              </div>
              <div>
                <GenericButton
                  color="blue"
                  size="small"
                  title="Browse files"
                  type="submit"
                />
              </div>
            </div>
            <div className={style.uploadBox}>
              <p>Uploaded</p>
              <div className={style.uploadFileBox}>
                <div className={style.uploadFileItem}>
                  <div className={style.uploadFileLeft}>
                    <FileIcon />
                    <p className={style.fileName}>pic1</p>
                  </div>
                  <button className={style.delBtnBox}>
                    <DeleteIcon className={style.delBtn} />
                  </button>
                </div>
                <div className={style.uploadFileItem}>
                  <div className={style.uploadFileLeft}>
                    <FileIcon />
                    <p className={style.fileName}>pic1</p>
                  </div>
                  <button className={style.delBtnBox}>
                    <DeleteIcon className={style.delBtn} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Adjust fine amount */}
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="fine-amount-inpt"
              labelTitle="Adjust Fine Amount"
            />
          </div>
          {/* confirm resolve */}
          <div className={style.confirmBox}>
            <p className={style.confirmTitle}>Confirm that you have resolved this problem</p>
            <div>
              <GenericButton
                color="blue"
                size="small"
                title="Confirm"
                type="submit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
