import { useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import style from './vendorDetails.module.css';
import { GET_ALL_PASS } from '../reportCenter/reportCenterReports/useReportCenterReports';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { backendClient } from '../../common_lib_front/utilities/BackendAPI';

const GET_USER = gql`
query GetUserInformation (
  $userId: String!
) {
  getUserInformation (
    userId: $userId
  ) {
    success
    error
    data {
      firstName
      lastName
      roles
      phoneNumber
      address
      country
      city
      state
      zipCode
      email
      barCodes
      companyName
      emergencyPhone
      businessPhoneNumber
    }
  }
}
`;

type useReportCenterDetailsReturn = {
  userData: any,
  userLoading: boolean,
  userError?: string
  // passData?: Array<any>,
  // passLoading?: boolean,
  // passError?: string
  reportData?: Array<any>,
  reportLoading?: boolean,
  reportError?: string,
  employeeData?: Array<any>,
  employeeLoading?: boolean,
  employeeError?: string,
  passTypeLoading?: boolean,
  passTypeError?: string,
  documentInfoData?: { [key: string]: string },
  documentData?: Array<any>,
  documentLoading?: boolean,
  documentError?: string,
  userId: string,
  history: History | any,
  vendorHidden: string,
}
export default function useVendorDetails(): useReportCenterDetailsReturn {
  const { userId } = useParams<{
    userId: string,
  }>();
  const history = useHistory();
  const { communityId } = useContext(CommunityContext);
  const [documentInfoData, setDocumentInfoData] = useState<{ [key: string]: string }>({});

  // get user info
  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  const {
    data: passTypeData,
    loading: passTypeLoading,
    error: passTypeError,
  } = useQuery(GET_ALL_PASS, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
      complete: true,
    },
  });
  // setting styles
  let vendorHidden = '';

  vendorHidden = style.vendorHidden;

  // console.log('fron logic file', passData);
  return {
    userData: userData?.getUserInformation?.data,
    userLoading,
    userError: userError?.message || userData?.getUserInformation.error || undefined,
    passTypeLoading,
    passTypeError: passTypeError?.message || passTypeData?.getPassInfosByCommunity.error
      || undefined,
    userId,
    history,
    vendorHidden,
  };
}
