import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InfoSection from './userInfoSection';
import PassGrid from './passGrid';
import style from './residentInfo.module.css';
import { ReactComponent as LeftNav } from '../../../../assets/leftArrow.svg';
import UseMyProfile from './useResidentInfo';

export default function ReportCenterDetails(): ReactElement {
  const {
    userCommunityInfo,
    userProfileInfo,
    userPassInfo,
  } = UseMyProfile();
  const history = useHistory();

  const backToReport = () => {
    history.replace('/admin/rapid-search');
  };
  return (

    <div className={style.container}>
      <div className={style.infoCard}>
        <div className={style.flexBox}>
          <button
            onClick={backToReport}
            className={style.back__button}
          >
            <LeftNav />
          </button>
          <div className={`${style.header}`}>
            <h3 className={`${style.title}`}>Resident Information</h3>
          </div>
        </div>
        <div className={style.line} />
        <InfoSection
          title="Resident Information"
          data={userProfileInfo || {}}
          communityData={userCommunityInfo[0] || {}}

        />
        <div className={style.line} />
        <PassGrid fetchedUserPasses={userPassInfo} />
      </div>
    </div>
  );
}
