import { gql, useLazyQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import style from './hostSearchDropdown.module.css';
import useOnClickOutside from '../../common_lib_front/hooks/useClickOutside';

const SEARCH_HOSTS = gql`
query SearchHostsByName($name: String!) {
  searchHostsByName(name: $name) {
    success
    error
    data {
      firstName
      lastName
      hostInfoId
      userId
      address
      companyName
    }
  }
}
`;
type SEARCH_HOSTS_VARS = {
  name: string,
}
type hostSearchRes = {
  firstName: string,
  lastName: string,
  hostInfoId: string,
  userId: string,
  address: string,
  companyName: string,
}
type SEARCH_HOSTS_RES = {
  searchHostsByName: backendResponse<hostSearchRes[]>
}

type HostSearchDropdownProps = {
  id?: string,
  title?: string,
  onSelect?: (val: hostSearchRes | undefined) => void,
  className?: string,
  invalid?: boolean,
};
export default function HostSearchDropdown(props: HostSearchDropdownProps): React.ReactElement {
  const {
    id, title, onSelect, className, invalid,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<hostSearchRes | undefined>();

  // when selected changes, call handler
  useEffect(() => {
    onSelect?.(selected);
  }, [selected, onSelect]);
  const inptRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLLabelElement | null>(null);

  const [search, { data }] = useLazyQuery<
    SEARCH_HOSTS_RES, SEARCH_HOSTS_VARS
  >(SEARCH_HOSTS);

  useOnClickOutside(containerRef, () => {
    setOpen(false);
  });

  return (
    <label
      htmlFor={id}
      ref={containerRef}
      className={`${style.hostSearch} ${className}`}
    >
      {title}
      <input
        id={id}
        ref={inptRef}
        data-invalid={typeof invalid === 'boolean'
          ? invalid
          : (open && !selected)}
        autoComplete="off"
        onChange={(e) => {
          setOpen(true);
          setSelected(undefined);
          search({
            variables: {
              name: e.target.value,
            },
          });
        }}
      />
      {!!(open && data?.searchHostsByName.data) && (
        <ul>
          {data.searchHostsByName.data.map((elem) => (
            <li>
              <button
                key={elem.hostInfoId}
                onClick={() => {
                  setOpen(false);
                  setSelected(elem);
                  if (inptRef.current) {
                    inptRef.current.value = `${elem.firstName} ${elem.lastName}${elem.companyName
                      ? ` / ${elem.companyName}`
                      : ''}`;
                  }
                  onSelect?.(elem);
                }}
              >
                {`${elem.firstName} ${elem.lastName}${elem.companyName
                  ? ` / ${elem.companyName}`
                  : ''}`}
              </button>
            </li>
          ))}
        </ul>
      )}
    </label>
  );
}
HostSearchDropdown.defaultProps = {
  id: undefined,
  title: undefined,
  onSelect: undefined,
  className: undefined,
  invalid: undefined,
};
