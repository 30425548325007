import React, { ReactElement, useState } from 'react';
import UserInfo from '../../common_lib_front/types/userInfo';
import GenericButton from '../genericButton/genericButton';
import InputField from '../inputField/inputField';
import style from './sharePassFormCollection.module.css';
import userSharePass from '../../assets/userSharePass.svg';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';

type SharePassFormProps = {
  data: UserInfo,
  update: (obj: any) => void,
  remove: () => void,
  idx: number,
}

export const SharePassForm = (props: SharePassFormProps): ReactElement => {
  const [disablePhone, setDisablePhone] = useState(true);
  const [disableEmail, setDisableEmail] = useState(false);

  const {
    data, update, remove, idx,
  } = props;

  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (key === 'phone') {
      setDisablePhone(!e.target.checked);
      if (!e.target.checked) {
        update({ phone: '' });
      }
    } else if (key === 'email') {
      setDisableEmail(!e.target.checked);
      if (!e.target.checked) {
        update({ email: '' });
      }
    }
  };
  return (
    <div className={`${style.box} white `}>
      <div className={`${style.header} mainColor `}>
        <img src={userSharePass} alt="" />
        <h5 className={`${style.title} textColorWhite `}>
          Recipient
          {idx}
        </h5>
        <div className={style.exit}>
          <GenericButton
            icon="exit-small"
            title=""
            clickHandler={() => {
              remove();
            }}
          />

        </div>

      </div>
      <div className={style.infoBox}>
        <h5 className={style.text}>
          To share a pass,
          please enter the recipients&#39; name,
          phone number and/or email

        </h5>
        <div className={style.inputArea}>
          <div className={style.inputBox}>
            <div className={style.checkBoxInvisible} />
            <div className={style.inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="first-name-inpt"
                labelTitle="First Name"
                inputType="text"
                inputValue={data.firstName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ firstName: e.target.value });
                }}
              />

            </div>
            <div className={style.inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="last-name-inpt"
                labelTitle="Last Name"
                inputType="text"
                inputValue={data.lastName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ lastName: e.target.value });
                }}
              />

            </div>
          </div>
          <div className={style.inputLongBox}>
            <form>
              <input className={style.checkBox} checked={!disableEmail} type="checkbox" id="email-check-enable" onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkHandler(e, 'email')} />

            </form>
            <div className={style.inputLong}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="email-inpt"
                labelTitle="Email Address"
                inputType="email"
                disabled={disableEmail}
                inputValue={data.email}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  update({ email: e.target.value });
                }}
              />

            </div>
          </div>
          <div className={style.inputLongBox}>
            <form>
              <input className={style.checkBox} checked={!disablePhone} type="checkbox" id="phone-check-enable" onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkHandler(e, 'phone')} />

            </form>
            <div className={style.inputLong}>
              <PhoneInputWrapper
                standardInput
                inputTitle="Phone Number"
                value={data.phoneNumber}
                changeHandler={(val) => {
                  update({ phoneNumber: val });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePassForm;
