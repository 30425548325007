import { ApolloQueryResult, gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

export const EXPORT_RESERVATIONS = gql`
query ExportReservations(
  $pageNumber: Float!,
	$pageSize: Float!,
	$firstName: String,
	$lastName: String,
	$phoneNumber: String,
	$email: String,
	$arrivalDateFrom: DateTime,
	$arrivalDateTo: DateTime,
	$departureDate: DateTime,
	$invitedBy: String,
	$address: String,
	$passNumber: Float,
	$includePastReservations: Boolean!,
) {
  exportReservations(
    pageNumber: $pageNumber,
		pageSize: $pageSize,
		firstName: $firstName,
		lastName: $lastName,
		phoneNumber: $phoneNumber,
		email: $email,
		arrivalDateFrom: $arrivalDateFrom,
		arrivalDateTo: $arrivalDateTo,
		departureDate: $departureDate,
		invitedBy: $invitedBy,
		address: $address,
		passNumber: $passNumber,
		includePastReservations: $includePastReservations,
  ) {
    data
    error
    success
  }
}
`;
export type EXPORT_RESERVATIONS_VARS = {
  pageNumber: number,
	pageSize: number,
	firstName?: string,
	lastName?: string,
	phoneNumber?: string,
	email?: string,
	arrivalDateFrom?: string,
	arrivalDateTo?: string,
	departureDate?: string,
	invitedBy?: string,
	address?: string,
	passNumber?: number,
	includePastReservations: boolean,
}
export type EXPORT_RESERVATIONS_RES = {
  exportReservations: backendResponse<string>,
}

export default function exportReservations(variables: EXPORT_RESERVATIONS_VARS)
: Promise<ApolloQueryResult<EXPORT_RESERVATIONS_RES>> {
  return backendClient.query<EXPORT_RESERVATIONS_RES, EXPORT_RESERVATIONS_VARS>({
    query: EXPORT_RESERVATIONS,
    fetchPolicy: 'network-only',
    variables,
  });
}
