import React, { ReactElement, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import UserInfo, { newUserInfo } from '../../common_lib_front/types/userInfo';
import collectionTools from '../../common_lib_front/utilities/collectionTools';
import GenericButton from '../genericButton/genericButton';
import style from './sharePassFormCollection.module.css';
import InputField from '../inputField/inputField';
import userSharePass from '../../assets/userSharePass.svg';
import SharePassForm from './sharePassForm';
import GenericAlert from '../genericAlert/genericAlert';
import { backendClient } from '../../common_lib_front/utilities/BackendAPI';

const SHARE_PASS = gql`
  query SharePass (
    $phone: String
    $email: String
    $lastName: String!
    $firstName: String!
    $passId: String!
  ) {
    sendPass (
      phone: $phone
      email: $email
      lastName: $lastName
      firstName: $firstName
      passId: $passId
    ) {
      success
      error
    }
  }
`;

export const SharePassFormCollection = (props: { passId: string }) :ReactElement => {
  const [data, setData] = useState<Array<UserInfo>>([]);
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<string>('');

  const { passId } = props;

  const doShareAll = () => {
    Promise.all(data.map((u: UserInfo) => (
      backendClient.query({
        query: SHARE_PASS,
        variables: {
          firstName: u.firstName,
          lastName: u.lastName,
          email: u.email || null,
          phone: u.phoneNumber || null,
          passId,
        },
      })
        .then((d) => {
          if (!d.data.sendPass.success) {
            throw d.data.sendPass.error;
          }
        })
    )))
      .then(() => {
        setAlertColor('green');
        setAlert('Passes sent successfully!');
      })
      .catch((error) => {
        setAlert(typeof error === 'string'
          ? error
          : 'Something went wrong. Please try again later');
        setAlertColor('red');
      });
  };

  const {
    add, update, remove,
  } = collectionTools({
    newElement: newUserInfo,
    data,
    setData,
  });
  useEffect(() => {
    if (data.length === 0) {
      add();
    }
  }, []);

  return (
    <div className={style.container}>
      <GenericAlert
        color={alertColor}
        title={alert}
        hidden={!alert}
      />
      {data.map((elem:UserInfo, idx:number) => (
        <SharePassForm
          data={elem}
          idx={idx}
          update={(obj: any) => update(idx, obj)}
          remove={() => remove(idx)}
        />
      ))}
      <div className={style.btn}>
        <GenericButton
          color="yellow"
          size="large"
          title="Share"
          clickHandler={doShareAll}
        />

      </div>
      <div className={style.addBtn}>
        <GenericButton
          icon="add"
          title=""
          clickHandler={add}
        />
        <p className={`${style.textBelow} textColorSecondary `}>Add Recipient</p>
      </div>

    </div>
  );
};
