import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const MARK_PASS_AS_PAID = gql`
mutation MarkPassAsPaid (
  $passId: String!
) {
  markPassAsPaid (
    passId: $passId
  ) {
    success
    error
  }
}
`;

export default function useMarkPaid() :{
  history: History | any,
  alert: string,
  doMarkPaid: (passId: string) => void,
  } {
  const history = useHistory();
  const [alert, setAlert] = useState<string>('');

  const [markPassAsPaid] = useMutation(MARK_PASS_AS_PAID, {
    onError: (e) => {
      setAlert(e.message);
    },
    onCompleted: (d) => {
      if (d.markPassAsPaid.success) {
        history.go(-1);
      } else {
        setAlert(d.markPassAsPaid.error);
      }
    },
  });

  return {
    history,
    alert,
    doMarkPaid: (passId: string) => {
      markPassAsPaid({ variables: { passId } });
    },
  };
}
