import React, { ReactElement, useState } from 'react';
import CheckBox from '../../../components/checkBox/checkBox';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import style from './violationIncident.module.css';

export default function ViolationIncident(): ReactElement {
  const [isViolation, setIsViolation] = useState<boolean>(true);

  function handleSelectChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (e.target.value === 'violation') {
      setIsViolation(true);
    } else {
      setIsViolation(false);
    }
  }

  return (
    <div className={style.violationIncidentBox}>
      <div className="choose-action">
        {/* input select */}
        <div className={style.slectInputLong}>
          <label
            htmlFor="type-inpt"
            className={style.labelBox}
          >
            <p className={style.selectTitile}>Enter a Violation/Incident</p>
            <select
              id="type-inpt"
              className={style.selectUpper}
              onChange={handleSelectChange}
            >
              <option selected value="violation">Enter a Violation</option>
              <option value="incident">Enter a Incident</option>
            </select>
          </label>
        </div>
      </div>
      {/* main input infor */}
      <div className="content">
        <div className={style.mainInfor}>
          {/* First row */}
          <div className={style.firstInfor}>
            <p className={style.title}>{isViolation === true ? 'Violation Information' : 'Incident Information'}</p>
            <div className={style.inputsContent}>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="date-violation-inpt"
                    labelTitle="Date of Violation"
                  />
                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="type-violation-inpt"
                    labelTitle="Time of Violation"
                  />
                </div>
              </div>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="time-violation-inpt"
                    labelTitle="Violation Type"
                  />
                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="location-violation-inpt"
                    labelTitle="Location"
                  />
                </div>
              </div>
            </div>
            <div className={style.textAreaInput}>
              <label
                htmlFor="role-inpt"
                className={style.labelBox}
              >
                <p className={style.summaryTitle}>Summary of Events</p>
                <textarea className={style.textArea}>
                  demo text
                </textarea>
              </label>
            </div>
            <div className={style.genericBtn}>
              <div className={style.genericBtnInnerBox}>
                <GenericButton
                  color="blue"
                  size="large"
                  title="Upload Photos"
                  type="submit"
                />
              </div>
            </div>
          </div>
          {/* Second row */}
          <div className={style.secondInfor}>
            {/*  eslint-disable-next-line react/no-unescaped-entities */}
            <p className={style.title}>Offender's Information</p>
            <div className={style.inputsContent}>
              <div className={`${style.inputRow} ${style.relative}`}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="offender-name-inpt"
                    labelTitle="Offender's Name"
                  />
                </div>

                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="license-inpt"
                    labelTitle="Offender's License Plate"
                  />
                </div>
                <div className={style.btnSearch}>
                  <div className={style.searchIcon} />
                </div>
              </div>

            </div>
            <p className={style.or}>-Or-</p>
            <div className={style.inputsContent}>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="firstname-inpt"
                    labelTitle="First Name"
                  />
                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="lastname-inpt"
                    labelTitle="Last Name"
                  />
                </div>
              </div>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="phone-inpt"
                    labelTitle="Phone Number"
                  />
                </div>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="email-inpt"
                    labelTitle="Email Address"
                  />
                </div>
              </div>
              <div className={style.inputRow}>
                <div className={style.inputLong}>
                  <InputField
                    closedInput
                    htmlFor="emergency-inpt"
                    labelTitle="Emergency Phone Number"
                  />
                </div>

                <div className={style.inputLong}>
                  <label
                    htmlFor="role-inpt"
                    className={style.labelBox}
                  >
                    Role
                    <select
                      id="role-inpt"
                      className={style.select}
                    >
                      <option value={undefined} hidden>{' '}</option>
                      <option value="guest">Guest</option>
                      <option value="resident">Resident</option>
                      <option value="vendor">Vendor</option>
                      {/* <option value="employee">Employee</option> */}
                      <option value="admin">Admin</option>
                    </select>
                  </label>
                </div>
              </div>

            </div>
            <div className={style.genericBtn}>
              <div className={style.genericBtnInnerBox}>
                <GenericButton
                  color="blue"
                  size="large"
                  title="Add to Registery"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
        {
          isViolation
            ? (
              <div>
                <p className={style.fineAmountTitle}>Fine Amount</p>
                <div className={style.other}>
                  <div className="chk-box">
                    <input className={style.checkBox} type="checkbox" id="email-check-enable" />
                  </div>
                  <div className={style.inputAdd}>
                    <InputField
                      closedInput
                      htmlFor="fineamount-inpt"
                    />
                  </div>
                  <div className={style.btnBox}>
                    <GenericButton
                      color="transparent"
                      outline="large"
                      size="medium"
                      title="Add to Registery"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            ) : <></>
        }
        <div className={style.btnSubmit}>
          {
            isViolation ? (
              <div className={style.genericSubmitBtn}>
                <div className={style.genericSubmitBtnInnerBox}>
                  <GenericButton
                    color="yellow"
                    size="large"
                    title="Submit Violation"
                    type="submit"
                  />
                </div>
              </div>
            )
              : (
                <div className={style.genericSubmitBtn}>
                  <div className={style.genericSubmitBtnInnerBox}>
                    <GenericButton
                      color="yellow"
                      size="large"
                      title="Submit Incident"
                      type="submit"
                    />
                  </div>
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
}
