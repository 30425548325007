import React, { ReactElement } from 'react';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import { PopUp } from '../../../components/popUp/popUp';
import style from './addUser.module.css';
import useAddUser from './useAddUser';

export default function AddUser(): ReactElement {
  const {
    history,
    userInfo,
    editUserInfo,
    doAddUser,
    addUserData,
    addUserLoading,
    addUserError,
  } = useAddUser();
  return (
    <PopUp
      title="Add User"
      close={() => history.goBack()}
    >
      <form
        className={style.form}
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          doAddUser();
        }}
      >
        {
          addUserLoading
            ? (
              <LoadingDiamonds />
            ) : null
        }
        <div className={style.infoBox}>
          <h3 className={style.title}>Personal Information</h3>
          <GenericAlert
            title={addUserError}
            color="red"
            hidden={!addUserError}
          />

          <div className={style.personalInfoBox}>
            <div className={style.row}>
              <div className={style.inputShort}>
                <InputField
                  closedInput
                  htmlFor="fname-inpt"
                  labelTitle="First Name"
                  inputValue={userInfo.firstName}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editUserInfo('firstName', e.target.value);
                  }}
                  required
                />

              </div>
              <div className={style.inputShort}>
                <InputField
                  closedInput
                  htmlFor="lname-inpt"
                  labelTitle="Last Name"
                  inputValue={userInfo.lastName}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editUserInfo('lastName', e.target.value);
                  }}
                  required
                />

              </div>

            </div>
            <div className={style.row}>
              <div className={style.inputShort}>
                <InputField
                  closedInput
                  htmlFor="email-inpt"
                  labelTitle="Email Address"
                  inputValue={userInfo.email}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editUserInfo('email', e.target.value);
                  }}
                  required
                />
              </div>
              <div className={style.inputShort}>
                <InputField
                  closedInput
                  htmlFor="password-inpt"
                  labelTitle="Temporary Password"
                  inputValue={userInfo.password}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editUserInfo('password', e.target.value);
                  }}
                  required
                />
              </div>

            </div>
            <div className={style.inputShort}>
              <label htmlFor="role-inpt" className={style.label}>
                Role
                <select
                  id="role-inpt"
                  className={style.input}
                  value={userInfo.role || null}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    editUserInfo('role', e.target.value);
                  }}
                  required
                >
                  <option value={undefined} hidden>{' '}</option>
                  <option value="guest">Guest</option>
                  <option value="resident">Resident</option>
                  <option value="vendor">Vendor</option>
                  {/* <option value="host">Host</option> // host is not a user role - Parker */}
                  {/* <option value="employee">Employee</option> hide employee role */}
                  <option value="admin">Admin</option>
                </select>
              </label>

            </div>
          </div>
          <p className={`${style.note}  textColorDark`}>Note: An activation email will be sent upon adding user</p>
          <div className={style.btnBox}>
            <div className={style.btnAdd}>
              <GenericButton
                title="Add User"
                size="medium"
                color="yellow"
                type="submit"
              />
              <div className={style.btnView}>
                {
                  addUserData?.userId
                    ? (
                      <GenericButton
                        title="View In User Settings"
                        type="button"
                        size="medium"
                        color="blue"
                        clickHandler={() => {
                          history.replace(`/admin/report-center/user-settings/details/${addUserData.userId}`);
                        }}
                      />
                    ) : null
                }
              </div>
            </div>
            <div className={style.btnCancel}>
              <GenericButton
                size="medium"
                title="Cancel"
                type="button"
                clickHandler={() => {
                  history.goBack();
                }}
              />

            </div>
          </div>
        </div>
      </form>
    </PopUp>
  );
}
