import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { PopUp } from '../../../components/popUp/popUp';
import style from './moreDetails.module.css';

export default function MoreDetails(): ReactElement {
  const history = useHistory();
  const { passInfo, role }: any = history.location.state;
  return (
    <PopUp
      title="More Details"
      close={() => history.goBack()}
    >
      <div className={style.moreDetailsBox}>
        <div className={style.radiusBox}>
          <table width="100%" className={style.table} cellSpacing={0}>
            <thead className={`${style.tHead} textColorWhite mainColor`}>
              <tr className={style.tableHeader}>
                <th>Primary Driver Name</th>
                <th>Primary Driver&apos;s License #</th>
                <th>License Plate State</th>
                <th hidden={role !== 'vendor'}>Vehicle Fleet #</th>
              </tr>
            </thead>
            <tbody className={style.tBody}>
              <tr>
                <td>{passInfo.primaryDriverName}</td>
                <td>{passInfo.licensePrimaryDriver}</td>
                <td>{passInfo.licensePlateState}</td>
                <td hidden={role !== 'vendor'}>{passInfo.fleetNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <div className={style.radiusBox}>
          <table width="100%" className={style.table} cellSpacing={0}>
            <thead className={`${style.tHead} textColorWhite superDarkGrey`}>
              <tr className={style.tableHeader}>
                <th>Date</th>
                <th>Time</th>
                <th>Pass Status</th>
                <th>Pass Action</th>
              </tr>
            </thead>
            <tbody className={style.tBody}>
              <tr>
                <td>06/23/2021</td>
                <td>10:17AM</td>
                <td>
                  <span className={`${passInfo.passStatus ===
                    'active' ? style.passStatusActive : ''}
                    ${passInfo.passStatus === 'expired' ? style.passStatusExpired : ''}
                    ${passInfo.passStatus === 'Upcomming' ? style.passStatusUpcomming : ''}
                    ${passInfo.passStatus === 'inactive' ? style.passStatusInactive : ''}
                    textColorWhite`}
                  >
                    {passInfo.passStatus}
                  </span>
                </td>
                <td>Registration Completed</td>
              </tr>
            </tbody>
          </table>
        </div> */}

        <div className={style.radiusBox}>
          <div className={style.pairBox}>
            <span className={style.pairLabel}>Assigned Bar Code</span>
            <span className={style.pairValue}>{passInfo.barCode}</span>
          </div>
        </div>
      </div>
    </PopUp>
  );
}
