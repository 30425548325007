import React, { ReactElement } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface PropsType {
  color?: string;
  labels?: (string[] | string)[];
  values?: number[];
  stepSize?: number;
  yAxisTag?: string;
}

export default function ReportChart(props: PropsType): ReactElement {
  const {
    color, labels, values, stepSize, yAxisTag,
  } = props;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end' as any,
        offset: -20,
        align: 'start' as any,
        display: true,
        color: 'black' as any,
        formatter: Math.round,
      },
    },
    layout: {
      padding: {
        top: 20,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          stepSize,
          callback(tick: string | number) {
            return `${yAxisTag}${+tick / 1000}k`;
          },
        },
      },
    },
  };

  const dataChart = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: color,
        maxBarThickness: 60,
      },
    ],
  };

  return <Bar options={options} data={dataChart} plugins={[ChartDataLabels]} />;
}

ReportChart.defaultProps = {
  color: '#3E81D0',
  labels: [],
  values: [],
  stepSize: 500,
  yAxisTag: '',
};
