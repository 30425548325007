import React, { ReactElement } from 'react';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import { PopUp } from '../../../components/popUp/popUp';
import style from './editUser.module.css';
import GenericButton from '../../../components/genericButton/genericButton';
import useEditUser from './useEditUser';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';

export default function EditUser() :ReactElement {
  const {
    editChangeData,
    history,
    editSuccess,
    editLoading,
    editError,
    userData,
    userLoading,
    userError,
    doEditUser,
  } = useEditUser();

  if (userLoading) {
    return (
      <LoadingDiamonds
        title="Getting User Info..."
      />
    );
  }
  return (
    <PopUp
      title="Edit User"
      close={() => history.goBack()}
    >
      <form
        className={style.form}
        onSubmit={(e:React.FormEvent) => {
          e.preventDefault();
          doEditUser();
        }}
      >
        <GenericAlert
          title="User Edited Successfuly"
          color="green"
          hidden={!editSuccess}
        />
        <GenericAlert
          title={userError}
          color="red"
          hidden={!userError}
        />
        <GenericAlert
          title={editError}
          color="red"
          hidden={!editError}
        />
        <div className={style.infoBox}>
          <div className={style.header}>
            <h4 className={style.title}>Personal Information</h4>

          </div>
          <div className={style.paddingBox}>
            <div className={style.personalInfoBox}>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="fname-inpt"
                  labelTitle="First Name"
                  inputValue={userData.firstName}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData('firstName', e.target.value);
                  }}
                />

              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="lname-inpt"
                  labelTitle="Last Name"
                  inputValue={userData.lastName}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData('lastName', e.target.value);
                  }}
                />

              </div>

              <div className={style.inputBox}>
                {/* <InputField
                  closedInput
                  htmlFor="phone-inpt"
                  labelTitle="Tel Number"
                  formatCode="tel-strict"
                  inputPlaceholder={userData.phoneNumber}
                  inputValue={changeData.phoneNumber}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData('phoneNumber', e.target.value);
                  }}
                /> */}
                <PhoneInputWrapper
                  // placeHolder={userData.phoneNumber}
                  closedInput
                  value={userData.phoneNumber}
                  changeHandler={(val:string) => {
                    editChangeData('phoneNumber', val);
                  }}
                  inputTitle="Phone Number"
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="email-inpt"
                  labelTitle="Email"
                  inputValue={userData.email}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData('email', e.target.value);
                  }}
                />
              </div>

              <div className={style.inputBox}>
                <h4 className={style.role}>
                  Roles

                </h4>
                <ul className={style.roleItem}>
                  {(userData.roles?.map((r:string) => (
                    <li>{r}</li>
                  )))}
                </ul>
              </div>
              {/* <div className={style.inputShort}>
              <label htmlFor="role-inpt" className={style.label}>
                Add Role
                <select
                  id="role-inpt"
                  className={style.input}
                  value={(() => {
                    if (changeData.role) {
                      return changeData.role;
                    }
                    try {
                      return userData.roles[0];
                    } catch {
                      return '';
                    }
                  })()}
                >
                  <option hidden>{' '}</option>
                  <option value="guest">Guest</option>
                  <option value="resident">Resident</option>
                  <option value="vendor">Vendor</option>
                  <option value="host">Host</option>
                  <option value="employee">Employee</option>
                  <option value="admin">Admin</option>
                </select>
              </label>
            </div> */}
            </div>

          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            title="Save Changes"
            type="submit"
            color="blue"
          />

        </div>
      </form>
    </PopUp>
  );
}
