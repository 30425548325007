import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { PopUp } from '../../../components/popUp/popUp';
import style from './viewComplaint.module.css';

export default function ViewComplaint() :ReactElement {
  const history = useHistory();

  return (
    <PopUp
      title="Complaint details"
      close={() => history.goBack()}
    >
      <div className={style.infoBox}>
        <div className={style.picBox}>
          <div className={style.img} />
          <div className={style.img} />
          <div className={style.img} />
          <div className={style.img} />
        </div>
        <h5 className={style.title}>Description:</h5>
        <textarea className={style.textArea}>
          demo text
        </textarea>
      </div>
    </PopUp>
  );
}
