import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import DateDropDown from '../../../components/dateDropDown/dateDropDown';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import SelectMany from '../../../components/selectMany/selectMany';
import style from './reportSecurity.module.css';

export default function ReportSecurity(): ReactElement {
  const history = useHistory();
  return (
    <div className={style.container}>
      <div className={style.overview}>
        <span className={style.overViewTitle}>Overview</span>
        <div className={style.box_overview}>
          <p className="totalUnresolve">Unresolved</p>
          <p> 12</p>
        </div>
        <div className={style.box_overview}>
          <p className="totalResolve">Resolved</p>
          <p> 12</p>
        </div>
        <div className={style.box_overview}>
          <p className="all">All</p>
          <p> 12</p>
        </div>
      </div>
      <div className={style.tableBox}>
        <div className={style.box_search}>
          <div className={style.inputLong}>
            <input placeholder="Search" className={style.searchInput} />
          </div>
          <div className={`${style.selectMany} ${style.idx1}`}>
            <SelectMany
              id="type-select-many-inpt"
              title="Type"
              options={[
                {
                  name: 'Violant',
                  title: 'Violant',
                }, {
                  name: 'Incident',
                  title: 'Incident',
                }, {
                  name: 'Complaint',
                  title: 'Complaint',
                },
              ]}
              changeHandler={() => console.log('test')}
              values={[]}
            />
          </div>
          <div className={`${style.selectMany} ${style.idx2}`}>
            <SelectMany
              id="status-select-many-inpt"
              title="Status"
              options={[
                {
                  name: 'All',
                  title: 'All',
                }, {
                  name: 'Resolved',
                  title: 'Resolved',
                }, {
                  name: 'Unresolved',
                  title: 'Unresolved',
                },
              ]}
              changeHandler={() => console.log('test')}
              values={[]}
            />
          </div>
          <div className={`${style.selectMany} ${style.idx3}`}>
            <SelectMany
              id="type-select-many-inpt"
              title="Assignee"
              options={[
                {
                  name: 'Carson',
                  title: 'Carson',
                }, {
                  name: 'Oscar',
                  title: 'Oscar',
                }, {
                  name: 'Peter',
                  title: 'Peter',
                },
              ]}
              changeHandler={() => console.log('test')}
              values={[]}
            />
          </div>

          <div className={`${style.selectMany} ${style.idx4}`}>
            <DateDropDown
              id="security-date-inputs"
              title="Date"
              values={[]}
              changeHandler={() => console.log('function')}
            />

          </div>
        </div>
        <div className={style.radiusBoxSecurity}>
          <table width="100%" cellSpacing="0px" className={style.tableSecurity}>
            <thead className={style.tHead}>
              <tr className={`${style.tableHeader}`}>
                <th className={style.firstTh}>
                  &#160;
                </th>
                <th className={style.secondTh}>
                  &#160;
                </th>
                <th className={`${style.theaderItem} ${style.nameLength} textColorWhite`}>
                  Type
                </th>
                <th className={`${style.theaderItem} ${style.phoneNumberLength} textColorWhite`}>
                  Description
                </th>
                <th className={`${style.theaderItem} ${style.emailLength} textColorWhite`}>
                  Submitted By
                </th>
                <th className={`${style.theaderItem} ${style.mailingLength} textColorWhite`}>
                  Concerning
                </th>
                <th className={`${style.theaderItem} ${style.ownerpinLength} textColorWhite`}>
                  Role
                </th>
                <th className={`${style.theaderItem} ${style.cityLength} textColorWhite`}>
                  Reporter
                </th>
                <th className={`${style.theaderItem} ${style.passNumberLength} textColorWhite`}>
                  Assignee
                </th>
                <th className={`${style.theaderItem} ${style.approverLength} textColorWhite`}>
                  Status
                </th>
                <th className={`${style.theaderItem} ${style.dateLength} textColorWhite`}>
                  Date
                </th>
                <th className={`${style.theaderItem} ${style.dateLength} textColorWhite`}>
                  Fine Amount
                </th>
              </tr>
            </thead>
            <tbody>

              <tr className={`${style.row} white`}>
                <td className={`${style.tdItems} ${style.center}`}>
                  <div className={style.detailBtnBox}>
                    <GenericButton
                      outline="small"
                      title="Details"
                      clickHandler={() => {
                        history.push('/admin/report-center/security-details');
                      }}
                    />
                  </div>
                  <ReactTooltip />

                </td>
                <td className={`${style.tdItems}`}>
                  CB-12
                </td>
                <td className={style.tdItems}>
                  Violation
                </td>
                <td className={style.tdItems} data-tip="Neighbourss are noisy...">
                  Neighbourss are noisy...
                </td>
                <td className={style.tdItems} data-tip="James Botosh">
                  James Botosh
                </td>
                <td className={style.tdItems} data-tip="Kaylinn Staton">
                  Kaylinn Staton
                </td>
                <td className={style.tdItems}>
                  Guest
                </td>
                <td className={`${style.tdItems}`} data-tip="Jay Zark">
                  <div className="avatar">avatar</div>
                  <span>Jay Zark</span>
                </td>
                <td className={style.tdItems} data-tip="Jay Zark">
                  <div className="avatar">avatar</div>
                  <span>Jay Zark</span>
                </td>
                <td className={style.tdItems}>
                  Unresolved
                </td>
                <td className={style.tdItems}>
                  2021-12-8 13:08
                </td>
                <td className={style.tdItems}>
                  20$
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div className={style.pageBox}>
          <div>
            <GenericButton
              size="small"
              color="grey"
              title="Prev Page"
              clickHandler={() => console.log('next function')}
            />

          </div>
          <div>
            <label htmlFor="page-num-display">
              <input
                placeholder="1"
                type="text"
                className={style.pageInput}
              />
              /10
            </label>
          </div>
          <div>
            <GenericButton
              size="small"
              color="grey"
              title="Next Page"
              clickHandler={() => console.log('next function')}
            />

          </div>
        </div>
      </div>
    </div>
  );
}
