import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import CheckBox from '../../../components/checkBox/checkBox';
import PassTypeList from '../../../components/passTypeList/passTypeList';
import ReportChart from '../../../components/reportChart/reportChart';
import style from './reportCenterReports.module.css';
import useReportCenterReports, { PassType, paymentTypes, periodOptions } from './useReportCenterReports';
import { ReactComponent as RightChevron } from '../../../assets/rightChevron.svg';
import { ReactComponent as MarkIcon } from '../../../assets/markIcon.svg';
import { ReactComponent as Dollar } from '../../../assets/dollar.svg';
import { ReactComponent as SoldIcon } from '../../../assets/soldIcon.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/creditCardIcon.svg';
import { capitalizeFirstLetter } from '../../../common_lib_front/utilities/stringUtils';
import YearPicker from '../../../components/yearPicker/yearPicker';
import DateRangePicker from '../../../components/dateRangePicker/dateRangePicker';
import TimePicker from '../../../components/timePicker/timePicker';
import GenericAlert from '../../../components/genericAlert/genericAlert';

export default function ReportCenterReports(): ReactElement {
  const {
    currentReport,
    portals,
    passTypes,
    passTypeIds,
    selectedPassTypes,
    setSelectedPassTypes,
    loading,
    reportData,
    paymentType,
    setPaymentType,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    year,
    setYear,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    periodOption,
    setPeriodOption,
    week,
    setWeek,
    isAllDayTime,
    setIsAllDayTime,
    searchPassesError,
  } = useReportCenterReports();

  const [open, setOpen] = useState(false);
  const [checkboxState, setCheckboxState] = useState<Array<boolean>>([]);

  const totalNumber = reportData?.totalNumberPasses || 0;
  const totalAmount = reportData?.totalDollarAmount || 0;

  const chartNumberValues = reportData?.listPassTypes?.map((i) => i.numberPasses) || [];
  const chartLabels = reportData?.listPassTypes?.map((i) => [i.name, (i.portal && i.portal.length > 0) ? `(${capitalizeFirstLetter(i.portal)})` : '']) || [];
  const chartAmountValues = reportData?.listPassTypes?.map((i) => i.dollarAmount) || [];

  const dummyData = {
    week: [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ],
  };

  const passTypesByPortal: { [key: string]: PassType[] } = {};
  portals.forEach((portal: string) => {
    passTypesByPortal[portal] = passTypes
      .filter((passType: PassType) => passType.portal === portal
        && passTypeIds.indexOf(passType.passInfoId) !== -1);
  });

  useEffect(() => {
    for (let i = 0; i <= passTypes.length; i += 1) {
      const newCheckboxState = [...checkboxState];
      newCheckboxState.push(false);
      setCheckboxState(newCheckboxState);
    }
  }, [passTypes]);

  const handleChecked = (index: number, isChecked: boolean, data: any, type: any, setType: any) => {
    const newType = [...type];
    if (selectedPassTypes.indexOf(data[index]) === -1
      && isChecked) {
      newType.push(data[index]);
      newType.sort((a, b) => {
        const order = [...data];
        return order.indexOf(a) - order.indexOf(b);
      });

      setType(newType);
    } else {
      newType.map((item, i) => {
        if (item === data[index]) {
          newType.splice(i, 1);
        }
        return newType;
      });
      setType(newType);
    }
  };

  const handleDateChange = (event: any, option: string) => {
    if (event.target.value) {
      setPeriodOption(option);
    }
  };
  const handleTimeChange = (event: any, data: boolean) => {
    if (event.target.value) {
      setIsAllDayTime(data);
    }
  };

  const showWeekDays = () => {
    if (week?.length === 0) {
      return 'No days';
    }
    if (week?.length === 7) {
      return 'All days';
    }
    if (week?.length === 1) {
      return week[0];
    }
    const first = week?.slice(0, week?.length - 1);
    const last = week?.[week?.length - 1];
    return `${first?.join(', ')} & ${last}`;
  };

  const showPaymentTypes = () => {
    if (paymentType?.length === 0) {
      return 'No Payment Types';
    }
    if (paymentType?.length === 1) {
      return paymentType[0];
    }
    const first = paymentType?.slice(0, paymentType?.length - 1);
    const last = paymentType?.[paymentType?.length - 1];
    return `${first?.join(', ')} & ${last}`;
  };

  const formatDate = (d?: Date | string): string => {
    const date = typeof d === 'object' ? d : new Date(d || '');
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const exportCsv = () => {
    let content = reportData!.listPassTypes.map((p) => `${p.name} (#),${p.name} ($)`).join(',');
    content += ',Total Number of Passs,Total Amount ($)\n';

    content += reportData!.listPassTypes.map((p) => `${p.numberPasses},${p.dollarAmount}`).join(',');
    content += `,${reportData!.totalNumberPasses},${reportData!.totalDollarAmount}`;

    const link = document.createElement('a');
    link.download = 'report.csv';
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`;
    link.click();
  };

  const exportPdf = function () {
    const container = document.getElementById('export-pdf-container');
    if (container) {
      html2canvas(container, {
        scale: Math.max(window.devicePixelRatio, 2),
      }).then((canvas) => {
        const margin = 10; // in millimetres

        const pdf = new JsPDF('p', 'mm', 'a4');

        const pageWidth = pdf.internal.pageSize.getWidth() - margin * 2;
        const pageHeight = pdf.internal.pageSize.getHeight() - margin * 2;

        const scaleFactor = canvas.width / pageWidth;
        const canvasPageHeight = pageHeight * scaleFactor;

        const ctx = canvas.getContext('2d');

        let top = 0;
        let page = pdf;

        // pagination
        while (top < canvas.height) {
          const cropHeight = Math.min(canvasPageHeight, canvas.height - top);
          const imgData = ctx!.getImageData(
            0,
            top,
            canvas.width,
            cropHeight,
          );

          page.addImage(
            imgData,
            'PNG',
            margin,
            margin,
            pageWidth,
            cropHeight / scaleFactor,
          );

          top += canvasPageHeight;
          if (top < canvas.height) {
            page = pdf.addPage();
          }
        }

        pdf.save('report.pdf');
      });
    }
  };

  const periodTitle = periodOption === periodOptions.year
    ? year : `${formatDate(startDate)} ~ ${formatDate(endDate)}`;

  return (
    <div className={style.container}>
      <GenericAlert color="red" title={searchPassesError as string} hidden={!searchPassesError} />
      <div className={`${style.topBar} ${style.boxShape} white`}>
        <div className={style.btnGroup}>
          <Link to="/admin/report-center/reports/passes-sold" className={`${style.btn} ${currentReport === 'passes-sold' ? style.active : ''}`}>
            Passes Sold
          </Link>
          <Link to="/admin/report-center/reports/passes-activated" className={`${style.btn} ${currentReport === 'passes-activated' ? style.active : ''}`}>
            Passes Activated
          </Link>
        </div>
        <div className={style.exportPart}>
          <button
            onClick={() => (
              setOpen(!open)
            )}
            className={style.ExportBtn}
          >
            Export Data
          </button>
          {open
            && (
              <div className={style.dropDown}>
                <button
                  onClick={() => exportCsv()}
                  disabled={!reportData || loading === true}
                >
                  Export a csv file
                </button>
                <button onClick={() => exportPdf()}>Export a pdf</button>
              </div>
            )}
        </div>
      </div>
      <div className={`${style.content} `} id="export-pdf-container">
        <div className={`${style.passTypeList} ${style.boxShape}`}>
          {' '}
          <div className={style.listTitle}>List of Pass Types</div>
          {portals.map((portal: string) => (
            <PassTypeList
              key={portal}
              listMode
              title={portal}
              data={passTypesByPortal[portal]}
              close={() => {
                setSelectedPassTypes(
                  selectedPassTypes.filter(
                    (passType) => passType.portal !== portal,
                  ),
                );
              }}
              isChecked={(
                index: number,
                isChecked: boolean,
                data: any,
                type: any,
                setType: any,
              ) => {
                handleChecked(
                  index,
                  isChecked,
                  passTypesByPortal[portal],
                  selectedPassTypes,
                  setSelectedPassTypes,
                );
              }}
            />
          ))}
        </div>
        <div className={style.boxRight}>
          <div className={`${style.paymentTypeBox} ${style.boxShape}`}>
            <h4 className={style.sectionTitle}>Payment Type</h4>
            <div className={style.paymentOutsideBox}>
              <span className={style.paymentFlexBox}>
                {paymentTypes.map((item, index) => (
                  <div className={style.paymentTypeItem}>
                    <CheckBox
                      large
                      normalMode
                      item={item}
                      index={index}
                      isChecked={(i: number, isChecked: boolean, data: any,
                        type: any, setType: any) => {
                        handleChecked(index, isChecked,
                          paymentTypes, paymentType, setPaymentType);
                      }}
                    />
                  </div>
                ))}
              </span>
            </div>
          </div>

          <div className={style.timeBox}>
            <div className={`${style.year} ${style.boxShape}`}>
              <p className={style.yearTitle}>Year</p>
              <label htmlFor="Year" className={style.label}>

                <div className={style.dateRangeBox}>
                  <input
                    type="radio"
                    name="Year"
                    id="Year"
                    checked={periodOption === periodOptions.year}
                    onChange={(evt) => handleDateChange(evt, periodOptions.year)}
                    className={style.radioBox}
                  />

                </div>
                <div style={{ width: '100%' }}>
                  <YearPicker
                    year={year}
                    setYear={setYear}
                    disabled={periodOption !== periodOptions.year}
                  />
                </div>

              </label>
              <p className={style.dateTitle}>Date Range</p>
              <label htmlFor="Date Range" className={style.label}>
                <div className={style.dateRangeBox}>
                  <input
                    type="radio"
                    name="DateRange"
                    id="DateRange"
                    checked={periodOption === periodOptions.dateRange}
                    onChange={(evt) => handleDateChange(evt, periodOptions.dateRange)}
                    className={style.radioBox}
                  />

                </div>
                <div>
                  <DateRangePicker
                    disabled={periodOption === periodOptions.dateRange}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </div>
              </label>
            </div>
            <div className={`${style.day} ${style.boxShape}`}>
              <h4 className={style.sectionTitle}>Day of Week</h4>
              <span className={style.weekFlexBox}>
                {dummyData.week.map((item, index) => (
                  <span className={style.checkBoxMargin}>
                    <CheckBox
                      large
                      normalMode
                      item={item}
                      index={index}
                      isChecked={(i: number, isChecked: boolean, data: any,
                        type: any, setType: any) => {
                        handleChecked(index, isChecked,
                          dummyData.week, week, setWeek);
                      }}
                    />
                  </span>
                ))}
              </span>
            </div>
            <div className={`${style.time} ${style.boxShape}`}>
              <h4 className={style.sectionTitle}>Time of Day</h4>
              <div className={style.timeBoxMargin}>
                <label htmlFor="All Day">
                  <div className={style.allDayBox}>
                    <input
                      type="radio"
                      name=""
                      id="All Day"
                      value="finish"
                      checked={isAllDayTime}
                      onChange={(evt) => handleTimeChange(evt, true)}
                      className={style.radioBox}
                    />
                    <p>All Day</p>
                  </div>
                </label>
              </div>
              <label htmlFor="Specific Time Frame">
                <div className={style.dateRangeBox}>
                  <input
                    type="radio"
                    name=""
                    id="Specific Time Frame"
                    checked={!isAllDayTime}
                    onChange={(evt) => handleTimeChange(evt, false)}
                    className={style.radioBox}
                  />
                  <p>
                    Specific Time Frame
                    {startTime > endTime && (
                      <span className={style.timeRangeError}>Invalid time range!</span>
                    )}
                  </p>
                </div>
                <div className={style.timeBoxes}>
                  <div className={style.timePickerBox}>
                    <p className={style.timeTitle}>Start Time</p>
                    <TimePicker time={startTime} setTime={setStartTime} disabled={!isAllDayTime} />
                  </div>
                  <div className={style.timePickerBox}>
                    <p className={style.timeTitle}>End Time</p>
                    <TimePicker time={endTime} setTime={setEndTime} disabled={!isAllDayTime} />
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div>
            <div className={`${style.overview} ${style.boxShape}`}>
              <div className={style.overview__boxLeft}>
                <span className={style.overview__title}>Overview</span>
                <div className={style.overview__filters}>
                  <span className={style.filterTexts}>
                    <RightChevron className={style.right__chevron} />
                    {periodTitle}
                  </span>
                  <span className={style.filterTexts}>
                    <RightChevron className={style.right__chevron} />
                    {showWeekDays()}
                  </span>
                </div>
              </div>
              <div className={style.overview__boxRight}>
                <div className={` ${style.overview__bg}`}>
                  <div className={style.overview__infos}>
                    <div>
                      <div className={style.overview__totalTitle}>{currentReport === 'passes-sold' ? 'Total number of passes sold' : 'Total number of passes activated'}</div>
                      <div className={style.overview__amount}>{totalNumber?.toLocaleString()}</div>
                    </div>
                    <MarkIcon />
                  </div>
                </div>
                <div className={` ${style.overview__bg}`}>
                  <div className={style.overview__infos}>
                    <div>
                      <div className={style.overview__totalTitle}>Total Amount</div>
                      <div className={style.overview__amount}>
                        $
                        {totalAmount?.toLocaleString()}
                      </div>
                    </div>
                    <Dollar />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.charts} ${style.boxShape}`}>
              <div className={style.passTypeItemBox}>
                {chartLabels?.map((item) => (
                  <li
                    className={style.passTypeItem}
                  >
                    {`${item[0]} ${item[1]}`}
                  </li>
                ))}
              </div>
              <div className={style.overview__filters}>
                <span className={style.filterTexts}>
                  {showPaymentTypes()}
                </span>
                <span className={style.filterTexts}>
                  <RightChevron className={style.right__chevron} />
                  {periodTitle}
                </span>
                <span className={style.filterTexts}>
                  <RightChevron className={style.right__chevron} />
                  {showWeekDays()}
                </span>
              </div>
              <div className={style.charts__box}>
                <div className={style.chart__container}>
                  <div className={style.chart__header}>
                    <div className={style.chart__header__title}>
                      {currentReport === 'passes-sold' ? 'Total number of passes sold' : 'Total number of passes activated'}
                      <span className={style.svg}>
                        <SoldIcon />
                      </span>
                    </div>
                    <div className={style.chart__header__amount}>{totalNumber}</div>
                  </div>
                  <div className={style.chart}>
                    <ReportChart color="#F89827" labels={chartLabels} values={chartNumberValues} />
                  </div>
                </div>
                <div className={style.chart__container}>
                  <div className={style.chart__header}>
                    <div className={style.chart__header__title}>
                      Total dollar amount
                      <span className={style.svg}>
                        <CreditCardIcon />
                      </span>
                    </div>
                    <div className={style.chart__header__amount}>{`$${totalAmount}`}</div>
                  </div>
                  <div className={style.chart}>
                    <ReportChart labels={chartLabels} values={chartAmountValues} stepSize={5000} yAxisTag="$" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
