import React, { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import style from './card.module.css';

interface Props {
  img: any;
  alt?: string;
  title: string;
  description: string;
  buttonLabel: string;
  href: string;
}

export const Card = (props: Props): ReactElement => {
  const {
    img, alt, title, description, buttonLabel, href,
  } = props;
  const { communityId } = useContext(CommunityContext);

  const body = (
    <div className={style.cardBox}>
      <div className={`${style.card} white `}>
        <img src={img} alt={alt} className={style.image} />
        <h4 className={`${style.title} textColorDark `}>{title}</h4>
        <p className={`${style.description} textColorDark `}>{description}</p>
        <div className={style.btnBox}><button className={`${communityId === 'grandcomplex' ? style.btnGc : style.btn} textColorWhite  mainColor `}>{buttonLabel}</button></div>
      </div>
    </div>
  );

  if (href.startsWith('https')) {
    return (
      <a href={href}>
        {body}
      </a>
    );
  }
  return (
    <Link to={href}>
      {body}
    </Link>
  );
};
Card.defaultProps = {
  alt: 'card img',
};
export default Card;
