import { AgGridReact } from 'ag-grid-react';
import React, {
  ReactElement, useState, useRef, useCallback,
} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom';
import { formatDate, formatDateWithMonthName } from '../../common_lib_front/utilities/formatDate';
import PassInfo from '../../common_lib_front/types/passInfo';
import dataGridStyle from './vendorPassGrid.module.css';
import PopupCellRenderer from './popupCellRenderer';

const passStatusMapTitles: { [key: string]: string } = {
  active: 'Activated',
  inactive: 'Ready to Scan',
  incomplete: 'Incomplete',
  'incomplete-rental-car': 'Incomplete',
  expired: 'Expired',
  refunded: 'Refunded',
  'temporary-issued': 'Temporary Pass',
};
const passStatusStyleMap: { [key: string]: string} = {
  active: dataGridStyle.active,
  inactive: dataGridStyle.readyToScan,
  incomplete: dataGridStyle.incomplete,
  expired: dataGridStyle.expired,
  refunded: dataGridStyle.refunded,
  // 'incomplete-rental-car': dataGridStyle.incompleteRentalCar,
  'incomplete-rental-car': dataGridStyle.incomplete,
};

const paymentStatusMapTitles: { [key: string]: string } = {
  paid: 'Paid',
  unpaid: 'Unpaid',
  'no-charge': 'No Charge',
  refunded: 'Refunded',
};
const paymentStatusStyleMap: { [key: string]: string } = {
  paid: dataGridStyle.active,
  unpaid: dataGridStyle.incomplete,
  'no-charge': dataGridStyle.active,
  refunded: dataGridStyle.refunded,
};

type VendorPassGridProps = {
  data: PassInfo[],
};

export default function VendorPassGrid(props: VendorPassGridProps): ReactElement {
  const { data } = props;
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const rowData = data.flatMap((item) => ({
    data: item,
    passNumber: item.number,
    companyName: item?.companyInfo?.companyName || '',
    vehicleMake: item?.vehicle?.licensePlate || '',
    destination: item?.vehicle?.destination || '',
    passType: item?.passInfo?.name || '',
    startDate: item.startDate ? item.startDate.slice(0, 10) : '',
    endDate: item.endDate ? item.endDate.slice(0, 10) : '',
    status: item.status,
    paymentStatus: item.paid,
    price: item?.passInfo?.priceInfo?.price ?? '',
  }));

  const gridRef = useRef<AgGridReact>(null);

  const PassNumber = (item: { data: PassInfo }) => {
    const { data: { passNumber, data: itemData } } = item;
    const { vehicle } = itemData;
    return (
      <Link className={dataGridStyle.tableLink} to={{ pathname: '/admin/popup/more-details', state: { passInfo: { ...itemData, ...vehicle }, role: 'vendor' } }}>
        {passNumber}
      </Link>
    );
  };

  const CompanyName = (item: { data: PassInfo }) => {
    const { data: { data: itemData } } = item;
    const { userId } = itemData;
    return (
      <Link className={dataGridStyle.tableLink} to={`/admin/report-center/vendor/details/${userId}`}>
        {itemData?.companyInfo?.companyName}
      </Link>
    );
  };

  const PassStatus = ({ value }: { value: string }) => (
    <div className={`${dataGridStyle.gridStatusBox} ${passStatusStyleMap[value] || ''}`}>
      {passStatusMapTitles[value] || value}
    </div>
  );

  const PaymentStatus = ({ value }: { value: string }) => (
    <div className={`${dataGridStyle.gridStatusBox} ${paymentStatusStyleMap[value] || ''}`}>
      {paymentStatusMapTitles[value] || value}
    </div>
  );

  const dateFormatter = ({ value }: { value: string | number }) => formatDate(new Date(value));

  const filterParams = {
    comparator: (filterDate: Date, cellValue: string) => {
      const cellDate = new Date(`${cellValue}T00:00:00`);
      if (cellDate < filterDate) {
        return -1;
      }
      if (cellDate > filterDate) {
        return 1;
      }
      return 0;
    },
  };

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    // wrapText: true, causing checkbox to wrap down
    flex: 1,
    wrapHeaderText: true,
  };

  const [columnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
      width: 160,
    },
    {
      headerName: 'Pass Number',
      field: 'passNumber',
      cellRendererFramework: PassNumber,
      minWidth: 200,
    },
    {
      headerName: 'Company Name',
      field: 'companyName',
      cellRendererFramework: CompanyName,
      minWidth: 200,
    },
    {
      headerName: 'License Plate #',
      field: 'vehicleMake',
      minWidth: 200,
    },
    {
      headerName: 'Destination',
      field: 'destination',
      minWidth: 200,
    },
    {
      headerName: 'Pass Type',
      field: 'passType',
      minWidth: 200,
    },
    {
      headerName: 'Price',
      field: 'price',
      minWidth: 100,
    },
    {
      headerName: 'Pass Purchase Date',
      field: 'startDate',
      filter: 'agDateColumnFilter',
      filterParams,
      valueFormatter: dateFormatter,
      minWidth: 200,
    },
    {
      headerName: 'Pass expiration Date',
      field: 'endDate',
      filter: 'agDateColumnFilter',
      filterParams,
      valueFormatter: dateFormatter,
      minWidth: 200,
    },
    {
      headerName: 'Pass Status',
      field: 'status',
      cellRendererFramework: PassStatus,
      minWidth: 200,
    },
    {
      headerName: 'Payment Status',
      field: 'paymentStatus',
      cellRendererFramework: PaymentStatus,
      minWidth: 200,
    },
  ]);

  // Export to CSV
  const onBtnExport = useCallback(() => {
    // gridRef.current?.api.exportDataAsCsv({
    //   columnKeys: [
    //     'passNumber',
    //     'companyName',
    //     'vehicleMake',
    //     'destination',
    //     'passType',
    //     'startDate',
    //     'endDate',
    //     'status',
    //     'paymentStatus',
    //   ],
    // });
    const columnKeys = [
      'passNumber',
      'companyName',
      'vehicleMake',
      'destination',
      'passType',
      'price',
      'startDate',
      'endDate',
      'status',
      'paymentStatus',
    ];
    const headers: string[] = [];
    columnDefs.slice(1).forEach((col) => {
      headers.push(col.headerName);
    });
    const dataToExport: string[] = [headers.join(',')];
    const allNodes: any[] = [];
    gridRef.current?.api.forEachNode((row) => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes.sort((a, b) => a.rowIndex - b.rowIndex).forEach((row) => {
      const { data: item } = row;
      const itemData: any[] = [];
      columnKeys.forEach((key: string) => {
        if (key === 'paymentStatus') {
          itemData.push(paymentStatusMapTitles[item[key]] || item[key]);
        } else if (key === 'status') {
          itemData.push(passStatusMapTitles[item[key]] || item[key]);
        } else if (key === 'endDate' || key === 'startDate') {
          itemData.push(formatDateWithMonthName(new Date(item[key])));
        } else if (['destination', 'companyName'].includes(key)) {
          itemData.push(`"${item[key]}"`);
        } else {
          itemData.push(`"${item[key]}"`);
        }
      });
      dataToExport.push(itemData.join(','));
    });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);

  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <div>
      <div className={dataGridStyle.topLine}>
        <input
          type="search"
          className={dataGridStyle.gridSearch}
          onChange={onFilterTextChange}
          placeholder="Search anything here .."
        />
        <label htmlFor="page-num-inpt">
          Show
          <select
            className={dataGridStyle.selectBox}
            value={itemsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setItemsPerPage(Number.parseInt(e.target.value, 10));
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          Per Page
        </label>
        <button className={dataGridStyle.btnExport} onClick={onBtnExport}>Export CSV</button>
      </div>
      <div className={dataGridStyle.radiusBox}>
        <div
          className="ag-theme-alpine"
          style={{
            height: '100%',
            width: '100%',
            overflow: 'scroll',
          }}
        >
          <AgGridReact
            rowData={ rowData }
            columnDefs={ columnDefs }
            defaultColDef={defaultColDef}
            enableCellTextSelection
            ensureDomOrder
            animateRows
            pagination
            paginationPageSize={itemsPerPage}
            // paginationAutoPageSize
            ref={gridRef}
          />
        </div>
      </div>
    </div>
  );
}
