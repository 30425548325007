import React, {
  ReactElement,
  useState,
  useCallback,
  useRef,
} from 'react';
import GenericButton from '../genericButton/genericButton';
import style from './dropDownGuestInfo.module.css';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import useOnClickOutside from '../../common_lib_front/hooks/useClickOutside';

export default function DropDownGuestInfo(props: {
  options?: Array<{
    title: string,
    userInfo: string,
  }>
  body?: ReactElement,
  title?: string,
  id:string
}): ReactElement {
  const {
    options, title, body, id,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const clickOutsideHandler = useCallback(() => setOpen(false), [setOpen]);
  useOnClickOutside(containerRef, clickOutsideHandler);

  //  const {
  //    open, setOpen, containerId,
  //  } = useContainerBlur(`${id}-container`, {
  //    defaultState: false,
  //    autoCloseInnerButton: true,
  //    autoOpen: true,
  //  });

  let buttonSelect;
  if (open) {
    buttonSelect = style.buttonSelectUp;
  }
  if (!open) {
    buttonSelect = style.buttonSelectDown;
  }

  return (
    <div ref={containerRef} className={style.infoBox}>
      <div className={buttonSelect}>
        <GenericButton
          title=""
          clickHandler={() => setOpen(!open)}
        />

      </div>
      {
        open
          ? (
            <div className={style.dropDownBox}>
              <h4 className={style.title}>{title}</h4>
              {options?.map((opt) => (
                <ul>
                  <li className={style.title}>
                    {opt.title}
                    {' '}
                    {opt.userInfo}
                  </li>
                </ul>
              ))}
              {body}
            </div>
          ) : null
      }
    </div>
  );
}
DropDownGuestInfo.defaultProps = {
  title: undefined,
  body: undefined,
  options: undefined,
};
