import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { QueryString } from '../../../common_lib_front/utilities/QueryString';
import { backendPaginatedResponse } from '../../../common_lib_front/types/backendResponse';

export type QueryParamsType = {
  'role'?: string,
  'firstName'?: string,
  'lastName'?: string,
  'email'?: string,
  'arrivalDate'?: string,
  'arrivalDateRange'?: string,
  'departureDate'?: string,
  'companyName'?: string,
  'phoneNumber'?: string,
  'rentalAddress'?: string,
  'streetAddr'?: string,
  'city'?: string,
  'barCode'?: string,
  'employeeFirstName'?: string,
  'employeeLastName'?: string,
  'passNumber'?: string,
  'propertyManager'?: string,
  'includePastReservations'?: string,
  [key: string]: string | undefined,
}

const SEARCH_RESERVATIONS = gql`
query SearchReservations(
  $arrivalDate: DateTime,
  $arrivalDateRange: DateTime,
  $departureDate: DateTime,
  $invitedBy: String,
  $address: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $phoneNumber: String,
  $passNumber: Float,
  $sortOrder: Float, 
  $pageSize: Float!, 
  $pageNumber: Float!, 
  $includePastReservations: Boolean,
) {
  searchReservations(
    arrivalDateFrom: $arrivalDate, 
    arrivalDateTo: $arrivalDateRange, 
    departureDate: $departureDate, 
    invitedBy: $invitedBy, 
    address: $address, 
    firstName: $firstName, 
    lastName: $lastName, 
    email: $email, 
    phoneNumber: $phoneNumber, 
    passNumber: $passNumber
    sortOrder: $sortOrder, 
    pageSize: $pageSize,
    pageNumber: $pageNumber,
    includePastReservations: $includePastReservations,
  ) {
    success
    error
    numberPages
    data {
      communityRentalId
      registrationId
      address
      arrivalDate
      departureDate
      guest {
        userId
        firstName
        lastName
        email
        phoneNumber
        address
        invitedBy
      }
      invitedBy {
        userId
        firstName
        lastName
        email
        phoneNumber
        address
        city
        state
        companyName
      }
      passes {
        passId
        passType
        number
        startDate
        endDate
        status
        paid
        registrationId
        vehicle {
          make
          color
          vehicleModel
          licensePlate
          type
        }
      }
    }
  }
}
`;
export type searchedReservations = {
  arrivalDate: Date,
  departureDate: Date,
  address: string,
  guest: UserType,
  invitedBy?: InvitedByType,
  numberOfVehicles: number,
  passes: [PassType],
}
type InvitedByType = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  companyName: string;
}
type UserType = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string
  phoneNumber: string;
  address: string;
  country: string;
  city: string;
  state: string;
  invitedBy: string;
}
type VehicleType = {
  make: string;
  color: string;
  vehicleModel: string;
  licensePlate: string;
  type: string;
}
type PassType = {
  passId: string;
  passType: string,
  number: number;
  startDate: Date;
  endDate: Date;
  status: string;
  paid: boolean;
  registrationId: string;
  vehicle: VehicleType;
}
type SEARCH_RESERVATIONS_VARS = {
  arrivalDate?: string,
  arrivalDateRange?: string,
  departureDate?: string,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  email?: string,
  invitedBy?: string,
  address?: string,
  passNumber?: number,
  sortOrder?: number,
  pageSize: number,
  pageNumber: number,
  includePastReservations: boolean,
}
type SEARCH_RESERVATIONS_RES = {
  searchReservations: backendPaginatedResponse<Array<searchedReservations>>
}

interface ISelected {
  [key: string]: boolean
}

export default function useReportCenterGuestSearch(): {
  search: (resetPageNum?: boolean) => void,
  queryParams: QueryParamsType,
  // eslint-disable-next-line
  qpChangeHandler: (key: keyof QueryParamsType) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string) => void,
  searchRes: Array<searchedReservations>,
  searchLoading: boolean,
  searchError: string,
  marginSet: string,
  marginSetUserSetting: string,
  role: 'guest' | 'vendor' | 'resident' | 'user-settings',
  history: History | any,
  pageInfo: { pageNum: number, numPerPage: number, numPages: number },
  pageChange: (num: number) => void,
  pageValidator: (num: number) => number,
  numPerPageChange: (num: number) => void,
  // Selected object takes ids as properties and boolean to determine which ids are selected
  selected: ISelected,
  setSelected: (object: any) => void,
  getRole: (userRoles: string[], queryRole: string | undefined) => string,
} { // eslint-disable-line indent
  const history = useHistory();
  const [selected, setSelected] = useState<ISelected>({});
  const getRole = (userRoles: string[], queryRole: string | undefined) => {
    if (queryRole === 'All' || queryRole === undefined) {
      return userRoles[0];
    }
    return queryRole;
  };

  const [queryParams, setQueryParams] = useState<QueryParamsType>({});
  // handler to safely and simply change queryParams state
  // eslint-disable-next-line
  const qpChangeHandler = useCallback((key: keyof QueryParamsType) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string): void => {
    setQueryParams((base) => {
      const res = { ...base };
      if (typeof e === 'string') {
        if (e === '') {
          delete res[key];
        } else {
          res[key] = e;
        }
      } else {
        res[key] = e.target.value;
      }
      return res;
    });
  }, [setQueryParams]);

  //
  useEffect(() => {
    // update queryParams state
    const parsedSearch = QueryString.parse(history.location.search, undefined);
    setQueryParams(parsedSearch || {});
  }, [history.location.search, setQueryParams]);

  const [pageInfo, setPageInfo] = useState<{
    pageNum: number, numPerPage: number, numPages: number,
  }>({
    pageNum: 0, numPerPage: 25, numPages: 0,
  });

  // page info helper to chage num per page
  const numPerPageChange = (num: number) => {
    const res = { ...pageInfo };
    res.numPerPage = num;
    res.pageNum = 0;
    setPageInfo(res);
  };
  // page info helper to validate page number
  const pageValidator = (num: number): number => {
    if (Number.isNaN(num) || num < 0) return 0;
    if (num + 1 >= pageInfo.numPages) return pageInfo.numPages - 1;
    return num;
  };
  // page info helper to change page
  const pageChange = (num: number) => {
    if (Number.isNaN(num)) return;
    const res = { ...pageInfo };
    res.pageNum = pageValidator(num);
    setPageInfo(res);
  };

  // request to backend
  const [fetch, {
    data: searchData, loading: searchLoading, error: searchError, refetch,
  }] = useLazyQuery<
      SEARCH_RESERVATIONS_RES, SEARCH_RESERVATIONS_VARS
    >(SEARCH_RESERVATIONS, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      onCompleted: (d) => {
        if (d.searchReservations.data && d.searchReservations.data.length > 0) {
          const res = { ...pageInfo };
          res.numPages = d.searchReservations.numberPages;
          setPageInfo(res);
        }
      },
    });
  // refetch data event
  useEffect(() => {
    const handler = () => {
      refetch?.();
    };
    document.body.addEventListener('UpdateRCSearch', handler);
    return () => {
      document.body.removeEventListener('UpdateRCSearch', handler);
    };
  }, [refetch]);
  // update page info on data retrieval
  useEffect(() => {
    // console.log(searchData);
    if (searchLoading) return;
    if (!searchData?.searchReservations.numberPages) {
      setPageInfo({
        pageNum: pageInfo.pageNum, numPerPage: pageInfo.numPerPage, numPages: 0,
      });
      return;
    }
    setPageInfo({
      pageNum: pageInfo.pageNum,
      numPerPage: pageInfo.numPerPage,
      numPages: searchData.searchReservations.numberPages,
    });
  }, [searchData]);

  // on search string change
  const search = useCallback((resetPageNum?: boolean) => {
    if (resetPageNum) {
      pageChange(0);
    }
    fetch({
      variables: {
        arrivalDate: queryParams.arrivalDate || undefined,
        arrivalDateRange: queryParams.arrivalDateRange || undefined,
        departureDate: queryParams.departureDate || undefined,
        address: queryParams.rentalAddress,
        invitedBy: queryParams.propertyManager,
        firstName: queryParams.firstName,
        lastName: queryParams.lastName,
        phoneNumber: queryParams.phoneNumber,
        email: queryParams.email,
        includePastReservations: !!queryParams.includePastReservations?.startsWith('t'),
        passNumber: Number(queryParams.passNumber),
        sortOrder: 1,
        pageNumber: resetPageNum ? 0 : Math.max(pageInfo.pageNum, 0),
        pageSize: pageInfo.numPerPage,
      },
    });
  }, [queryParams, pageInfo.pageNum, pageInfo.numPerPage, pageChange]);
  // }, [history.location.search, pageInfo.pageNum, pageInfo.numPerPage, fetch, setQueryParams]);

  // search on page num and num per page changes
  useEffect(() => {
    search();
  }, [pageInfo.pageNum, pageInfo.numPerPage]);

  // returned values
  return {
    search,
    queryParams,
    qpChangeHandler,
    searchRes: searchData?.searchReservations?.data || [],
    searchLoading,
    searchError: searchData?.searchReservations.error || searchError?.message || '',
    marginSet: '',
    marginSetUserSetting: '',
    role: 'guest',
    history,
    pageInfo,
    pageChange,
    pageValidator,
    numPerPageChange,
    selected,
    setSelected,
    getRole,
  };
}
