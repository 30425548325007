import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';

export const GET_ALL_PASS = gql`
  query getPassInfosByCommunity (
    $communityId: String!
    $complete: Boolean!
  ) {
    getPassInfosByCommunity(
      communityId: $communityId
      complete: $complete
    ){
      success
      error
      data {
        passInfoId
        portal
        name
      }
    }
  }
`;

export const GET_PASS_GROUP = gql`
  query getPassGroups (
    $communityId: String!
  ) {
    getPassGroups(
      communityId: $communityId
    ){
      success
      error
      data {
        passInfoList
      }
    }
  }
`;
type reportPassType = {
  name: string;
  portal: string;
  numberPasses: number;
  dollarAmount: number;
}

type reportDataType = {
  totalDollarAmount: number;
  totalNumberPasses: number;
  listPassTypes: reportPassType[];
}

export const periodOptions = {
  year: 'year',
  dateRange: 'dateRange',
};

export const paymentTypes = [
  'Credit Card (by user)',
  'ACH (by user)',
  'Cash/Paper Check/Other at the gate',
  'Free',
  'Prepaid Credit Card (by host)',
  'Prepaid ACH (by host)',
];

const matchCreditCard = ['Credit Card (by user)', 'Prepaid Credit Card (by host)'];
const matchACH = ['ACH (by user)', 'Prepaid ACH (by host)'];
const matchCash = ['Cash/Paper Check/Other at the gate'];
const matchFree = ['Free'];

export const SEARCH_REPORTS_PASSES = gql`
  query searchPasses (
    $searchPassInputData: SearchPassInput
  ) {
    searchPasses(
      searchPassInputData: $searchPassInputData
    ) {
      success
      data {
        totalNumberPasses
        totalDollarAmount
        listPassTypes {
            name
            portal
            numberPasses
            dollarAmount
        }
      }
      error
    }
}
`;

export type PassType = {
  passInfoId: string,
  portal: string,
  name: string,
}

export type GroupType = {
  communityId: string,
  passInfoList: string[],
  passGroupId: string
  name: string
}

export default function useReportCenterReports(): {
  currentReport: string,
  passTypes: PassType[],
  passTypeIds: string[],
  portals: string[],
  selectedPassTypes: PassType[],
  setSelectedPassTypes: React.Dispatch<React.SetStateAction<PassType[]>>,
  searchPassesError: string | null,
  loading: boolean | null,
  reportData: reportDataType | null,
  paymentType: string[],
  setPaymentType: React.Dispatch<React.SetStateAction<string[]>>,
  startDate: Date,
  endDate: Date,
  setStartDate: React.Dispatch<React.SetStateAction<Date>>,
  setEndDate: React.Dispatch<React.SetStateAction<Date>>,
  year: number,
  setYear: React.Dispatch<React.SetStateAction<number>>,
  startTime: Date,
  setStartTime: React.Dispatch<React.SetStateAction<Date>>,
  endTime: Date,
  setEndTime: React.Dispatch<React.SetStateAction<Date>>,
  periodOption: string,
  setPeriodOption: React.Dispatch<React.SetStateAction<string>>,
  week: string[],
  setWeek: React.Dispatch<React.SetStateAction<string[]>>,
  isAllDayTime: boolean,
  setIsAllDayTime: React.Dispatch<React.SetStateAction<boolean>>,
  } {
  const { currentReport } = useParams<{
    currentReport: string
  }>();

  const [passTypes, setPassTypes] = useState<PassType[]>([]);
  const [passTypeIds, setPassTypeIds] = useState<string[]>([]);
  const [portals, setPortals] = useState<string[]>([]);
  const { communityId } = useContext(CommunityContext);
  const [selectedPassTypes, setSelectedPassTypes] = useState<Array<PassType>>([]);
  const [searchPassesError, setSearchPassesError] = useState<string | null>(null);
  const [paymentType, setPaymentType] = useState<Array<string>>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [startTime, setStartTime] = useState<Date>(new Date(2000, 1, 1, 0, 0));
  const [endTime, setEndTime] = useState<Date>(new Date(2000, 1, 1, 23, 59));
  const [periodOption, setPeriodOption] = useState<string>(periodOptions.year);
  const [week, setWeek] = useState<Array<string>>([]);
  const [isAllDayTime, setIsAllDayTime] = useState<boolean>(true);
  const [reportData, setReportData] = useState<reportDataType | null>(null);

  useQuery(GET_ALL_PASS, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
      complete: true,
    },
    onCompleted: (allPassData) => {
      setPortals(Array.from(
        new Set(allPassData?.getPassInfosByCommunity
          .data.map((passType: PassType) => passType.portal)),
      ));
      setPassTypes(allPassData?.getPassInfosByCommunity.data);
    },
  });

  useQuery(GET_PASS_GROUP, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
    },
    onCompleted: (allPassGroup) => {
      setPassTypeIds([].concat(...allPassGroup?.getPassGroups.data
        .map((i: any) => i.passInfoList)));
    },
  });

  const [doSearch, { loading }] = useLazyQuery(SEARCH_REPORTS_PASSES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => {
      const res = d?.searchPasses;
      if (res?.error) {
        setSearchPassesError(res?.error);
      }
      if (res?.success) {
        setReportData(res?.data);
      }
    },
    onError: (e) => {
      setSearchPassesError(e.message);
    },
  });

  useEffect(() => {
    let invalid = false;
    const passInfoIds = selectedPassTypes?.map((i) => i.passInfoId) || [];
    const searchSelection = currentReport === 'passes-activated' ? 'passesActivated' : 'passesSold';
    const paymentTypesInput = {
      creditCard: paymentType.some((p) => matchCreditCard.includes(p)),
      ach: paymentType.some((p) => matchACH.includes(p)),
      cash: paymentType.some((p) => matchCash.includes(p)),
      free: paymentType.some((p) => matchFree.includes(p)),
    };

    const period = {
      optionSelected: periodOption,
      year,
      dateRangeStart: startDate,
      dateRangeEnd: endDate,
    };

    // * Notice to synchronize this with days in ReportCenterReports
    const daysOfWeek = {
      sunday: week.includes('Sun'),
      monday: week.includes('Mon'),
      tuesday: week.includes('Tue'),
      wednesday: week.includes('Wed'),
      thursday: week.includes('Thu'),
      friday: week.includes('Fri'),
      saturday: week.includes('Sat'),
    };

    const timeDay = {
      all: isAllDayTime,
      timeStart: startTime,
      timeEnd: endTime,
    };

    if (week.length === 0) {
      invalid = true;
    }
    if (!isAllDayTime && (startTime > endTime)) {
      invalid = true;
    }

    if (!invalid) {
      doSearch({
        variables: {
          searchPassInputData: {
            passInfoIds,
            searchSelection,
            paymentTypes: paymentTypesInput,
            period,
            daysOfWeek,
            timeDay,
          },
        },
      });
    } else {
      // Clearing the report data if the filters are invalid
      setReportData(null);
    }
  }, [
    selectedPassTypes,
    currentReport,
    periodOption,
    year,
    startDate,
    endDate,
    week,
    isAllDayTime,
    startTime,
    endTime,
    paymentType,
  ]);

  return {
    currentReport,
    passTypes,
    passTypeIds,
    portals,
    selectedPassTypes,
    setSelectedPassTypes,
    searchPassesError,
    loading,
    reportData,
    paymentType,
    setPaymentType,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    year,
    setYear,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    periodOption,
    setPeriodOption,
    week,
    setWeek,
    isAllDayTime,
    setIsAllDayTime,
  };
}
