import Tippy from '@tippyjs/react';
import React, { useState, useRef, ReactElement } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ReactComponent as ActionImg } from '../../../../assets/actionIcon.svg';
import style from './popUpCell.module.css';
import useParkingPass from '../../../../hooks/useParkingPass';

export function PopupCellRenderer(props:any): ReactElement {
  const { data } = props;
  const tippyRef = useRef<Element>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const { doGetParkingPass } = useParkingPass({
    autoNavToPass: true,
  });
  const dropDownContent = (
    <div className={style.menuContainer} role="menu">
      <Link to={{
        pathname: '/admin/popup/view-pass',
        state: {
          passInfo: { ...data.actions },
          role: 'host',
        },
      }}
      >
        <button className={style.actionMenuBtn}>
          Download
        </button>
      </Link>
      <Link to={{
        pathname: '/admin/popup/share-pass',
      }}
      >
        <button className={style.actionMenuBtn}>
          Share
        </button>
      </Link>
      {data.actions.passId && (
      <button
        className={style.actionMenuBtn}
        onClick={() => doGetParkingPass(data.actions.passId)}
      >
        Print Parking Pass
      </button>
      )}
      <Link to={{
        pathname: `/admin/popup/refund-pass/${data.actions.passId}`,
        state: { ...data.actions },
      }}
      >
        <button className={style.actionMenuBtn}>
          Refund
        </button>
      </Link>
      <Link to={{
        pathname: `/admin/popup/delete-pass/${data.actions.passId}`,
        state: { ...data.actions },
      }}
      >
        <button className={style.actionMenuBtn}>
          Delete
        </button>
      </Link>
    </div>
  );
  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
}
export default PopupCellRenderer;
