import React, {
  ReactElement, useEffect, useState, useContext,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Card from '../card/card';
import style from './securityTemplate.module.css';
import reportCenterIcon from '../../assets/reportCenter.svg';
import scanPassIcon from '../../assets/scanPass.svg';
import vehicleSearchIcon from '../../assets/vehicleSearch.svg';
import addressIcon from '../../assets/addressIcon.svg';
import violationIcon from '../../assets/violationIcon.svg';
import securityIcon from '../../assets/security.svg';
import officerInsightsIcon from '../../assets/officerInsights.svg';
import complaintIcon from '../../assets/complaintIcon.svg';
import messageIcon from '../../assets/messageIcon.svg';
import vehicleIcon from '../../assets/vehicle.svg';
import { Footer } from '../footer/footer';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';

export default function SecurityTemplate(props: {
  children?: ReactElement | Array<ReactElement>;
}): ReactElement {
  const { children } = props;

  const location = useLocation<any>();

  const [active, setActive] = useState<'violation-incident' | 'complaint' | 'messenger' | 'name-address-search' | 'vehicle-search' | 'scan-pass' | ''>('');

  const { communityId } = useContext(CommunityContext);

  useEffect(() => {
    switch (true) {
      case /violation-incident/.test(location.pathname):
        setActive('violation-incident');
        break;
      case /complaint/.test(location.pathname):
        setActive('complaint');
        break;
      case /name-address-search/.test(location.pathname):
        setActive('name-address-search');
        break;
      case /vehicle-search/.test(location.pathname):
        setActive('vehicle-search');
        break;
      case /scan-pass/.test(location.pathname):
        setActive('scan-pass');
        break;
      default: setActive('');
    }
  }, [location, active]);

  const lstPart: { id: number, name: string, icon?: string, linkTo?: any }[] = [
    {
      id: 0, name: 'Scan a Pass', icon: `${scanPassIcon}`, linkTo: 'scan-pass',
    },
    {
      id: 1, name: 'Vehicle Search', icon: `${vehicleSearchIcon}`, linkTo: 'vehicle-search',
    },
    {
      id: 2, name: 'Name & Address Search', icon: `${addressIcon}`, linkTo: 'name-address-search',
    },
    // {
    //   id: 3, name: 'Enter a Violation/Incident',
    // icon: `${violationIcon}`, linkTo: 'violation-incident',
    // },
    // {
    //   id: 4, name: 'Enter Complaint', icon: `${complaintIcon}`, linkTo: 'complaint',
    // },
    // {
    //   id: 5, name: 'Messenger', icon: `${messageIcon}`, linkTo: 'messenger',
    // },
  ];

  if (communityId === 'sipoa') {
    lstPart.push({
      id: 3,
      name: 'Officer Insights',
      icon: `${officerInsightsIcon}`,
    });
  }

  return (
    <div>
      <div className={style.container}>

        <div className={style.box}>
          <div className={style.subNav}>
            <img src={securityIcon} className={style.logo} alt="logo" />
            <p className={style.subTitle}>Security Portal</p>
          </div>
          <div className={style.securityPart}>

            <div className={style.boxInfor}>

              <div className={style.lstCard}>
                {/* item card */}
                {
                  lstPart.map((item) => (
                    <div className={`${style.cardBox}`} key={item.id}>
                      {item.name === 'Officer Insights' ? (
                        <a href="https://sipoa.officerreports.net/" target="_blank" className={style.link} rel="noreferrer">
                          {' '}
                          <div className={`${style.card} white ${active === item.linkTo && style.activePart}`}>
                            <div className={style.imgOutBox}>
                              <img src={item.icon} className={`${style.imageIcon} ${active === item.linkTo && style.activeIcon}`} alt="img" />
                            </div>
                            <h4 className={`${style.title} ${active === item.linkTo ? style.activeText : 'textColorDark'}`}>{item.name}</h4>
                          </div>
                        </a>
                      ) : (
                        <Link
                          to={item.linkTo}
                          onClick={() => setActive(item.linkTo)}
                          className={style.link}
                        >
                          <div className={`${style.card} white ${active === item.linkTo && style.activePart}`}>
                            <div className={style.imgOutBox}>
                              <img src={item.icon} className={`${style.imageIcon} ${active === item.linkTo && style.activeIcon}`} alt="img" />
                            </div>
                            <h4 className={`${style.title} ${active === item.linkTo ? style.activeText : 'textColorDark'}`}>{item.name}</h4>
                          </div>
                        </Link>
                      )}

                    </div>
                  ))
                }

              </div>
              <div className="mainContent">
                {children}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
SecurityTemplate.defaultProps = {
  children: undefined,
};
