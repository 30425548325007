import React, { ReactElement, useEffect } from 'react';
import GenericButton from '../genericButton/genericButton';
import style from './popUp.module.css';

type PopUpProps = {
  children: any;
  title?: string;
  close: () => void;
  isOpen?: boolean
}

export const PopUp = (props: PopUpProps): ReactElement => {
  const {
    title, isOpen, close, children,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isOpen) {
    return (
      <div className={`${style.greyBackGround} darkGrey `}>
        <div className={`${style.box} white `}>
          <div className={`${style.box__header} mainColor `}>
            <h2 className={`${style.box__title} textColorWhite `}>{title}</h2>
            <div className={style.btn}>
              <GenericButton
                icon="exit"
                title=""
                clickHandler={() => close()}
              />

            </div>
          </div>
          <div className={style.itemBox}>{children}</div>
        </div>
      </div>
    );
  }
  return (<></>);
};
PopUp.defaultProps = {
  title: '',
  isOpen: true,
};
