import React, { useRef, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import style from './passPaymentSetter.module.css';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import { paymentStatusMapTitles } from '../../common_lib_front/types/passInfo';

const MARK_PASS_AS_PAID = gql`
mutation MarkPassAsPaid (
  $passId: String!
) {
  markPassAsPaid (
    passId: $passId
  ) {
    success
    error
  }
}
`;
type MARK_PASS_AS_PAID_VARS = {
  passId: string,
}
type MARK_PASS_AS_PAID_RES = {
  markPassAsPaid: backendResponse<undefined>,
}

type PassPaymentSetterProps = {
  passId: string,
  paid: string,
}

export default function PassPaymentSetter(props: PassPaymentSetterProps): React.ReactElement {
  const { passId, paid: _paid } = props;
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [paid, setPaid] = useState<string>(_paid);
  const [doMarkAsPaid] = useMutation<
    MARK_PASS_AS_PAID_RES,
    MARK_PASS_AS_PAID_VARS
  >(MARK_PASS_AS_PAID, {
    onCompleted: (res) => {
      if (res.markPassAsPaid.success) {
        setPaid('paid');
        wrapperRef.current?.dispatchEvent(new CustomEvent('UpdateRCSearch', {
          bubbles: true,
        }));
      }
    },
  });

  return (
    <div
      ref={wrapperRef}
    >
      {paid === 'unpaid' ? (
        <select
          className={style.passPaymentStatus}
          onChange={(e) => {
            if (e.target.value !== 'paid') return;
            doMarkAsPaid({
              variables: {
                passId,
              },
            });
          }}
        >
          <option className={style.optionPaymentStatus} hidden value="unpaid">
            {paymentStatusMapTitles.unpaid}
          </option>
          <option className={style.optionPaymentStatus} value="paid">
            {paymentStatusMapTitles.paid}
          </option>
        </select>
      )
        : (
          // TODO styles for other payment statuses
          <p className={`${paid === 'paid' && style.paid}
          ${paid === 'refunded' && style.refunded}
          ${paid === 'no-charge' && style.noCharge}`}
          >
            {paymentStatusMapTitles[paid] || ''}
          </p>
        )}
    </div>
  );
}
