import Tippy from '@tippyjs/react';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import style from './vendorPassGrid.module.css';
import PassInfo from '../../common_lib_front/types/passInfo';
import useParkingPass from '../../hooks/useParkingPass';
import { ReactComponent as ActionImg } from '../../assets/actionIcon.svg';

interface PopupCellRendererProps {
  data: {
    data: PassInfo,
    [key: string]: unknown
  };
}

/* eslint-disable react/prop-types */
const PopupCellRenderer: React.FC<PopupCellRendererProps> = ({
  data,
}) => {
  const { data: passInfo } = data;
  const { passId } = passInfo;
  const role = 'vendor';
  const tippyRef = useRef<Element>(null);
  const [visible, setVisible] = useState(false);
  const { doGetParkingPass } = useParkingPass({
    autoNavToPass: true,
  });
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div className={style.menuContainer} role="menu">
      <div
        role="menuitem"
        tabIndex={0}
        className={style.menuItem}
      >
        <Link to={{
          pathname: `/admin/popup/edit-pass/${passId}`,
          state: {
            passInfo,
            role,
          },
        }}
        >
          <button className={style.actionBtn}>
            Edit
          </button>
        </Link>
      </div>
      <div
        role="menuitem"
        tabIndex={0}
        className={style.menuItem}
      >
        <Link to={{
          pathname: '/admin/popup/share-pass',
          state: passInfo,
        }}
        >
          <button className={style.actionBtn}>
            Share
          </button>
        </Link>
      </div>
      <div
        role="menuitem"
        tabIndex={0}
        className={style.menuItem}
      >
        <Link to={{
          pathname: '/admin/popup/view-pass',
          state: {
            passInfo,
            role,
          },
        }}
        >
          <button className={style.actionBtn}>
            View/Download
          </button>
        </Link>
      </div>
      {passId && (
        <div
          role="menuitem"
          tabIndex={0}
          className={style.menuItem}
        >
          <button
            className={style.actionBtn}
            onClick={() => doGetParkingPass(passId)}
          >
            Print Parking Pass
          </button>
        </div>
      )}
      <div
        role="menuitem"
        tabIndex={0}
        className={style.menuItem}
      >
        <Link to={{
          pathname: `/admin/popup/refund-pass/${passId}`,
          state: passInfo,
        }}
        >
          <button className={style.actionBtn}>
            Refund
          </button>
        </Link>
      </div>
      <div
        role="menuitem"
        tabIndex={0}
        className={style.menuItem}
      >
        <Link to={{
          pathname: `/admin/popup/delete-pass/${passId}`,
          state: passInfo,
        }}
        >
          <button className={style.actionBtn}>
            Delete
          </button>
        </Link>
      </div>
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
