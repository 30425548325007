import {
  QueryHookOptions, QueryResult, gql, useQuery,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

export const GET_EMPLOYEE = gql`
  query Query (
  $userId: String
) {
  getEmployee (
    userId: $userId
  ) {
    success
    error
    data {
      employeeId
      firstName
      lastName
      phoneNumber
      email
      driversLicenseNum
      isLicenseSelected
    }
  }
}
`;
type GET_EMPLOYEE_VARS = {
  userId?: string;
};
type GET_EMPLOYEE_RES = {
  getEmployee: backendResponse<{
    employeeId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    driversLicenseNum: string;
    isLicenseSelected: boolean;
  }[]>;
}

export const useEmployees = (
  options?: QueryHookOptions<GET_EMPLOYEE_RES, GET_EMPLOYEE_VARS>,
): QueryResult<GET_EMPLOYEE_RES, GET_EMPLOYEE_VARS> => useQuery<
GET_EMPLOYEE_RES, GET_EMPLOYEE_VARS
>(GET_EMPLOYEE, options);
