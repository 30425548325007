import React, {
  ReactElement, useCallback, useRef, useState, useContext, useMemo,
} from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import jsQR from 'jsqr';
import JsPDF from 'jspdf';
import GenericButton from '../../../components/genericButton/genericButton';
import qrCodeIcon from '../../../assets/securQrScan.svg';
import style from './scanPass.module.css';
import InputField from '../../../components/inputField/inputField';
import useScanPass from './useScanPass';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import { QueryString } from '../../../common_lib_front/utilities/QueryString';
import { ReactComponent as SuccessIcon } from '../../../assets/success.svg';
import { ReactComponent as FailedIcon } from '../../../assets/failed.svg';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import QrReader from '../../../components/qrReader/qrReader';
import { passStatusMapTitles, paymentStatusMapTitles } from '../../../common_lib_front/types/passInfo';
import useOrderPrice from '../../../common_lib_front/hooks/useOrderPrice';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';

const PARKING_PASS = gql(`
  query ParkingPass (
    $communityId: String
    $passId: String!
  ) {
    generateAdminPassImage (
      communityId: $communityId
      passId: $passId
    ) {
      data
      error
      success
    }
  }
  `);

export default function ScanPass(): ReactElement {
  const {
    history,
    alert,
    loading,
    passData,
    getPassData,
    passNumber,
    setPassNumber,
    setBarCode,
    saveBarCode,
    userData,
    activatePass,
  } = useScanPass();

  const { communityId } = useContext(CommunityContext);

  const [isScanning, setIsScanning] = useState<boolean>(false);
  const { totalPrice } = useOrderPrice(useMemo(() => (passData ? [passData] : []), [passData]));

  const getRedirectLink = (): string => {
    if (!userData?.roles?.length) {
      return '';
    }

    if (userData?.roles?.length > 1) {
      return `/admin/report-center/user-settings/details/${userData.userId}`;
    }

    return `/admin/report-center/${userData.roles[0]}/details/${userData.userId}/vehicles-passes`;
  };
  const viewLink = getRedirectLink();

  const submitHandler = (data: number) => {
    history.replace({
      pathname: '/admin/security/scan-pass',
      hash: history.location.hash,
      search: QueryString.encode({ passNumber: data }),
    });
    getPassData(data);
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const scanQR = async function (event: any) {
    const file = event.target.files[0];

    const imgDataURL: string = await new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });

    const imgData: any = await new Promise((resolve, any) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx!.drawImage(img, 0, 0);
        resolve(ctx!.getImageData(0, 0, canvas.width, canvas.height));
      };
      img.src = imgDataURL;
    });

    const code = jsQR(imgData.data, imgData.width, imgData.height);

    if (code) {
      const data = parseFloat(code.data);
      setPassNumber(data);
      submitHandler(data);
    }
  };

  const onQrSuccess = useCallback((data: any) => {
    setIsScanning(false);
    const d = parseFloat(data?.text) || '';
    if (d) {
      setPassNumber(d);
      submitHandler(d);
    }
  }, []);

  const exportPdf = async (src: string) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = src;

    await new Promise((resolve) => { image.onload = resolve; });

    const canvas = document.createElement('canvas');
    const pdf = new JsPDF(image.width > image.height ? 'l' : 'p', 'px', [image.width, image.height]);

    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext('2d');
    ctx!.drawImage(image, 0, 0);

    const imgData = ctx!.getImageData(0, 0, image.width, image.height);

    pdf.addImage(imgData, 'PNG', 0, 0, image.width, image.height);

    return pdf;
  };

  const takeScreenshot = async (type: string, url: string) => {
    if (type === 'print') {
      const pdf: any = await exportPdf(url);
      const blob = pdf.output('blob');
      const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
      nextWindow?.focus();
      nextWindow?.print();
      return;
    }

    const link = document.createElement('a');
    link.href = passData!.url;
    link.click();
  };

  const [parkingQuery, {
    loading: parkingLoading,
    data: parkingData,
    error: parkingError,
  }] = useLazyQuery(PARKING_PASS, {
    onCompleted: (d) => {
      takeScreenshot('print', d.generateAdminPassImage.data);
    },
  });

  return (
    <div className={style.box}>
      <div className={style.scanPassBox}>
        <div className={style.qrCodeBox}>
          <div className={style.qrCodeBackground}>
            <button
              onClick={() => setIsScanning(true)}
              type="button"
              className={style.scanButtonContainer}
            >
              <img src={qrCodeIcon} className={style.image} alt="img" />
              <p className={style.qrCodeTitle}>QR code Scanner</p>
            </button>
            <QrReader
              open={isScanning}
              onClose={() => setIsScanning(false)}
              onSuccess={onQrSuccess}
            />
            <div className="btnBox">
              <GenericButton
                color="yellow"
                size="medium"
                title="Scan Image File"
                type="submit"
                clickHandler={() => {
                  inputFile.current?.click();
                }}
              />
              <input
                className={style.hidden}
                ref={inputFile}
                type="file"
                accept="image/*"
                onChange={(e) => scanQR(e)}
              />
            </div>
          </div>
        </div>
        <div className={style.enterNumBox}>
          <div>
            <div className={style.text}>
              <p className={style.enterNumTitle}>Enter Pass Number</p>
              <p className={style.enterNumSubTitle}>
                If you cannot scan the pass, please enter the Pass Number
              </p>
            </div>
            <GenericAlert
              title={alert}
              color="red"
              hidden={!alert || !!passData}
            />
            <form
              className={style.form}
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                if (passNumber !== null) {
                  submitHandler(passNumber);
                }
              }}
            >
              <div className={style.inputLong}>
                <InputField
                  closedInput
                  htmlFor="pass-num-inpt"
                  labelTitle="Pass Number"
                  inputType="number"
                  inputValue={`${passNumber}`}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassNumber(parseFloat(e.target.value));
                  }}
                  disabled={loading}
                />
              </div>
              <div className={style.genericSubmitBtn}>
                <div className={style.genericSubmitBtnInnerBox}>
                  <GenericButton
                    color="blue"
                    size="large"
                    title="Submit"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading && (
        <div className={style.loading}>
          <LoadingDiamonds />
        </div>
      )}
      {!loading && passData && (
        <div className={style.passBox}>
          <div className={style.passInfo}>
            <img src={passData.url} alt="ticket" className={style.imageTicket} />
          </div>
          <div className={style.passAction}>
            <div className={style.actionInner}>
              <GenericAlert
                title={alert}
                color="red"
                hidden={!alert || !passData}
              />

              <div className={style.passField}>
                <div className={style.passAttr}>
                  Pass Status:
                </div>
                <div className={style.passValue}>
                  <div className={`${passData.status === 'incomplete' ? style.incomplete : ''} 
                  ${passData.status === 'active' ? style.active : ''}
                  ${passData.status === 'expired' ? style.expired : ''}
                  ${passData.status === 'inactive' ? style.inactive : ''}`}
                  >
                    {' '}
                    {passStatusMapTitles[passData.status]}
                  </div>
                </div>
              </div>

              <div className={style.passField}>
                <div className={style.passAttr}>
                  Payment Type:
                </div>
                <div className={style.passTextValue}>
                  {passData.paymentType}
                </div>
              </div>

              <div className={style.passField}>
                <div className={style.passAttr}>
                  Payment Status:
                </div>
                <div className={style.passValue}>
                  {/* TODO new styles for other payment statuses */}
                  <div className={`${passData.paid === 'paid' && style.paid}
                  ${passData.paid === 'unpaid' && style.unpaid}
                  ${passData.paid === 'refunded' && style.refunded}
                  ${passData.paid === 'no-charge' && style.noCharge}
                  `}
                  >
                    {paymentStatusMapTitles[passData.paid || ''] || ''}
                  </div>
                </div>
              </div>

              <div className={style.passFieldCost}>
                <div className={style.passAttr}>
                  Total Cost:
                </div>
                <div className={style.passTextValue}>
                  {`${'$'} ${totalPrice}`}
                </div>
              </div>

              {passData.paid === 'unpaid' ? (
                <div>
                  <div className={style.paymentInfo}>
                    <FailedIcon />
                    <p className={style.paymentTitle}>Payment has not been collected</p>
                  </div>
                  <div className={style.alert}>
                    <Link to={{
                      pathname: '/admin/popup/mark-paid',
                      state: passData,
                    }}
                    >
                      Click here to mark when cash/physical check has been collected
                    </Link>
                  </div>
                </div>
              ) : (
                <div className={style.paymentInfo}>
                  <SuccessIcon />
                  <p className={style.paymentTitle}>Payment has been collected</p>
                </div>
              )}

              <div className={style.btnBox}>
                <div>
                  <GenericButton
                    color="blue"
                    size="large"
                    title="View in Admin Center"
                    type="submit"
                    disabled={viewLink === ''}
                    clickHandler={() => {
                      history.push(viewLink);
                    }}
                  />
                </div>
              </div>

              <div className={style.btnBox}>
                <div>
                  <GenericButton
                    // color="transparent"
                    outline="large"
                    // size="large"
                    title="Print & Download Pass"
                    type="submit"
                    disabled={loading}
                    clickHandler={() => {
                      takeScreenshot('print', passData!.url);
                    }}
                  />
                </div>
              </div>
              <div className={style.btnBox}>
                <div>
                  <GenericButton
                    // color="transparent"
                    outline="large"
                    // size="large"
                    title="Print Parking Pass"
                    type="submit"
                    disabled={loading || parkingLoading}
                    clickHandler={() => {
                      parkingQuery({
                        variables: {
                          passId: passData!.passId,
                          communityId,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {passData.status === 'inactive' && (
                <div className={style.btnBox}>
                  <div>
                    <GenericButton
                      // color="transparent"
                      outline="large"
                      // size="large"
                      title="Activate Pass"
                      type="button"
                      clickHandler={() => {
                        activatePass({
                          variables: {
                            passId: passData.passId,
                            status: 'active',
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className={style.inputClose}>
                <InputField
                  closedInput
                  htmlFor="pass-barcode-inpt"
                  labelTitle="Assign Pass Bar Code Number (Optional)"
                  inputValue={passData.barCode}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBarCode(e.target.value);
                  }}
                  disabled={loading}
                />
              </div>

              <div className={style.btnBox}>
                <div>
                  <GenericButton
                    color="blue"
                    size="large"
                    title="Confirm"
                    type="submit"
                    clickHandler={saveBarCode}
                    disabled={!passData.barCode || loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
