import React, { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import footerStyle from './footer.module.css';

export const Footer = (): ReactElement => {
  const { name: communityName } = useContext(CommunityContext);
  return (
    <div className={` ${footerStyle.footer} darkGrey `}>
      <p className={` ${footerStyle.footer__title} textColorLight `}>
        Copyright
        {' '}
        {' '}
        <span className={footerStyle.titleName}>{communityName}</span>
        {' '}
        {' '}
        2021 |
        <Link to="/"><span className={` ${footerStyle.footer__link} textColorLight `}> Terms of use</span></Link>
        | Powered by
        <a className={` ${footerStyle.footer__link} textColorLight `} href="https://symliv.com/">SymLiv</a>
      </p>
    </div>
  );
};
