import React, { ReactElement, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PopUp } from '../../../components/popUp/popUp';
import style from './markPaid.module.css';
import alertPic from '../../../assets/alert.svg';
import useMarkPaid from './useMarkPaid';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import useOrderPrice from '../../../common_lib_front/hooks/useOrderPrice';

export default function MarkPaid() :ReactElement {
  const {
    history,
    alert,
    doMarkPaid,
  } = useMarkPaid();
  const passInfo: any = history.location.state;
  const { totalPrice } = useOrderPrice(useMemo(() => [passInfo], [passInfo]));

  return (
    <PopUp
      title=""
      close={() => history.go(-1)}
    >
      <div className={style.box}>
        <div className={style.alertBox}>
          <img src={alertPic} alt="" className={style.alertImg} />
          <h2>Warning:</h2>
          <h4 className={style.text}>Are you sure you collected the correct amount?</h4>
          <h3>Total Cost</h3>
          <div className={style.price}>{`${'$'}${totalPrice}`}</div>
          <GenericAlert
            color="red"
            title={alert}
            hidden={!alert}
          />
        </div>
        <div className={style.btnBox}>
          <button onClick={() => history.go(-1)} className={style.cancel}>
            Cancel
          </button>
          <button
            onClick={() => {
              doMarkPaid(passInfo.passId);
            }}
            className={style.confirm}
          >
            Confirm
          </button>
        </div>

      </div>
    </PopUp>
  );
}
