import React, { ReactElement, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import EditProfileTemplate from '../../../components/editProfileTemplate/editProfileTemplate';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
// import PasswordResetForm from '../../../components/passwordResetForm/passwordResetForm';
import style from './resetPassword.module.css';
import GenericAlert from '../../../components/genericAlert/genericAlert';
import store from '../../../common_lib_front/utilities/store';
// import SchemeDecider from '../../../common_lib_front/utilities/schemeDecider';

const CHANGE_PASSWORD = gql`
  mutation ChangePassword (
    $newPassword: String!
    $confirmPassword: String!
    $currentPassword: String!
  ) {
    changePassword (
      newPassword: $newPassword
      confirmPassword: $confirmPassword
      currentPassword: $currentPassword
    ) {
      success
      error
    }
  }
`;

// const colorStyle :any = {}; // SchemeDecider();
export default function ResetPassword() :ReactElement {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const [doChangePassword] = useMutation(CHANGE_PASSWORD, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      console.log(d);
      if (d.changePassword.success) {
        setAlert('Changes submitted successfully');
        setAlertColor('green');
      } else {
        setAlert(d.changePassword.error);
        setAlertColor('red');
      }
    },
  });

  return (
    <EditProfileTemplate
      active="password"
    >
      <div className={`${style.subHeader} mainColor `}>
        <div className={`${style.circle} white `} />
        <h1 className={`${style.title} textColorWhite `}>
          Reset Password
        </h1>
      </div>
      <div className={style.form}>
        <form
          className={style.innerForm}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!newPassword || !currentPassword) {
              setAlert('Please enter all required info');
              setAlertColor('red');
              return;
            }
            if (newPassword !== confirmPassword) {
              setAlert('Passwords do not match');
              setAlertColor('red');
              return;
            }
            doChangePassword({
              variables: {
                newPassword,
                confirmPassword,
                currentPassword,
              },
            });
          }}
        >
          <GenericAlert
            color={alertColor}
            title={alert}
            hidden={!alert}
          />
          <div className={style.inputLong}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="old-pass-inpt"
              labelTitle="Enter your old password"
              inputType="password"
              inputValue={currentPassword}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentPassword(e.target.value);
              }}
            />

          </div>
          <div className={style.inputLong}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="new-pass-inpt"
              labelTitle="Enter your new password"
              inputType="password"
              inputValue={newPassword}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewPassword(e.target.value);
              }}
            />

          </div>
          <div className={style.inputLong}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="conf-pass-inpt"
              labelTitle="Confirm your new password"
              inputType="password"
              inputValue={confirmPassword}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(e.target.value);
              }}
            />

          </div>
          <div className={style.btnBox}>
            <div className={style.saveBtn}>
              <GenericButton
                color="blue"
                size="large"
                type="submit"
                title="Save Changes"
              />

            </div>
          </div>
        </form>
      </div>
    </EditProfileTemplate>
  );
}
