import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const DELETE_RENTAL = gql`
mutation Mutation($passId: String!) {
    deletePassVehicle(passId: $passId) {
      success
      error
    }
  }
`;

export default function useEditRental() :{
  passId: string,
  history: History | any,
  // deleteSuccess: boolean,
  // deleteError: string,
  deleteHandler: () => void,
  } {
  const { passId } = useParams<{ passId: string }>();
  const history = useHistory();

  const [doDelete, {
    data: deleteData, error: deleteError,
  }] = useMutation(DELETE_RENTAL);
  const deleteHandler = () => {
    doDelete({
      variables: { passId },
    });
  };

  return {
    passId,
    history,
    deleteHandler,
  };
}
