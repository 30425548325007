import { useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import style from './reportCenterDetails.module.css';
import { GET_ALL_PASS } from '../reportCenterReports/useReportCenterReports';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

const GET_USER = gql`
query GetUserInformation (
  $userId: String!
) {
  getUserInformation (
    userId: $userId
  ) {
    success
    error
    data {
      firstName
      lastName
      roles
      phoneNumber
      address
      email
      barCodes
      companyName
      emergencyPhone
      businessPhoneNumber
    }
  }
}
`;

const GET_EMPLOYEES = gql`
query GetEmployees (
  $userId: String!
) {
  getEmployee (
    userId: $userId
  ) {
    success
    error
    data {
      employeeId
      firstName
      lastName
      phoneNumber
      email
    }
  }
}
`;

const GET_REPORTS = gql`
query GetReports (
  $userId: String
) {
  listReportsByUser(
    userId: $userId
    complaintsOnly: false
  ) {
    success
    error
    data {
      reportId
      submittedBy {
        firstName
        lastName
        userId
      }
      concerning {
        userId
        firstName
        lastName
      }
      submittedDate
      reportDate
      fine
    }
  }
}
`;

const GET_DOCUMENT_INFO_CONFIG = gql`
  query GetDocumentInfoConfig (
    $passInfoId: String!
  ) {
    getDocumentsInfo (
      passInfoId: $passInfoId
    ) {
      success
      error
      data {
        name
      }
    }
  }
`;

const GET_COMPANY_DOCUMENTS = gql`
  query GetCompanyDocuments (
    $userId: String!
  ) {
    getCompanyDocuments (
      userId: $userId
    ) {
      success
      error
      data {
        fileName
        documentName
        url
      }
    }
  }
`;

type useReportCenterDetailsReturn = {
  userData: any,
  userLoading: boolean,
  userError?: string
  // passData?: Array<any>,
  // passLoading?: boolean,
  // passError?: string
  reportData?: Array<any>,
  reportLoading?: boolean,
  reportError?: string,
  employeeData?: Array<any>,
  employeeLoading?: boolean,
  employeeError?: string,
  passTypeLoading?: boolean,
  passTypeError?: string,
  documentInfoData?: { [key: string]: string },
  documentData?: Array<any>,
  documentLoading?: boolean,
  documentError?: string,
  role: 'guest' | 'resident' | 'vendor',
  userId: string,
  currentTable: string,
  history: History | any,
  guestHidden: string,
  vendorHidden: string,
  residentHidden: string,
  tableHiddenVehicle: string,
  tableHiddenEmployee: string,
  tableHiddenCVI: string,
}
export default function useReportCenterDetails(): useReportCenterDetailsReturn {
  const { role, userId, currentTable } = useParams<{
    role: 'guest' | 'resident' | 'vendor', userId: string, currentTable: string
  }>();
  const history = useHistory();
  const { communityId } = useContext(CommunityContext);
  const [documentInfoData, setDocumentInfoData] = useState<{ [key: string]: string }>({});

  // get user info
  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  const [
    fetchReports, { data: reportData, loading: reportLoading, error: reportError },
  ] = useLazyQuery(GET_REPORTS, {
    fetchPolicy: 'network-only',
  });

  const [
    fetchEmployees, { data: employeeData, loading: employeeLoading, error: employeeError },
  ] = useLazyQuery(GET_EMPLOYEES, {
    fetchPolicy: 'network-only',
  });

  const [
    fetchDocuments, { data: documentData, loading: documentLoading, error: documentError },
  ] = useLazyQuery(GET_COMPANY_DOCUMENTS, {
    fetchPolicy: 'network-only',
  });

  const {
    data: passTypeData,
    loading: passTypeLoading,
    error: passTypeError,
  } = useQuery(GET_ALL_PASS, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
      complete: true,
    },
  });

  useEffect(() => {
    if (role !== 'vendor' || !passTypeData?.getPassInfosByCommunity?.data) return;

    fetchDocuments({
      variables: {
        userId,
      },
    });

    Promise.all(passTypeData?.getPassInfosByCommunity?.data
      .filter((passType: any) => passType.portal === role)
      .map((passType: any) => backendClient.query({
        query: GET_DOCUMENT_INFO_CONFIG,
        variables: {
          passInfoId: passType.passInfoId,
        },
      })))
      .then((res) => {
        const data: { [key: string]: string } = {};
        res.forEach((documentInfos: any) => {
          documentInfos.data?.getDocumentsInfo?.data.forEach((documentInfo: any) => {
            data[documentInfo.name] = 't';
          });
        });
        setDocumentInfoData(data);
      });
  }, [passTypeData]);

  // fetch data for tables
  useEffect(() => {
    switch (currentTable) {
      case 'complaints':
        fetchReports({
          variables: {
            userId,
          },
        });
        break;
      case 'employees':
        fetchEmployees({
          variables: {
            userId,
          },
        });
        break;
      default:
        break;
    }
  }, [currentTable]);

  // setting styles
  let guestHidden = '';
  let vendorHidden = '';
  let residentHidden = '';
  if (role === 'guest') {
    guestHidden = style.guestHidden;
  }
  if (role === 'vendor') {
    vendorHidden = style.vendorHidden;
  }
  if (role === 'resident') {
    residentHidden = style.residentHidden;
  }

  let tableHiddenVehicle = '';
  let tableHiddenCVI = '';
  let tableHiddenEmployee = '';
  if (currentTable === 'vehicles-passes') {
    tableHiddenVehicle = style.vehicleHidden;
  }
  if (currentTable === 'complaints') {
    tableHiddenCVI = style.complaintHidden;
  }
  if (currentTable === 'employees') {
    tableHiddenEmployee = style.employeeHidden;
  }
  // console.log('fron logic file', passData);
  return {
    userData: userData?.getUserInformation?.data || {},
    userLoading,
    userError: userError?.message || userData?.getUserInformation.error || undefined,
    reportData: reportData?.listReportsByUser?.data || [],
    reportLoading,
    reportError: reportError?.message || reportData?.listReportsByUser.error || undefined,
    employeeData: employeeData?.getEmployee?.data,
    employeeLoading,
    employeeError: employeeError?.message || employeeData?.getEmployee.error || undefined,
    passTypeLoading,
    passTypeError: passTypeError?.message || passTypeData?.getPassInfosByCommunity.error
      || undefined,
    documentInfoData,
    documentData: documentData?.getCompanyDocuments?.data,
    documentLoading,
    documentError: documentError?.message || documentData?.getCompanyDocuments.error
      || undefined,
    role,
    userId,
    currentTable,
    history,
    guestHidden,
    vendorHidden,
    residentHidden,
    tableHiddenVehicle,
    tableHiddenEmployee,
    tableHiddenCVI,
  };
}
