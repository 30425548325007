import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import UserInfo, { newUserInfo } from '../../../common_lib_front/types/userInfo';

const ADD_USER = gql`
mutation Mutation(
  $role: String!,
  $email: String!, 
  $password: String!,
  $lastName: String!, 
  $firstName: String!,
) {
  registerUser(
    role: $role, 
    email: $email, 
    password: $password,
    lastName: $lastName, 
    firstName: $firstName
  ) {
    success
    error
    data {
      userId
    }
  }
}
`;

interface UseAddUser {
  history: History | any,
  userInfo: any,
  editUserInfo: (key:string, val:string) => void,
  doAddUser: () => void,
  addUserData: any,
  addUserLoading: boolean,
  addUserError: string,
}
export default function useAddUser() :UseAddUser {
  const [userInfo, setUserInfo] = useState<any>(newUserInfo());
  const [userError, setUserError] = useState<string>('');
  const editUserInfo = (key:string, val:string) => {
    const tmp = { ...userInfo };
    tmp[key] = val;
    setUserInfo(tmp);
  };

  const history = useHistory();

  const [doAddUser, {
    data: addUserData, loading: addUserLoading,
  }] = useMutation(ADD_USER, {
    onError(error) {
      setUserError(error?.message);
    },
  });
  const addUserHelper = () => {
    doAddUser({
      variables: {
        email: userInfo.email,
        password: userInfo.password,
        lastName: userInfo.lastName,
        firstName: userInfo.firstName,
        role: userInfo.role,
      },
    });
  };

  return {
    history,
    userInfo,
    editUserInfo,
    doAddUser: addUserHelper,
    addUserData: newUserInfo(addUserData?.registerUser?.data || {}),
    addUserLoading,
    addUserError: userError || addUserData?.registerUser?.error || '',
  };
}
