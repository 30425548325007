import React, { ReactElement, useEffect, useState } from 'react';
import { formatDateForInput, formatDateForInputUTC } from '../../../common_lib_front/utilities/formatDate';
import GenericAlert from '../../../components/genericAlert/genericAlert';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import { PopUp } from '../../../components/popUp/popUp';
import style from './editVehiclePass.module.css';
import useEditVehiclePass from './useEditVehiclePass';
import { passStatusMapTitles } from '../../../common_lib_front/types/passInfo';

export default function EditVehiclePass(): ReactElement {
  const {
    history,
    role,
    vehicleInfo,
    editVehicleInfo,
    successMessage,
    editError,
    editPass,
  } = useEditVehiclePass();

  return (
    <PopUp
      title="Edit Vehicle/Pass"
      close={() => history.goBack()}
    >
      <form
        className={style.box}
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          editPass();
        }}
      >
        <GenericAlert
          title={editError}
          hidden={!editError}
          color="red"
        />
        <GenericAlert
          title={successMessage}
          hidden={!successMessage}
          color="green"
        />
        <div className={style.flexBox}>
          <div className={style.infoBox}>
            <div className={`${style.header} textColorWhite superDarkGrey`}>
              <h4 className={style.headerTitle}>Vehicle Information</h4>
            </div>
            <div className={style.inputBox}>
              <div className={style.inputArea}>
                <InputField
                  closedInput
                  htmlFor="make-inpt"
                  labelTitle="Make"
                  inputValue={vehicleInfo?.make || vehicleInfo?.vehicle?.make || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editVehicleInfo('make', e.target.value);
                  }}
                />

              </div>
              <div className={style.inputArea}>
                <label htmlFor="vehicle-type-inpt" className={style.label}>
                  Vehicle Type
                  <select
                    id="vehicle-type-inpt"
                    className={style.input}
                    value={vehicleInfo?.type || vehicleInfo?.vehicle?.type || ''}
                    onChange={(e: any) => editVehicleInfo('vehicleType', e.target.value)}
                  >
                    <option hidden>Unknown</option>
                    {
                      [
                        'Coupe (2 Door Car)',
                        'Sedan (4 Door Car)',
                        'Sports Car',
                        'Wagon/Station Wagon',
                        'Sport Utility Vehicle (SUV)',
                        'Minivan',
                        'High Capacity Passenger Van',
                        'Pickup Truck',
                        'Motorcycle',
                        'OHV (Off Highway Vehicle)',
                        'Golf Cart',
                        'Bus',
                        'Utility Truck',
                        'Other Commercial Vehicle (Box Truck/Semi)',
                      ].map((v) => (
                        <option>{v}</option>
                      ))
                    }
                  </select>
                </label>

              </div>
              <div className={style.inputArea}>
                <InputField
                  closedInput
                  htmlFor="color-inpt"
                  labelTitle="Color"
                  inputValue={vehicleInfo?.color || vehicleInfo?.vehicle?.color || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editVehicleInfo('color', e.target.value);
                  }}
                />

              </div>
              <div className={style.inputArea}>
                <InputField
                  closedInput
                  htmlFor="plate-inpt"
                  labelTitle="Licence Plate #"
                  inputValue={vehicleInfo?.licensePlate || vehicleInfo?.vehicle?.licensePlate || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editVehicleInfo('licensePlate', e.target.value);
                  }}
                />

              </div>

            </div>
          </div>
          <div className={style.infoBox}>
            <div className={`${style.header} textColorWhite superDarkGrey`}>
              <h4 className={style.headerTitle}>Pass Information</h4>
            </div>
            <div className={style.inputBox}>
              <div className={style.inputArea}>
                <label htmlFor="pass-type-inpt" className={style.label}>
                  Pass Type
                  <select
                    id="pass-type-inpt"
                    className={`${style.input} ${style.inputSelect}`}
                    value={vehicleInfo?.passType || ''}
                    onChange={(e: any) => editVehicleInfo('passType', e.target.value)}
                  >
                    <option>single-use</option>
                    <option>gate-attendant</option>
                    <option>invited-guest</option>
                    <option>host</option>
                    <option>generic</option>
                    <option>vendor</option>
                  </select>
                </label>

              </div>
              <div className={style.inputArea}>
                <label htmlFor="pass-status-inpt" className={style.label}>
                  Pass Status
                  <select
                    id="pass-status-inpt"
                    className={style.input}
                    value={vehicleInfo?.passStatus || ''}
                    onChange={(e) => editVehicleInfo('passStatus', e.target.value)}
                  >
                    <option value="active">{passStatusMapTitles.active}</option>
                    <option value="inactive">{passStatusMapTitles.inactive}</option>
                    <option value="incomplete">{passStatusMapTitles.incomplete}</option>
                    <option value="incomplete-rental-car">{passStatusMapTitles['incomplete-rental-car']}</option>
                    <option value="expired">{passStatusMapTitles.expired}</option>
                    <option value="refunded">{passStatusMapTitles.refunded}</option>
                    <option value="deleted">{passStatusMapTitles.deleted}</option>
                  </select>
                </label>

              </div>
              <div
                className={style.inputArea}
                hidden={role !== 'guest'}
              >
                <InputField
                  closedInput
                  htmlFor="rental-address-inpt"
                  labelTitle="Rental Address"
                  inputValue={vehicleInfo?.rentalAddress || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editVehicleInfo('rentalAddress', e.target.value);
                  }}
                />
              </div>
              <div className={style.inputArea}>
                <InputField
                  closedInput
                  htmlFor="start-date-inpt"
                  labelTitle="Start Date"
                  inputType="date"
                  inputValue={vehicleInfo?.startDate
                    ? formatDateForInputUTC(vehicleInfo?.startDate) : undefined}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editVehicleInfo('startDate', e.target.value);
                  }}
                />

              </div>
              <div className={style.inputArea}>
                <InputField
                  closedInput
                  htmlFor="end-date-inpt"
                  labelTitle="Valid Through"
                  inputType="date"
                  inputValue={vehicleInfo?.endDate
                    ? formatDateForInputUTC(vehicleInfo?.endDate) : undefined}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editVehicleInfo('endDate', e.target.value);
                  }}
                  min={formatDateForInput(vehicleInfo?.startDate)}
                />

              </div>

            </div>
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            title="Save Changes"
            type="submit"
            color="blue"
          />
        </div>
      </form>
    </PopUp>
  );
}
