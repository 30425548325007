import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../../../components/genericButton/genericButton';
import style from './hostInfoSection.module.css';
import InputField from '../../../../components/inputField/inputField';

interface PropsType {
  title: string,
  editable?: string,
  data: { [key:string]: string },
  hidden?: boolean,
}
export default function HostInfoSection(props: PropsType) :ReactElement {
  const {
    title,
    editable,
    data,
    hidden,
  } = props;
  if (hidden) return <div />;
  return (
    <>
      <h3 className={style.subtitle}> Host Information</h3>
      <div className={style.infoSectionBox}>

        <div className={style.info1}>
          <div className={style.inputParent}>
            <div className={style.inputField}>
              <InputField
                boxInput
                highlightOnFocus
                htmlFor="first-name-inpt"
                labelTitle="First Name"
                inputType="text"
                inputValue={data.firstName}
                disabled
              />
            </div>
            <div className={style.inputField}>

              <InputField
                boxInput
                highlightOnFocus
                htmlFor="last-name-inpt"
                labelTitle="Last Name"
                inputType="text"
                inputValue={data.lastName}
                disabled
              />
            </div>
          </div>
          <div className={style.inputField}>

            <InputField
        // smallSize
              boxInput
              highlightOnFocus
              htmlFor="company-name-inpt"
              labelTitle="Company Name"
              inputType="text"
              inputValue={data.companyName}
              disabled
            />
          </div>

          <div className={style.inputField}>

            <InputField
        // smallSize
              boxInput
              highlightOnFocus
              htmlFor="phone-number-inpt"
              labelTitle="Phone Number"
              inputType="text"
              inputValue={data.phoneNumber}
              disabled
            />
          </div>

          <div className={style.inputField}>

            <InputField
        // smallSize
              boxInput
              highlightOnFocus
              htmlFor="email-inpt"
              labelTitle="Email"
              inputType="text"
              inputValue={data.email}
              disabled
            />
          </div>
          <div className={style.inputField}>

            <InputField
// smallSize
              boxInput
              highlightOnFocus
              htmlFor="mailing-address-inpt"
              labelTitle="Mailing Address"
              inputType="text"
              inputValue={data.address}
              disabled
            />
          </div>
        </div>
        <div className={style.info1}>
          <div className={style.inputParent}>
            <div className={style.inputField}>

              <InputField
// smallSize
                boxInput
                highlightOnFocus
                htmlFor="city-inpt"
                labelTitle="City"
                inputType="text"
                inputValue={data.city}
                disabled
              />
            </div>
            <div className={style.inputField}>

              <InputField
// smallSize
                boxInput
                highlightOnFocus
                htmlFor="state-inpt"
                labelTitle="State"
                inputType="text"
                inputValue={data.state}
                disabled
              />
            </div>
          </div>
          <div className={style.inputParent}>

            <div className={style.inputField}>

              <InputField
// smallSize
                boxInput
                highlightOnFocus
                htmlFor="zipcode-inpt"
                labelTitle="Zipcode"
                inputType="text"
                inputValue={data.zipCode}
                disabled
              />
            </div>

            <div className={style.inputField}>

              <InputField
// smallSize
                boxInput
                highlightOnFocus
                htmlFor="owners-pin-inpt"
                labelTitle="Owners pin"
                inputType="text"
                inputValue={data.ownerPin}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
HostInfoSection.defaultProps = {
  editable: '',
  hidden: false,

};
