import queryString from 'querystring';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import style from './nameAddressSearch.module.css';
import HostSearchDropdown from '../../../components/hostSearchDropdown/hostSearchDropdown';

export interface IFilter {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  invitedBy?: string;
}

export default function NameAddressSearch(): ReactElement {
  const [filters, setFilters] = useState<IFilter>({});
  const { search: urlSearch } = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (!urlSearch) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { invitedBy, ...parsedSearch } = queryString.parse(urlSearch.replace(/\?/, ''));
    setFilters(parsedSearch as IFilter);
  }, []);

  const changeFilter = useCallback((name: keyof IFilter, value: any) => setFilters((prev) => {
    const res = {
      ...prev,
    };
    if (value !== undefined) {
      res[name] = value;
    } else {
      delete res[name];
    }
    return res;
  }), [setFilters]);

  return (
    <div className={style.nameAddressSearchBox}>
      <div className={style.infoBox}>
        <div className={style.titleBox}>
          <p className={style.title}>Name & Address Search</p>
          <p className={style.subTitle}>
            Please fill in any or all of the information below to search
          </p>
        </div>
        <form className={style.form}>
          <div className={style.formInfor}>
            <div className={style.inputRow}>
              <div className={style.inputLong}>
                <InputField
                  closedInput
                  htmlFor="first-name-inpt"
                  labelTitle="First Name"
                  inputValue={filters.firstName}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeFilter('firstName', e.target.value);
                  }}
                />
              </div>
              <div className={style.inputLong}>
                <InputField
                  closedInput
                  htmlFor="last-name-inpt"
                  labelTitle="Last Name"
                  inputValue={filters.lastName}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeFilter('lastName', e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={style.inputRow}>
              <div className={style.inputLong}>
                {/* <InputField
                  closedInput
                  htmlFor="property-manager-inpt"
                  labelTitle="Host/Property Manager Name"
                  inputValue={filters.propertyManager}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeFilter('propertyManager', e.target.value);
                  }}
                /> */}
                <HostSearchDropdown
                  id="property-manager-inpt"
                  title="Host/Property Manager Name"
                  onSelect={useCallback((e) => {
                    changeFilter('invitedBy', e?.userId);
                  }, [changeFilter])}
                />
              </div>
              <div className={style.inputLong}>
                <InputField
                  closedInput
                  htmlFor="phone-inpt"
                  labelTitle="Phone Number"
                  inputValue={filters.phoneNumber}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeFilter('phoneNumber', e.target.value);
                  }}
                />
              </div>

            </div>
            <div className={style.inputRow}>
              <div className={style.inputLong}>
                <InputField
                  closedInput
                  htmlFor="address-inpt"
                  labelTitle="Address"
                  inputValue={filters.address}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeFilter('address', e.target.value);
                  }}
                />
              </div>
              <div className={style.inputLong}>
                <InputField
                  closedInput
                  htmlFor="city-inpt"
                  labelTitle="City"
                  inputValue={filters.city}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeFilter('city', e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

        </form>
        <div className={style.genericSubmitBtn}>
          <div className={style.genericSubmitBtnInnerBox}>
            <GenericButton
              color="blue"
              size="large"
              title="Search"
              type="submit"
              clickHandler={() => {
                // Save the search params
                history.replace({
                  pathname: history.location.pathname,
                  search: queryString.stringify(filters as any),
                });
                history.push({ pathname: '/admin/popup/search-name-address', search: queryString.stringify(filters as any) });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
