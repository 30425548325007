import React, { ReactElement, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ChervonDown } from '../../assets/chervonDown.svg';
import { ReactComponent as LeftNav } from '../../assets/leftNav.svg';
import { ReactComponent as RectIcon } from '../../assets/rect.svg';
import { ReactComponent as RightNav } from '../../assets/rightNav.svg';
import useOnClickOutside from '../../hooks/useClickOutside';
import styles from './dateRangePicker.module.css';

interface IProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  disabled?: boolean
}

// Due to have not fit properties to use, we need to use this styled component library
const Wrapper = styled.div`
.react-datepicker {
  background: #FFFFFF;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.react-datepicker__day {
  font-size: 14px;
  font-weight: 500;
  border-radius: 100%;
  margin: 8px;
}
.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  background: #CEDAE9;
  color: #0F2552;
}
.react-datepicker__day--selected {
  color: #ffffff;
  background: #3E81D0;
}
.react-datepicker__day-name {
  margin: 8px;
  color: #848A95;
}
.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: 0.8px solid #E4E5E7;
}
`;

export default function DateRangePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  disabled,
}: IProps): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popupRef = useRef();
  useOnClickOutside(popupRef, () => setIsOpen(false));
  const years: number[] = [];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  for (let i = new Date().getFullYear() - 20; i <= new Date().getFullYear() + 10; i += 1) {
    years.push(i);
  }

  const formatDateForInput = (d?: Date | string): string => {
    const date = typeof d === 'object' ? d : new Date(d || '');
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: {
    date: Date,
    changeYear: any,
    changeMonth: any,
    decreaseMonth: any,
    increaseMonth: any,
    prevMonthButtonDisabled: any,
    nextMonthButtonDisabled: any,
  }) => (
    <div className={styles.header__container}>
      <div>
        <select
          value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(value)}
          className={styles.dropdownButton}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select
          value={months[date.getMonth()]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          className={styles.dropdownButton}
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.nav}>
        <button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className={styles.nav__button}
        >
          <LeftNav />
        </button>
        <button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className={styles.nav__button}
        >
          <RightNav />
        </button>
      </div>
    </div>
  );

  let { input } = styles;
  if (disabled === false) {
    input = styles.inputDisabled;
  }

  const onChangeStartDate = (date: Date) => {
    if (date > endDate) {
      setEndDate(date);
    }
    setStartDate(date);
  };

  const onChangeEndDate = (date: Date) => {
    if (date < startDate) {
      setStartDate(date);
    }
    setEndDate(date);
  };

  return (
    <div>
      <button className={styles.container} onClick={() => setIsOpen(true)}>
        <span className={disabled ? styles.svg : styles.svgDisabled}>
          <CalendarIcon />
        </span>
        <span className={input}>
          {formatDateForInput(startDate)}
        </span>
        <RectIcon />
        <span className={input}>
          {formatDateForInput(endDate)}
        </span>
        <ChervonDown />
      </button>
      {disabled && isOpen && (
        <div className={styles.popup} ref={popupRef as any}>
          <Wrapper>
            <DatePicker
              renderCustomHeader={renderCustomHeader}
              selected={startDate}
              onChange={onChangeStartDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              inline
              maxDate={new Date()}
              disabledKeyboardNavigation
            />
          </Wrapper>
          <Wrapper>
            <DatePicker
              renderCustomHeader={renderCustomHeader}
              selected={endDate}
              onChange={onChangeEndDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              inline
              maxDate={new Date()}
              disabledKeyboardNavigation
            />
          </Wrapper>
        </div>
      )}
    </div>
  );
}

DateRangePicker.defaultProps = {
  disabled: false,
};
