import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, {
  ReactElement, useState, useRef, useCallback,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import style from './searchPasses.module.css';
import GenericAlert from '../../../components/genericAlert/genericAlert';
import PopupCellRenderer from './popUpCellRender';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { formatDateWithMonthName } from '../../../common_lib_front/utilities/formatDate';

const GET_ALL_DOCUMENTS = gql`
  query GetAllDocuments {
    getAllDocuments {
      error
      success
      data {
        documentType
        documentUrl
        userDocumentName
        documentId
        uploadDate
      }
    }
  }
`;
type GET_ALL_DOCUMENTS_RES = {
  getAllDocuments: backendResponse<{
    documentType: string;
    documentUrl: string;
    userDocumentName: string;
    documentId: string;
    uploadDate: string;
  }[]>
}

export default function ReportCenterDocumentSearch(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const gridRef = useRef<AgGridReact>(null);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const allNodes: any[] = [];
  gridRef.current?.api?.forEachNode((row) => {
    if (row.displayed) allNodes.push(row);
  });
  const { loading, data } = useQuery<GET_ALL_DOCUMENTS_RES>(GET_ALL_DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
  });

  const DocUrl = ({ value }: { value: string }) => (
    // console.log('date', value)
    <div>
      { new Date(value).toLocaleDateString()}
    </div>
  );
  const [columnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: 'Document Name', field: 'fileName', flex: 1.5, sortable: true, filter: 'agMultiColumnFilter',

    },
    {
      headerName: 'Document Type', field: 'documentType', flex: 1.5, sortable: true, filter: 'agMultiColumnFilter',

    },
    {
      headerName: 'Upload Date', cellRendererFramework: DocUrl, field: 'uploadDate', flex: 1.5, sortable: true, filter: 'agMultiColumnFilter',

    },

  ]);
  const onBtnExport = useCallback(() => {
    const columnKeys = ['fileName', 'documentType', 'uploadDate'];
    const headers: string[] = [];
    columnDefs.slice(1).forEach((col) => {
      headers.push(col.headerName);
    });
    const dataToExport: string[] = [headers.join(',')];
    gridRef.current?.api.forEachNode((row) => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes.sort((a, b) => a.rowIndex - b.rowIndex).forEach((row) => {
      const { data: item } = row;
      const itemData: any[] = [];
      columnKeys.forEach((key: string) => {
        if (key === 'uploadDate') {
          itemData.push(formatDateWithMonthName(new Date(item[key])));
        } else {
          itemData.push(`"${item[key]}"`);
        }
      });
      dataToExport.push(itemData.join(','));
    });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);
  const rowData = data?.getAllDocuments?.data?.flatMap((item) => ({
    fileName: item.userDocumentName,
    documentType: item.documentType,
    documentUrl: item.documentUrl,
    documentId: item.documentId,
    uploadDate: item.uploadDate,
    actions: item,
    // download: item,
  }));
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 250px)', width: '100%' }}>
      {loading ? (
        <div className={style.loadingBox}>
          <LoadingDiamonds
            title="Loading Company Documents"
          />
        </div>
      ) : (
        <>
          <GenericAlert
            color={alertColor}
            title={alert}
            hidden={!alert}
          />
          <div className={style.topLine}>
            <input
              type="search"
              className={style.gridSearch}
              onChange={onFilterTextChange}
              placeholder="Search anything here .."
            />
            <label htmlFor="page-num-inpt">
              Show
              <select
                className={style.selectBox}
                value={itemsPerPage}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setItemsPerPage(Number.parseInt(e.target.value, 10));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              Per Page
            </label>
            <button className={style.btnExport} onClick={onBtnExport}>Export CSV</button>

          </div>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            enableCellTextSelection
            ensureDomOrder
            animateRows
            pagination
            ref={gridRef}
            paginationPageSize={itemsPerPage}
            // paginationAutoPageSize
          />
        </>
      )}

    </div>
  );
}
