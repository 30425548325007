import React, { useEffect, useState } from 'react';
import { QrReader as RQrReader } from 'react-qr-reader';
import style from './qrReader.module.css';

interface IProps {
    open?: boolean;
    onSuccess: (data: any) => void;
    onClose: () => void;
}

function QrReader({
  open,
  onSuccess,
  onClose,
}: IProps): React.ReactElement | null {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
  }, []);

  if (!open || !isMobile) {
    return null;
  }

  return (
    <div className={style.container}>
      <RQrReader
        onResult={(result: any, error: any) => {
          if (result) {
            onSuccess(result);
          }

          if (error) {
            if (
              error?.message
                ?.toString()
                .startsWith('The request is not allowed')
            ) {
              window.alert(
                'Some thing went wrong. Please check your permission or try again later.',
              );
              onClose();
            }
          }
        }}
        constraints={{
          facingMode: { exact: 'environment' },
        }}
      />
      <div className={style.button__container}>
        <button
          className={style.button}
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

QrReader.defaultProps = {
  open: false,
};

export default QrReader;
