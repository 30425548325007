import React from 'react';
import { useHistory } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import GenericButton from '../../../components/genericButton/genericButton';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import { PopUp } from '../../../components/popUp/popUp';
import style from './editHostInfo.module.css';
import useEditHostInfo from './useEditHostInfo';

export default function EditHostInfo(): React.ReactElement {
  const history = useHistory();
  const {
    hostInfo,
    hostInfoLoading,
    errorMsg,
    changeData,
    editChangeData,
    success,
    onEditHostInfo,
    editLoading,
  } = useEditHostInfo();

  if (hostInfoLoading) {
    return (
      <LoadingDiamonds
        title="Loading Host Information..."
      />
    );
  }

  return (
    <PopUp
      title="Edit Host Info"
      close={history.goBack}
    >
      <form
        className={style.form}
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (changeData && Object.keys(changeData).length > 0) {
            onEditHostInfo();
          }
        }}
      >
        <GenericAlert
          title="Host Info Edited Successfully"
          color="green"
          hidden={!success}
        />
        <GenericAlert
          title={errorMsg}
          color="red"
          hidden={!errorMsg}
        />
        <div className={style.infoBox}>
          <div className={style.header}>
            <h4 className={style.title}>Host Information</h4>
          </div>
          <div className={style.paddingBox}>
            <div className={style.personalInfoBox}>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="fname-inpt"
                  labelTitle="First Name"
                  inputPlaceholder={hostInfo?.firstName}
                  inputValue={changeData?.firstName}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData({ firstName: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="lname-inpt"
                  labelTitle="Last Name"
                  inputPlaceholder={hostInfo?.lastName}
                  inputValue={changeData?.lastName}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData({ lastName: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="company-name-inpt"
                  labelTitle="Company Name (Property Mamagement Companies Only)"
                  inputPlaceholder={hostInfo?.companyName}
                  inputValue={changeData?.companyName}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData({ companyName: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <PhoneInputWrapper
                  closedInput
                  value={changeData?.phoneNumber || hostInfo?.phoneNumber}
                  changeHandler={(val: string) => {
                    editChangeData({ phoneNumber: val });
                  }}
                  inputTitle="Phone Number"
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="addr-inpt"
                  labelTitle="Address"
                  inputPlaceholder={hostInfo?.address}
                  inputValue={changeData?.address}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData({ address: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="city-inpt"
                  labelTitle="City"
                  inputPlaceholder={hostInfo?.city}
                  inputValue={changeData?.city}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData({ city: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="state-inpt"
                  labelTitle="State"
                  inputPlaceholder={hostInfo?.state}
                  inputValue={changeData?.state}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData({ state: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="zip-inpt"
                  labelTitle="Zip Code"
                  inputPlaceholder={hostInfo?.zipCode}
                  inputValue={changeData?.zipCode}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData({ zipCode: e.target.value });
                  }}
                />
              </div>
              <div className={style.inputBox}>
                <InputField
                  closedInput
                  htmlFor="owner-p-inpt"
                  labelTitle="Owner Pin"
                  inputPlaceholder={hostInfo?.ownerPin}
                  inputValue={changeData?.ownerPin}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editChangeData({ ownerPin: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.btn}>
          {!editLoading ? (
            <GenericButton
              size="large"
              title="Save Changes"
              type="submit"
              color="blue"
            />
          ) : (
            <LoadingDiamonds
              title="Saving..."
            />
          )}
        </div>
      </form>
    </PopUp>
  );
}
