import React, { ReactElement, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import ActionsBtn from '../../../components/actionsBtn/actionsBtn';
import DropDownUserInfo from '../../../components/dropDownUserInfo/dropDownUserInfo';
import GenericAlert from '../../../components/genericAlert/genericAlert';
// import GenericButton from '../../../components/genericButton/genericButton';
import InfoStection from '../../../components/infoSection/infoSection';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import ReportTable from '../../../components/reportTable/reportTable';
import style from './hostDetails.module.css';
import useHostDetails from './useHostDetails';
import FullInfoPopup from '../../../components/fullInfoPopup/fullInfoPopup';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';

export default function ReportCenterDetails(): ReactElement {
  const {
    hostData,
    hostLoading,
    hostError,
    rentalData,
    rentalError,
    history,
    checkAllHandler,
    passStatusChangeHandler,
  } = useHostDetails();

  const { communityId } = useContext(CommunityContext);

  if (hostLoading) {
    return (
      <LoadingDiamonds
        title="Getting User..."
      />
    );
  }
  return (
    <div className={style.container}>
      <div className={style.flexBox}>
        <div className={`${style.infoBox} white`}>
          <GenericAlert
            title={hostError}
            hidden={!hostError}
            color="red"
          />
          <button
            className={style.exitBtn}
            onClick={() => history.goBack()}
          >
            &#160;
          </button>
          <h1 className={`${style.infoTitle} textColorSuperDark`}>
            {`${hostData.firstName || ''} ${hostData.lastName || ''}`}
          </h1>
          <div className={style.infoSectionBox}>
            <div className={`${style.section}`}>
              <InfoStection
                editable={`/admin/popup/edit-host-info/${hostData.hostInfoId}`}
                title="Host Information"
                data={{
                  NAME: `${hostData.firstName || ''} ${hostData.lastName || ''}`,
                  'COMPANY NAME': hostData.companyName || '',
                  'PHONE #': hostData.phoneNumber || '',
                  EMAIL: hostData.email || '',
                  'MAILING ADDRESS': hostData.address,
                  CITY: hostData.city,
                  STATE: hostData.state,
                  'ZIP CODE': hostData.zipCode,
                  'OWNER PIN': hostData.ownerPin,
                }}
              />

            </div>
            {/* <div className={`${style.section} `}>
              <InfoStection
                editable=""
                title="Host Documents"
                data={{
                  'Business License': 't',
                  'Rental Insurance': 't',
                }}
                dataMapper={(obj) => (k, idx) => (
                  <div key={k} className={style.listItemBox}>
                    <ul className={style.circleBox}><div className={`${obj[k]
                       === 't' ? 'green' : 'red'} ${style.circle}`} /></ul>
                    <li className={style.listContent}>{k}</li>
                  </div>
                )}
              />
            </div> */}
          </div>
        </div>
        <div>
          <div className={`${style.tabBox} white `}>
            <div className={style.tab}>
              <div className={`${style.tabTitle}`}>
                Passes/Rental Units
              </div>

            </div>
          </div>
          <div className={style.tablesBox}>
            <div className="">
              <ReportTable
                headers={[
                  '',
                  (
                    <th className={style.theaderItemDropDown}>
                      &#160;
                    </th>
                  ),
                  'Property Address',
                  'Start Date',
                  'Valid Through',
                  (
                    <th className={style.selectArea}>
                      <span className={style.alignBox}>
                        <input
                          type="checkbox"
                          value="ready"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            checkAllHandler(e.target.checked);
                          }}
                          className={style.checkBox}
                        />
                        <select
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            passStatusChangeHandler(e.target.value);
                            e.target.value = 'ready';
                          }}
                          className={style.passStatusSelection}
                        >
                          <option value="ready" hidden>Set Pass Status</option>
                          <option value="Pending Review">Pending Review</option>
                          <option value="Active">Active</option>
                          <option value="Rejected">Rejected</option>
                          <option value="Refunded">Refunded</option>
                          <option value="Expired">Expired</option>
                        </select>

                      </span>
                    </th>
                  ),

                ]}
                data={rentalData || []}
                loading={hostLoading}
                error={rentalError}
                mapper={(elem, idx) => (
                  <tr key={elem.rentalUnitId} className={style.rentalUnitTableData}>

                    <ActionsBtn idx={idx || 0}>
                      <div className={`${style.actionBox} white`}>
                        <Link to={{
                          pathname: `/admin/popup/edit-rental/${elem.rentalUnitId}`,
                          state: elem,
                        }}
                        >
                          <button className={style.actionBtn}>
                            Edit
                          </button>
                        </Link>
                        <Link to={{
                          pathname: `/admin/popup/delete-rental/${elem.rentalUnitId}`,
                          state: elem,
                        }}
                        >
                          <button className={style.actionBtn}>
                            Delete
                          </button>
                        </Link>
                        {/* <Link to={{
                            pathname: '/admin/popup/share-pass',
                            state: elem,
                          }}
                          >
                            <button className={style.actionBtn}>
                              Share
                            </button>
                          </Link> */}
                        {/* <Link to={{
                            pathname: '/admin/popup/refund-pass',
                            state: elem,
                          }}
                          >
                            <button className={style.actionBtn}>
                              Refund
                            </button>
                          </Link> */}
                      </div>
                    </ActionsBtn>

                    <td className={style.tdItemsDropDown}>
                      <DropDownUserInfo
                        id={`${elem.rentalUnitId}`}
                        title="Rental Management Information:"
                        options={[
                          {
                            title: communityId === 'sipoa' ? 'Management Company Name:' : 'Company Name or Name if Self Managed:',
                            userInfo: elem.companyName,
                          }, {
                            title: 'Primary Emergency Contact Name:',
                            userInfo: elem.primaryEmergencyContactName,
                          }, {
                            title: 'Primary Emergency Phone Number:',
                            userInfo: elem.primaryEmergencyPhone,
                          }, {
                            title: 'Secondary Emergency Phone Number:',
                            userInfo: elem.secondaryEmergencyPhone,
                          }, {
                            title: 'Primary Emergency Contact Email Address:',
                            userInfo: elem.email,
                          },
                        ]}
                      />
                    </td>
                    <td>
                      <FullInfoPopup
                        id="fullAddress-popup"
                        title={elem.address}
                        infoTitle="Full Property Address:"
                        content={`${elem.address}, ${elem.city || ''}, ${elem.state || ''}, ${elem.zipCode || ''} `}
                      />
                    </td>

                    {/* <td>{elem.city}</td>
                    <td>{elem.state}</td>
                    <td>{elem.zipCode}</td> */}
                    {/* <td>{elem.ownerPin}</td> */}
                    <td>
                      {formatDate(elem.startDate ? new Date(elem.startDate) : undefined)}
                    </td>
                    <td>
                      {formatDate(elem.endDate ? new Date(elem.endDate) : undefined)}
                    </td>
                    <td>
                      <div className={style.statusBox}>
                        <input data-rental-id={elem.rentalUnitId} type="checkbox" className={style.checkBox} />
                        <span className={`${style.passStatus} 
                        ${elem.passStatus === 'Pending Review' ? style.grey : ''}
                        ${elem.passStatus === 'Active' ? style.green : ''}
                        ${elem.passStatus === 'Rejected' ? style.pink : ''}
                        ${elem.passStatus === 'Refunded' ? style.orange : ''}
                        ${elem.passStatus === 'Expired' ? style.red : ''}`}
                        >
                          {elem.passStatus || 'Pending Review'}
                        </span>

                      </div>
                    </td>

                  </tr>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
