import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { PopUp } from '../../../components/popUp/popUp';
import { SharePassFormCollection } from '../../../components/sharePassForm/sharePassFormCollection';

export default function SharePass() :ReactElement {
  const history :any = useHistory();

  return (
    <PopUp
      title="Share Pass"
      close={() => history.goBack()}
    >
      <div>
        <SharePassFormCollection
          passId={history.location.state?.passId}
        />
      </div>
    </PopUp>
  );
}
