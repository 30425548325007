import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import alertPic from '../../../assets/alert.svg';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import { PopUp } from '../../../components/popUp/popUp';
import style from './deleteEmployee.module.css';

export const DELETE_EMPLOYEE = gql`
mutation deletePassEmployeeById (
  $userId: String!
  $employeeId: String!
) {
  deletePassEmployeeById (
    userId: $userId
    employeeId: $employeeId
  ) {
    success
    error
  }
}
`;

export default function DeleteEmployee(): ReactElement {
  const history = useHistory();
  const { employeeId, userId } = useParams<{ employeeId: string, userId: string }>();
  const [alert, setAlert] = useState<string>('');

  const [doDeleteEmployee, { loading }] = useMutation(DELETE_EMPLOYEE, {
    onError: (e) => {
      setAlert(e.message);
    },
    onCompleted: (d) => {
      if (d.deletePassEmployeeById.success) {
        history.goBack();
      } else {
        setAlert(d.deletePassEmployeeById.error);
      }
    },
  });

  return (
    <PopUp
      title="Delete Employee"
      close={() => history.goBack()}
    >
      <div className={style.box}>
        <div className={style.alertBox}>
          <img src={alertPic} alt="" className={style.alertImg} />
          <h2>Warning:</h2>
          <h4 className={style.text}> Are you sure you want to delete the employee ?</h4>
        </div>
        <GenericAlert
          color="red"
          title={alert}
          hidden={!alert}
        />
        <div className={style.btnBox}>
          <button onClick={() => history.goBack()} className={style.cancel}>
            Cancel
          </button>
          <button
            onClick={() => doDeleteEmployee({ variables: { userId, employeeId } })}
            className={style.delete}
          >
            Delete
          </button>
        </div>

      </div>
    </PopUp>
  );
}
