import queryString from 'querystring';
import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IFilter } from '../../security/nameAddressSearch/nameAddressSearch';
import { backendPaginatedResponse } from '../../../common_lib_front/types/backendResponse';

const SEARCH_RESERVATIONS = gql`
query SearchReservations(
  $arrivalDate: DateTime,
  $departureDate: DateTime,
  $invitedBy: String,
  $address: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $phoneNumber: String,
  $passNumber: Float,
  $sortOrder: Float, 
  $pageSize: Float!, 
  $pageNumber: Float!, 
) {
  searchReservations(
    arrivalDate: $arrivalDate, 
    departureDate: $departureDate, 
    invitedBy: $invitedBy, 
    address: $address, 
    firstName: $firstName, 
    lastName: $lastName, 
    email: $email, 
    phoneNumber: $phoneNumber, 
    passNumber: $passNumber
    sortOrder: $sortOrder, 
    pageSize: $pageSize,
    pageNumber: $pageNumber,
  ) {
    success
    error
    numberPages
    data {
      communityRentalId
      registrationId
      address
      arrivalDate
      departureDate
      guest {
        userId
        firstName
        lastName
        phoneNumber
        address
        city
        state
        zipCode
        roles
      }
      invitedBy {
        firstName
        lastName
        companyName
        userId
      }
    }
  }
}
`;

type SEARCH_RESERVATIONS_VARS = {
  arrivalDate?: string,
  departureDate?: string,
  invitedBy?: string,
  address?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  phoneNumber?: string,
  passNumber?: number,
  sortOrder?: number,
  pageSize: number,
  pageNumber: number,
}
export type IUser = {
  communityRentalId: string,
  registrationId: string,
  address: string,
  arrivalDate: string,
  departureDate: string,
  guest?: {
    userId: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    address: string,
    city: string,
    state: string,
    zipCode: string,
    roles: string[],
  },
  invitedBy: {
    firstName: string,
    lastName: string,
    companyName: string,
    userId: string,
  },
  // passes: {
  //   passId: string,
  //   number: string,
  //   startDate: string,
  //   endDate: string,
  //   vehicle: {
  //     make: string,
  //     color: string,
  //     vehicleModel: string,
  //     licensePlate: string,
  //   },
  // },
}
type SEARCH_RESERVATIONS_RES = {
  searchReservations: backendPaginatedResponse<IUser[]>,
};

interface IPageInfo {
  pageSize: number;
  pageNumber: number;
  numberPages: number;
}

export default function useSearchNameAddress(): {
  users: IUser[];
  errorMessage?: string;
  pageInfo: IPageInfo;
  pageChange: (num: number) => void;
  pageValidator: (num: number) => number,
  } {
  const history = useHistory();
  const [filters, setFilters] = useState<IFilter>({});
  const { search: urlSearch } = useLocation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    pageSize: 7, pageNumber: 0, numberPages: 0,
  });

  // page info helper to change page number
  const pageValidator = (num: number): number => {
    if (Number.isNaN(num) || num < 0) return 0;
    if (num + 1 >= pageInfo.numberPages) return pageInfo.numberPages - 1;
    return num;
  };
  const pageChange = (num: number) => {
    if (Number.isNaN(num)) return;
    const res = { ...pageInfo };
    res.pageNumber = pageValidator(num);
    setPageInfo(res);
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...filters, ...res }),
    });
  };

  useEffect(() => {
    if (!urlSearch) {
      return;
    }
    const parsedSearch = queryString.parse(urlSearch.replace(/\?/, ''));
    setFilters(parsedSearch as IFilter);
    setPageInfo((prev) => ({
      ...prev,
      pageNumber: +parsedSearch.pageNumber || prev.pageNumber,
      numberPages: +parsedSearch.numberPages || prev.numberPages,
    }));
  }, []);

  const [fetch, { data }] = useLazyQuery<
    SEARCH_RESERVATIONS_RES, SEARCH_RESERVATIONS_VARS
  >(SEARCH_RESERVATIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      firstName: filters.firstName,
      lastName: filters.lastName,
      phoneNumber: filters.phoneNumber,
      address: filters.address,
      invitedBy: filters.invitedBy,
      // city: filters.city,
      pageSize: pageInfo.pageSize,
      pageNumber: pageInfo.pageNumber,
    },
    onError: () => {
      setErrorMessage('Something went wrong');
    },
    onCompleted: (d) => {
      if (!d.searchReservations?.success) {
        setErrorMessage(d.searchReservations.error);
      } else {
        const numberPages = d.searchReservations?.numberPages;
        setPageInfo((prev) => ({
          ...prev,
          numberPages,
        }));
      }
    },
  });

  useEffect(() => {
    fetch();
  }, [filters]);

  return {
    users: data?.searchReservations?.data || [],
    errorMessage,
    pageInfo,
    pageChange,
    pageValidator,
  };
}
