import React, { ReactElement, useState } from 'react';
import style from './passTypeList.module.css';
import CheckBox from '../checkBox/checkBox';

export default function PassTypeList(props:any): ReactElement {
  const {
    title, data, isChecked, close, listMode,
  } = props;
  const [open, setOpen] = useState(true);
  return (
    <div>
      <button
        onClick={() => {
          setOpen(!open);
          if (open === true) {
            close();
          }
        }}
        className={open ? style.titleButtonOpen : style.titleButtonClose}
      >
        <div className={style.title}>{title}</div>
      </button>
      {open && (
      <ul>
        {data.map((item:any, index:number) => (
          <CheckBox
            key={item.passInfoId}
            index={index}
            item={item.name}
            isChecked={isChecked}
            listMode={listMode}
            small
          />
        ))}
      </ul>
      )}
    </div>
  );
}
