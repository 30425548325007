import React, { ReactElement, useContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import * as Pages from './pages/index';
import * as CommonPages from './common_lib_front/pages';
import Header from './components/header/header';
import './css';
import ReportCenterTemplate from './components/reportCenterTemplate/reportCenterTemplate';
import { CommunityContext } from './common_lib_front/communityConfigs/communityContextProvider';
import store, { isLogedIn as reduxIsLogedIn } from './common_lib_front/utilities/store';
import SecurityTemplate from './components/securityTemplate/securityTemplate';
import useUserGuiding from './common_lib_front/utilities/useUserGuiding';
import VendorDetailsTemplate from './components/vendorDetailsTemplate/vendorDetailsTemplate';
import RapidSearch from './components/rapidSearch/rapidSearch';

const flatten = (routes: ReactElement): Array<ReactElement> => routes.props.children;

export default function App(): ReactElement {
  const { communityId } = useContext(CommunityContext);
  const [isLogedin, setIsLogedin] = useState<boolean>(reduxIsLogedIn());
  store.subscribe(() => setIsLogedin(reduxIsLogedIn()));
  useUserGuiding('vendor');

  return (
    <div className="container">
      <Router basename={`${communityId}`}>
        <Header />
        <Switch>
          <Route path="/help">
            <CommonPages.HelpPage
              role="admin" // eslint-disable-line
            />
          </Route>

          {isLogedin // check login
            ? flatten(
              <>
                <Route exact path="/admin">
                  <Pages.Home />
                </Route>
                <Route exact path="/">
                  <Redirect to="/admin" />
                </Route>
                <Route path="/admin/edit-profile/my-profile">
                  <Pages.MyProfile />
                </Route>
                <Route path="/admin/rapid-search/host/details/:hostInfoId">
                  <Pages.HostGuestDetails />
                </Route>
                <Route path="/admin/rapid-search/resident/details/:userId">
                  <Pages.ResidentInfo />
                </Route>
                <Route path="/admin/edit-profile/reset-password">
                  <Pages.ResetPassword />
                </Route>
                <Route path="/admin/edit-profile">
                  <Redirect to="/admin/edit-profile/my-profile" />
                </Route>
                {/* pop ups */}
                <Route path="/admin/popup/more-details">
                  <Pages.MoreDetailsPopup />
                </Route>
                <Route exact path="/admin/report-center/smart-access-managers/passDetail/:passId">
                  <Pages.PassDetails />
                </Route>
                <Route path="/admin/popup/more-details:passId">
                  <Pages.MoreDetailsPopup />
                </Route>
                <Route path="/admin/popup/edit-pass/:passId">
                  <Pages.EditVehiclePassPopUp />
                </Route>
                <Route path="/admin/popup/edit-user/:userId">
                  <Pages.EditUserPopUp />
                </Route>
                <Route path="/admin/popup/edit-host-info/:hostInfoId">
                  <Pages.EditHostInfoPopUp />
                </Route>
                <Route path="/admin/popup/edit-rental/:rentalId">
                  <Pages.EditRentalPopUp />
                </Route>
                <Route path="/admin/popup/delete-rental/:rentalId">
                  <Pages.DeleteRentalPopUp />
                </Route>
                <Route path="/admin/popup/delete-pass/:passId">
                  <Pages.DeleteVehiclePassPopUp />
                </Route>
                <Route path="/admin/popup/add-user">
                  <Pages.AddUserPopUp />
                </Route>
                <Route path="/admin/popup/view-pass">
                  <Pages.ViewPassPopup />
                </Route>
                <Route path="/admin/popup/refund-pass/:passId">
                  <Pages.RefundPopUp />
                </Route>
                <Route path="/admin/popup/add-doc">
                  <Pages.AddDocPopup />
                </Route>
                <Route path="/admin/popup/share-pass">
                  <Pages.SharePassPopUp />
                </Route>
                <Route path="/admin/popup/view-complaint">
                  <Pages.ViewComplaintPopUp />
                </Route>
                <Route path="/admin/popup/mark-paid">
                  <Pages.MarkPaid />
                </Route>
                <Route path="/admin/popup/assign-barcode">
                  <Pages.AssignBarcode />
                </Route>
                <Route path="/admin/popup/search-vehicle">
                  <Pages.SearchVehiclePopup />
                </Route>
                <Route path="/admin/popup/delete-employee/:employeeId/:userId">
                  <Pages.DeleteEmployeePopup />
                </Route>
                <Route path="/admin/popup/delete-doc/:docId">
                  <Pages.DeleteDocument />
                </Route>
                <Route path="/admin/popup/search-name-address">
                  <Pages.SearchNameAddressPopup />
                </Route>
                {/* report center */}
                <Route exact path="/admin/report-center">
                  <Redirect to="/admin/report-center/guest" />
                </Route>
                <Route exact path="/admin/report-center/dashboard">
                  <ReportCenterTemplate />
                </Route>
                <Route exact path="/admin/report-center/reports">
                  <Redirect to="/admin/report-center/reports/passes-sold" />
                </Route>
                <Route exact path="/admin/report-center/reports/:currentReport">
                  <ReportCenterTemplate>
                    <Pages.ReportCenterReports />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/host">
                  <ReportCenterTemplate>
                    <Pages.HostSearch />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/host/details/:hostInfoId">
                  <ReportCenterTemplate>
                    <Pages.HostDetails />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/security">
                  <ReportCenterTemplate>
                    <Pages.ReportSecurity />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/security-details">
                  <ReportCenterTemplate>
                    <Pages.ReportSecurityDetail />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/guest">
                  <ReportCenterTemplate>
                    <Pages.ReportCenterGuestSearch />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/vendor">
                  <ReportCenterTemplate>
                    <Pages.ReportCenterSearchPasses />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/documents">
                  <ReportCenterTemplate>
                    <Pages.ReportSearchDocument />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/:role">
                  <ReportCenterTemplate>
                    <Pages.ReportCenterSearch />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/user-settings/details/:userId">
                  <ReportCenterTemplate>
                    <Pages.UserSettings />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/:role/details/:userId/:currentTable">
                  <ReportCenterTemplate>
                    <Pages.ReportCenterDetails />
                  </ReportCenterTemplate>
                </Route>
                <Route exact path="/admin/report-center/:role/details/:userId">
                  <ReportCenterTemplate>
                    <Pages.ReportCenterDetails />
                  </ReportCenterTemplate>
                </Route>
                {/* Security */}
                <Route exact path="/admin/security">
                  <Redirect to="/admin/security/scan-pass" />
                </Route>
                <Route exact path="/admin/security/violation-incident">
                  <SecurityTemplate>
                    <Pages.ViolationIncident />
                  </SecurityTemplate>
                </Route>
                <Route exact path="/admin/security/scan-pass">
                  <SecurityTemplate>
                    <Pages.ScanPass />
                  </SecurityTemplate>
                </Route>
                <Route exact path="/admin/security/vehicle-search">
                  <SecurityTemplate>
                    <Pages.VehicleSearch />
                  </SecurityTemplate>
                </Route>
                <Route exact path="/admin/security/name-address-search">
                  <SecurityTemplate>
                    <Pages.NameAddressSearch />
                  </SecurityTemplate>
                </Route>
                <Route exact path="/admin/security/complaint">
                  <SecurityTemplate>
                    <Pages.Complaint />
                  </SecurityTemplate>
                </Route>
                <Route exact path="/admin/vendor-details/:userId">
                  <VendorDetailsTemplate>
                    <Pages.VendorDetails />
                  </VendorDetailsTemplate>
                </Route>
                <Route exact path="/admin/rapid-search">
                  <RapidSearch />
                </Route>
              </>,
            )
            : flatten(
              // default route if not loged in
              <>
                <Route exact path="/create-account">
                  <CommonPages.CreateAccount
                    role="admin" // eslint-disable-line
                    successHref="/"
                  />
                </Route>
                <Route exact path="/login">
                  <CommonPages.Login
                    role="admin" // eslint-disable-line
                    successHref="/"
                    disableCreation
                  />
                </Route>
                <Route exact path="/forgot-password">
                  <CommonPages.ForgotPasswordEmail />
                </Route>
                <Route exact path="/forgot-password/verification-code">
                  <CommonPages.ForgotPasswordVerification />
                </Route>
                <Route path="/forgot-password/reset-password/:identifier">
                  <CommonPages.ForgotPasswordReset />
                </Route>
                <Route path="/">
                  <Redirect to="/login" />
                </Route>
              </>,
            )}

        </Switch>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}
