import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
// import { QueryString } from '../../../common_lib_front/utilities/QueryString';
import { backendPaginatedResponse } from '../../../common_lib_front/types/backendResponse';

export type QueryParamsType = {
  sortOrder?: number,
  sortField?: string,
  toDate?: string,
  fromDate?: string,
  passStatus?: string,
  firstName?: string,
  email?: string,
  lastName?: string,
  phoneNumber?: string,
  city?: string,
  rentalAddress?: string,
  state?: string,
  ownerPin?: string,
  approvedBy?: string,
  licenseStatus?: string,
  propertyManager?: string,
  [key: string]: number | Date | string | undefined,
}
export const makeQueryParamsType = (data: any = {}): QueryParamsType => {
  const res: { [key: string]: any } = {};
  Object.keys(data).forEach((k: string) => {
    if (
      [
        'sortOrder',
        'sortField',
        'toDate',
        'fromDate',
        'passStatus',
        'firstName',
        'email',
        'lastName',
        'phoneNumber',
        'city',
        'rentalAddress',
        'state',
        'ownerPin',
        'approvedBy',
        'licenseStatus',
        'propertyManager',
      ].includes(k)
    ) {
      res[k] = data[k];
    }
  });
  return res;
};

const SEARCH_USERS = gql`
query SearchHosts (
  $pageSize: Float!, 
  $pageNumber: Float!, 
  $sortOrder: Float, 
  $sortField: String, 
  $toDate: DateTime, 
  $fromDate: DateTime, 
  $passStatus: String, 
  $firstName: String, 
  $email: String, 
  $lastName: String, 
  $phoneNumber: String, 
  $city: String, 
  $rentalAddress: String, 
  $state: String, 
  $ownerPin: String, 
  $approvedBy: String, 
  $licenseStatus: String
  $propertyManager: String
) {
  searchHosts(
    pageSize: $pageSize
    pageNumber: $pageNumber
    sortOrder: $sortOrder
    sortField: $sortField
    toDate: $toDate
    fromDate: $fromDate
    passStatus: $passStatus
    firstName: $firstName
    email: $email
    lastName: $lastName
    phoneNumber: $phoneNumber
    city: $city
    rentalAddress: $rentalAddress
    state: $state
    ownerPin: $ownerPin
    approvedBy: $approvedBy
    licenseStatus: $licenseStatus
    companyName: $propertyManager
  ) {
    success
    error
    numberPages
    data {
      hostInfoId
      userId
      registrationId
      email
      firstName
      lastName
      phoneNumber
      address
      city
      state
      ownerPin
      zipCode
      applicationDate
      licenseStatus
      passStatus
      approvedBy {
        userId
        firstName
        lastName
      }
    }
  }
}
`;
type SEARCH_USERS_VARS = {
  pageSize: number,
  pageNumber: number,
  sortOrder?: number,
  sortField?: string,
  toDate?: string,
  fromDate?: string,
  passStatus?: string,
  firstName?: string,
  email?: string,
  lastName?: string,
  phoneNumber?: string,
  city?: string,
  rentalAddress?: string,
  state?: string,
  ownerPin?: string,
  approvedBy?: string,
  licenseStatus?: string,
  propertyManager?: string,
}
type SEARCH_USERS_RES = {
  searchHosts: backendPaginatedResponse<{
    hostInfoId: string,
    userId: string,
    registrationId: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    address: string,
    city: string,
    state: string,
    ownerPin: string,
    zipCode: string,
    applicationDate: string,
    licenseStatus: string,
    passStatus: string,
    arrivalDates: Array<string>,
    departureDates: Array<string>,
    rentalAddresses: Array<string>,
    passes: {
      number: string,
    }[][],
    approvedBy: {
      userId: string,
      firstName: string,
      lastName: string,
    },
    numberOfActivePasses: number,
  }>
}

type useReportCenterSearchType = {
  queryParams: QueryParamsType,
  // eslint-disable-next-line
  qpChangeHandler: (key: keyof QueryParamsType, val: QueryParamsType[keyof QueryParamsType]) => void,
  searchRes: Array<any>,
  searchLoading: boolean,
  searchError: string,
  history: History | any,
  pageInfo: { pageNum: number, pageSize: number, numPages: number },
  pageChange: (num: number) => void,
  pageValidator: (num: number) => number,
  numPerPageChange: (num: number) => void,
}
export default function useReportCenterSearch(): useReportCenterSearchType {
  const history = useHistory();
  const { search: urlSearch } = useLocation();

  const [queryParams, setQueryParams] = useState<QueryParamsType>({});
  const [pageInfo, setPageInfo] = useState<{
    pageNum: number, pageSize: number, numPages: number,
  }>({
    pageNum: 0, pageSize: 25, numPages: 0,
  });

  // request to backend
  const [fetch, {
    data: searchData, loading: searchLoading, error: searchError,
  }] = useLazyQuery(SEARCH_USERS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (d) => {
      if (d.searchHosts.data.length > 0) {
        const res = { ...pageInfo };
        res.numPages = d.searchHosts.numberPages;
        setPageInfo(res);
      }
    },
  });

  // handler to safely and simply change queryParams state
  // eslint-disable-next-line
  const qpChangeHandler = (key: keyof QueryParamsType, val: QueryParamsType[keyof QueryParamsType]): void => {
    const res = { ...queryParams };
    res[key] = val;
    setQueryParams(res);
    if (
      ['licenseStatus', 'passStatus', 'fromDate', 'toDate'].includes(key as string)
      && searchData
    ) {
      history.replace({
        pathname: '/admin/report-center/host/',
        hash: history.location.hash,
        search: queryString.stringify({ ...res, force: !queryParams.force }),
      });
    }
  };

  // page info helper to chage num per page
  const numPerPageChange = (num: number) => {
    const res = { ...pageInfo };
    res.pageSize = num;
    setPageInfo(res);
  };
  // page info helper to change page number
  const pageValidator = (num: number): number => {
    if (Number.isNaN(num) || num < 0) return 0;
    if (num + 1 >= pageInfo.numPages) return pageInfo.numPages - 1;
    return num;
  };
  const pageChange = (num: number) => {
    if (Number.isNaN(num)) return;
    const res = { ...pageInfo };
    res.pageNum = pageValidator(num);
    setPageInfo(res);
  };
  // update page info on data retrieval
  useEffect(() => {
    // console.log(searchData);
    if (searchLoading) return;
    if (!searchData?.searchHosts.numberPages) {
      setPageInfo({
        pageNum: pageInfo.pageNum, pageSize: pageInfo.pageSize, numPages: 0,
      });
      return;
    }
    setPageInfo({
      pageNum: pageInfo.pageNum,
      pageSize: pageInfo.pageSize,
      numPages: searchData.searchHosts.numberPages,
    });
  }, [history.location.search, pageInfo.pageNum, pageInfo.pageSize]);

  // on search string change
  const getHosts = () => {
    // update queryParams state
    const parsedSearch = makeQueryParamsType(queryString.parse(urlSearch.replace(/\?/, '')));
    setQueryParams(parsedSearch);
    // query backend if params exist
    if (!urlSearch) return;
    fetch({
      variables: {
        pageNumber: pageInfo.pageNum,
        pageSize: pageInfo.pageSize,
        // communityIdentifier: communityPicker(),
        communityIdentifier: null,
        ...parsedSearch,
        toDate: parsedSearch.toDate ? new Date(parsedSearch.toDate) : null,
        fromDate: parsedSearch.fromDate ? new Date(parsedSearch.fromDate) : null,
      },
    });
  };

  // useEffect(getHosts, [pageInfo]);
  useEffect(getHosts, [pageInfo.pageNum, pageInfo.pageSize, urlSearch]);

  // returned values
  return {
    queryParams,
    qpChangeHandler,
    searchRes: searchData?.searchHosts?.data,
    searchLoading,
    searchError: searchData?.searchHosts.error || searchError?.message || '',
    history,
    pageInfo,
    pageChange,
    pageValidator,
    numPerPageChange,
  };
}
