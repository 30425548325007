import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export class QueryString {
  public static parse(queryString: string, defaultVal: any = ''): any {
    let qs = queryString;
    if (qs.charAt(0) === '?') {
      qs = qs.slice(1);
    }
    const res: any = {};
    qs.split('&').forEach((param: string) => {
      const vals = param.split('=');
      if (vals.length === 2) {
        const [v0, v1] = vals;
        res[v0] = v1 || defaultVal;
      }
    });
    return res;
  }

  public static encode(data: { [key: string]: any }): string {
    let res = '?';
    Object.keys(data).forEach((val: string) => {
      if (data[val]) {
        res += `${val}=${data[val]}&`;
      }
    });
    return res;
  }
}

export default function useQueryString(): { [key: string]: string } {
  const location = useLocation();
  let res = {};

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    res = QueryString.parse(location.search);
  }, [location]);

  return res;
}
