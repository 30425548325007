import { useQuery } from '@apollo/client';
import { ReactElement, createContext, useEffect, useMemo } from 'react';
import store, { isLogedIn } from '../utilities/store';
import { getAndSetToken, logout } from '../utilities/thunks';
import {
  CommunityType,
  GET_COMMUNITY_DATA,
  GET_COMMUNITY_DATA_RES,
  GET_COMMUNITY_DATA_VARS,
} from './communitiesRequests';
import { getCommunityId } from './getCommunityId';
import { CommunityConfig } from './ICommunityConfig';
import './color.css';

type CommunityAssets = {
  logo: string;
  passLogo: string;
  bigLogo: string;
  subLogo: string;
  /**
   * @deprecated
   */
  config: any; // eslint-disable-line
};

const defaultAssets = {
  logo: '',
  passLogo: '',
  bigLogo: '',
  subLogo: '',
  config: new CommunityConfig({}),
};

const defaultCommunity: CommunityType = {
  communityId: '',
  name: 'Community Does Not Exist',
};

interface CommunityContextType extends CommunityType, CommunityAssets {}
// context object default
export const CommunityContext = createContext<CommunityContextType>({
  ...defaultCommunity,
  ...defaultAssets,
});

// Context provider component
export function CommunityContextProvider(props: {
  children: ReactElement | Array<ReactElement> | undefined;
}): ReactElement {
  const { children } = props;
  // const [community, setCommunity] = useState<CommunityType>(defaultCommunity);
  const storeCommunityId = store.getState()?.community?.communityId;
  const communityId = getCommunityId();
  // fetch community data
  const { data: communityData } = useQuery<
    GET_COMMUNITY_DATA_RES,
    GET_COMMUNITY_DATA_VARS
  >(GET_COMMUNITY_DATA, {
    variables: {
      communityId,
      __cfg_no_token: true,
    },
  });
  const fetchedCommunity = communityData?.getCommunityById.data?.[0];

  /**
   * Automatically log out the user if their community is changed
   */
  useEffect(() => {
    if (
      fetchedCommunity?.communityId &&
      isLogedIn() &&
      storeCommunityId !== fetchedCommunity.communityId
    ) {
      store.dispatch(logout);
    }
  }, [fetchedCommunity?.communityId, storeCommunityId]);

  /**
   * hook to set css color class and assets
   */
  useEffect(() => {
    document.body.className = fetchedCommunity?.communityId || '';
    return () => {
      // cleanup function
      document.body.className = '';
    };
  }, [fetchedCommunity?.communityId]);

  /**
   * hook to update token
   */
  useEffect(() => {
    if (fetchedCommunity?.communityId && !isLogedIn()) {
      store.dispatch(getAndSetToken(fetchedCommunity?.communityId));
    }
  }, [fetchedCommunity?.communityId]);

  /**
   * memoize context value
   */
  const communityContextValue = useMemo(() => {
    let assets: CommunityAssets = defaultAssets;
    if (fetchedCommunity?.communityId) {
      try {
        assets = require(`./${fetchedCommunity?.communityId}/index.ts`) as CommunityAssets; // eslint-disable-line
        // include raw and prepared values for safety
        assets = {
          ...assets,
          /**
           * todo remove
           */
          config: {
            ...assets.config,
            ...new CommunityConfig(assets.config),
          },
        };
        // assets.config = {
        //   ...new CommunityConfig(assets.config),
        //   ...assets.config,
        // };
      } catch {
        console.warn('Could not import community assets'); // eslint-disable-line no-console
        assets = defaultAssets;
      }
    }
    return {
      ...(fetchedCommunity || defaultCommunity),
      ...assets,
    };
  }, [fetchedCommunity]);

  // return context provider and children or a helper to select community
  return (
    <CommunityContext.Provider value={communityContextValue}>
      {fetchedCommunity?.communityId ? (
        children // render children if mounted, ie community id is not empty string
      ) : (
        // render community selection helper
        <div>Community does not exist</div>
      )}
    </CommunityContext.Provider>
  );
}
