import React, { ReactElement, useCallback, useState } from 'react';
import {
  Link,
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import GenericButton from '../../../components/genericButton/genericButton';
import InputField from '../../../components/inputField/inputField';
import { QueryString } from '../../../common_lib_front/utilities/QueryString';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import LoadingDiamonds from '../../../components/loadingDiamonds/loadingDiamonds';
import style from './reportCenterSearch.module.css';
import useReportCenterSearch from './useReportCenterSearch';
import DropDownUserInfo from '../../../components/dropDownUserInfo/dropDownUserInfo';
import DropDownGuestInfo from '../../../components/dropDownGuestInfo/dropDownGuestInfo';
import HostSearchDropdown from '../../../components/hostSearchDropdown/hostSearchDropdown';

export default function ReportCenterSearch(): ReactElement {
  const {
    queryParams,
    qpChangeHandler,
    searchRes,
    searchLoading,
    searchError,
    marginSet,
    marginSetUserSetting,
    role,
    history,
    pageInfo,
    pageChange,
    pageValidator,
    numPerPageChange,
    selected,
    setSelected,
    getRole,
  } = useReportCenterSearch();

  const userIds = searchRes?.map((item: any) => item.userId) || [];
  const [openExportDropdown, setOpenExportDropdown] = useState(false);
  const [openReservationsDropdown, setOpenReservationsDropdown] = useState(false);

  const onChangeSelectOne = (userId: string, value: boolean) => {
    setSelected((prev: any) => ({
      ...prev,
      [userId]: value,
    }));
  };

  const onChangeSelectAll = (value: boolean) => {
    if (!value) {
      setSelected({});
    } else {
      let nextState = {};
      userIds?.forEach((id: string) => {
        nextState = {
          ...nextState,
          [id]: value,
        };
      });

      setSelected(nextState);
    }
  };

  const exportCsv = () => {
    const content = 'Report CSV';

    const link = document.createElement('a');
    link.download = 'report.csv';
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`;
    link.click();
  };

  const isChecked = role === 'user-settings' && Object.keys(selected)?.some((k: string) => selected[k] === true);

  console.log('searchRes', searchRes);
  return (
    <div className={style.container}>
      <form
        className={`${style.searchBox} white`}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          history.replace({
            pathname: `/admin/report-center/${role}`,
            hash: history.location.hash,
            search: QueryString.encode({ ...queryParams, force: !queryParams.force }),
          });
        }}
      >
        <div className={style.inputShort}>
          <InputField
            closedInput
            htmlFor="arrival-inpt"
            labelTitle="Arrival"
            inputType="date"
            inputValue={queryParams.arrivalDate}
            changeHandler={qpChangeHandler('arrivalDate')}
            hidden={role !== 'guest'}
          />
        </div>
        <div className={`${style.inputShort} ${style.removeMargin}`}>
          <InputField
            closedInput
            htmlFor="departure-inpt"
            labelTitle="Departure"
            inputType="date"
            inputValue={queryParams.departureDate}
            changeHandler={qpChangeHandler('departureDate')}
            hidden={role !== 'guest'}
          />
          {' '}

        </div>
        <div className={`${style.inputLong} ${marginSet}`}>
          <InputField
            closedInput
            htmlFor="cpmny-name-inpt"
            labelTitle="Company Name"
            inputValue={queryParams.companyName}
            changeHandler={qpChangeHandler('companyName')}
            hidden={!['vendor', 'user-settings'].includes(role)}
          />

        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="lname-inpt"
            labelTitle="Last Name"
            inputValue={queryParams.lastName}
            changeHandler={qpChangeHandler('lastName')}
          />
          {' '}

        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="fname-inpt"
            labelTitle="First Name"
            inputValue={queryParams.firstName}
            changeHandler={qpChangeHandler('firstName')}
          />

        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="phone-inpt"
            labelTitle="Phone Number"
            inputType="tel"
            inputValue={queryParams.phoneNumber}
            changeHandler={qpChangeHandler('phoneNumber')}
          />

        </div>
        <div className={`${style.inputLong} ${marginSet} ${marginSetUserSetting}`}>
          <InputField
            closedInput
            htmlFor="email-inpt"
            labelTitle="Email Address"
            inputValue={queryParams.email}
            changeHandler={qpChangeHandler('email')}
          />

        </div>
        <div className={`${style.inputLong} ${role !== 'vendor' ? style.removeMargin : ''}`}>
          <InputField
            closedInput
            htmlFor="employee-fname-inpt"
            labelTitle="Employee First Name"
            inputValue={queryParams.employeeFirstNamet}
            changeHandler={qpChangeHandler('employeeFirstName')}
            hidden={role !== 'vendor'}
          />
        </div>
        <div className={`${style.inputLong} ${role !== 'vendor' ? style.removeMargin : ''}`}>
          <InputField
            closedInput
            htmlFor="employee-lname-inpt"
            labelTitle="Employee Last Name"
            inputValue={queryParams.employeeLastName}
            changeHandler={qpChangeHandler('employeeLastName')}
            hidden={role !== 'vendor'}
          />
        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="assign-bar-inpt"
            labelTitle="Assigned Bar Code"
            inputValue={queryParams.barCode}
            changeHandler={qpChangeHandler('barCode')}
            hidden={role !== 'vendor'}
          />
        </div>

        <div className={`${style.inputLong} ${marginSetUserSetting} ${role === 'resident' ? style.removeMargin : ''}`}>
          <InputField
            closedInput
            htmlFor="rental-inpt"
            labelTitle="Rental Address"
            inputValue={queryParams.rentalAddress}
            changeHandler={qpChangeHandler('rentalAddress')}
            hidden={role !== 'guest'}
          />

        </div>

        <div className={`${style.inputLong} ${marginSetUserSetting} ${role === 'resident' ? style.removeMargin : ''}`}>
          <InputField
            closedInput
            htmlFor="pass-number-inpt"
            inputType="number"
            labelTitle="Pass Number"
            inputValue={queryParams.passNumber}
            changeHandler={qpChangeHandler('passNumber')}
            hidden={role !== 'guest'}
          />

        </div>

        <div className={`${style.inputLong} ${marginSetUserSetting} ${role === 'resident' ? style.removeMargin : ''}`}>
          {/* <InputField
            closedInput
            htmlFor="property-manager-inpt"
            labelTitle="Property Manager"
            inputValue={queryParams.propertyManager}
            changeHandler={qpChangeHandler('propertyManager')}
            hidden={role !== 'guest'}
          /> */}
          {(role === 'guest') && (
            <HostSearchDropdown
              id="property-manager-inpt"
              title="Property Manager"
              onSelect={useCallback(
                (val) => qpChangeHandler('propertyManager')(val?.userId || ''),
                [qpChangeHandler],
              )}
            />
          )}

        </div>

        <div className={`${style.inputLong} ${marginSetUserSetting}`}>
          <InputField
            closedInput
            htmlFor="street-address-inpt"
            labelTitle="Street Address"
            inputValue={queryParams.streetAddr}
            changeHandler={qpChangeHandler('streetAddr')}
            hidden={role !== 'resident'}
          />

        </div>
        <div className={style.inputLong}>
          <InputField
            closedInput
            htmlFor="city-inpt"
            labelTitle="City"
            inputValue={queryParams.city}
            changeHandler={qpChangeHandler('city')}
            hidden={role !== 'resident'}
          />

        </div>
        <div hidden={role !== 'user-settings'} className={style.inputLong}>
          <label
            htmlFor="role-inpt"
            hidden={role !== 'user-settings'}
            className={style.labelBox}
          >
            Role
            <select
              id="role-inpt"
              onChange={qpChangeHandler('role')}
              value={queryParams.role}
              className={style.select}
            >
              <option value={undefined}>All</option>
              <option value="guest">Guest</option>
              <option value="vendor">Vendor</option>
              <option value="resident">Resident</option>
              {/* <option value="host">Host</option> */}
              {/* <option value="employee">Employee</option> */}
              <option value="admin">Admin</option>
            </select>
          </label>

        </div>
        <div className={style.inputLong}>
          <div className={style.btnBox}>
            <GenericButton
              color="yellow"
              size="medium"
              title="Search"
              type="submit"
              clickHandler={() => {
                pageInfo.pageNum = 0;
              }}
            />

          </div>

        </div>
      </form>
      <div className={style.tableContainer}>
        <div className={`${style.numInputBox} white`}>
          <div className={style.exportPart} hidden={role !== 'guest'}>
            <button
              onClick={() => (
                setOpenReservationsDropdown(!openReservationsDropdown)
              )}
              className={style.ExportBtn}
            >
              Reservations
            </button>
            {openReservationsDropdown
              && (
                <div className={style.dropDown}>
                  <button>
                    Show New & Updated
                  </button>
                  <button>
                    Mark as Reviewed
                  </button>
                </div>
              )}
          </div>
          <div className={style.exportPart} hidden={role !== 'guest'}>
            <button
              onClick={() => (
                setOpenExportDropdown(!openExportDropdown)
              )}
              className={style.ExportBtn}
            >
              Export Data
            </button>
            {openExportDropdown
              && (
                <div className={style.dropDown}>
                  <button
                    onClick={() => exportCsv()}
                  >
                    Export to CSV
                  </button>
                  <button onClick={() => exportCsv()}>Export Wristband CSV</button>
                </div>
              )}
          </div>
          <label htmlFor="page-num-inpt" className={style.inputBox}>
            Show
            <select
              className={style.selectBox}
              value={pageInfo.numPerPage}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                numPerPageChange(Number.parseInt(e.target.value, 10));
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            Per Page
          </label>
        </div>
        <div className={style.radiusBox}>

          <table width="100%" cellSpacing="0px" className={style.table}>
            <thead className={style.tHead}>
              <tr className={`${style.tableHeader}   `}>
                <th className={style.firstTh}>
                  &#160;
                </th>
                <th hidden={role !== 'guest'} className={`${style.theaderItemDropDown} textColorWhite`}>
                  &#160;
                </th>
                <th hidden={['vendor', 'user-settings'].includes(role)} className={`${style.theaderItem} textColorWhite`}>
                  Name
                </th>
                <th hidden={role !== 'guest'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Property Manager'}
                </th>
                {/* <th hidden={role !== 'guest'} className={`${style.theaderItem} textColorWhite`}>
                  Arrival Date
                </th>
                <th hidden={role !== 'guest'} className={`${style.theaderItem} textColorWhite`}>
                  Departure Date
                </th> */}
                <th hidden={role !== 'user-settings'} className={`${style.theaderItemCheckBox} textColorWhite`}>
                  <div className={style.checkboxLayout}>
                    <input type="checkbox" onChange={(v: React.ChangeEvent<HTMLInputElement>) => onChangeSelectAll(v.target.checked)} />

                  </div>
                </th>
                <th hidden={!['vendor', 'user-settings'].includes(role)} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? 'Download' : 'Company Name'}
                </th>
                <th hidden={role !== 'user-settings'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? 'Deactivate Users' : 'Last Name'}
                </th>
                <th hidden={role !== 'user-settings'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'First Name'}
                </th>
                <th hidden={['guest', 'user-settings'].includes(role)} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Phone Number'}
                </th>
                <th hidden={['guest', 'vendor'].includes(role)} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Email Address'}
                </th>
                <th hidden={role !== 'user-settings'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Status'}
                </th>
                <th hidden={role !== 'user-settings'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Last Login'}
                </th>
                <th hidden={role !== 'user-settings'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Role'}
                </th>
                <th hidden={role !== 'resident'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Street Address'}
                </th>
                <th hidden={role !== 'resident'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'City'}
                </th>
                <th hidden={role !== 'guest'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Rental Address'}
                </th>
                <th hidden={role !== 'guest'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Arrival Date'}
                </th>
                <th hidden={role !== 'guest'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'Departure Date'}
                </th>
                <th hidden={['resident', 'user-settings'].includes(role)} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : '#Vehicles'}
                </th>
                <th hidden={role !== 'vendor'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : '#Active Passes'}
                </th>
                <th hidden={role !== 'vendor'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : '#Complaints'}
                </th>
                <th hidden={role !== 'guest'} className={`${style.theaderItem} textColorWhite`}>
                  {isChecked ? null : 'WB Req/Auth/Issued'}
                </th>
              </tr>
            </thead>
            <tbody>
              {
                searchLoading
                  ? (
                    <LoadingDiamonds
                      title="Loading Results"
                    />
                  ) : (
                    searchRes.map((elem: any, rowIdx) => (
                      <tr key={elem?.guest ? elem.guest?.userId : elem?.userId} className={`${style.row} white`}>
                        <td className={`${style.tdItems} ${style.center}`}>
                          <Link to={`/admin/report-center/${role}/details/${elem.guest ? elem.guest?.userId : elem?.userId}`}>
                            <div className={style.detailBtnBox}>
                              <GenericButton
                                outline="small"
                                title="Details"
                              />

                            </div>
                          </Link>
                          <ReactTooltip />
                        </td>
                        <td hidden={role !== 'guest'} className={style.tdItemsDropDown}>
                          <DropDownGuestInfo
                            id={`${elem.guest?.userId}-${rowIdx}`}
                            options={[
                              {
                                title: 'Phone Number:',
                                userInfo: elem.guest?.phoneNumber || '',
                              },
                              {
                                title: 'Email Address:',
                                userInfo: elem.guest?.email || '',
                              },
                            ]}
                            body={(
                              <div className={style.rentalBox}>
                                <div className={style.grayBackground}>
                                  <table
                                    key={elem.guest?.userId}
                                    className={style.rentalTable}
                                    cellSpacing={0}
                                  >
                                    <thead className={style.rentalThead}>
                                      <tr>
                                        <th>Pass Number</th>
                                        <th>Vehicle Make</th>
                                        <th>Vehicle Color</th>
                                      </tr>
                                    </thead>
                                    <tbody className={style.rentalTbody}>
                                      {elem.passes?.map((pass: any) => (
                                        <tr key={pass?.passId}>
                                          <td>{pass?.number}</td>
                                          <td>{pass?.vehicle?.make || ''}</td>
                                          <td>{pass?.vehicle?.color || ''}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          />
                        </td>
                        <td hidden={['vendor', 'user-settings'].includes(role)} className={style.tdItems} data-tip={(elem.guest ? elem.guest?.lastName : elem.lastName) || ''}>
                          {`${(elem.guest ? elem.guest?.firstName : elem?.firstName) || ''} ${(elem.guest ? elem.guest?.lastName : elem.lastName) || ''}`}
                        </td>
                        <td hidden={['vendor', 'user-settings', 'resident'].includes(role)} className={style.tdItems}>
                          {`${elem.invitedBy?.firstName || ''} ${elem.invitedBy?.lastName || ''}`}
                        </td>
                        <td hidden={role !== 'user-settings'} className={style.tdItemsCheckBox}>
                          <input type="checkbox" onChange={(v: React.ChangeEvent<HTMLInputElement>) => onChangeSelectOne(elem.guest?.userId, v.target.checked)} checked={selected?.[elem.guest?.userId] || false} />
                          {' '}
                        </td>
                        <td hidden={['guest', 'resident'].includes(role)} className={style.tdItems} data-tip={elem.firstName || ''}>
                          {elem.firstName || ''}

                        </td>
                        <td hidden={['guest', 'user-settings'].includes(role)} className={style.tdItems}>
                          {elem.phoneNumber || ''}
                        </td>
                        {/* <td hidden={role !== 'guest'} className={style.tdItems}>
                          {formatDate(new Date((() => {
                            try {
                              return elem.arrivalDates[0];
                            } catch {
                              return undefined;
                            }
                          })()))}
                        </td>
                        <td hidden={role !== 'guest'} className={style.tdItems}>
                          {formatDate(new Date((() => {
                            try {
                              return elem.departureDates[0];
                            } catch {
                              return undefined;
                            }
                          })()))}
                        </td> */}
                        <td hidden={role !== 'user-settings'} className={style.tdItems}>
                          {elem?.lastName || ''}
                        </td>
                        <td hidden={role !== 'user-settings'} className={style.tdItems}>
                          {elem?.firstName || ''}
                        </td>
                        <td hidden={['guest', 'vendor'].includes(role)} className={style.tdItems} data-tip={elem.guest?.email || ''}>
                          {elem.email || ''}
                        </td>
                        <td hidden={role !== 'user-settings'} className={style.tdItems}>
                          <span className={`${style.passStatus} ${(() => {
                            switch (elem.status) {
                              case 'active':
                                return ' green ';
                              case 'deactivated':
                                return ' red ';
                              case 'unactivated':
                                return ' specialGrey ';
                              default:
                                return ' ';
                            }
                          })()} textColorWhite`}
                          >
                            {elem?.status?.charAt(0)?.toUpperCase()}
                            {elem?.status?.slice(1)}
                          </span>
                          {/* {
                            elem.status === 'active'
                              ? (
                                <span className={`${style.passStatus} green textColorWhite`}>
                                  Active
                                </span>
                              ) : (
                                <span className={`${style.passStatus} red textColorWhite`}>
                                  Deactivated
                                </span>
                              )
                          } */}
                        </td>
                        <td hidden={role !== 'user-settings'} className={style.tdItems}>
                          {formatDate(elem.lastLogin ? new Date(elem.lastLogin) : undefined) || ''}
                        </td>
                        <td hidden={role !== 'user-settings'} className={style.tdItems}>
                          {elem?.roles ? getRole(elem?.roles, queryParams.role) : ''}
                        </td>
                        <td hidden={role !== 'resident'} className={style.tdItems} data-tip={elem.guest?.address || ''}>
                          {elem?.address || ''}
                        </td>
                        <td hidden={role !== 'resident'} className={style.tdItems}>
                          {elem?.city || ''}
                        </td>
                        <td hidden={role !== 'guest'} className={style.tdItems} data-tip={elem.address || ''}>
                          {elem.address || ''}
                        </td>
                        <td hidden={role !== 'guest'} className={style.tdItems}>
                          {elem.arrivalDate?.slice(0, 10) || ''}
                        </td>
                        <td hidden={role !== 'guest'} className={style.tdItems}>
                          {elem.departureDate?.slice(0, 10) || ''}
                        </td>

                        <td hidden={['resident', 'user-settings'].includes(role)} className={style.tdItems}>
                          {elem?.passes?.filter((p: any) => !!p?.vehicle).length || 0}
                        </td>
                        <td hidden={role !== 'guest'} className={style.tdItems}>
                          <span className={style.wristbandValueBox}>
                            {' '}
                            {elem.wristbandValues || '0/0/0'}
                          </span>
                        </td>
                        <td hidden={role !== 'vendor'} className={style.tdItems}>
                          {elem.numberOfActivePasses || ''}
                        </td>
                        <td hidden={role !== 'vendor'} className={style.tdItems}>
                          {elem.numberOfComplaints || ''}
                        </td>

                      </tr>
                    )))
              }
            </tbody>
          </table>

        </div>
        <div className={style.pageBox}>
          <div>
            <GenericButton
              size="small"
              color="grey"
              title="Prev Page"
              clickHandler={() => pageChange(pageInfo.pageNum - 1)}
            />

          </div>
          <div>
            <label htmlFor="page-num-display">
              <input
                placeholder={(pageInfo.pageNum + 1).toString()}
                type="text"
                className={style.pageInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const val = Number.parseInt(e.target.value, 10) - 1;
                  if (Number.isNaN(val)) return;
                  e.target.value = (pageValidator(val) + 1).toString();
                  pageChange(val);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.target.value = '';
                }}
              />
              {`/ ${pageInfo.numPages}`}
            </label>
          </div>
          <div>
            <GenericButton
              size="small"
              color="grey"
              title="Next Page"
              clickHandler={() => pageChange(pageInfo.pageNum + 1)}
            />

          </div>
        </div>
      </div>

    </div>
  );
}
