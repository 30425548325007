import { useParams, useHistory } from 'react-router-dom';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useCallback, useState } from 'react';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';

const GET_USER = gql`
query GetUserInformation (
  $userId: String!
) {
  getUserInformation (
    userId: $userId
  ) {
    success
    error
    data {
      firstName
      lastName
      roles
      phoneNumber
      address
      email
      status
    }
  }
}
`;

const DELETE_USER = gql`
mutation DeleteUser (
  $userId: String!
) {
  deleteUser (
    userId: $userId
  ) {
    success
    error
  }
}
`;

const SEND_RESET_CODE = gql`
query SendResetCode (
  $email: String!
) {
  sendResetCode (
    email: $email
  ) {
    success
    error
  }
}
`;

const SET_USER_STATUS = gql`
mutation EditAccountStatuses(
  $status: String!,
  $userInfos: [String!]!,
) {
  editAccountStatuses(
    status: $status,
    userInfos: $userInfos
  ) {
    success
    error
  }
}
`;
type SET_USER_STATUS_VARS = {
  status: 'active' | 'deactivated',
  userInfos: string[],
}
type SET_USER_STATUS_RES = {
  editAccountStatuses: backendResponse<undefined>
}

type useUserSettingsRes = {
  userId: string,
  history: History | any,
  alert: string,
  alertColor: string,
  userData: any,
  userLoading: boolean,
  userError?: string
  deleteUser: () => void,
  resetPassword: () => void,
  editAccountStatus: (status: 'active' | 'deactivated') => Promise<unknown>,
}
export default function useUserSettings(): useUserSettingsRes {
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<string>('red');

  // get user info
  const {
    data: userData, loading: userLoading, error: userError, refetch: userRefetch,
  } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { userId },
  });

  const [doDeleteUser] = useMutation(DELETE_USER, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (d.deleteUser.success) {
        history.goBack();
      } else {
        setAlert(d.deleteUser.error);
        setAlertColor('red');
      }
    },
  });
  const deleteUserHanler = () => {
    if (window.confirm('Warning: This will permanently delete this user and all of their information. Usually we recommend deactivating a user instead. Are you sure you want to delete this user?')) {
      doDeleteUser({
        variables: { userId },
      });
    }
  };

  const [doSendResetCode] = useLazyQuery(SEND_RESET_CODE, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (d.sendResetCode.success) {
        setAlert('Password reset email sent successfully');
        setAlertColor('green');
      } else {
        setAlert(d.sendResetCode.error);
        setAlertColor('red');
      }
    },
  });
  const resetPasswordHanler = () => {
    if (!userData?.getUserInformation?.data?.email) {
      setAlert('Could not find user informaion to send reset code');
      setAlertColor('red');
      return;
    }
    if (window.confirm('Warning: This will send an email to this user\'s email address with a code and instructions to reset their password. Are you sure you want to continue?')) {
      doSendResetCode({
        variables: { email: userData.getUserInformation.data.email },
      });
    }
  };

  const [editAccountStatuses] = useMutation<
    SET_USER_STATUS_RES, SET_USER_STATUS_VARS
  >(SET_USER_STATUS);
  const editAccountStatus = (status: 'active' | 'deactivated') => editAccountStatuses({
    variables: {
      status,
      userInfos: [userId],
    },
  })
    .then(() => userRefetch());

  return {
    userId,
    history,
    alert,
    alertColor,
    userData: userData?.getUserInformation?.data || {},
    userLoading,
    userError: userError?.message || userData?.getUserInformation.error || undefined,
    deleteUser: deleteUserHanler,
    resetPassword: resetPasswordHanler,
    editAccountStatus,
  };
}
