import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import GenericButton from '../../../components/genericButton/genericButton';
import { PopUp } from '../../../components/popUp/popUp';
import style from './searchVehicle.module.css';
import useSearchVehicle, { IPassVehicleUser } from './useSearchVehicle';

export default function SearchVehicle(): ReactElement {
  const history = useHistory();
  const {
    passVehicleUsers,
    pageInfo, pageChange,
    pageValidator,
  } = useSearchVehicle();

  const getRedirectLink = (i: IPassVehicleUser): string => {
    if (!i.userId || !i.userType || i.userType.length === 0) {
      return '';
    }

    if (i.userType.length > 1) {
      return `/admin/report-center/user-settings/details/${i.userId}`;
    }

    return `/admin/report-center/${i.userType[0]}/details/${i.userId}/vehicles-passes`;
  };

  return (
    <PopUp
      close={history.goBack}
    >
      <div className={style.container}>
        <div className={style.tableBox}>
          <table className={style.table} width="100%" cellSpacing="0px">
            <thead className={`${style.tHead} textColorWhite superDarkGrey`}>
              <tr className={style.tableHeader}>
                <th>Make</th>
                <th>Vehicle Type</th>
                <th>Color</th>
                <th>License Plate</th>
                <th>Pass Type</th>
                <th>Pass Status</th>
                <th>Valid through</th>
                <th className={style.lastTh}> </th>
              </tr>
            </thead>
            <tbody>
              {
                passVehicleUsers.map((elem: IPassVehicleUser) => (
                  <tr key={elem.vehicleId}>

                    <td data-tip={elem.make || ''}>
                      {' '}
                      <ReactTooltip />
                      {elem.make || '-'}
                    </td>
                    <td data-tip={elem.type || ''}>
                      {' '}
                      <ReactTooltip />
                      {elem.type || '-'}
                    </td>
                    <td data-tip={elem.color || ''}>{elem.color || '-'}</td>
                    <td data-tip={elem.licensePlate || ''}>{elem.licensePlate || '-'}</td>
                    <td data-tip={elem.passType || ''}>{elem.passType || '-'}</td>
                    <td>
                      <span className={`${elem.passStatus === 'active' ? style.passStatusActive : ''}
                      ${elem.passStatus === 'expired' ? style.passStatusExpired : ''}
                      ${elem.passStatus === 'inactive' ? style.passStatusInactive : ''}
                      ${elem.passStatus === 'incomplete' ? style.passStatusInactive : ''}
                      textColorWhite`}
                      >
                        {elem.passStatus || '-'}
                      </span>
                    </td>
                    <td>{elem.passEndDate ? formatDate(new Date(elem.passEndDate)) : '-'}</td>
                    <td className={style.buttonTd}>
                      <button
                        className={style.viewInReport__button}
                        onClick={() => {
                          const link = getRedirectLink(elem);
                          if (link) {
                            history.push(link);
                          }
                        }}
                      >
                        View in Admin Center
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className={style.pageBox}>
          <div className={style.pageBtn}>
            <GenericButton
              size="medium"
              color="yellow"
              title="Prev Page"
              clickHandler={() => pageChange(pageInfo.pageNumber - 1)}
            />

          </div>
          <div>
            <label htmlFor="page-num-display">
              <input
                placeholder={(pageInfo.pageNumber).toString()}
                type="text"
                className={style.pageInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const val = Number.parseInt(e.target.value, 10);
                  if (Number.isNaN(val)) return;
                  e.target.value = pageValidator(val).toString();
                  pageChange(val);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.target.value = '';
                }}
              />
              {`/ ${pageInfo.numberPages}`}
            </label>
          </div>
          <div className={style.pageBtn}>
            <GenericButton
              size="medium"
              color="blue"
              title="Next Page"
              clickHandler={() => pageChange(pageInfo.pageNumber + 1)}
            />

          </div>
        </div>
      </div>
    </PopUp>
  );
}
