import React, { ReactElement } from 'react';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import GenericAlert from '../../../components/genericAlert/genericAlert';
import GenericButton from '../../../components/genericButton/genericButton';
import { PopUp } from '../../../components/popUp/popUp';
import useEditRental from './useEditRental';
import style from './editRental.module.css';

export default function EditRental() :ReactElement {
  const {
    rentalId,
    history,
    data,
    editData,
    loading,
    error,
    editSuccess,
    submitHandler,
  } = useEditRental();
  return (
    <PopUp
      title="Edit Rental"
      close={() => history.goBack()}
    >
      <form
        onSubmit={(e:React.FormEvent) => {
          e.preventDefault();
          submitHandler();
        }}
        className={style.form}
      >
        <GenericAlert
          title={error}
          hidden={!error}
          color="red"
        />
        <GenericAlert
          title="Changes saved successfully"
          hidden={!editSuccess}
          color="green"
        />
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="property-address-inpt"
              labelTitle="Property Address"
              inputValue={data.address || ''}
              changeHandler={(e) => {
                editData('address', e.target.value);
              }}
            />

          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputShort}>
            <InputField
              closedInput
              htmlFor="city-inpt"
              labelTitle="City"
              inputValue={data.city || ''}
              changeHandler={(e) => {
                editData('city', e.target.value);
              }}
            />

          </div>
          <div className={style.inputShort}>
            <InputField
              closedInput
              htmlFor="state-inpt"
              labelTitle="State"
              inputValue={data.state || ''}
              changeHandler={(e) => {
                editData('state', e.target.value);
              }}
            />

          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="zip-inpt"
              labelTitle="Zip Code"
              inputValue={data.zipCode || ''}
              changeHandler={(e) => {
                editData('zipCode', e.target.value);
              }}
            />

          </div>

        </div>
        <div className={style.rentalTitleBox}>
          <div className={style.rentalTitle}>
            Rental Management Information

          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="company-name-inpt"
              labelTitle="Company Name or Host Name if Self-Managed"
              inputValue={data.companyName || ''}
              changeHandler={(e) => {
                editData('companyName', e.target.value);
              }}
            />

          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <PhoneInputWrapper
              closedInput
              inputTitle="Primary Emergency Phone Number"
              value={data.primaryEmergencyPhone || ''}
              changeHandler={(val) => editData('primaryEmergencyPhone', val)}
            />

          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <PhoneInputWrapper
              closedInput
              inputTitle="Secondary Emergency Phone Number"
              value={data.secondaryEmergencyPhone || ''}
              changeHandler={(val) => editData('secondaryEmergencyPhone', val)}
            />

          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              htmlFor="email-inpt"
              labelTitle="Email Address"
              inputValue={data.email || ''}
              changeHandler={(e) => {
                editData('email', e.target.value);
              }}
            />

          </div>

        </div>
        <div className={style.btnBox}>
          <div className={style.btn}>
            <GenericButton
              size="large"
              title="Save Changes"
              type="submit"
              color="blue"
            />

          </div>

        </div>
      </form>
    </PopUp>
  );
}
