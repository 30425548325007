import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { PopUp } from '../../../components/popUp/popUp';
import alertPic from '../../../assets/checked.svg';
import style from './assignBarcode.module.css';
import { ReactComponent as CheckedIcon } from '../../../assets/success.svg';

export default function AssignBarcode() :ReactElement {
  const history :any = useHistory();

  return (
    <PopUp
      title="Assign Pass Bar Code (Optional)"
      close={() => history.goBack()}
    >
      <div className={style.box}>
        <div className={style.alertBox}>
          <div className={style.checkedIcon}>
            <CheckedIcon />
          </div>
          <p className={style.title}>Barcode Updated Successfully</p>
        </div>
        <div className={style.btnBox}>
          <button
            onClick={() => history.goBack()}
            className={style.confirm}
          >
            Confirm
          </button>
        </div>

      </div>
    </PopUp>
  );
}
