import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import alertPic from '../../../assets/alert.svg';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import { PopUp } from '../../../components/popUp/popUp';
import style from './deleteDoc.module.css';

export const DELETE_DOCUMENT = gql`
mutation deleteDocumentById (
  $documentId: String!
) {
  deleteDocumentById (
    documentId: $documentId
  ) {
    success
    error
  }
}
`;

export default function DeleteDocument(): ReactElement {
  const history = useHistory();
  const { docId } = useParams<{ docId: string }>();
  const [alert, setAlert] = useState<string>('');

  const [doDeleteDocument, { loading }] = useMutation(DELETE_DOCUMENT, {
    onError: (e) => {
      setAlert(e.message);
    },
    onCompleted: (d) => {
      if (d.deleteDocumentById.success) {
        history.goBack();
      } else {
        setAlert(d.deleteDocumentById.error);
      }
    },
  });
  return (
    <PopUp
      title="Delete Document"
      close={() => history.goBack()}
    >
      <div className={style.box}>
        <div className={style.alertBox}>
          <img src={alertPic} alt="" className={style.alertImg} />
          <h2>Warning:</h2>
          <h4 className={style.text}> Are you sure you want to delete the document ?</h4>
        </div>
        <GenericAlert
          color="red"
          title={alert}
          hidden={!alert}
        />
        <div className={style.btnBox}>
          <button onClick={() => history.goBack()} className={style.cancel}>
            Cancel
          </button>
          <button
            onClick={() => doDeleteDocument({ variables: { documentId: docId } })}
            className={style.delete}
          >
            Delete
          </button>
        </div>

      </div>
    </PopUp>
  );
}
