import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../genericButton/genericButton';
import style from './infoSection.module.css';

interface PropsType {
  title: string,
  editable?: string,
  data: { [key:string]: string },
  hidden?: boolean,
  dataMapper?: (obj:{[key:string]:string}) => (k:string, idx:number) => ReactElement,
}

export default function InfoStection(props: PropsType) :ReactElement {
  const {
    title,
    editable,
    data,
    hidden,
    dataMapper,
  } = props;

  if (hidden) return <div />;
  return (
    <div className={style.infoSectionBox}>
      <div className={`${style.sectionHeader} superDarkGrey`}>
        <h3 className={`${style.title} textColorWhite`}>{title}</h3>
        <div className={style.editBtnBox}>
          {editable
            ? (
              <Link to={editable}>
                <GenericButton
                  color="transparent"
                  title="Edit"
                />
              </Link>
            ) : null}
        </div>
      </div>
      <div className={style.infoBox}>

        <div className={style.listBox}>
          {dataMapper
            ? Object.keys(data).map(dataMapper(data))
            : null}
        </div>

      </div>
    </div>
  );
}
InfoStection.defaultProps = {
  editable: '',
  hidden: false,
  dataMapper: (obj:{[key:string]: string}) => (k:string, idx:number) => (
    <div key={k} className={style.listItemBox}>
      <ul className={style.listTitle}>{k}</ul>
      <li className={style.listContent}>{obj[k]}</li>
    </div>
  ),
};
