import React, { ReactElement, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GenericButton from '../genericButton/genericButton';
import style from './userProfileButton.module.css';
import store from '../../common_lib_front/utilities/store';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';

export const UserProfileButton = (): ReactElement => {
  const { open, containerId } = useContainerBlur('user-profile-btn', {
    defaultState: false,
    autoCloseInnerButton: true,
    autoOpen: true,
  });
  const [user, setUser] = useState<any>(store.getState().user);
  store.subscribe(() => setUser(store.getState().user));
  const history = useHistory();
  const smallScreen = useMediaQuery('(max-width: 767px)');

  return (
    <div id={containerId} className={style.box}>
      <GenericButton
        color={smallScreen ? 'transparent' : 'yellow'}
        icon={smallScreen ? 'user' : 'none'}
        shape={smallScreen ? 'rectangle' : 'circle'}
        title={smallScreen ? '' : user?.firstName?.slice(0, 1).toUpperCase() || ''}
      />
      {open
        ? (
          <div className={`${style.itemsBox} white`}>
            <h4 className={`${style.name} textColorMedium`}>{`${user?.firstName} ${store.getState().user?.lastName}`}</h4>
            <p className={`${style.email} textColorMedium`}>{user?.email}</p>
            <Link to="/admin/edit-profile/my-profile">
              <button className={`${style.btn} textColorMedium white`}>
                <div className={style.imgUser} />
                {' '}
                My Profile
              </button>
            </Link>
            <button
              onClick={() => {
                store.dispatch({ type: 'user/logout' });
                history.push('/admin/login');
              }}
              className={`${style.btn} textColorMedium white`}
            >
              <div className={style.imgSignout} />
              Sign Out
            </button>
            <Link to="/help">
              <p className={`${style.help} textColorMedium`}>Help</p>
            </Link>
          </div>
        )
        : null}
    </div>
  );
};

export default UserProfileButton;
