import { gql, useLazyQuery } from '@apollo/client';
import { useCallback, useContext } from 'react';
import JsPDF from 'jspdf';
import { CommunityContext } from '../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const exportPdf = async (src: string) => {
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  image.src = src;

  await new Promise((resolve) => { image.onload = resolve; });

  const canvas = document.createElement('canvas');
  const pdf = new JsPDF(image.width > image.height ? 'l' : 'p', 'px', [image.width, image.height]);

  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext('2d');
  ctx!.drawImage(image, 0, 0);

  const imgData = ctx!.getImageData(0, 0, image.width, image.height);

  pdf.addImage(imgData, 'PNG', 0, 0, image.width, image.height);

  return pdf;
};

const takeScreenshot = async (url: string) => {
  const pdf: any = await exportPdf(url);
  const blob = pdf.output('blob');
  const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
  nextWindow?.focus();
  nextWindow?.print();
};

const PARKING_PASS = gql(`
  query ParkingPass (
    $communityId: String
    $passId: String!
  ) {
    generateAdminPassImage (
      communityId: $communityId
      passId: $passId
    ) {
      data
      error
      success
    }
  }
  `);
type PARKING_PASS_VARS = {
  communityId: string;
  passId: string;
}
type PARKING_PASS_RES = {
  generateAdminPassImage: backendResponse<string>;
}

type useParkingPassRes = {
  loading: boolean,
  error?: string,
  data?: string,
  doGetParkingPass: (passId: string) => void,
}

type useParkingPassOptions = {
  autoDownload?: boolean;
  autoNavToPass?: boolean;
}

export default function useParkingPass(options?: useParkingPassOptions): useParkingPassRes {
  const { communityId } = useContext(CommunityContext);

  const [doGetParkingPass, {
    loading,
    data,
    error,
  }] = useLazyQuery<
    PARKING_PASS_RES, PARKING_PASS_VARS
  >(PARKING_PASS, {
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      const url = d.generateAdminPassImage.data;
      if (!url) return;
      // url;
      if (options?.autoDownload) {
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Parking Pass';
        a.click();
        // takeScreenshot(url);
      }
      if (options?.autoNavToPass) {
        takeScreenshot(url);
      }
    },
  });

  return {
    loading,
    error: error?.message,
    data: data?.generateAdminPassImage.data || undefined,
    doGetParkingPass: useCallback((passId: string) => doGetParkingPass({
      variables: {
        passId,
        communityId,
      },
    }), [communityId, doGetParkingPass]),
  };
}
