import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ProfileImg } from '../../assets/profileSmall.svg';
import { ReactComponent as LockImg } from '../../assets/lockSmall.svg';
import style from './editProfileTemplate.module.css';
import { Footer } from '../footer/footer';
// import SchemeDecider from '../../../common_lib_front/utilities/schemeDecider';

// const colorStyle :any = {};

interface EditProfileGuestProps {
  children?: ReactElement | ReactElement[];
  active?: string;
}

export default function EditProfileTemplate(props:EditProfileGuestProps) :ReactElement {
  const { children, active } = props;

  // TODO: remove this it is only for example
  const tmpStyles :any = {
    profile: {},
    password: {},
  };

  switch (active) {
    case 'profile':
      // TODO: add styles for active 'my profile' tab
      tmpStyles.profile.backgroundColor = 'var(--thirdColor)';
      break;
    case 'password':
      // TODO: add styles for active 'reset password' tab
      tmpStyles.password.backgroundColor = 'var(--thirdColor)';
      break;
    default:
      // TODO: add default styles
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>

        <div className={`${style.subNav} darkGrey `}>
          <div className={style.btnBox}>
            <Link to="/admin/edit-profile/my-profile">
              <button style={tmpStyles.profile} className={`${style.btn} textColorWhite  darkGrey `}>
                <div>
                  {' '}
                  <ProfileImg />
                </div>
                <p className={style.text}>My Profile</p>
              </button>
            </Link>
            <Link to="/admin/edit-profile/reset-password">
              <button style={tmpStyles.password} className={`${style.btn}  textColorWhite darkGrey `}>
                <div>
                  {' '}
                  <LockImg />
                </div>
                <p className={style.text}>Reset Password</p>
              </button>
            </Link>
          </div>

        </div>
        <div className={style.infoBox}>
          {children}
        </div>
      </div>
      <Footer />
    </div>

  );
}
EditProfileTemplate.defaultProps = {
  children: undefined,
  active: '',
};
