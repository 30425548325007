import React, { ReactElement } from 'react';
import style from './genericAlert.module.css';

export const GenericAlert = (props:{
	color?: string,
  children?: string,
  title?: string,
  hidden?: boolean,
}) :ReactElement => {
  const {
    color, children, hidden, title,
  } = props;

  const containerStyle :any = {};

  switch (color) {
    case 'red':
      containerStyle.backgroundColor = 'var(--alertColor)';
      break;
    case 'green':
      containerStyle.backgroundColor = 'var(--sucessColor)';
      break;
    default:
      break;
  }

  if (hidden) {
    return <></>;
  }
  return (
    <div style={containerStyle} className={`${style.alertLine} textColorWhite `}>
      <h5 className={style.title}>{children || title}</h5>
    </div>
  );
};
GenericAlert.defaultProps = {
  color: 'none',
  children: '',
  title: '',
  hidden: false,
};

export default GenericAlert;
