import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const EDIT_PASS = gql`
mutation EditVehiclePass (
  $passId: String!,
  $licensePlate: String, 
  $color: String, 
  $vehicleType: String, 
  $make: String, 
  $endDate: DateTime, 
  $startDate: DateTime, 
  $passStatus: String, 
  $passType: String, 
  $rentalAddress: String,
) {
  editPassVehicle(
    licensePlate: $licensePlate, 
    color: $color, 
    vehicleType: $vehicleType, 
    make: $make, 
    endDate: $endDate, 
    startDate: $startDate, 
    passStatus: $passStatus, 
    passType: $passType, 
    passId: $passId,
    rentalAddress: $rentalAddress,
  ) {
    success
    error
  }
}
`;

type EditVehiclePassType = {
  history: History | any,
  role: string,
  passId: string,
  vehicleInfo: any,
  editVehicleInfo: (key:string, value:string) => void,
  editLoading?: boolean,
  editError?: string,
  editPass: () => void,
  successMessage?: string;
}
export default function useEditVehiclePass() :EditVehiclePassType {
  const history = useHistory<any>();
  const { passInfo, role }: any = useLocation().state;
  const { passId } = useParams<{ passId: string }>();
  const [vehicleInfo, setVehicleInfo] = useState<any>(passInfo);
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const editVehicleInfo = (key:string, value:string) => {
    const tmp = { ...vehicleInfo };
    tmp[key] = value;
    if (tmp.vehicleType !== null && tmp.type !== tmp.vehicleType) {
      tmp.type = tmp.vehicleType;
      setVehicleInfo(tmp);
    }
    setVehicleInfo(tmp);
  };

  const [doEditPass, {
    data: editData, loading: editLoading, error: editError,
  }] = useMutation(EDIT_PASS, {
    onCompleted: (d) => {
      setSuccessMessage(d?.editPassVehicle?.success ? 'Data saved successfully' : undefined);
      window.history.replaceState({
        ...window.history.state,
        state: {
          passInfo: vehicleInfo,
          role,
        },
      }, '');
    },
  });

  return {
    history,
    role,
    passId,
    vehicleInfo,
    editVehicleInfo,
    editLoading,
    editError: editError || editData?.editPassVehicle?.error || undefined,
    editPass: () => {
      doEditPass({
        variables: {
          passId,
          ...vehicleInfo,
        },
      });
    },
    successMessage,
  };
}
