import { useHistory, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import UserInfo, { newUserInfo } from '../../../common_lib_front/types/userInfo';

const GET_USER = gql`
query GetUserInformation (
  $userId: String!
) {
  getUserInformation (
    userId: $userId
  ) {
    success
    error
    data {
      userId
      firstName
      lastName
      roles
      phoneNumber
      email
    }
  }
}
`;

const EDIT_USER = gql`
mutation EditUserInfo(
  $userId: String!,
  $phoneNumber: String, 
  $lastName: String, 
  $firstName: String,
  $email: String,
) {
  editUserInfo(
    userId: $userId,
    phoneNumber: $phoneNumber, 
    lastName: $lastName, 
    firstName: $firstName
    email: $email
  ) {
    success
    error
  }
}
`;

export default function useEditUser() :{
  userId: string,
  editChangeData: (key:string, value:string) => void,
  history: History | any,
  editSuccess: boolean,
  editLoading: boolean,
  editError: string,
  userData: UserInfo,
  userLoading: boolean,
  userError: string,
  doEditUser: () => void;
  } {
  const history:any = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const [userData, setUserData] = useState<UserInfo>(newUserInfo({}));
  const editChangeData = (key: string, value:string) => {
    const tmp: any = { ...userData };
    tmp[key] = value;
    setUserData(tmp);
  };

  const {
    data, loading: userLoading, error: userError, refetch: userRefetch,
  } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    onCompleted: (d: any) => {
      if (d.getUserInformation?.success) {
        setUserData(newUserInfo(d.getUserInformation.data || {}));
      }
    },
  });

  const [doEditUser, {
    data: editData, loading: editLoading, error: editError,
  }] = useMutation(EDIT_USER, {
    onCompleted: () => {
      userRefetch();
    },
  });

  return {
    userId,
    editChangeData,
    history,
    editSuccess: editData?.editUserInfo?.success || false,
    editLoading,
    editError: editError?.message || editData?.editUserInfo?.error || '',
    userData,
    userLoading,
    userError: userError?.message || data?.editUserInfo?.error || '',
    doEditUser: () => doEditUser({
      variables: {
        ...userData,
        userId,
      },
    }),
  };
}
