import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ProfileImg } from '../../../assets/profile.svg';
import GenericButton from '../../../components/genericButton/genericButton';
import style from './userSettings.module.css';
import pencilImg from '../../../assets/pencil.svg';
import profileImg from '../../../assets/profileUser.svg';
import lockImg from '../../../assets/lockUser.svg';
import reactiveImg from '../../../assets/ReactiveUser.svg';
import deleteImg from '../../../assets/deleteUser.svg';
import useUserSettings from './useUserSettings';
import GenericAlert from '../../../components/genericAlert/genericAlert';

export default function UserSettings(): ReactElement {
  const {
    userId,
    history,
    alert,
    alertColor,
    userData,
    userLoading,
    userError,
    deleteUser,
    resetPassword,
    editAccountStatus,
  } = useUserSettings();

  if (userLoading) {
    return (
      <div>
        Loading User Info ...
      </div>
    );
  }
  // if (userError) {
  //   return (
  //     <div>
  //       {userError}
  //     </div>
  //   );
  // }
  return (
    <div className={`${style.box} white`}>
      <button
        className={style.exitBtn}
        onClick={() => history.goBack()}
      >
        &#160;
      </button>

      <div className={`${style.subNav} mediumGrey`}>
        <div className={style.btnBox}>
          <button className={`${style.btn} thirdColor`}>
            <img src={profileImg} alt="" className={style.icon} />
            {' '}
            Profile
          </button>
          {/* <button
            className={`${style.btn} mediumGrey`}
            onClick={resetPassword}
          >
            {' '}
            <img src={lockImg} alt="" className={style.icon} />
            {' '}
            Reset Password

          </button> */}
          <button
            className={`${style.btn} mediumGrey`}
            onClick={() => {
              // const btn = e.target as HTMLButtonElement;
              editAccountStatus(
                userData.status === 'active'
                  ? 'deactivated'
                  : 'active',
              );
            }}
          >
            {' '}
            <img src={reactiveImg} alt="" className={style.icon} />
            {' '}
            {userData.status === 'active' ? 'Deactivate' : 'Activate'}
            {' '}
            Account
          </button>
          <button
            className={`${style.btn} mediumGrey`}
            onClick={deleteUser}
          >
            {' '}
            <img src={deleteImg} alt="" className={style.icon} />
            {' '}
            Delete User
          </button>
        </div>
      </div>
      <div className={style.infoBox}>
        <div className={style.alert}>
          <GenericAlert
            title={alert}
            color={alertColor}
            hidden={!alert}
          />

        </div>
        <div className={style.userBox}>
          <div className={style.userImg}><ProfileImg /></div>
          <div className={style.userNameBox}>
            <h1 className={`${style.userName} textColorSuperDark`}>
              {`${userData.firstName} ${userData.lastName}`}
            </h1>
            <p className={`${style.userCompany} textColorMedium`}>
              {userData.companyName || ''}
            </p>
          </div>
        </div>
        <div className={style.personalInfoBox}>
          <div className={style.firstLine}>
            <Link to={`/admin/popup/edit-user/${userId}`}>
              <button className={`${style.editBtn} mainColor`}>
                {' '}
                <img src={pencilImg} alt="" />
              </button>
            </Link>
            <h3 className={style.personalTitle}>Personal Info</h3>

          </div>
          <form className={style.form}>
            <ul>
              <li className={`${style.listTitle} textColorSuperDark`}>First Name:</li>
              <li className={`${style.listItem} `}>
                {userData.firstName || ''}
              </li>
            </ul>
            <ul>
              <li className={`${style.listTitle} textColorSuperDark`}>Last Name:</li>
              <li className={`${style.listItem} `}>
                {userData.lastName || ''}
              </li>
            </ul>
            <ul>
              <li className={`${style.listTitle} textColorSuperDark`}>Email Address:</li>
              <li className={`${style.listItem} `}>
                {userData.email || ''}
              </li>
            </ul>
            <ul>
              <li className={`${style.listTitle} textColorSuperDark`}>Phone Number:</li>
              <li className={`${style.listItem} `}>
                {userData.phoneNumber || ''}
              </li>
            </ul>
            <ul>
              <li className={`${style.listTitle} textColorSuperDark`}>Status:</li>
              <li className={`${style.listItem} `}>
                {userData.status || ''}
              </li>
            </ul>
            <ul>
              <li className={`${style.listTitle} textColorSuperDark`}>Role:</li>
              <li className={`${style.listItem} `}>
                <ul className={style.rolesBox}>
                  {userData.roles?.map((role: string) => (
                    <li className={style.roles}>
                      {
                        ['guest', 'vendor', 'resident'].includes(role)
                          ? (
                            <Link to={`/admin/report-center/${role}/details/${userId}`}>
                              {role}
                            </Link>
                          ) : role
                      }
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </form>
          <Link to={`/admin/report-center/${'guest'}/details/${userId}`}>
            <div className={style.btnView}>
              <GenericButton
                color="blue"
                size="medium"
                title="View More"
              />

            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
