import React, { useMemo, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { passStatusMapTitles } from '../../common_lib_front/types/passInfo';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import styles from './passStatusSetter.module.css';

const EDIT_PASS_STATUS = gql`
mutation editPassStatus(
  $passId: String!
  $status: String!
) {
  editPassStatus(
    passId: $passId,
    status: $status
  ) {
    error
    success
  }
}
`;
type EDIT_PASS_STATUS_VARS = {
  passId: string,
  status: string,
}
type EDIT_PASS_STATUS_RES = {
  editPassStatus: backendResponse<undefined>
}

const passStatusMapColor: Record<
  keyof typeof passStatusMapTitles,
  'red' | 'green' | 'grey' | 'darkGrey' | 'lightGreen' | 'orange'
> = {
  active: 'green',
  inactive: 'lightGreen',
  incomplete: 'grey',
  'incomplete-rental-car': 'darkGrey',
  expired: 'red',
  refunded: 'orange',
  temporary: 'green',
};

type PassStatusSetterProps = {
  status: string,
  passId: string,
  hidden?: boolean,
  disabled?: boolean,
}

export default function PassStatusSetter(props: PassStatusSetterProps): React.ReactElement {
  const {
    status, passId, hidden, disabled,
  } = props;
  const selectRef = useRef<HTMLSelectElement | null>(null);

  const [doSetStatus] = useMutation<EDIT_PASS_STATUS_RES, EDIT_PASS_STATUS_VARS>(EDIT_PASS_STATUS, {
    onCompleted: (res) => {
      if (!res.editPassStatus.success && selectRef.current) {
        selectRef.current.value = status;
      }
      if (res.editPassStatus.success && selectRef.current) {
        selectRef.current.dispatchEvent(new CustomEvent('UpdateRCSearch', {
          bubbles: true,
        }));
      }
    },
    onError: () => {
      if (selectRef.current) {
        selectRef.current.value = status;
      }
    },
  });

  const statusArray = useMemo(
    () => Object.entries(passStatusMapTitles),
    [passStatusMapTitles],
  );

  if (hidden) return <></>;
  return (
    <select
      disabled={disabled}
      ref={selectRef}
      className={styles.passStatusSetter}
      defaultValue={status}
      data-color={passStatusMapColor[selectRef?.current?.value || status] || 'grey'}
      onChange={(e) => {
        doSetStatus({
          variables: {
            passId,
            status: e.target.value,
          },
        });
      }}
    >
      <option
        hidden
        value={status}
      >
        {passStatusMapTitles[status] || status}
      </option>
      {statusArray.map(([key, title]) => (
        <option
          key={key}
          value={key}
        >
          {title}
        </option>
      ))}
    </select>
  );
}
PassStatusSetter.defaultProps = {
  hidden: false,
  disabled: false,
};
