import { gql, useQuery } from '@apollo/client';
import {
  useCallback, useMemo, useState,
} from 'react';
import { backendPaginatedResponse } from '../../common_lib_front/types/backendResponse';

const GET_PASSES = gql`
query GetPasses (
  $userId: String!
  $sortOrder: Float
  $sortField: String
  $pageNumber: Float!
  $numberPerPage: Float!
) {
  getPassesVehiclesInfo (
    userId: $userId
    sortOrder: $sortOrder
    sortField: $sortField
    pageNumber: $pageNumber
    numberPerPage: $numberPerPage
  ) {
    success
    error
    numberPages
    data {
      vehicleId
      make
      type
      color
      passId
      passType
      vehicleType
      licensePlate
      fleetNumber
      passStatus
      startDate
      endDate
      licensePlateState
      primaryDriverName
      licensePrimaryDriver
      barCode
      paid
      number
      rentalAddress
      url
      registrationId
      destination
    }
  }
}
`;

type GET_PASSES_VARS = {
  userId: string,
  sortOrder?: number,
  sortField?: string,
  pageNumber: number,
  numberPerPage: number,
}

type GET_PASSES_RES = {
  getPassesVehiclesInfo: backendPaginatedResponse<{
    vehicleId: string,
    make: string,
    type: string,
    color: string,
    passId: string,
    passType: string,
    vehicleType: string,
    licensePlate: string,
    fleetNumber: string,
    passStatus: string,
    startDate: string,
    endDate: string,
    licensePlateState: string,
    primaryDriverName: string,
    licensePrimaryDriver: string,
    barCode: string,
    paid: boolean,
    number: string,
    rentalAddress: string,
    url: string,
    registrationId: string,
    destination: string,
  }[]>
}

type useGetPassesRes = {
  data?: GET_PASSES_RES['getPassesVehiclesInfo']['data'],
  loading: boolean,
  error?: string,
  pageNum: number,
  numberPages: number,
  setPage: (val: number | ((prev: number) => number)) => void,
}
export default function useGetPasses(userId: string): useGetPassesRes {
  const [pageNum, setPageNum] = useState<number>(0);
  const numberPerPage = 25;
  const {
    data, loading, error, refetch,
  } = useQuery<
    GET_PASSES_RES, GET_PASSES_VARS
  >(GET_PASSES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      userId,
      pageNumber: pageNum,
      numberPerPage,
    },
  });

  // ===== function to safely set page =====
  const setPage = useCallback((val: number | ((prev: number) => number)): void => {
    let res = typeof val === 'number' ? val : val(pageNum);
    if (!data) return;
    // number validation
    if (res < 0) {
      res = 0;
    }
    const maxPage = data.getPassesVehiclesInfo.numberPages - 1;
    if (res > maxPage) {
      res = maxPage;
    }
    // set page and refetch
    setPageNum(res);
    refetch({
      userId,
      numberPerPage,
      pageNumber: res,
    });
  }, [pageNum, data, userId]);
  // ===== function to safely set page =====

  const res = useMemo(() => ({
    data: data?.getPassesVehiclesInfo?.data,
    loading,
    error: error?.message || data?.getPassesVehiclesInfo?.error,
    pageNum,
    setPage,
    numberPages: data?.getPassesVehiclesInfo?.numberPages || 0,
  }), [data, loading, error, pageNum, setPage]);

  return res;
}
