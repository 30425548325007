import React, { ReactElement, useState } from 'react';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import GenericButton from '../genericButton/genericButton';
import style from './selectMany.module.css';

export default function SelectMany(props: {
  id: string,
  title: string,
  options: Array<{name: string, title: string}>
  changeHandler: (name: string, val: boolean) => void,
  values: Array<string>,
}) :ReactElement {
  const {
    id, title, options, changeHandler, values,
  } = props;

  // const [open, setOpen] = useState<boolean>(false);
  const {
    open, setOpen, containerId,
  } = useContainerBlur(`${id}-container`);

  let buttonSelect;
  if (open) {
    buttonSelect = style.buttonSelectUp;
  }
  if (!open) {
    buttonSelect = style.buttonSelectDown;
  }

  return (
    <div id={containerId} className={style.selectManyBox}>
      <div className={buttonSelect}>
        <GenericButton
          title={title}
          clickHandler={() => setOpen(!open)}
        />

      </div>
      {
        open
          ? (
            <div className={style.dropDownBox}>
              {options.map((opt) => (
                <label htmlFor={`${id}-${opt.name}-inpt`} key={opt.name} className={style.label}>
                  <input
                    id={`${id}-${opt.name}-inpt`}
                    type="checkbox"
                    checked={values.includes(opt.name)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      changeHandler(opt.name, e.target.checked);
                    }}
                    className={style.checkbox}
                  />
                  {opt.title}
                </label>
              ))}
            </div>
          ) : null
      }
    </div>
  );
}
