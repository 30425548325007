import queryString from 'querystring';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IFilter } from '../../security/vehicleSearch/vehicleSearch';

export interface IPassVehicleUser {
  userId: string;
  userType: string[];
  passId: string;
  make: string;
  type: string;
  fleetNumber: string;
  licensePlate: string;
  color: string;
  vehicleId: string;
  passType: string;
  passStatus: string;
  passEndDate: string;
}

const FIND_PASS_VEHICLE_USER = gql`
query findPassVehicleUser (
    $licensePlate: String,
    $color: String,
    $type: String,
    $make: String,
    $vehicleModel: String,
    $fleetNumber: String,
    $numberPerPage: Float,
    $pageNumber: Float,
) {
  getPassesByVehiclesDescriptions (
    licensePlate: $licensePlate,
    color: $color,
    type: $type,
    make: $make,
    vehicleModel: $vehicleModel,
    fleetNumber: $fleetNumber,
    numberPerPage: $numberPerPage,
    pageNumber: $pageNumber,
  ) {
    success
    error
    numberPages
    data {
      userId
      userType
      passId
      make
      type
      fleetNumber
      licensePlate
      color
      vehicleId
      passType
      passStatus
      passEndDate
    }
  } 
}
`;

interface IPageInfo {
  numberPerPage: number;
  pageNumber: number;
  numberPages: number;
}

export default function useSearchVehicle(): {
  passVehicleUsers: IPassVehicleUser[];
  errorMessage?: string;
  pageInfo: IPageInfo;
  pageChange: (num: number) => void;
  pageValidator: (num: number) => number,
  } {
  const history = useHistory();
  const [filters, setFilters] = useState<IFilter>({});
  const { search: urlSearch } = useLocation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    numberPerPage: 7, pageNumber: 1, numberPages: 1,
  });

  // page info helper to change page number
  const pageValidator = (num: number): number => {
    if (Number.isNaN(num) || num < 1) return 1;
    if (num + 1 > pageInfo.numberPages) return pageInfo.numberPages;
    return num;
  };
  const pageChange = (num: number) => {
    if (Number.isNaN(num)) return;
    const res = { ...pageInfo };
    res.pageNumber = pageValidator(num);
    setPageInfo(res);
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...filters, ...res }),
    });
  };

  useEffect(() => {
    if (!urlSearch) {
      return;
    }
    const parsedSearch = queryString.parse(urlSearch.replace(/\?/, ''));
    setFilters(parsedSearch as IFilter);
    setPageInfo((prev) => ({
      ...prev,
      pageNumber: +parsedSearch.pageNumber || prev.pageNumber,
      numberPages: +parsedSearch.numberPages || prev.numberPages,
    }));
  }, []);

  const { data } = useQuery(FIND_PASS_VEHICLE_USER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      licensePlate: filters.licensePlate,
      color: filters.color,
      type: filters.type,
      make: filters.make,
      numberPerPage: pageInfo.numberPerPage,
      pageNumber: pageInfo.pageNumber,
    },
    onError: () => {
      setErrorMessage('Something went wrong');
    },
    onCompleted: (d) => {
      if (!d.getPassesByVehiclesDescriptions?.success) {
        setErrorMessage(d.getPassesByVehiclesDescriptions.error);
      } else {
        const numberPages = d.getPassesByVehiclesDescriptions?.numberPages;
        setPageInfo((prev) => ({
          ...prev,
          numberPages,
        }));
      }
    },
  });

  return {
    passVehicleUsers: data?.getPassesByVehiclesDescriptions?.data || [],
    errorMessage,
    pageInfo,
    pageChange,
    pageValidator,
  };
}
