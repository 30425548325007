import { gql, useLazyQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useState } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import { QueryParamsType as HostQueryParamsType } from '../../pages/reportCenter/hostSearch/useHostSearch';
import style from './rcDownloader.module.css';

const SEARCH_USERS = gql`
query SearchHosts (
  $pageSize: Float!, 
  $pageNumber: Float!, 
  $sortOrder: Float, 
  $sortField: String, 
  $toDate: DateTime, 
  $fromDate: DateTime, 
  $passStatus: String, 
  $firstName: String, 
  $email: String, 
  $lastName: String, 
  $phoneNumber: String, 
  $city: String, 
  $state: String, 
  $ownerPin: String, 
  $approvedBy: String, 
  $licenseStatus: String
) {
  searchHosts(
    pageSize: $pageSize
    pageNumber: $pageNumber
    sortOrder: $sortOrder
    sortField: $sortField
    toDate: $toDate
    fromDate: $fromDate
    passStatus: $passStatus
    firstName: $firstName
    email: $email
    lastName: $lastName
    phoneNumber: $phoneNumber
    city: $city
    state: $state
    ownerPin: $ownerPin
    approvedBy: $approvedBy
    licenseStatus: $licenseStatus
  ) {
    success
    error
    data {
      userId
    }
  }
}
`;
type SEARCH_USERS_VARS = {
  pageSize: number,
  pageNumber: number,
  sortOrder?: number,
  sortField?: string,
  toDate?: string,
  fromDate?: string,
  passStatus?: string,
  firstName?: string,
  email?: string,
  lastName?: string,
  phoneNumber?: string,
  city?: string,
  state?: string,
  ownerPin?: string,
  approvedBy?: string,
  licenseStatus?: string
}
type SEARCH_USERS_RES = {
  searchHosts: backendResponse<Array<{
    userId: string,
  }>>
}

const GET_CSV = gql`
query GetCsv(
  $userIds: [String!]!
) {
  exportRentals(
    userIds: $userIds
  ) {
    success
    error
    data
  }
} 
`;
type GET_CSV_VARS = {
  userIds: Array<string>,
}
type GET_CSV_RES = {
  exportRentals: backendResponse<string>
}

type RcDownloaderProps = {
  vars: HostQueryParamsType
}
export default function RcDownloader(props: RcDownloaderProps): ReactElement {
  const {
    vars,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const doDownload = useCallback((filename: string, dataValue: string) => {
    const useURL = window.webkitURL || window.URL;
    const a = document.createElement('a');
    a.download = `${filename}.csv`;
    const bb = new Blob([dataValue], { type: 'text/csv' });
    a.href = useURL.createObjectURL(bb);
    a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
    a.click();
  }, []);

  const [fetchCsv] = useLazyQuery<GET_CSV_RES, GET_CSV_VARS>(GET_CSV, {
    onError: () => {
      setIsLoading(false);
    },
    onCompleted: (data) => {
      if (data.exportRentals.data) {
        doDownload('hostInfo', data.exportRentals.data);
      }
      setIsLoading(false);
    },
  });

  const [fetchIds] = useLazyQuery<
    SEARCH_USERS_RES, SEARCH_USERS_VARS
  >(SEARCH_USERS, {
    fetchPolicy: 'network-only',
    onError: () => {
      setIsLoading(false);
    },
    onCompleted: (data) => {
      if (data.searchHosts.data && data.searchHosts.data.length > 0) {
        fetchCsv({
          variables: {
            userIds: data.searchHosts.data.map((host) => host.userId),
          },
        });
      }
    },
  });

  return (
    <GenericButton
      title="Export"
      color="yellow"
      outline="no-border"
      size="small"
      disabled={isLoading}
      clickHandler={() => {
        setIsLoading(true);
        fetchIds({
          variables: {
            ...vars,
            pageSize: 0,
            pageNumber: 0,
          },
        });
      }}
    />
  );
}
