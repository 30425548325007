import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PopUp } from '../../../components/popUp/popUp';
import alertPic from '../../../assets/alert.svg';
import style from './viewPass.module.css';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import useGetPassImage from '../../../common_lib_front/hooks/useGetPassImage';

export default function ViewPass() :ReactElement {
  const history: any = useHistory();
  const { passInfo }: any = history.location.state;

  const { data: imgData } = useGetPassImage(passInfo.passId, !!passInfo?.url);
  const passUrl = passInfo?.url || imgData?.url || '';

  useEffect(() => {
    console.log(passInfo);
  }, []);

  return (
    <PopUp
      title={passInfo.passStatus === 'incomplete' ? '' : 'View/Download'}
      close={() => history.goBack()}
    >
      {passInfo.passStatus === 'incomplete' ? (

        <div className={style.box}>
          <div className={style.alertBox}>
            <img src={alertPic} alt="" className={style.alertImg} />
            <h2>Warning:</h2>
            <h4 className={style.text}>
              {' '}
              This pass cannot be viewed, printed, or downloaded until it has been
              completed and any fees have been paid.
            </h4>
          </div>
          <div className={style.btnBoxIncomplete}>
            <button onClick={() => history.goBack()} className={style.confirm}>
              Confirm
            </button>
          </div>

        </div>
      ) : (
        <div
          className={style.wrapper}
        >
          <img
            className={style.image}
            src={passUrl === imgData?.url ? `${passUrl}?${Date.now()}` : passUrl}
            alt="Pass"
          />
          <span className={style.spacer} />
          <div className={style.btnBox}>
            {/* <button>Download</button> */}
            <a
              className={style.btnBox}
              href={passUrl}
            >
              <GenericButton
                title="Download"
                color="blue"
                size="medium"
              />
            </a>

          </div>
        </div>
      )}
    </PopUp>
  );
}
