import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { QueryString } from '../../../common_lib_front/utilities/QueryString';
import style from './reportCenterSearch.module.css';
import { backendPaginatedResponse } from '../../../common_lib_front/types/backendResponse';

export type QueryParamsType = {
  'role'?: string,
  'firstName'?: string,
  'lastName'?: string,
  'email'?: string,
  'arrivalDate'?: string,
  'departureDate'?: string,
  'companyName'?: string,
  'phoneNumber'?: string,
  'rentalAddress'?: string,
  'streetAddr'?: string,
  'city'?: string,
  'barCode'?: string,
  'employeeFirstName'?: string,
  'employeeLastName'?: string,
  'passNumber'?: string,
  'propertyManager'?: string,
  [key: string]: string | undefined,
}

const SEARCH_USERS = gql`
query SearchByFilters(
  $pageNumber: Float!,
  $numberPerPage: Float!, 
  $communityIdentifier: String,
  $role: String, 
  $firstName: String, 
  $email: String, 
  $sortField: String, 
  $sortOrder: Float, 
  $city: String, 
  $streetAddr: String, 
  $companyName: String, 
  $phoneNumber: String, 
  $lastName: String, 
  $rentalAddress: String, 
  $departureDate: DateTime, 
  $arrivalDate: DateTime, 
  $barCode: String,
  $employeeFirstName: String,
  $employeeLastName: String,
) {
  searchByFilters(
    pageNumber: $pageNumber, 
    numberPerPage: $numberPerPage, 
    communityIdentifier: $communityIdentifier
    role: $role, 
    firstName: $firstName, 
    email: $email, 
    sortField: $sortField, 
    sortOrder: $sortOrder, 
    city: $city, 
    streetAddr: $streetAddr, 
    companyName: $companyName, 
    phoneNumber: $phoneNumber, 
    lastName: $lastName, 
    rentalAddress: $rentalAddress, 
    departureDate: $departureDate, 
    arrivalDate: $arrivalDate, 
    barCode: $barCode,
    employeeFirstName: $employeeFirstName,
    employeeLastName: $employeeLastName,
  ) {
    success
    error
    numberPages
    data {
      userId
      firstName
      lastName
      email
      phoneNumber
      address
      city
      roles
      companyName
      status
    }
  }
}
`;
type SEARCH_USERS_VARS = {
  pageNumber: number,
  numberPerPage: number,
  communityIdentifier?: string,
  role?: string,
  firstName?: string,
  email?: string,
  sortField?: string,
  sortOrder?: number,
  city?: string,
  streetAddr?: string,
  companyName?: string,
  phoneNumber?: string,
  lastName?: string,
  rentalAddress?: string,
  departureDate?: string,
  arrivalDate?: string,
}
export type searchedUser = {
  userId: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  phoneNumber?: string,
  address?: string,
  city?: string,
  roles?: string[],
  companyName?: string,
  lastLogin?: string, // not implemented will always be undefined
  status?: 'active' | 'deactivated' | 'unactivated',
}
type SEARCH_USERS_RES = {
  searchByFilters: backendPaginatedResponse<Array<searchedUser>>
}

// export type searchedReservations = {
//   arrivalDate: Date,
//   departureDate: Date,
//   address: string,
//   guest: UserType,
//   invitedBy: string,
//   numberOfVehicles: number,
//   passes: [PassType],
// }
// type UserType = {
//   userId: string;
//   firstName: string;
//   lastName: string;
//   email: string
//   phoneNumber: string;
//   address: string;
//   country: string;
//   city: string;
//   state: string;
//   invitedBy: string;
// }
// type VehicleType = {
//   make: string;
//   color: string;
//   vehicleModel: string;
//   licensePlate: string;
// }
// type PassType = {
//   passId: string;
//   number: number;
//   startDate: Date;
//   endDate: Date;
//   vehicle: VehicleType;
// }

interface ISelected {
  [key: string]: boolean
}

export default function useReportCenterSearch(): {
  queryParams: QueryParamsType,
  // eslint-disable-next-line
  qpChangeHandler: (key: keyof QueryParamsType) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string) => void,
  searchRes: Array<searchedUser>,
  searchLoading: boolean,
  searchError: string,
  marginSet: string,
  marginSetUserSetting: string,
  role: 'guest' | 'vendor' | 'resident' | 'user-settings',
  history: History | any,
  pageInfo: { pageNum: number, numPerPage: number, numPages: number },
  pageChange: (num: number) => void,
  pageValidator: (num: number) => number,
  numPerPageChange: (num: number) => void,
  // Selected object takes ids as properties and boolean to determine which ids are selected
  selected: ISelected,
  setSelected: (object: any) => void,
  getRole: (userRoles: string[], queryRole: string | undefined) => string,
} { // eslint-disable-line indent
  const history = useHistory();
  const { role } = useParams<{ role: 'guest' | 'vendor' | 'resident' | 'user-settings' }>();
  const [selected, setSelected] = useState<ISelected>({});
  const getRole = (userRoles: string[], queryRole: string | undefined) => {
    if (queryRole === 'All' || queryRole === undefined) {
      return userRoles[0];
    }
    return queryRole;
  };

  const [queryParams, setQueryParams] = useState<QueryParamsType>({});
  // handler to safely and simply change queryParams state
  // eslint-disable-next-line
  const qpChangeHandler = useCallback((key: keyof QueryParamsType) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string): void => {
    setQueryParams((base) => {
      const res = { ...base };
      if (typeof e === 'string') {
        res[key] = e;
      } else {
        res[key] = e.target.value;
      }
      return res;
    });
  }, [setQueryParams]);

  const [pageInfo, setPageInfo] = useState<{
    pageNum: number, numPerPage: number, numPages: number,
  }>({
    pageNum: 0, numPerPage: 25, numPages: 0,
  });

  // page info helper to chage num per page
  const numPerPageChange = (num: number) => {
    const res = { ...pageInfo };
    res.numPerPage = num;
    res.pageNum = 0;
    setPageInfo(res);
  };
  // page info helper to validate page number
  const pageValidator = (num: number): number => {
    if (Number.isNaN(num) || num < 0) return 0;
    if (num + 1 >= pageInfo.numPages) return pageInfo.numPages - 1;
    return num;
  };
  // page info helper to change page
  const pageChange = (num: number) => {
    if (Number.isNaN(num)) return;
    const res = { ...pageInfo };
    res.pageNum = pageValidator(num);
    setPageInfo(res);
  };

  // request to backend
  const [fetch, {
    data: searchData, loading: searchLoading, error: searchError,
  }] = useLazyQuery<
    SEARCH_USERS_RES, SEARCH_USERS_VARS
  >(SEARCH_USERS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (d) => {
      if (d.searchByFilters.data && d.searchByFilters.data.length > 0) {
        const res = { ...pageInfo };
        res.numPages = d.searchByFilters.numberPages;
        setPageInfo(res);
      }
    },
  });
  // update page info on data retrieval
  useEffect(() => {
    // console.log(searchData);
    if (searchLoading) return;
    if (!searchData?.searchByFilters.numberPages) {
      setPageInfo({
        pageNum: pageInfo.pageNum, numPerPage: pageInfo.numPerPage, numPages: 0,
      });
      return;
    }
    setPageInfo({
      pageNum: pageInfo.pageNum,
      numPerPage: pageInfo.numPerPage,
      numPages: searchData.searchByFilters.numberPages,
    });
  }, [searchData]);

  // on search string change
  useEffect(() => {
    // update queryParams state
    const parsedSearch = QueryString.parse(history.location.search, undefined);
    setQueryParams(parsedSearch);
    console.log(parsedSearch);
    // query backend if params exist
    if (!history.location.search) return;
    fetch({
      variables: {
        pageNumber: pageInfo.pageNum,
        numberPerPage: pageInfo.numPerPage,
        // communityIdentifier: communityPicker(),
        communityIdentifier: null,
        ...parsedSearch,
        role: (() => {
          if (role === 'user-settings') {
            if (parsedSearch.role === 'All') {
              return null;
            }
            return parsedSearch.role;
          }
          return role;
        })(),
      },
    });
  }, [history.location.search, pageInfo.pageNum, pageInfo.numPerPage]);

  // set styles
  let marginSet = '';
  let marginSetUserSetting = '';
  if (role === 'resident') {
    marginSet = style.removeMargin;
  }
  if (role === 'user-settings') {
    marginSetUserSetting = style.removeMargin;
  }

  // returned values
  return {
    queryParams,
    qpChangeHandler,
    searchRes: searchData?.searchByFilters?.data || [],
    searchLoading,
    searchError: searchData?.searchByFilters.error || searchError?.message || '',
    marginSet,
    marginSetUserSetting,
    role,
    history,
    pageInfo,
    pageChange,
    pageValidator,
    numPerPageChange,
    selected,
    setSelected,
    getRole,
  };
}
