import { useEffect, useState } from 'react';
import {
  ApolloCache, DefaultContext, gql, MutationFunctionOptions, useLazyQuery, useMutation,
} from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PassInfo, { newPassInfo, PassStatuses } from '../../../common_lib_front/types/passInfo';
import { QueryString } from '../../../common_lib_front/utilities/QueryString';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';

const GET_PASS = gql`
query GetPass (
  $number: Float!
) {
  getPassByPassNumber (
    number: $number
  ) {
    success
    error
    data {
      passId
      passInfoId
      userId
      paymentType
      number
      startDate
      endDate
      status
      paid
      url
      vehicle {
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
      }
      addons
    }
  }
}
`;
type GET_PASS_VARS = {
  number: number,
}
type GET_PASS_RES = {
  getPassByPassNumber: backendResponse<{
    passId: string,
    passInfoId: string,
    userId: string,
    paymentType: string,
    number: string,
    startDate: string,
    endDate: string,
    status: string,
    paid: string,
    barCode: string,
    url: string,
    addons: string[],
    vehicle: {
      make: string,
      vehicleModel: string,
      type: string,
      color: string,
      licensePlate: string,
      fleetNumber: string,
      licensePlateState: string,
      primaryDriverName: string,
      licensePrimaryDriver: string,
    },
  }>,
}

const ASSIGN_BAR_CODE = gql`
mutation AssignBarcode (
  $barCode: String!
  $passId: String!
) {
  assignBarcode (
    barCode: $barCode
    passId: $passId
  ) {
    success
    error
  }
}
`;
type ASSIGN_BAR_CODE_VARS = {
  barCode: string,
  passId: string,
}
type ASSIGN_BAR_CODE_RES = {
  assignBarcode: backendResponse<undefined>
}

const GET_USER = gql`
query GetUserInformation (
  $userId: String!
) {
  getUserInformation (
    userId: $userId
  ) {
    success
    error
    data {
      userId
      firstName
      lastName
      roles
      phoneNumber
      address
      email
      country
      city
      state
      zipCode
    }
  }
}
`;
type GET_USER_VARS = {
  userId: string,
}
type GET_USER_RES = {
  getUserInformation: backendResponse<{
    userId: string,
    firstName: string,
    lastName: string,
    roles: string,
    phoneNumber: string,
    address: string,
    email: string,
    country: string,
    city: string,
    state: string,
    zipCode: string,
  }>
}

const ACTIVATE_PASS = gql`
mutation EditPassStatus (
  $status: String!,
  $passId: String!
) {
  editPassStatus (
    status: $status,
    passId: $passId
  ) {
    success
    error
  }
}
`;
type ACTIVATE_PASS_VARS = {
  status: PassStatuses,
  passId: string,
}
type ACTIVATE_PASS_RES = {
  editPassStatus: backendResponse<undefined>
}

type useScanPassRes = {
  history: History | any,
  alert: string,
  loading: boolean,
  passData: PassInfo | null,
  getPassData: (data: number) => void,
  passNumber: number | null,
  setPassNumber: React.Dispatch<React.SetStateAction<number | null>>,
  setBarCode: (value: string) => void,
  saveBarCode: () => void,
  userData: GET_USER_RES['getUserInformation']['data'],
  activatePass: (
    options?: MutationFunctionOptions<
      ACTIVATE_PASS_RES, ACTIVATE_PASS_VARS, DefaultContext, ApolloCache<any>
    > | undefined
  ) => Promise<unknown>,
}
export default function useScanPass(): useScanPassRes {
  const history = useHistory();

  const [passData, setPassData] = useState<PassInfo | null>(null);
  const [alert, setAlert] = useState<string>('');
  const [passNumber, setPassNumber] = useState<number | null>(null);
  const [userData, setUserData] = useState<GET_USER_RES['getUserInformation']['data']>(null);

  const [getUser, { loading: getUserLoading }] = useLazyQuery<
    GET_USER_RES, GET_USER_VARS
  >(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      if (d.getUserInformation.success) {
        setUserData(d.getUserInformation.data);
      } else {
        setUserData(null);
      }
    },
  });

  const [getPassData, { loading: getPassDataLoading }] = useLazyQuery<
    GET_PASS_RES, GET_PASS_VARS
  >(GET_PASS, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      setAlert(e.message);
    },
    onCompleted: (d) => {
      if (d.getPassByPassNumber.success) {
        setAlert('');
        setPassData(
          newPassInfo(d.getPassByPassNumber.data as Partial<PassInfo> | null || undefined),
        );
        getUser({
          variables: {
            userId: d.getPassByPassNumber.data?.userId || '',
          },
        });
      } else {
        setAlert(d.getPassByPassNumber.error);
        setPassData(null);
      }
    },
  });

  const [assignBarCode] = useMutation<
    ASSIGN_BAR_CODE_RES, ASSIGN_BAR_CODE_VARS
  >(ASSIGN_BAR_CODE, {
    onError: (e) => {
      setAlert(e.message);
    },
    onCompleted: (d) => {
      if (d.assignBarcode.success) {
        history.push('/admin/popup/assign-barcode', passData);
      } else {
        setAlert(d.assignBarcode.error);
      }
    },
  });

  const [setPassStatus] = useMutation<
    ACTIVATE_PASS_RES, ACTIVATE_PASS_VARS
  >(ACTIVATE_PASS, {
    onCompleted: (d) => {
      if (d.editPassStatus.success && passData) {
        getPassData({
          variables: {
            number: Number(passData.number) || 0,
          },
        });
      }
    },
  });

  const setBarCode = (value: string) => {
    setPassData({ ...passData!, barCode: value });
  };

  useEffect(() => {
    const parsedSearch = QueryString.parse(history.location.search, undefined);
    if (parsedSearch.passNumber) {
      const number = parseFloat(parsedSearch.passNumber);
      setPassNumber(number);
      getPassData({ variables: { number } });
    }
  }, []);

  return {
    history,
    alert,
    loading: getPassDataLoading || getUserLoading,
    passData,
    getPassData: (data: number) => {
      setPassData(null);
      getPassData({ variables: { number: data } });
    },
    passNumber,
    setPassNumber,
    setBarCode,
    saveBarCode: () => {
      assignBarCode({
        variables: {
          barCode: passData!.barCode,
          passId: passData!.passId,
        },
      });
    },
    userData,
    activatePass: setPassStatus,
  };
}
