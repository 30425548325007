import React, {
  ReactElement, useContext, useEffect, useMemo, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './vendorDetailsTemplate.module.css';
import { ReactComponent as DashboardImg } from '../../assets/dashBoardSubnav.svg';
import { ReactComponent as GuestImg } from '../../assets/guestSubnav.svg';
import { ReactComponent as VendorImg } from '../../assets/vendorSubnav.svg';
import { ReactComponent as ResidentImg } from '../../assets/residentSubnav.svg';
import { ReactComponent as HostImg } from '../../assets/hostSubnav.svg';
import { ReactComponent as UserSettingImg } from '../../assets/userSettingSubnav.svg';
import { ReactComponent as ReportsImg } from '../../assets/reportsSubnav.svg';
import { ReactComponent as SecurityImg } from '../../assets/securitySubnav.svg';
import GenericButton from '../genericButton/genericButton';
import { ReactComponent as DocImg } from '../../assets/fileSmall.svg';

import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';

export default function VendorDetailsTemplate(props: {
  children?: ReactElement | Array<ReactElement>;
}): ReactElement {
  console.log(props);
  const { children } = props;
  const location = useLocation<any>();
  const [role, setRole] = useState<'dashboard' | 'reports' | 'guest' | 'resident' | 'vendor' | 'host' | 'user-setting' | 'security' | 'documents' | ''>('');
  const [showNav, setShowNav] = useState<boolean>(!location.state?.hideNav);
  const { communityId } = useContext(CommunityContext);
  const showDeadLinks = useMemo(() => communityId === 'palmettodunes', [communityId]);

  const hTitle = 'Vendors';
  const roleImgPick = <VendorImg />;
  let invisiableBox;
  let drawerBar;
  let subHeader;
  let infoBox;
  let triangle;
  let icon;
  if (showNav) {
    invisiableBox = role === 'security' ? style.invisiableBoxSecurity : style.invisiableBox;
    drawerBar = style.drawerBar;
    subHeader = style.subHeader;
    infoBox = style.infoBox;
    triangle = style.triangleLeft;
    icon = style.icon;
  } else {
    invisiableBox = role === 'security' ? style.invisableBoxShortSecurity : style.invisableBoxShort;
    drawerBar = style.drawerBarLeft;
    subHeader = style.subHeaderWithoutNav;
    infoBox = style.infoBoxWithoutNav;
    triangle = style.triangleRight;
    icon = style.iconLeft;
  }

  // useEffect(() => {}, [location, role]);
  return (
    <div className={style.box}>
      {/* <div className={invisiableBox} /> */}
      <div className={`${subHeader} white`}>
        <div className={icon}>
          {' '}
          {roleImgPick}
        </div>
        <h1 className={`${style.title} textColorMain`}>
          {hTitle}
        </h1>
      </div>
      <div className={infoBox}>
        {children}
      </div>
    </div>
  );
}
VendorDetailsTemplate.defaultProps = {
  children: undefined,
};
