import React, { ReactElement } from 'react';
import { ReactComponent as AddImg } from '../../assets/add.svg';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import { ReactComponent as EditImg } from '../../assets/edit.svg';
import { ReactComponent as DeleteImg } from '../../assets/delete.svg';
import { ReactComponent as UserImg } from '../../assets/userMobile.svg';
import { ReactComponent as HamburgerImg } from '../../assets/hamburger.svg';
import { ReactComponent as HamburgerCloseImg } from '../../assets/hamburgerClose.svg';
import btnStyle from './genericButton.module.css';

type GenericButtonProps = {
  color?: 'white' | 'blue' | 'yellow' | 'transparent' | 'grey' | 'none',
  size?: 'medium' | 'large' | 'small' | 'none',
  outline?: 'small' | 'large' | 'no-border' | 'none',
  icon?: 'exit' | 'exit-small' | 'add' | 'edit' | 'delete' | 'hamburger' |'hambutger-x' | 'user' | 'none',
  shape?: 'circle' | 'left-circle' | 'right-circle' | 'rectangle' | 'drop-down' | 'view'| 'none',
  type?: 'button' | 'submit' | 'reset',
  disabled?: boolean,
  hidden?: boolean,
	title:string,
  clickHandler?: (() => void) | undefined;
}

export const GenericButton = (props: GenericButtonProps) :ReactElement => {
  const {
    color,
    size,
    outline,
    icon,
    shape,
    type,
    disabled,
    hidden,
    title,
    clickHandler,
  } = props;

  let cName = '';
  let btnImg :any = null;

  // set color style
  switch (color) {
    case 'white':
      cName = ' white textColorMain';
      break;
    case 'blue':
      cName = ' mainColor textColorWhite';
      break;
    case 'yellow':
      cName = ' thirdColor textColorWhite';
      break;
    case 'transparent':
      cName = ` ${btnStyle.transparent} textColorWhite `;
      break;
    case 'grey':
      cName = ' mediumGrey textColorWhite ';
      break;
    default: break;
  }
  // set size style
  switch (size) {
    case 'medium':
      cName += ` ${btnStyle.mediumSize} `;
      break;
    case 'large':
      cName += ` ${btnStyle.largeSize} `;
      break;
    case 'small':
      cName += ` ${btnStyle.smallSize}`;
      break;
    case 'none':
    default:
      break;
  }
  // set outline style
  switch (outline) {
    case 'small':
      cName += ` ${btnStyle.outLineSmall}  `;
      break;
    case 'large':
      cName += ` ${btnStyle.outLineLarge} textColorMain white `;
      break;
    case 'no-border':
      cName += ` ${btnStyle.noBorder} `;
      break;
    case 'none':
    default:
      break;
  }
  // set shape style
  switch (shape) {
    case 'circle':
      cName += ` ${btnStyle.circle} `;
      break;
    case 'left-circle':
      cName += ` ${btnStyle.leftCircle} `;
      break;
    case 'right-circle':
      cName += ` ${btnStyle.rightCircle} `;
      break;
    case 'drop-down':
      cName += ` ${btnStyle.dropDown} `;
      break;
    case 'view':
      cName += ` ${btnStyle.view} white textColorSuperDark `;
      break;
    case 'rectangle':
      cName += ` ${btnStyle.rectangle} `;
      break;
    case 'none':
    default:
      break;
  }
  // set icon style and image
  switch (icon) {
    case 'add':
      cName += ` ${btnStyle.addBtn} textColorSecondary white `;
      btnImg = <AddImg className={` ${btnStyle.addBtnImg} `} />;
      break;
    case 'edit':
      cName += ` ${btnStyle.edit} `;
      btnImg = <EditImg className={` ${btnStyle.editBtnImg} `} />;
      break;
    case 'exit':
      cName += ` ${btnStyle.exitBtn} textColorWhite `;
      btnImg = <CloseImg className={` ${btnStyle.exitBtnImg} `} />;
      break;
    case 'delete':
      cName += ` ${btnStyle.deleteBtn}  `;
      btnImg = <DeleteImg className={` ${btnStyle.deleteBtnImg} `} />;
      break;
    case 'exit-small':
      cName += ` ${btnStyle.exitBtnSmall} textColorWhite `;
      btnImg = <CloseImg className={` ${btnStyle.exitBtnSmallImg}`} />;
      break;
    case 'hamburger':
      // TODO: add style and icon for currently closed mobile menu
      cName += ` ${btnStyle.hamburger} `;
      btnImg = <HamburgerImg className={btnStyle.hamburgerImg} />;
      break;
    case 'hambutger-x':
      // TODO: add style and icon for currently open mobile menu
      cName += ` ${btnStyle.hamburgerClose}  `;
      btnImg = <HamburgerCloseImg className={btnStyle.hamburgerCloseImg} />;
      break;
    case 'user':
      btnImg = <UserImg className={btnStyle.userImg} />;
      break;
    case 'none':
    default:
      break;
  }

  if (disabled) {
    // TODO add style for disabled button here
    cName = `${btnStyle.disabled} textColorWhite mediumGrey`;
  }

  return (
    <div className={btnStyle.btnBox}>
      <button
        className={cName}
        onClick={clickHandler}
        disabled={disabled}
        hidden={hidden}
        type={type}
      >
        {btnImg}
        {title}
      </button>
    </div>
  );
};
GenericButton.defaultProps = {
  color: 'none',
  size: 'none',
  outline: 'none',
  icon: 'none',
  shape: 'rectangle',
  clickHandler: undefined,
  disabled: false,
  type: 'button',
  hidden: false,
};

export default GenericButton;
