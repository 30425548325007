import React, { ReactElement } from 'react';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import GenericButton from '../genericButton/genericButton';
import style from './actionsBtn.module.css';

interface ActionsBtnProps {
  children: ReactElement | Array<ReactElement> | undefined;
  idx: number;
}
export default function ActionsBtn(props: ActionsBtnProps) :ReactElement {
  const { children, idx } = props;
  const { containerId, open, setOpen } = useContainerBlur(`actions-btn-container-${idx}`);
  let btn;
  let stickyTd;
  if (open) {
    btn = style.btnOpen;
    stickyTd = style.tdIndexHigh;
  }
  if (!open) {
    btn = style.btn;
    stickyTd = style.tdIndexLow;
  }

  return (
    <td id={containerId} className={`${style.action} ${stickyTd}`}>
      <div className={btn}>
        <GenericButton
          outline="small"
          title="Actions"
          clickHandler={() => setOpen(!open)}
        />

      </div>
      {
        open
          ? children
          : null
      }
    </td>
  );
}
