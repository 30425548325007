import { gql, useMutation, useQuery } from '@apollo/client';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';

const GET_HOST_DETAILS = gql`
  query GetHostDetails (
    $hostInfoId: String!
    ) {
    getHostDetails (
      hostInfoId: $hostInfoId
    ) {
      success
      error
      data {
        userId
        hostInfoId
        registrationId
        email
        firstName
        lastName
        companyName
        phoneNumber
        address
        city
        state
        zipCode
        ownerPin
      }
    }
  }
`;
export const GET_GUEST_DETAILS = gql`
  query GetMyGuests($pageNumber: Float!, $pageSize: Float!, $userId: String) {
    getMyGuests(pageNumber: $pageNumber, pageSize: $pageSize, userId: $userId) {
      data {
        communityRental {
          address
          arrivalDate
          departureDate
        }
        guestInfo {
          firstName
          email
          lastName
          phoneNumber
        }
        passes {
          paymentType
          passType
          paid
          passId
          passInfoId
          userId
          vehicle {
            vehicleId
            color
            destination
            employeeId
            fleetNumber
            insuranceDocUrl
            isRental
            licensePlate
            licensePlateState
            licensePrimaryDriver
            make
            passId
            primaryDriverName
            registrationDocUrl
            type
            userId
            vehicleModel
            year
          }
          startDate
          endDate
          number
          amtPaid
          status
          registrationId
          barCode
          addons
          url
        }
      }
    }
  }
`;

type HostDetailsInitialization = {
  hostProfileInfo: any;
  getHostLoading: boolean;
  getHostError?: string;
  hostProfileError?: string;
  userGuestInfo:any
  userGuestLoading:boolean
  getUserGuestError?:string;
};

export default function UseHostInfo(): HostDetailsInitialization {
  const { hostInfoId } = useParams<{ hostInfoId: string }>();
  let hostUserId = '';
  const {
    data: hostProfileInfo,
    loading: getHostLoading,
    error: getHostError,
  } = useQuery(GET_HOST_DETAILS, {
    variables: {
      hostInfoId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      console.log(d);
    },
  });
  hostUserId = hostProfileInfo?.getHostDetails?.data[0]?.userId;

  const {
    data: userGuestInfo,
    loading: userGuestLoading,
    error: getUserGuestError,
  } = useQuery(GET_GUEST_DETAILS,
    {
      skip: !hostUserId,
      variables: {
        pageNumber: 0,
        pageSize: 0,
        userId: hostUserId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (d) => {
        console.log(d);
      },
    });

  return {
    getHostLoading: getHostLoading || false,
    getHostError: getHostError?.message || '',
    hostProfileError: hostProfileInfo?.getHostDetails?.error,
    hostProfileInfo: hostProfileInfo?.getHostDetails?.data?.[0] || {},
    userGuestLoading: userGuestLoading || false,
    getUserGuestError: getUserGuestError?.message || '',
    userGuestInfo: userGuestInfo?.getMyGuests?.data || [],

  };
}
