import React, { ReactElement, useState } from 'react';
import style from './checkBox.module.css';

export default function CheckBox(props:any): ReactElement {
  const {
    index, item, isChecked, listMode, normalMode, small, large,
  } = props;
  const [isClick, setIsClick] = useState(false);

  let label;
  if (listMode) {
    label = style.labelList;
  }
  if (normalMode) {
    label = style.labelNormal;
  }

  let checkBox;
  if (small) {
    checkBox = style.checkBoxSmall;
  }
  if (large) {
    checkBox = style.checkBoxLarge;
  }

  return (
    <div>
      {' '}
      <label htmlFor={`${item}`} className={label}>
        <li key={item}>{item}</li>
        <input
          className={ checkBox}
          type="checkbox"
          onChange={() => {
            setIsClick(!isClick);
            isChecked(index, !isClick);
          } }
          id={`${item}`}
        />
      </label>
    </div>
  );
}
