import React, { ReactElement, useState } from 'react';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import GenericButton from '../genericButton/genericButton';
import style from './dataDropDown.module.css';

export default function DateDropDown(props: {
  id: string,
  title: string,
  changeHandler: (name: string, val: string) => void,
  values: Array<string | undefined>,
}) :ReactElement {
  const {
    id, title, changeHandler, values,
  } = props;

  // const [open, setOpen] = useState<boolean>(false);
  const {
    open, setOpen, containerId,
  } = useContainerBlur(`${id}-container`);
  let buttonSelect;
  if (open) {
    buttonSelect = style.buttonSelectUp;
  }
  if (!open) {
    buttonSelect = style.buttonSelectDown;
  }

  return (
    <div id={containerId} className={style.dateDropDownBox}>
      <div className={buttonSelect}>
        <GenericButton
          title={title}
          clickHandler={() => setOpen(!open)}
        />
      </div>
      {
        open
          ? (
            <div className={style.dropDownBox}>
              <div className={style.dateInput}>
                <label
                  htmlFor={`${id}-from-inpt`}
                >
                  From
                  <input
                    type="date"
                    id={`${id}-from-inpt`}
                    value={values[0] || ''}
                    onChange={(e) => {
                      changeHandler('fromDate', e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className={style.dateInput}>
                <label
                  htmlFor={`${id}-to-inpt`}
                >
                  From
                  <input
                    type="date"
                    id={`${id}-to-inpt`}
                    value={values[1] || ''}
                    onChange={(e) => {
                      changeHandler('toDate', e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>
          ) : null
      }
    </div>
  );
}
