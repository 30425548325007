import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { hostInfo as THostInfo, newHostInfo } from '../../../common_lib_front/types/hostInfo';
import { checkNull } from '../../../common_lib_front/utilities/BackendAPI';

const GET_HOST_INFO = gql`
query GetHostInfo (
   $hostInfoId: String!
  ) {
   getHostDetails(
     hostInfoId: $hostInfoId
    ) {
    error
    success
    data {
      ownerPin
      zipCode
      state
      city
      address
      phoneNumber
      lastName
      firstName
      companyName
    }
  }
}
`;

const EDIT_HOST_INFO = gql`
mutation EditHostInfo(
  $hostInfoId: String!,
  $newHostInfo: HostInfoInput!,
) {
  editHostInfo(
    hostInfoId: $hostInfoId,
    newHostInfo: $newHostInfo,
  ) {
    success
    error
  }
}
`;

type TChangeData = Partial<THostInfo>;

type useEditHostInfoRes = {
  hostInfoId: string,
  hostInfo: THostInfo,
  hostInfoLoading: boolean,
  errorMsg: string | undefined,
  changeData: TChangeData,
  editChangeData: (data: TChangeData) => void,
  success: string | undefined,
  onEditHostInfo: () => void,
  editLoading: boolean,
}
export default function useEditHostInfo(): useEditHostInfoRes {
  const { hostInfoId } = useParams<{ hostInfoId: string }>();
  const [changeData, setChangeData] = useState<TChangeData>({});
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const editChangeData = (data: TChangeData) => {
    setChangeData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const {
    data: hostInfo,
    loading: hostInfoLoading,
    refetch: hostInfoRefetch,
  } = useQuery(GET_HOST_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      hostInfoId,
    },
    onError: (err: Error) => {
      setErrorMsg(err.message);
    },
    onCompleted: (d: any) => {
      if (d.GetHostInfo?.error) {
        setErrorMsg(d.GetHostInfo?.error);
      }
    },
  });

  const [doEditHostInfo, {
    data: editData, loading: editLoading,
  }] = useMutation(EDIT_HOST_INFO, {
    onError: (err: Error) => {
      setErrorMsg(err.message);
    },
    onCompleted: (d: any) => {
      if (d.editHostInfo?.success) {
        hostInfoRefetch();
      } else {
        setErrorMsg(d.editHostInfo?.error);
      }
    },
  });

  const onEditHostInfo = () => doEditHostInfo({
    variables: {
      hostInfoId,
      newHostInfo: checkNull(changeData),
    },
  });

  return {
    hostInfoId,
    hostInfo: newHostInfo(hostInfo?.getHostDetails?.data?.[0]) || {},
    hostInfoLoading,
    errorMsg,
    changeData,
    editChangeData,
    success: editData?.editHostInfo?.success || false,
    onEditHostInfo,
    editLoading,
  };
}
