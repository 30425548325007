import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import GenericButton from '../../../components/genericButton/genericButton';
import PhoneNumberShow from '../../../components/phoneNumberShow/phoneNumberShow';
import { PopUp } from '../../../components/popUp/popUp';
import style from './searchNameAddress.module.css';
import useSearchNameAddress, { IUser } from './useSearchNameAddress';

export default function SearchVehicle(): ReactElement {
  const history = useHistory();
  const {
    users,
    pageInfo, pageChange,
    pageValidator,
  } = useSearchNameAddress();

  const getRedirectLink = (i: IUser): string => {
    if (!i.guest?.userId || !i.guest.roles || i.guest.roles.length === 0) {
      return '';
    }

    if (i.guest.roles.length > 1) {
      return `/admin/report-center/user-settings/details/${i.guest.userId}`;
    }

    return `/admin/report-center/${i.guest.roles[0]}/details/${i.guest.userId}`;
  };

  return (
    <PopUp
      close={history.goBack}
    >
      <div className={style.container}>
        <div className={style.tableBox}>
          <table width="100%" cellSpacing="0px" className={style.table}>
            <thead className={`${style.tHead} textColorWhite superDarkGrey`}>
              <tr className={style.tableHeader}>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Property Manager</th>
                <th>Rental Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zip Code</th>
                <th className={style.lastTh}> </th>
              </tr>
            </thead>
            <tbody>
              {
                users?.map((elem) => (
                  <tr key={elem.guest?.userId}>
                    <td data-tip={`${elem.guest?.firstName || ''} ${elem.guest?.lastName || ''}` || '-'}>
                      <ReactTooltip />
                      {`${elem.guest?.firstName || ''} ${elem.guest?.lastName || ''}` || '-'}
                    </td>
                    <td data-tip={elem.guest?.phoneNumber || '-'}>
                      <ReactTooltip />
                      {elem.guest?.phoneNumber
                        ? (
                          <PhoneNumberShow value={elem.guest?.phoneNumber} />
                        ) : '-'}
                    </td>
                    <td data-tip={elem.invitedBy?.companyName || `${elem.invitedBy?.firstName || ''} ${elem.invitedBy?.lastName || ''}`}>
                      <ReactTooltip />
                      {elem.invitedBy?.companyName || `${elem.invitedBy?.firstName || ''} ${elem.invitedBy?.lastName || ''}`}
                    </td>
                    <td data-tip={elem.address || ''}>
                      <ReactTooltip />
                      {elem.address || '-'}
                    </td>
                    <td data-tip={elem.guest?.city || ''}>
                      <ReactTooltip />
                      {elem.guest?.city || '-'}
                    </td>
                    <td data-tip={elem.guest?.state || ''}>
                      <ReactTooltip />
                      {elem.guest?.state || '-'}
                    </td>
                    <td data-tip={elem.guest?.zipCode || ''}>
                      <ReactTooltip />
                      {elem.guest?.zipCode || '-'}
                    </td>
                    <td className={style.buttonTd}>
                      <button
                        className={style.viewInReport__button}
                        onClick={() => {
                          const link = getRedirectLink(elem);
                          if (link) {
                            history.push(link);
                          }
                        }}
                      >
                        View in Admin Center
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className={style.pageBox}>
          <div className={style.pageBtn}>
            <GenericButton
              size="medium"
              color="yellow"
              title="Prev Page"
              clickHandler={() => pageChange(pageInfo.pageNumber - 1)}
            />

          </div>
          <div>
            <label htmlFor="page-num-display">
              <input
                placeholder={(pageInfo.pageNumber + 1).toString()}
                type="text"
                className={style.pageInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const val = Number.parseInt(e.target.value, 10);
                  if (Number.isNaN(val)) return;
                  e.target.value = pageValidator(val).toString();
                  pageChange(val);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.target.value = '';
                }}
              />
              {`/ ${pageInfo.numberPages}`}
            </label>
          </div>
          <div className={style.pageBtn}>
            <GenericButton
              size="medium"
              color="blue"
              title="Next Page"
              clickHandler={() => pageChange(pageInfo.pageNumber + 1)}
            />

          </div>
        </div>
      </div>
    </PopUp>
  );
}
