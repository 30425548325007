import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, {
  ReactElement, useState, useRef, useCallback,
} from 'react';
import LoadingDiamonds from '../../../../components/loadingDiamonds/loadingDiamonds';
import style from './guestList.module.css';
import { passStatusMapTitles, paymentStatusMapTitles } from '../../../../common_lib_front/types/passInfo';
import PopupCellRenderer from './popUpCellRender';

interface PropsType {
  fetchedUserGuests:any[],

}
const defaultColDef = {
  sortable: true,
  resizable: true,
  flex: 1,
  wrapHeaderText: true,
};
export default function GuestList(props: PropsType) :ReactElement {
  const gridRef = useRef<AgGridReact>(null);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const {
    fetchedUserGuests,
  } = props;

  const allNodes: any[] = [];
  gridRef.current?.api?.forEachNode((row) => {
    if (row.displayed) allNodes.push(row);
  });
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const DateRender = ({ value }: { value: string }) => (
    <div>
      {`${new Date(value).getUTCDate()} ${
        months[new Date(value).getUTCMonth()]
      }  ${new Date(value).getUTCFullYear()}`}
    </div>
  );

  const PassStatusRender = ({ value }: { value: string }) => (
    <div>
      {passStatusMapTitles[value] || value}
    </div>
  );
  const onBtnExport = useCallback(() => {
    const exportParams = {
      columnKeys: ['firstName', 'lastName', 'phoneNumber', 'email', 'address', 'startDate', 'endDate', 'passNumber', 'paymentStatus', 'passStatus'],
    };
    gridRef.current?.api.exportDataAsCsv(exportParams);
  }, []);
  const [columnDefs] = useState([
    {
      headerName: 'Actions',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      editable: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: 'Guest Information',
      children: [
        {
          headerName: 'First Name', field: 'firstName', sortable: true, filter: true, minWidth: 180,
        },
        {
          headerName: 'Last Name', field: 'lastName', sortable: true, filter: true, minWidth: 180,
        },
        {
          headerName: 'Phone Number', field: 'phoneNumber', sortable: true, filter: true, minWidth: 180,
        },
        {
          headerName: 'email', field: 'email', sortable: true, filter: true, minWidth: 180,
        },
      ],

    },
    {
      headerName: 'Reservation Information',
      children: [
        {
          headerName: 'Rental Address', field: 'address', sortable: true, filter: true, minWidth: 180,
        },
        {
          headerName: 'Arrival Date', field: 'startDate', cellRendererFramework: DateRender, sortable: true, filter: true, minWidth: 180,
        },
        {
          headerName: 'Departure Date', field: 'endDate', cellRendererFramework: DateRender, sortable: true, filter: true, minWidth: 180,
        },

      ],
    },
    {
      headerName: 'Pass Information',
      children: [

        {
          headerName: 'Pass number', field: 'passNumber', sortable: true, filter: true, minWidth: 180,
        },
        {
          headerName: 'Payment Status', field: 'paymentStatus', sortable: true, filter: true, minWidth: 180,
        },
        {
          headerName: 'Pass Status', field: 'passStatus', cellRendererFramework: PassStatusRender, sortable: true, filter: true, minWidth: 180,
        },
      ],
    },

  ]);

  const rowData = fetchedUserGuests.flatMap((elem) => {
    // I suspect this code might be smelly to the well-trained nose
    if (elem.passes && elem.passes.length > 1) {
      return elem.passes.map((pass:any) => ({
        firstName: elem.guestInfo ? elem.guestInfo.firstName : '',
        lastName: elem.guestInfo ? elem.guestInfo.lastName : '',
        phoneNumber: elem?.guestInfo?.phoneNumber || '',
        email: elem.guestInfo ? elem.guestInfo.email : '',
        address: elem.communityRental ? elem.communityRental.address : '',
        startDate: elem.communityRental
          ? elem.communityRental.arrivalDate.slice(0, 10)
          : '',
        endDate: elem.communityRental
          ? elem.communityRental.departureDate.slice(0, 10)
          : '',
        passNumber: pass.number,
        paymentStatus: paymentStatusMapTitles[pass.paid] || '',
        passStatus: pass.status,
        actions: pass || '',
        // passId: pass.passId,
        // download: elem,
      }));
    }
    return {
      firstName: elem.guestInfo ? elem.guestInfo.firstName : '',
      lastName: elem.guestInfo ? elem.guestInfo.lastName : '',
      phoneNumber: elem?.guestInfo?.phoneNumber || '',
      email: elem.guestInfo ? elem.guestInfo.email : '',
      address: elem.communityRental ? elem.communityRental.address : '',
      passNumber: elem.passes ? elem.passes[0]?.number : '',
      startDate: elem.communityRental
        ? elem.communityRental.arrivalDate.slice(0, 10)
        : '',
      endDate: elem.communityRental
        ? elem.communityRental.departureDate.slice(0, 10)
        : '',
      paymentStatus: elem.passes
        ? paymentStatusMapTitles[elem.passes[0]?.paid] || ''
        : '',
      passStatus: elem.passes ? elem.passes[0]?.status : '',
      actions: elem.passes[0] || '',
      // passId: elem.passes[0],
      // download: elem,
    };
  });

  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: 'calc(100vh - 500px)', width: '100%', boxSizing: 'border-box', padding: '20px',
      }}
    >
      {!fetchedUserGuests ? (
        <div className={style.loadingBox}>
          <LoadingDiamonds
            title="Loading Company Documents"
          />
        </div>
      ) : (
        <>
          <div className={style.topLine}>
            <input
              type="search"
              className={style.gridSearch}
              onChange={onFilterTextChange}
              placeholder="Search anything here .."
            />
            <div className={style.topLineRight}>
              <label htmlFor="page-num-inpt">
                Show
                <select
                  className={style.selectBox}
                  value={itemsPerPage}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setItemsPerPage(Number.parseInt(e.target.value, 10));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                Per Page
              </label>
              <button className={style.btnExport} onClick={onBtnExport}>Export CSV</button>
            </div>

          </div>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            enableCellTextSelection
            ensureDomOrder
            animateRows
            pagination
            ref={gridRef}
            paginationPageSize={itemsPerPage}
            defaultColDef={defaultColDef}
          />
        </>
      )}

    </div>
  );
}
