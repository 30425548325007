import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../../../components/genericButton/genericButton';
import style from './UserInfoSection.module.css';
import InputField from '../../../../components/inputField/inputField';

interface PropsType {
  title: string,
  editable?: string,
  data: { [key:string]: string },
  communityData:{ [key:string]: string },
  hidden?: boolean,
}
export default function InfoStection(props: PropsType) :ReactElement {
  const {
    title,
    editable,
    data,
    hidden,
    communityData,
  } = props;
  if (hidden) return <div />;
  return (
    <div className={style.infoSectionBox}>
      <div className={style.info1}>
        <h3 className={style.subtitle}> Resident Information</h3>
        <div className={style.inputField}>
          <InputField
            boxInput
            highlightOnFocus
            htmlFor="first-name-inpt"
            labelTitle="First Name"
            inputType="text"
            inputValue={data.firstName}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
            boxInput
            highlightOnFocus
            htmlFor="last-name-inpt"
            labelTitle="Last Name"
            inputType="text"
            inputValue={data.lastName}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="email-inpt"
            labelTitle="Email"
            inputType="text"
            inputValue={data.email}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="phone-inpt"
            labelTitle="Phone Number (Primary)"
            inputType="text"
            inputValue={data.phoneNumber}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="alt-phone-inpt"
            labelTitle="Phone Number (Alternate)"
            inputType="text"
            inputValue={data.phoneNumber}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="owner-pin-inpt"
            labelTitle="Owner Verification password"
            inputType="text"
            inputValue={data.ownerPin}
            disabled
          />
        </div>
      </div>
      <div className={style.info1}>
        <h3 className={style.subtitle}> Community Address</h3>
        <div className={style.inputField}>
          <InputField
            boxInput
            highlightOnFocus
            htmlFor="street-inpt"
            labelTitle="Street"
            inputType="text"
            inputValue={communityData.street}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
            boxInput
            highlightOnFocus
            htmlFor="apt-unit-inpt"
            labelTitle="APT/Unit"
            inputType="text"
            inputValue={communityData.unit}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="city-inpt"
            labelTitle="City"
            inputType="text"
            inputValue={communityData.city}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="state-inpt"
            labelTitle="State"
            inputType="text"
            inputValue={communityData.state}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="zip-inpt"
            labelTitle="Zip Code"
            inputType="text"
            inputValue={communityData.zipCode}
            disabled
          />
        </div>

      </div>
      <div className={style.info1}>
        <h3 className={style.subtitle}> Mailing Address</h3>
        <div className={style.inputField}>
          <InputField
            boxInput
            highlightOnFocus
            htmlFor="mailingStreet-inpt"
            labelTitle="MailingStreet"
            inputType="text"
            inputValue={data.mailingStreet}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
            boxInput
            highlightOnFocus
            htmlFor="mailingUnit-inpt"
            labelTitle="APT/Unit"
            inputType="text"
            inputValue={data.mailingUnit}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="mailingCity-inpt"
            labelTitle="City"
            inputType="text"
            inputValue={data.mailingCity}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="mailingState-inpt"
            labelTitle="State"
            inputType="text"
            inputValue={data.mailingState}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="mailingZipCode-inpt"
            labelTitle="Zip Code"
            inputType="text"
            inputValue={data.mailingZipCode}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
// smallSize
            boxInput
            highlightOnFocus
            htmlFor="mailingCountry-inpt"
            labelTitle="Country"
            inputType="text"
            inputValue={data.mailingCountry}
            disabled
          />
        </div>

      </div>
      <div className={style.info1}>
        <h3 className={style.subtitle}> Emergency Contact</h3>
        <div className={style.inputField}>
          <InputField
            boxInput
            highlightOnFocus
            htmlFor="emergency-first-name-inpt"
            labelTitle="First Name"
            inputType="text"
            inputValue={data.emergencyFirstName}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
            boxInput
            highlightOnFocus
            htmlFor="emergency-last-name-inpt"
            labelTitle="Last Name"
            inputType="text"
            inputValue={data.emergencyLastName}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="emergencyEmail-inpt"
            labelTitle="Email"
            inputType="text"
            inputValue={data.emergencyEmail}
            disabled
          />
        </div>
        <div className={style.inputField}>

          <InputField
        // smallSize
            boxInput
            highlightOnFocus
            htmlFor="emergency-phone-number-inpt"
            labelTitle="Phone Number (Primary)"
            inputType="text"
            inputValue={data.emergencyPhoneNumber}
            disabled
          />
        </div>

      </div>
    </div>
  );
}
InfoStection.defaultProps = {
  editable: '',
  hidden: false,

};
