import React, {
  ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { gql, useQuery } from '@apollo/client';
import style from './rapidSearch.module.css';
import GenericButton from '../genericButton/genericButton';

import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import { formatDate } from '../../common_lib_front/utilities/formatDate';

const RecordTypes: { [key: string]: string } = {
  vendor: 'Vendor',
  resident: 'Resident',
  host: 'Host',
  invitedGuest: 'Guest',
  guest: 'Guest',
  fastPass: 'Guest',
  single: 'Single-Use Pass',
};

type GET_PASSES_RES = {
  getAllPasses: backendResponse<{
    passId: string,
    userId: string,
    passType: string,
    number: string,
    startDate: string,
    endDate: string,
    communityRental: {
      address: string,
    },
    userInfo: {
      firstName: string,
      lastName: string,
      roles: string,
      userId: string,
    },
    companyInfo: {
      companyName: string,
    },
    passInfo: {
      name: string,
      portal: string,
    },
    vehicle: {
      licensePlate: string,
    },
  }[]>
}
const GET_PASSES = gql`
query GetPasses {
  getAllPasses {
    success
    error
    data {
      passId
      userId
      passType
      number
      startDate
      endDate
      communityRental {
        address
      }
      userInfo {
        firstName
        lastName
        roles
        userId
      }
      companyInfo {
        companyName
      }
      passInfo {
        name
        portal
      }
      vehicle {
        licensePlate
      }
    }
  }
}
`;

export default function RapidSearch(): ReactElement {
  const location = useLocation<any>();
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const gridRef = useRef<AgGridReact>(null);

  const {
    data, loading, error, refetch,
  } = useQuery<GET_PASSES_RES>(GET_PASSES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const rowData = data?.getAllPasses?.data?.flatMap((item) => ({
    item,
    recordType: RecordTypes[item?.passType] || 'Unknown',
    name: item?.passInfo?.portal === 'vendor' ? `${item?.companyInfo?.companyName || ''}` : `${item?.userInfo?.firstName || ''} ${item?.userInfo?.lastName || ''}`,
    address: item?.communityRental?.address || '',
    licensePlate: item?.vehicle?.licensePlate || '',
    passNumber: item?.number || '',
    passType: item?.passInfo?.name || '',
    startDate: item.startDate ? item.startDate.slice(0, 10) : '',
    endDate: item.endDate ? item.endDate.slice(0, 10) : '',
  }));

  const dateFormatter = ({ value }: { value: string | number }) => formatDate(new Date(value));
  const filterParams = {
    comparator: (filterDate: Date, cellValue: string) => {
      const cellDate = new Date(`${cellValue}T00:00:00`);
      if (cellDate < filterDate) {
        return -1;
      }
      if (cellDate > filterDate) {
        return 1;
      }
      return 0;
    },
  };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    // wrapText: true, causing checkbox to wrap down
    flex: 1,
    wrapHeaderText: true,
  };

  const PassNumber = (item: any) => {
    const { data: { passNumber, item: itemData } } = item;
    return (
      <Link className={style.tableLink} to={{ pathname: `/admin/rapid-search/host/details/${itemData?.passId}` }}>
        {passNumber}
      </Link>
    );
  };

  const Name = (item: any) => {
    const { data: { name, item: itemData } } = item;
    let pathname = '';
    if (itemData?.passInfo?.portal === 'vendor') {
      pathname = `/admin/rapid-search/resident/details/${itemData?.userId}`;
    } else if (itemData?.passInfo?.portal === 'resident') {
      pathname = `/admin/rapid-search/resident/details/${itemData?.userId}`;
    } else if (itemData?.passInfo?.portal === 'guest') {
      pathname = `/admin/rapid-search/guest/details/${itemData?.userId}`;
    } else if (itemData?.passInfo?.portal === 'host') {
      pathname = `/admin/rapid-search/host/details/${itemData?.userId}`;
    }
    return (
      // eslint-disable-next-line no-script-url
      <Link className={style.tableLink} to={{ pathname }}>
        {name}
      </Link>
    );
  };

  const [columnDefs] = useState([
    {
      headerName: 'User Information',
      children: [
        {
          field: 'recordType',
          headerName: 'Record Type',
          minWidth: 200,
        },
        {
          field: 'name',
          cellRendererFramework: Name,
          headerName: 'Name',
          minWidth: 200,
        },
        {
          field: 'address',
          headerName: 'Address/Destination',
          minWidth: 200,
        },
      ],
    },
    {
      headerName: 'Vehicle Information',
      children: [
        {
          field: 'licensePlate',
          headerName: 'Lic Plate #',
          minWidth: 200,
        },
      ],
    },
    {
      headerName: 'Pass Information',
      children: [
        {
          field: 'passNumber',
          cellRendererFramework: PassNumber,
          headerName: 'Pass Number',
          minWidth: 200,
        },
        {
          field: 'passType',
          headerName: 'Pass Type',
          minWidth: 200,
        },
        {
          field: 'startDate',
          filter: 'agDateColumnFilter',
          filterParams,
          valueFormatter: dateFormatter,
          headerName: 'Issue/Arrival Date',
          minWidth: 200,
        },
        {
          field: 'endDate',
          filter: 'agDateColumnFilter',
          filterParams,
          valueFormatter: dateFormatter,
          headerName: 'Expiration/Departure Date',
          minWidth: 200,
        },
      ],
    },
  ]);

  const onBtnExport = useCallback(() => {
    // gridRef.current?.api.exportDataAsCsv({
    //   columnKeys: [
    //     'passNumber',
    //     'companyName',
    //     'vehicleMake',
    //     'destination',
    //     'passType',
    //     'startDate',
    //     'endDate',
    //     'status',
    //     'paymentStatus',
    //   ],
    // });
    const columnKeys = [
      'recordType',
      'name',
      'licensePlate',
      'passNumber',
      'passType',
      'startDate',
      'endDate',
    ];
    const headers: string[] = [
      'Record Type',
      'Name',
      'Address/Destination',
      'Lic Plate #',
      'Pass Number',
      'Pass Type',
      'Issue/Arrival Date',
      'Expiration/Departure Date',
    ];
    const dataToExport: string[] = [headers.join(',')];
    const allNodes: any[] = [];
    gridRef.current?.api.forEachNode((row) => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes.sort((a, b) => a.rowIndex - b.rowIndex).forEach((row: any) => {
      const { data: item } = row;
      const itemData: any[] = [];
      columnKeys.forEach((key: string) => {
        if (key === 'endDate' || key === 'startDate') {
          itemData.push(formatDate(new Date(item[key])));
        } else {
          itemData.push(item[key]);
        }
      });
      dataToExport.push(itemData.join(','));
    });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);

  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };
  return (
    <div className={style.box}>
      <div className={style.infoBox}>
        <div className={style.header}>
          <h1 className={style.title}>Rapid Search</h1>
        </div>
        <div className={style.topLine}>
          <input
            type="search"
            className={style.gridSearch}
            onChange={onFilterTextChange}
            placeholder="Search anything here .."
          />
          <div className={style.topLineRight}>
            <label htmlFor="page-num-inpt">
              Show
              <select
                className={style.selectBox}
                value={itemsPerPage}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setItemsPerPage(Number.parseInt(e.target.value, 10));
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              Per Page
            </label>
            <button className={style.btnExport} onClick={onBtnExport}>Export CSV</button>
          </div>
        </div>
        <div className={style.radiusBox}>
          <div
            className="ag-theme-alpine"
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={ columnDefs }
              defaultColDef={defaultColDef}
              enableCellTextSelection
              ensureDomOrder
              animateRows
              pagination
              paginationPageSize={itemsPerPage}
              // paginationAutoPageSize
              ref={gridRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
