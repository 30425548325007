import { useParams, useHistory } from 'react-router-dom';
import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';

const GET_ALL = gql`
query GetHostInfoAndRentals (
   $hostInfoId: String!
  ) {
   getHostDetails(
     hostInfoId: $hostInfoId
    ) {
    error
    success
    data {
      userId
      hostInfoId
      registrationId
      email
      firstName
      lastName
      companyName
      phoneNumber
      address
      city
      state
      ownerPin
      zipCode
      applicationDate
      licenseStatus
      approvedBy {
        userId
        firstName
        lastName
      }
      passStatus
      numPasses
    }
  }
  getRentalsByHost (
    hostInfoId: $hostInfoId
  ) {
    success
    error
    data {
      rentalUnitId
      companyName
      address
      city
      state
      zipCode
      primaryEmergencyContactName
      primaryEmergencyPhone
      secondaryEmergencyPhone
      email
      passStatus
      startDate
      endDate
    }
  }
}
`;
type GET_ALL_VARS = {
  hostInfoId: string,
}
type hostDetailsRes = {
  userId: string
  hostInfoId: string
  registrationId: string
  email: string
  firstName: string
  lastName: string
  companyName: string
  phoneNumber: string
  address: string
  city: string
  state: string
  ownerPin: string
  zipCode: string
  applicationDate: string
  licenseStatus: string
  approvedBy: {
    userId: string
    firstName: string
    lastName: string
  }
  passStatus: string
  numPasses: string
}
type rentalsByHostRes = {
  rentalUnitId: string
  companyName: string
  address: string
  city: string
  state: string
  zipCode: string
  primaryEmergencyPhone: string
  secondaryEmergencyPhone: string
  email: string
  passStatus: string
  startDate: string
  endDate: string
}
type GET_ALL_RES = {
  getHostDetails: backendResponse<hostDetailsRes[]>,
  getRentalsByHost: backendResponse<rentalsByHostRes[]>
}

const EDIT_HOST_RENTAL_PASSES = gql`
mutation EditHostRentalPasses (
  $rentalUnitIds: [String!]!,
  $passStatus: String!,
  $hostInfoId: String,
) {
  editHostRentalPasses (
    rentalUnitIds: $rentalUnitIds,
    passStatus:$passStatus,
    hostInfoId: $hostInfoId,
  ) {
    success
    error
  }
}
`;

type useReportCenterDetailsRes = {
  hostData: hostDetailsRes | Record<string, never>,
  hostLoading: boolean,
  hostError?: string
  rentalData: Array<rentalsByHostRes>,
  rentalError?: string,
  hostInfoId: string,
  history: History | any,
  checkAllHandler: (checked: boolean) => void;
  passStatusChangeHandler: (newStatus: string) => void;
};
export default function useReportCenterDetails(): useReportCenterDetailsRes {
  const { hostInfoId } = useParams<{ hostInfoId: string }>();
  const history = useHistory();

  // get user info
  const {
    data: hostData, loading: hostLoading, error: hostError, refetch: hostRefetch,
  } = useQuery<
    GET_ALL_RES, GET_ALL_VARS
  >(GET_ALL, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { hostInfoId },
  });

  const [doEditPassStatus, {
    error: editPassError, loading: editPassLoading,
  }] = useMutation(EDIT_HOST_RENTAL_PASSES, {
    onCompleted: () => {
      hostRefetch();
    },
  });

  const checkAllHandler = (checked: boolean) => {
    const boxes = document.getElementsByTagName('input');
    for (let i = 0; i < boxes.length; i += 1) {
      const box = boxes.item(i);
      if (box && box.type === 'checkbox') {
        box.checked = checked;
      }
    }
  };

  const passStatusChangeHandler = (newStatus: string) => {
    const boxes = document.getElementsByTagName('input');
    const rentalUnitIds = [];
    for (let i = 0; i < boxes.length; i += 1) {
      const box = boxes.item(i);
      if (box && box.type === 'checkbox' && box.checked && box.getAttribute('data-rental-id')) {
        rentalUnitIds.push(box.getAttribute('data-rental-id'));
      }
    }
    doEditPassStatus({
      variables: {
        rentalUnitIds,
        passStatus: newStatus,
        hostInfoId,
      },
    });
  };

  return {
    hostData: (() => { // hostData singleton
      try {
        return hostData?.getHostDetails?.data?.[0] || {};
      } catch {
        return {};
      }
    })(), // end hostData
    hostLoading,
    hostError: hostError?.message
      || hostData?.getHostDetails.error
      || undefined,
    rentalData: hostData?.getRentalsByHost?.data
      || [],
    rentalError: hostError?.message
      || hostData?.getHostDetails.error
      || undefined,
    hostInfoId,
    history,
    checkAllHandler,
    passStatusChangeHandler,
  };
}
