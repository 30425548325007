/* eslint-disable max-len */
import React, { ReactElement, useState } from 'react';
import HostInfosection from './hostInfoSection';
import GuestList from './guestList';

import style from './hostGuestDetails.module.css';
import { ReactComponent as LeftNav } from '../../../../assets/leftArrow.svg';
import UseHostInfo from './useHostInfo';
import GenericAlert from '../../../../components/genericAlert/genericAlert';

export default function HostGuestDetails(): ReactElement {
  const {
    hostProfileInfo,
    userGuestInfo,
    hostProfileError,
  } = UseHostInfo();

  const backToReport = () => {
    // TODO : integrated back button url
  };
  return (

    <div className={style.container}>
      <div className={style.infoCard}>
        <div className={style.flexBox}>
          <button
            onClick={backToReport}
            className={style.back__button}
          >
            <LeftNav />
          </button>
          <div className={`${style.header}`}>
            <h3 className={`${style.title}`}>Host Rental Property Manger (Record)</h3>
          </div>
        </div>
        <div className={style.line} />
        <GenericAlert
          title={hostProfileError}
          hidden={!hostProfileError}
          color="red"
        />
        <HostInfosection
          title="Host Information"
          data={hostProfileInfo || {}}
        />
        <div className={style.line} />
        <GuestList fetchedUserGuests={userGuestInfo} />
      </div>
    </div>
  );
}
