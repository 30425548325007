import React, { ReactElement, useState } from 'react';
import GenericButton from '../genericButton/genericButton';
import style from './dropDownUserInfo.module.css';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';

export default function DropDownUserInfo(props: {
  options?: Array<{
    title: string,
    userInfo: string,
  }>
  body?: ReactElement,
  title?: string,
  id:string
}): ReactElement {
  const {
    options, title, body, id,
  } = props;

  const {
    open, setOpen, containerId,
  } = useContainerBlur(`${id}-container`, {
    defaultState: false,
    autoCloseInnerButton: true,
    autoOpen: true,
  });

  let buttonSelect;
  if (open) {
    buttonSelect = style.buttonSelectUp;
  }
  if (!open) {
    buttonSelect = style.buttonSelectDown;
  }

  return (
    <div id={containerId} className={style.infoBox}>
      <div className={buttonSelect}>
        <GenericButton
          title=""
          clickHandler={() => setOpen(!open)}
        />

      </div>
      {
        open
          ? (
            <div className={style.dropDownBox}>
              <h4 className={style.title}>{title}</h4>
              {options?.map((opt) => (
                <ul>
                  <li className={style.title}>{opt.title}</li>
                  <li className={style.info}>{opt.userInfo}</li>
                </ul>
              ))}
              {body}
            </div>
          ) : null
      }
    </div>
  );
}
DropDownUserInfo.defaultProps = {
  title: undefined,
  body: undefined,
  options: undefined,
};
