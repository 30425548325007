import React, { ReactElement } from 'react';
import PhoneInput from 'react-phone-input-2';
import style from './phoneNumberShow.module.css';

export default function PhoneNumberShow({
  value,
}: {
  value: string;
}): ReactElement {
  return (
    <PhoneInput
      value={value}
      disabled
      disableDropdown
      inputClass={style.input}
      buttonClass={style.input}
    />
  );
}
