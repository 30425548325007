import React, { ReactElement } from 'react';
import style from './loadingDiamonds.module.css';

export default function LoadingDiamonds(props: { title?: string }) :ReactElement {
  const { title } = props;
  return (
    <p className={style.loading}>
      <div className={style.loader}>
        <div className={style.one} />
        <div className={style.two} />
        <div className={style.three} />
      </div>
      { title }

    </p>
  );
}
LoadingDiamonds.defaultProps = {
  title: '',
};
