import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const GET_RENTAL = gql`
query GetRental (
  $rentalUnitId: String!
) {
  getRental (
    rentalUnitId: $rentalUnitId
  ) {
    success
    error
    data {
      registrationId
      price
      address
      city
      state
      zipCode
      companyName
      primaryEmergencyPhone
      secondaryEmergencyPhone
      email
    }
  }
}
`;

const DELETE_RENTAL = gql`
mutation Mutation(
  $rentalUnitId: String!
) {
  deleteRental(
    rentalUnitId: $rentalUnitId
  ) {
    success
    error
  }
}
`;

export default function useEditRental() :{
  rentalId: string,
  history: History | any,
  data: any,
  loading: boolean,
  error: string,
  // deleteSuccess: boolean,
  // deleteError: string,
  deleteHandler: () => void,
  } {
  const { rentalId } = useParams<{ rentalId: string }>();
  const history = useHistory();

  const {
    data, loading, error,
  } = useQuery(GET_RENTAL, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'network-only',
    variables: { rentalUnitId: rentalId },
  });

  const [doDelete, {
    data: deleteData, error: deleteError,
  }] = useMutation(DELETE_RENTAL);
  const deleteHandler = () => {
    doDelete({
      variables: { rentalUnitId: rentalId },
    });
  };

  return {
    rentalId,
    history,
    data: (() => {
      try {
        return data.getRental.data[0];
      } catch {
        return {};
      }
    })(),
    deleteHandler,
    loading,
    error: deleteError?.message
      || deleteData?.editRental?.error
      || error?.message
      || data?.getRental?.error
      || '',
    // deleteSuccess: deleteData?.editRental?.success
    //   || false,
    // deleteError: deleteError?.message
    //   || deleteData?.editRental?.error
    //   || '',
  };
}
