import React, { ReactElement } from 'react';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import GenericAlert from '../../../components/genericAlert/genericAlert';
import GenericButton from '../../../components/genericButton/genericButton';
import { PopUp } from '../../../components/popUp/popUp';
import useEditRental from './useDelteteRental';
import alertPic from '../../../assets/alert.svg';
import style from './deleteRental.module.css';

export default function EditRental() :ReactElement {
  const {
    rentalId,
    history,
    data,
    loading,
    error,
    deleteHandler,
  } = useEditRental();
  return (
    <PopUp
      title="Delete Rental"
      close={() => history.goBack()}
    >
      <form
        onSubmit={(e:React.FormEvent) => {
          e.preventDefault();
          deleteHandler();
          history.goBack();
        }}
        className={style.form}
      >
        <div className={style.alert}>
          <img src={alertPic} alt="" className={style.alertPic} />
          You are about to delete this Host Rental. Are you sure you want to continue?
        </div>
        <GenericAlert
          title={error}
          hidden={!error}
          color="red"
        />
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              disabled
              htmlFor="property-address-inpt"
              labelTitle="Property Address"
              inputValue={data.address || ''}
            />
          </div>
        </div>
        <div className={style.inputBox}>
          <div className={style.inputShort}>
            <InputField
              closedInput
              disabled
              htmlFor="city-inpt"
              labelTitle="City"
              inputValue={data.city || ''}
            />

          </div>
          <div className={style.inputShort}>
            <InputField
              closedInput
              disabled
              htmlFor="state-inpt"
              labelTitle="State"
              inputValue={data.state || ''}
            />

          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              disabled
              htmlFor="zip-inpt"
              labelTitle="Zip Code"
              inputValue={data.zipCode || ''}
            />
          </div>

        </div>
        <div className={style.rentalTitleBox}>
          <div className={style.rentalTitle}>
            Rental Management Information
          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              disabled
              htmlFor="company-name-inpt"
              labelTitle="Company Name or Host Name if Self-Managed"
              inputValue={data.companyName || ''}
            />
          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <PhoneInputWrapper
              closedInput
              inputTitle="Primary Emergency Phone Number"
              value={data.primaryEmergencyPhone || ''}
              changeHandler={() => null}
            />
          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <PhoneInputWrapper
              closedInput
              inputTitle="Secondary Emergency Phone Number"
              value={data.secondaryEmergencyPhone || ''}
              changeHandler={() => null}
            />
          </div>

        </div>
        <div className={style.inputBox}>
          <div className={style.inputLong}>
            <InputField
              closedInput
              disabled
              htmlFor="email-inpt"
              labelTitle="Email Address"
              inputValue={data.email || ''}
            />
          </div>

        </div>
        <div className={style.btnBox}>
          <div className={style.btn}>
            <GenericButton
              size="large"
              title="Delete Rental"
              type="submit"
              color="blue"
            />
          </div>

        </div>
      </form>
    </PopUp>
  );
}
