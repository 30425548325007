import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const GET_PASS = gql`
query GetPass (
  $passId: String!
) {
  getPass (
    passId: $passId
  ) {
    success
    error
    data {
      passId
      passInfoId
      userId
      paymentType
      number
      passType
      startDate
      endDate
      status
      paid
      barCode
      url
      addons
      vehicle{registrationDocUrl destination}
    }
  }
}
`;
const GET_VEHICLE = gql`
query GetVehicle (
  $passId: String!
) {
  getVehicle (
    passId: $passId
  ) {
    success
    error
    data {
      userId
      make
      vehicleModel
      color
      year
      licensePlate
      licensePlateState
      fleetNumber
      primaryDriverName
      licensePrimaryDriver
      destination
      driverPhone
      driverEmail
      driversLicenseExp
      driversLicenseImageUrl
    }
  }
}
`;
type RegistrationInitialization = {
  userPassInfo?:any
  userVehicleInfo?:any
  getPassLoading?:boolean
  getVehicleLoading?:boolean

};

export default function UsePassDetails(): RegistrationInitialization {
  const { passId } = useParams<{ passId: string, role:string }>();

  const {
    data: userPassInfo,
    loading: getPassLoading,
    error: getPassError,
  } = useQuery(GET_PASS, {
    variables: { passId },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      console.log('pass', d);
    },
  });
  const {
    data: userVehicleInfo,
    loading: getVehicleLoading,
    error: getVehicleError,
  } = useQuery(GET_VEHICLE, {
    variables: { passId },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      console.log('vehicle', d);
    },
  });
  return {
    userPassInfo: (userPassInfo?.getPass?.data && userPassInfo?.getPass?.data[0])
      ? userPassInfo?.getPass?.data[0] : {},
    userVehicleInfo: userVehicleInfo?.getVehicle.data ? userVehicleInfo?.getVehicle.data : {},
    getPassLoading,
    getVehicleLoading,
  };
}
