import React, { ReactElement, useState } from 'react';
import inptStyle from './inputField.module.css';
// import SchemeDecider from '../../common_lib_front/utilities/schemeDecider';

// const colorStyle = SchemeDecider();

interface InputFieldProps {
  htmlFor: string;
  labelTitle?: string;
  inputType?: string;
  inputPlaceholder?: string;
  inputValue?: string | undefined;
  changeHandler?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  labelStyle?: string;
  inputStyle?: string;
  smallSize?: boolean;
  largeSize?: boolean;
  lightText?: boolean;
  closedInput?: boolean;
  checkBox?: boolean;
  boxInput?: boolean;
  required?: boolean;
  // enables or disables style changeing on fucus and blur
  highlightOnFocus?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  min?: string;
  max?: string;
  name?: string;
  defaultValue?: string;
  checked?: boolean;
}

export const InputField = (props: InputFieldProps): ReactElement => {
  // tracks if the element is currently focused
  const [focused, setFocus] = useState<boolean>(false);

  const {
    labelTitle,
    htmlFor,
    inputType,
    inputPlaceholder,
    changeHandler,
    inputValue,
    labelStyle,
    inputStyle,
    smallSize,
    largeSize,
    lightText,
    closedInput,
    checkBox,
    boxInput,
    required,
    highlightOnFocus, //
    disabled,
    hidden,
    min,
    max,
    name,
    defaultValue,
    checked,
  } = props;

  /// / determine css for component
  let inptCname = inputStyle;
  let labelCname = labelStyle;
  if (smallSize) {
    labelCname = ` ${inptStyle.labelSmall} textColorMedium  `;
    inptCname = ` ${inptStyle.smallSize} textColorSuperDark  `;
  } else if (largeSize) {
    labelCname = ` ${inptStyle.labelLarge} textColorMedium  `;
    inptCname = ` ${inptStyle.largeSize} textColorSuperDark  `;
  } else if (closedInput) {
    labelCname = ` ${inptStyle.labelClosedInput} textColorSuperDark  `;
    inptCname = ` ${inptStyle.closedInput} textColorMedium  `;
  } else if (boxInput) {
    labelCname = ` ${inptStyle.labelClosedInput} textColorSuperDark  `;
    inptCname = ` ${inptStyle.boxInput} textColorMedium  `;
  } else if (checkBox) {
    labelCname = ` ${inptStyle.labelCheckBox} textColorSuperDark  `;
    inptCname = ` ${inptStyle.checkBox} textColorMedium  `;
  }
  if (lightText) {
    labelCname += 'textColorSecondary';
    inptCname += 'textColorSecondary';
  }
  if (focused && highlightOnFocus) {
    // TODO add css for a focused element here
    labelCname += 'textColorMain';
    inptCname += 'textColorMain';
  }

  return (
    <label
      htmlFor={htmlFor}
      className={labelCname}
      hidden={hidden}
    >
      {labelTitle}
      <div className={inptStyle.inputBox}>
        <input
          className={inptCname}
          type={inputType}
          placeholder={inputPlaceholder}
          value={inputValue}
          checked={checked}
          id={htmlFor}
          required={required}
          onChange={changeHandler}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          disabled={disabled}
          min={min}
          max={max}
          name={name}
          defaultValue={defaultValue}
        />
      </div>
    </label>
  );
};
InputField.defaultProps = {
  labelTitle: '',
  inputType: 'text',
  inputPlaceholder: '',
  inputValue: undefined,
  changeHandler: undefined,
  labelStyle: ' default_style',
  inputStyle: ' default_style',
  smallSize: false,
  largeSize: false,
  closedInput: false,
  checkBox: false,

  // I don't like the idea of setting the default
  // value of required to false for an input element
  // but I don't want to break things elsewhere
  required: false,
  boxInput: false,
  lightText: false,
  highlightOnFocus: false,
  disabled: false,
  hidden: false,
  min: undefined,
  max: undefined,
  name: undefined,
  defaultValue: undefined,
  checked: undefined,
};

export default InputField;
